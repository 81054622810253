import React from "react";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";
import { AnalyticsWidget as AnalyticsWidgetComponent } from "../../../components/widgets";

const AnalyticsWidget = props => {
  return (
    <BaseTimeSeriesWidget {...props}>
      {baseProps => {
        return (
          <AnalyticsWidgetComponent {...baseProps} data={baseProps.streams} />
        );
      }}
    </BaseTimeSeriesWidget>
  );
};

export default AnalyticsWidget;
