import React, { useMemo, useState } from "react";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { makeStyles } from "@material-ui/core/styles";

import NumberFormat from "react-number-format";
import ISXUtils from "services/Utils";

import Widget from "../../widget/Widget";
import CSVExporter from "services/CSVExporter";

const ANIMATION_DURATION = 500;

const useStyles = makeStyles({
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: "white",
  },
});

const TagLabel = ({ tag, stacks }) => {
  const stack = stacks?.[tag.stack];

  const dev_session = stack?.dev_session;
  const stackTitle = useMemo(() => {
    const title = (stack?.name || JSON.parse(dev_session || "{}")["stack.label"]) ?? "?";
    return title;
  }, [dev_session]);

  const tagDisplayText = ISXUtils.getTagDisplayText(
    tag.attribute,
    stacks?.[tag.stack]?.data_config,
    true
  );
  return `${stackTitle}:${tagDisplayText}`;
};

const AnimatedTableCell = (props) => {
  const { children, direction, container, ...rest } = props;
  return (
    <TableCell {...rest}>
      <Slide
        container={container}
        direction={direction}
        in={true}
        timeout={ANIMATION_DURATION}
      >
        {children}
      </Slide>
    </TableCell>
  );
};

const PeriodsWidget = (props) => {
  const [direction, setDirection] = useState("right");
  const [animating, setAnimating] = useState(false);
  const animationContainerRef = React.useRef(null);
  const { widget, stacks, periods, data, loading, offset, setOffset } = props;
  const { tags = [], options } = widget;
  const { "periods.tracking": tracking, periods_to_show: periodsToShow = 0 } =
    options ?? {};

  const classes = useStyles();

  const exportToCSV = () => {
    const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

    // columns:
    // - tag
    // - period name
    // - period start in ISO 8601 format
    // - period end in ISO 8601 format
    // - period start in excel-friendly format
    // - period end in excel-friendly format
    // - tracking type ("counts" or "states")
    // - state (if tracking type is "states")
    // - summary unit (% of time, total time, counts; if tracking type is "states"),
    // - value
    const headers = [
      "Tag",
      "Period Name",
      "Period Start ISO 8601",
      "Period End ISO 8601",
      "Period Start",
      "Period End",
      "Tracking Type",
      "State",
      "Summary Unit",
      "Value",
    ];

    const body = tags.flatMap((tag) => {
      const dev_session = stacks?.[tag.stack]?.dev_session ?? "{}";
      const stackTitle = (stacks?.[tag.stack]?.name || JSON.parse(dev_session)["stack.label"]) ?? "?";
      const tagDisplayText = ISXUtils.getTagDisplayText(
        tag.attribute,
        stacks?.[tag.stack]?.data_config,
        false
      );
      const tagLabel = `${stackTitle}:${tagDisplayText}`;

      const trackingType = tracking[tag.guuid]?.trackingType;
      return periods.flatMap((period) => {
        const periodLabel = period.template.period.label ?? "";
        const startISO = period.start.format();
        const endISO = period.end.format();
        const start = period.start.format(DATE_TIME_FORMAT);
        const end = period.end.format(DATE_TIME_FORMAT);
        const dataKey = JSON.stringify([
          tag.stack,
          tag.attribute,
          period.startValue,
          period.endValue,
        ]);
        const value = data[dataKey];
        if (trackingType === "counts") {
          return [
            [
              tagLabel,
              periodLabel,
              startISO,
              endISO,
              start,
              end,
              trackingType,
              null,
              null,
              value,
            ],
          ];
        } else {
          // states
          const entries = Object.entries(value ?? []);
          const summaryUnit = tracking[tag.guuid]?.summaryUnit ?? "percent";
          return entries.map(([label, val]) => {
            let formattedValue;
            if (summaryUnit === "percent") {
              formattedValue = isNaN(val) ? null : Number(val);
            } else if (summaryUnit === "time") {
              formattedValue = moment
                .utc((isNaN(val) ? 0 : val) * 1000)
                .format("HH:mm:ss");
            } else {
              formattedValue = isNaN(val) ? null : Number(val);
            }
            return [
              tagLabel,
              periodLabel,
              startISO,
              endISO,
              start,
              end,
              trackingType ?? "states",
              label,
              summaryUnit,
              formattedValue,
            ];
          });
        }
      });
    });

    const filename = `${(widget.title ?? "Report")
      .replace(/\s+/g, "")
      .replace(/,/g, "-")}.csv`;

    CSVExporter.export(filename, [headers, ...body]);
  };

  const showPrevious = () => {
    setOffset((offset) => offset + Math.max(periodsToShow - 1, 1));
    setDirection("left");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const showNext = () => {
    setOffset((offset) => Math.max(0, offset - Math.max(periodsToShow - 1, 1)));
    setDirection("right");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const reset = () => {
    setOffset(0);
    setDirection("right");
    setAnimating(true);
    setTimeout(() => setAnimating(false), ANIMATION_DURATION);
  };

  const getLabelIndicators = (indicators) => {
    let labelIndicators = {}
    indicators.forEach((i) => {
      if(i?.derivedlabel) {
        if(!labelIndicators[i?.derivedlabel]) {
          labelIndicators[i?.derivedlabel] = []
        }
        labelIndicators[i?.derivedlabel].push(i);
    }})
    return labelIndicators;
  }

  const _computeDisplayOptions = (indicators,value) => {
    let displayOptions = {};
    ISXUtils.buildIndicators(indicators).forEach((op) => {
      displayOptions = op(value) || displayOptions;
    });
    return displayOptions;
  };

  return (
    <Widget
      {...props}
      configurable={true}
      showDerivedTags={false}
      dataType="tags"
      widgetTitle="Multi-tag Report"
      exportToCSV={exportToCSV}
    >
      <div style={{ height: "calc(100% - 36px)", overflow: "auto" }}>
        <Backdrop
          style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
          open={loading && periods.length > 0}
        >
          <Paper style={{ backgroundColor: "white", padding: 10 }}>
            Loading Data...
          </Paper>
        </Backdrop>
        <Table
          ref={animationContainerRef}
          style={{ overflow: animating ? "hidden" : "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.stickyColumn}></TableCell>
              {periods.map((period, idx) => {
                const key = JSON.stringify([
                  period.template.period.guuid,
                  period.template.day,
                  idx,
                ]);
                const range =
                  period.start.year() !== period.end.year() ||
                  period.start.month() !== period.end.month() ||
                  period.start.day() !== period.end.day()
                    ? `${period.start.format(
                        "ddd MMM D HH:mm"
                      )} - ${period.end.format("ddd MMM D HH:mm")}`
                    : `${period.start.format(
                        "ddd MMM D HH:mm"
                      )} - ${period.end.format("HH:mm")}`;
                return (
                  <AnimatedTableCell
                    key={key}
                    direction={direction}
                    container={animationContainerRef.current}
                    style={{ textAlign: "right" }}
                  >
                    <div>
                      <Typography
                        variant="overline"
                        style={{ lineHeight: "1rem" }}
                      >
                        {period.template.period.label || ""}
                      </Typography>
                      <Typography style={{ fontWeight: 600 }}>
                        {range}
                      </Typography>
                    </div>
                  </AnimatedTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((t, idx) => { 
              const indicatorsByLabel = t?.indicator_source === "custom" ? getLabelIndicators(t?.indicators || []) : {}
              return (
              <TableRow key={t.guuid}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.stickyColumn}
                >
                  <TagLabel tag={t} stacks={stacks} />
                </TableCell>
                {periods.map((period, idx) => {
                  const { stack, attribute } = t;
                  const key = JSON.stringify([
                    period.template.period.guuid,
                    period.template.day,
                    stack,
                    attribute,
                    idx,
                  ]);
                  const dataKey = JSON.stringify([
                    stack,
                    attribute,
                    period.startValue,
                    period.endValue,
                  ]);
                  const value = data[dataKey];
                  const trackingType = tracking[t.guuid]?.trackingType;
                  if (trackingType === "counts") {
                    const displayOptions = _computeDisplayOptions(indicatorsByLabel["count"] || [],value);
                    return (
                      <AnimatedTableCell
                        key={key}
                        direction={direction}
                        container={animationContainerRef.current}
                        style={{ textAlign: "right" }}
                      >
                        <div style={{ color: displayOptions?.color, backgroundColor:displayOptions?.bgcolor,}}>
                          <span style={displayOptions?.color ? {fontWeight:'bold'} : {fontWeight:'normal'} }>Counts:&nbsp;
                          {!isNaN(value) ? (
                            <NumberFormat
                              value={value}
                              displayType="text"
                              thousandSeparator={true}
                              allowNegative={true}
                              decimalScale={2}
                            />
                          ) : (
                            <div>-</div>
                          )}
                        </span></div>
                      </AnimatedTableCell>
                    );
                  } else {
                    const entries = Object.entries(value ?? []);
                    const summaryUnit =
                      tracking[t.guuid]?.summaryUnit ?? "percent";
                    return (
                      <AnimatedTableCell
                        key={key}
                        container={animationContainerRef.current}
                        direction={direction}
                        style={{ textAlign: "right" }}
                      >
                        {entries.length > 0 ? (
                          <div>
                            {entries.map(([label, val]) => {
                              const displayOptions = _computeDisplayOptions(indicatorsByLabel[label] || [],val);
                              let formattedValue;
                              if (summaryUnit === "percent") {
                                const numval = Number(val);
                                formattedValue = `${
                                  numval === 100 || numval === 0
                                    ? numval
                                    : isNaN(val)
                                    ? "___"
                                    : val
                                }%`;
                              } else if (summaryUnit === "time") {
                                formattedValue = moment
                                  .utc((isNaN(val) ? 0 : val) * 1000)
                                  .format("HH:mm:ss");
                              } else {
                                formattedValue = isNaN(val) ? "---" : val;
                              }
                              return (
                                <div key={label} style={{ color: displayOptions?.color, backgroundColor:displayOptions?.bgcolor,}} >
                                  <span style={displayOptions?.color ? {fontWeight:'bold'} : {fontWeight:'normal'} }>{label}:&nbsp;{formattedValue}</span>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>-</div>
                        )}
                      </AnimatedTableCell>
                    );
                  }
                })}
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </div>
      <Grid container style={{ height: 36 }} justifyContent="space-between">
        <Grid item>
          <Button onClick={showPrevious} disabled={loading}>
            <NavigateBeforeIcon />
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={reset} disabled={loading || offset === 0}>
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={showNext} disabled={loading || offset === 0}>
            Next <NavigateNextIcon />
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};

export default PeriodsWidget;
