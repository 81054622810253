import React from "react";
import { ManageDashboardsDialog as _ManageDashboardsDialogComponent } from "components";
import { useSelector } from "react-redux";

const ManageDashboardsDialogComponent = React.memo(
  _ManageDashboardsDialogComponent
);

const ManageDashboardsDialog = (props) => {
  const dashboards = useSelector((state) => state.isx.dashboards);
  const user = useSelector((state) => state.isx.user);

  return (
    <ManageDashboardsDialogComponent
      open={props.open}
      removeDialog={props.removeDialog}
      addDashboard={props.addDashboard}
      dashboards={dashboards}
      user={user}
    />
  );
};

export default ManageDashboardsDialog;
