import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import PasswordTextField from "components/PasswordTextField";
import { ISXContext } from "services/Utils";

const PasswordCreateSuccessDialog = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CheckIcon fontSize="large" color="primary" />
          &nbsp;New Password Successfully Created
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="h6">
          You will be sent back to the login page shortly...
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const isxContext = useContext(ISXContext);

  useEffect(() => {
    document.title = `Create New Password | ${isxContext.labels.organization}`;
  }, [isxContext.labels.organization]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{ width: "100%", padding: 20, paddingTop: 56, flex: 1 }}
    >
      <Grid item sm={12} md={6} lg={4} style={{ width: "100%" }}>
        <Grid container spacing={3} align="left">
          <Grid item xs={12}>
            <Typography variant="h6">Create New Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              type="email"
              value={props.username}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              fullWidth
              label="Create New Password"
              onChange={props.handleNewPassword}
              disabled={props.disabled}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              fullWidth
              label="Confirm New Password"
              onChange={props.handleNewPasswordAgain}
              disabled={props.disabled}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.attemptChangePassword}
                disabled={props.disabled || (props.mfa && !props.mfactor)}
              >
                Change Password
              </Button>
            </div>
          </Grid>
          {props.error && (
            <Grid item xs={12}>
              <Typography color="error" align="right">
                {props.error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <PasswordCreateSuccessDialog open={props.successful} />
    </Grid>
  );
};

export default ResetPassword;
