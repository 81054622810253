import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { getRoleComponent } from "services/RolesService";

const useStyles = makeStyles({
  inputs: {
    fontSize: 12,
  },
  inputLabels: {
    fontSize: 12,
  },
});

const TagGeneralAttributes = ({
  attributes,
  tagId,
  tagProperties,
  handleTagPropChange,
  readOnly,
}) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={1}>
      {Object.entries(attributes ?? {}).map(([attr, metadata], idx) => (
        <Grid key={attr} item xs={12} md={6} lg={4}>
          <FormControl fullWidth={true}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <TextField
                  label={metadata.label || attr}
                  fullWidth
                  type={
                    metadata.type === "string" || metadata.type === "number"
                      ? metadata.type
                      : "string"
                  }
                  value={
                    attr
                      .split(".")
                      .reduce((o, i) => o[i] || "", tagProperties) ||
                    metadata.defaultValue ||
                    ""
                  }
                  InputLabelProps={{
                    className: classes.inputLabels,
                  }}
                  InputProps={{
                    className: classes.inputs,
                  }}
                  onChange={(event) =>
                    handleTagPropChange(tagId, attr, event.target.value)
                  }
                  disabled={readOnly || metadata.editable === false}
                />
              </div>
            </div>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

const TagRoleProperties = ({
  tagId,
  tagProperties,
  role,
  handleTagPropChange,
  handleTagPropsChange,
  readOnly,
}) => {
  const TagRoleBespokeComponent = getRoleComponent(role.guuid);
  return TagRoleBespokeComponent ? (
    <TagRoleBespokeComponent
      tagId={tagId}
      tagProperties={tagProperties}
      handleTagPropChange={handleTagPropChange}
      handleTagPropsChange={handleTagPropsChange}
      readOnly={readOnly}
    />
  ) : (
    <TagGeneralAttributes
      attributes={role.attributes}
      tagId={tagId}
      tagProperties={tagProperties}
      handleTagPropChange={handleTagPropChange}
      readOnly={readOnly}
    />
  );
};

export default TagRoleProperties;
export { TagGeneralAttributes };
