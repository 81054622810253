import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// import { FormControl, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
});

class IntervalOption extends React.Component {
  render = () => {
    const options = this.props.options;
    return (
      <FormControl
        fullWidth={true}
        style={{
          flexDirection: "column",
          margin: 24,
          width: "calc(100% - 48px)",
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: 14, fontStyle: "italic" }}
            component="legend"
          >
            Refresh
          </FormLabel>
          <RadioGroup
            aria-label="Interval Type"
            name="interval-type"
            value={options["interval.type"] || "set"}
            row={true}
            onChange={(event) => {
              this.props.setOptions({ "interval.type": event.target.value });
            }}
          >
            <FormControlLabel value="auto" control={<Radio />} label="Auto" />
            <FormControlLabel value="set" control={<Radio />} label="Set" />
          </RadioGroup>
          {options["interval.type"] !== "auto" && (
            <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
              {options["interval.units"] === "seconds" && (
                <TextField
                  id="select-interval-seconds"
                  select
                  label="Length"
                  value={options["interval.value"]}
                  onChange={(event) => {
                    this.props.setOptions({
                      "interval.value": event.target.value,
                    });
                  }}
                  margin="normal"
                  variant="filled"
                  style={{ margin: 6, marginLeft: 0, width: "50%" }}
                  SelectProps={{
                    className: this.props.classes.selectInput,
                  }}
                >
                  <MenuItem key="s5" value="5">
                    5
                  </MenuItem>
                  <MenuItem key="s10" value="10">
                    10
                  </MenuItem>
                  <MenuItem key="s20" value="20">
                    20
                  </MenuItem>
                  <MenuItem key="s30" value="30">
                    30
                  </MenuItem>
                  <MenuItem key="s40" value="40">
                    40
                  </MenuItem>
                  <MenuItem key="s50" value="50">
                    50
                  </MenuItem>
                </TextField>
              )}
              {options["interval.units"] !== "seconds" && (
                <TextField
                  id="standard-number"
                  label="Update interval"
                  value={options["interval.value"]}
                  onChange={(event) => {
                    this.props.setOptions({
                      "interval.value": event.target.value,
                    });
                  }}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  style={{ margin: 6, marginTop: 14, width: "50%" }}
                />
              )}
              <TextField
                id="select-update-time-units"
                select
                label="Units"
                value={options["interval.units"]}
                onChange={(event) => {
                  const val = event.target.value;
                  let update = { "interval.units": val };
                  if (val === "seconds" && options["interval.value"] % 10 > 0) {
                    update["interval.value"] = "10";
                  }
                  this.props.setOptions(update);
                }}
                margin="normal"
                variant="filled"
                style={{ margin: 6, width: "50%" }}
                SelectProps={{
                  className: this.props.classes.selectInput,
                }}
              >
                <MenuItem key="seconds" value="seconds">
                  Seconds
                </MenuItem>
                <MenuItem key="minutes" value="minutes">
                  Minutes
                </MenuItem>
                <MenuItem key="hours" value="hours">
                  Hours
                </MenuItem>
              </TextField>
            </FormControl>
          )}
        </FormControl>
        {/* <FormControl fullWidth={true} style={{ marginTop: 24 }}>
          <FormLabel
            style={{ fontSize: 14, fontStyle: "italic" }}
            component="legend"
          >
            Policy
          </FormLabel>
          <TextField
            id="select-interval-policy"
            select
            value={options["interval.policy"]}
            onChange={event => {
              this.props.setOptions({ "interval.policy": event.target.value });
            }}
            margin="normal"
            variant="filled"
            style={{ margin: 6, marginLeft: 0, width: "100%" }}
            SelectProps={{
              className: this.props.classes.selectInput
            }}
          >
            <MenuItem key="policy-snapshot" value="snapshot">
              Snapshot
            </MenuItem>
            <MenuItem key="policy-average" value="average">
              Average
            </MenuItem>
            <MenuItem key="policy-maximum" value="max">
              Maximum
            </MenuItem>
            <MenuItem key="policy-minimum" value="min">
              Minimum
            </MenuItem>
          </TextField>
        </FormControl> */}
      </FormControl>
    );
  };
}

export default withStyles(styles)(IntervalOption);
