import React, { useState, useContext, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import { ISXContext } from "services/Utils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Auth from "services/Auth";

const LubeCartridgeWidget = (props) => {
  //console.log("in LubeCartridge", Auth.isVerified(), props);
  const isxContext = useContext(ISXContext);
  const widget = props.widget || {};
  const stackId =
    "tags" in widget && widget.tags.length
      ? widget.tags[0].stack
      : (widget.stacks || [])[0];
  const tag =
    "tags" in widget && widget.tags.length ? widget.tags[0].attribute : null;
  const stacks = props.stacks || {};
  const stack = stacks[stackId] || {};
  const tagdc =
    tag && "data_config" in stack && tag in stack["data_config"]
      ? stack["data_config"][tag]
      : null;
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [msg, setMsg] = useState({
    //capacity: tagdc["capacity"] || 250,
    capacity: tagdc && "capacity" in tagdc ? parseInt(tagdc["capacity"]) : 0,
    unitvolume:
      tagdc && "unitvolume" in tagdc ? parseFloat(tagdc["unitvolume"]) : 0.5,
    totalcount:
      tagdc && "totalcount" in tagdc ? parseInt(tagdc["totalcount"] || 0) : 0,
    remaining:
      (tagdc && "capacity" in tagdc ? parseInt(tagdc["capacity"]) : 0) -
      (tagdc && "totalcount" in tagdc
        ? parseInt(tagdc["totalcount"] || 0)
        : 0) *
        (tagdc && "unitvolume" in tagdc ? parseFloat(tagdc["unitvolume"]) : 0),
    statusMsg: null,
  });

  const cartridgeResetLevel = React.createRef();

  const userCred = React.createRef();

  const [requestPending, setRequestPending] = useState(false);
  const saveAttrsTimeout = useRef(null);

  if (stackId) {
    if (tagdc && tagdc.capacity) {
      //console.log("tagdc capacity is", tagdc.capacity, msg);
      const newMsg = { ...msg };
      let changed = false;
      if (
        "capacity" in tagdc &&
        tagdc.capacity &&
        msg.capacity !== parseInt(tagdc.capacity)
      ) {
        newMsg.capacity = parseInt(tagdc.capacity);
        changed = true;
      }
      if (
        "totalcount" in tagdc &&
        tagdc.totalcount !== undefined &&
        msg.totalcount !== parseInt(tagdc.totalcount || 0)
      ) {
        newMsg.totalcount = parseInt(tagdc.totalcount || 0);
        changed = true;
      }
      if (
        "unitvolume" in tagdc &&
        tagdc.unitvolume &&
        msg.unitvolume !== parseFloat(tagdc.unitvolume)
      ) {
        newMsg.unitvolume = parseFloat(tagdc.unitvolume);
        changed = true;
      }
      if (changed) {
        newMsg.remaining =
          newMsg.capacity -
          parseFloat((newMsg.totalcount * newMsg.unitvolume).toFixed(2));
        setMsg(newMsg);
      }
    } else {
      //setMsg({ status: "---" });
      //console.log("tagdc capacity is undefined", tagdc.capacity);
      msg.status = "---";
    }
  }
  //console.log("status and last sent is", msg);

  const handleVerifyDialog = () => setOpenVerifyDialog(true);

  const removeVerifyDialog = () => {
    setVerifyStatus("");
    setOpenVerifyDialog(false);
  };

  const verifyUser = () => {
    //console.log("usercred", userCred.current.value);
    Auth.verify(userCred.current.value).then((rv) => {
      //console.log("rv is", rv);
      if (rv) {
        //console.log("verified");
        setVerifyStatus("User Verified");
        removeVerifyDialog();
        /*saveAttrsTimeout.current = setTimeout(() => {
          removeVerifyDialog();
        }, 2000);*/
        handleConfirmDialog();
      } else {
        console.log("error on verification");
        setVerifyStatus("Unable to Verify, Try Again!");
      }
    });
  };

  const handleConfirmDialog = () => setOpenConfirmDialog(true);

  const removeConfirmDialog = () => {
    const newMsg = { ...msg };
    newMsg.statusMsg = null;
    setMsg(newMsg);
    setRequestPending(false);
    setOpenConfirmDialog(false);
  };

  const resetCartridge = () => {
    if (!cartridgeResetLevel.current.value) {
      console.log("default to maxvalue", tagdc.capacity);
    }
    cartridgeResetLevel.current.value = Math.max(
      0,
      cartridgeResetLevel.current.value
    );
    let resetamount = cartridgeResetLevel.current.value
      ? cartridgeResetLevel.current.value
      : tagdc.capacity;
    console.log(
      "resetting cartridge now",
      resetamount,
      msg.capacity,
      tagdc.capacity
    );
    if (resetamount > msg.capacity) {
      const newMsg = { ...msg };
      newMsg.statusMsg =
        "Enter a value between 0 and " + msg.capacity.toString();
      setMsg(newMsg);
    } else {
      tagdc.totalcount = parseInt(
        (msg.capacity - resetamount) / msg.unitvolume
      );
      //console.log("tag dc is", tagdc);
      stack.data_config[tag] = tagdc;
      //console.log("saving new data config", stack.data_config);
      setRequestPending(true);
      const msgtoSend = {};
      msgtoSend[`data_config.${tag}.totalcount`] = tagdc.totalcount || 0;
      props.updateVolume(stackId, tag, tagdc.totalcount || 0).then((rv) => {
        clearTimeout(saveAttrsTimeout.current);
        if (rv.status === 0) {
          //console.log("tagdc saved");
          const newMsg = { ...msg };
          newMsg.statusMsg =
            "Cartridge Grease Amount successfully reset to " +
            resetamount.toString();
          setMsg(newMsg);
          saveAttrsTimeout.current = setTimeout(() => {
            removeConfirmDialog();
          }, 2000);
        } else {
          console.log("error on saving");
        }
      });
    }
  };
  return (
    <Widget
      {...props}
      dataType="tags"
      maxStacks={1}
      showDerivedTags={false}
      maxTags={1}
      widgetTitle={`${isxContext.labels.deviceCapitalized} Lubrication Cartridge Status`}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={1}
        style={{ overflowY: "auto", height: "100%", padding: 6 }}
      >
        <Grid container justifyContent="center">
          <Typography variant="subtitle2" style={{ fontSize: 12 }}>
            Remaining Volume:&nbsp;
            {Number.parseFloat(msg.remaining).toFixed(2)}
            {" cc ("}
            {((msg.remaining / msg.capacity) * 100.0).toFixed(1)}
            {"%)"}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignitems="center"
          //style={{ paddingLeft: 60, paddingRight: 50 }}
        >
          <svg width="28%" height="100%" viewBox="0 0 470 620">
            <rect
              x="20"
              y="20"
              width="410"
              height="530"
              rx="25"
              stroke="black"
              fill="#009fe9"
              fillOpacity="0.9"
              strokeOpacity="0.8"
              strokeWidth="10"
              strokeLinecap="round"
            />
            <rect
              x="25"
              y="25"
              width="400"
              height={
                (msg.totalcount * msg.unitvolume * 530) / msg.capacity || 0
              }
              rx="25"
              stroke="white"
              fill="white"
              fillOpacity="1.0"
              strokeOpacity="0.1"
              strokeWidth="10"
              strokeLinecap="round"
            />
            <rect
              x="125"
              y="550"
              width="200"
              height="40"
              rx="5"
              stroke="black"
              fill="#009fe9"
              fillOpacity="0.5"
              strokeOpacity="0.8"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <line
              x1="125"
              x2="325"
              y1="570"
              y2="570"
              stroke="gray"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <text
              x="220"
              y={
                120 +
                25 *
                  (8 -
                    (tagdc && "devicetype" in tagdc
                      ? tagdc["devicetype"].length
                      : 6))
              }
              writingMode="tb"
              glyphOrientationVertical="0"
              textLength={
                50 *
                (tagdc && "devicetype" in tagdc
                  ? tagdc["devicetype"].length
                  : 6)
              }
              lengthAdjust="spacing"
              rotate="-90 -90 -90 -90 -90 -90 -90 -90"
              fontSize="4em"
              //fontWeight="bold"
              fontFamily="monospace"
            >
              {tagdc && "devicetype" in tagdc
                ? tagdc["devicetype"].replace("-", "·").toUpperCase()
                : "UNKNOWN"}
            </text>
            <rect
              x="190"
              y={
                75 +
                25 *
                  (8 -
                    (tagdc && "devicetype" in tagdc
                      ? tagdc["devicetype"].length
                      : 6))
              }
              width="60"
              height={
                50 *
                (tagdc && "devicetype" in tagdc
                  ? tagdc["devicetype"].length
                  : 6)
              }
              rx="5"
              stroke="#111111"
              fill="#009fe9"
              fillOpacity="0.2"
              strokeOpacity="0.8"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Grid>
        <Grid container justifyContent="center">
          <Button
            onClick={
              Auth.isVerified() ? handleConfirmDialog : handleVerifyDialog
            }
            disabled={
              props.readOnly ||
              requestPending ||
              !tag ||
              !tagdc ||
              !tagdc.capacity
            }
            /*disabled={
                props.readOnly ||
                requestPending ||
                !haveValidAttributes() ||
                msg.status === "Pending"
              }*/
            variant="contained"
            color="primary"
            style={{ fontSize: "10px" }}
          >
            Reset Lubricant Volume
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openConfirmDialog}
        onClose={removeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {
            "Are you sure you want to reset the lubricant volume in the cartridge?"
          }
        </DialogTitle>
        <DialogContent justify="center">
          <DialogContentText id="confirm-dialog-description">
            Enter Lubricant Volume (cc) - any value between 0 and {msg.capacity}{" "}
            (cartridge capacity)
          </DialogContentText>
          <TextField
            inputRef={cartridgeResetLevel}
            type="number"
            min="0"
            max={msg.capacity}
            InputProps={{
              inputProps: {
                //max: msg.capacity,
                //min: 0,
                placeholder: msg.capacity,
              },
            }}
          />
          {msg.statusMsg && <Typography>{msg.statusMsg}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={resetCartridge}
            color="primary"
            autoFocus
            disabled={props.readOnly || requestPending}
          >
            Confirm
          </Button>
          <Button onClick={removeConfirmDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openVerifyDialog}
        onClose={removeVerifyDialog}
        aria-labelledby="verify-dialog-title"
        aria-describedby="verify-dialog-description"
      >
        <DialogTitle id="verify-dialog-title">
          {"Enter Password to Verify Action"}
        </DialogTitle>
        <DialogContent justify="center">
          <DialogContentText id="verify-dialog-description">
            Please Enter your Account password to verify before proceeding with
            the action
          </DialogContentText>
          <TextField
            type="password"
            inputRef={userCred}
            InputProps={{
              inputProps: {
                placeholder: "Password",
              },
            }}
          />
          {verifyStatus && <Typography>{verifyStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={verifyUser}
            color="primary"
            autoFocus
            //disabled={props.readOnly || requestPending}
          >
            Confirm
          </Button>
          <Button onClick={removeVerifyDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Widget>
  );
};

export default LubeCartridgeWidget;
