import React from "react";
import "chartjs-plugin-annotation";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Column from "react-virtualized/dist/commonjs/Table/Column";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Table from "react-virtualized/dist/commonjs/Table";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import WebWorker from "../../workers/workerSetup";
import stateTableProcessingWorker from "../../workers/stateTableProcessingWorker";
import Widget from "../../widget/Widget";
import CSVExporter from "../../../services/CSVExporter";
import { ChartService } from "../../../services/ChartService";
import { TimeSeriesService } from "../../../services/TimeSeriesService";
import {
  WIDGET_COLORS_ARRAY,
  WIDGET_OFF_ON_COLORS_ARRAY,
  WIDGET_COLORS_DARKGREY,
} from "../../color/colors";
import { TimeframeService } from "../../../services/TimeframeService";

import { default as _isEqual } from "lodash/isEqual";
import { default as _pick } from "lodash/pick";

var Chart = require("chart.js");

const _rowStyle = ({ index }) => {
  let style = { borderBottom: "1px solid #e0e0e0" };
  if (index < 0) {
    style.textTransform = "none";
  } else if (index % 2 === 0) {
    // won't see border without this
    style.zIndex = 1;
  } else {
    style.backgroundColor = "#fafafa";
  }
  style.fontSize = "0.8125rem";
  return style;
};

const convertTimeToLabel = (value) => {
  const duration = moment.duration(value);
  let output = "";
  if (duration.asDays() >= 1) {
    output = output + duration.days() + "d ";
  }
  if (duration.asHours() >= 1) {
    output = output + duration.hours() + "h ";
  }
  if (duration.asMinutes() >= 1) {
    output = output + duration.minutes() + "m ";
  }
  output = output + duration.seconds() + "s";
  return output;
};

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const tagAttributeToRoot = (attr) => {
  const attrParts = attr.split("|"); //check for derived tags
  const derived = attrParts.length > 1;
  return derived ? attrParts[0] : attr;
};

class StateTableWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      loading: false,
      dataStart: null,
      dataEnd: null,
      dataCount: null,
      stateTableData: {},
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        bounds: "ticks",
        elements: { line: { tension: 0 } },
        title: { display: false, text: "" },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              type: "category",
              display: true,
              scaleLabel: { display: true, labelString: "State" },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: { beginAtZero: true },
              scaleLabel: { display: true, labelString: "Percentage" },
            },
          ],
        },
        annotation: {
          events: ["click"],
          annotations: [],
        },
      },
      csv: { filename: "", headers: [], data: [] },
    };

    this.chartRef = React.createRef();
    this.tableRef = React.createRef();
    this.worker = new WebWorker(stateTableProcessingWorker);
    this.worker.addEventListener("message", (event) => {
      this.handleCallback(event.data);
    });
  }

  _getTagStateProps = (tag, props) => {
    const attr = tag?.attribute?.split("|")?.[0];
    const rootAttr = tagAttributeToRoot(attr);
    const stateProps = _pick(
      props.stacks?.[tag?.stack]?.data_config?.[rootAttr] ?? {},
      [
        "states",
        "statesNoDataColor",
        "statesNoDataMinDuration",
        "statesSeriesType",
        "s_int",
      ]
    );
    return stateProps;
  };

  handleCallback = ({ dataset, result }) => {
    dataset.type = "bar";
    dataset.states = result.history;
    //Build full summary data from state table data
    const summary = result.history.reduce(
      (totals, state) => {
        const duration = state.end - state.start;
        totals.durations.set(
          state.id,
          duration +
            (totals.durations.has(state.id)
              ? totals.durations.get(state.id)
              : 0)
        );
        totals.cycles.set(
          state.id,
          1 + (totals.cycles.has(state.id) ? totals.cycles.get(state.id) : 0)
        );
        if (!totals.labels.has(state.id)) {
          totals.labels.set(state.id, state.label);
        }
        return totals;
      },
      { durations: new Map(), cycles: new Map(), labels: new Map() }
    );

    //Build char X-Axis from summary data
    const chartLabelIds = Array.from(summary.labels.keys()).sort(
      (left, right) => left - right
    );

    const newChartOptions = { ...this.state.chartOptions };

    newChartOptions.scales.xAxes[0].labels = chartLabelIds.map((id) =>
      summary.labels.get(id)
    );

    if (summary.durations.size > 0) {
      //Build chart data from summary data
      newChartOptions.scales.yAxes[0].scaleLabel.labelString = "";
      newChartOptions.scales.yAxes[0].ticks = {
        ...newChartOptions.scales.yAxes[0].ticks,
        ...{ callback: (value) => value },
      };
      newChartOptions.tooltips = {
        ...newChartOptions.tooltips,
        ...{
          callbacks: {
            label: (tooltipItem) => tooltipItem.yLabel,
          },
        },
      };

      const SUMMARY_UNIT =
        (this.props.widget.options.statereporting || {}).outputUnit ||
        "percent";

      if (SUMMARY_UNIT === "percent") {
        newChartOptions.scales.yAxes[0].scaleLabel.labelString =
          "Percent of Time";

        const totalDuration = Array.from(summary.durations.values()).reduce(
          (total, current) => total + current
        );

        dataset.data = chartLabelIds.map((id) =>
          ((100 * summary.durations.get(id)) / totalDuration).toFixed(2)
        );
      } else if (SUMMARY_UNIT === "time") {
        //const valueCallbackHandler = value => moment(value).format("hh:mm:ss");
        const valueCallbackHandler = (value) => "";
        const tooltipItemCallbackHandler = (tooltipItem) =>
          convertTimeToLabel(tooltipItem.yLabel);

        newChartOptions.scales.yAxes[0].scaleLabel.labelString = "Total Time";
        newChartOptions.scales.yAxes[0].ticks = {
          ...newChartOptions.scales.yAxes[0].ticks,
          ...{ callback: valueCallbackHandler },
        };
        newChartOptions.tooltips = {
          ...newChartOptions.tooltips,
          ...{
            callbacks: {
              label: tooltipItemCallbackHandler,
            },
          },
        };

        dataset.data = chartLabelIds.map((id) => summary.durations.get(id)); //.map(convertMillisecondsToDuration);
      } else if (SUMMARY_UNIT === "count") {
        newChartOptions.scales.yAxes[0].scaleLabel.labelString = "# of Cycles";
        dataset.data = chartLabelIds.map((id) => summary.cycles.get(id));
      }

      const stateProps = this._getTagStateProps(dataset.tag, this.props);
      const EDGES_ONLY = (stateProps.statesSeriesType ?? "full") === "edges";
      const STATES_FOR_MACHINE = stateProps.states || [];
      const FULL_COLORS = EDGES_ONLY
        ? WIDGET_OFF_ON_COLORS_ARRAY
        : WIDGET_COLORS_ARRAY;
      const noDataColor = stateProps.statesNoDataColor;

      dataset.backgroundColor = newChartOptions.scales.xAxes[0].labels
        .map((label, index) => {
          const state = STATES_FOR_MACHINE.find(
            (state) => state.label === label
          );

          if (!!state) {
            return !!state.color
              ? state.color
              : FULL_COLORS[index % FULL_COLORS.length];
          }
          return !!noDataColor ? noDataColor : WIDGET_COLORS_DARKGREY;
        })
        .map((color) => Chart.helpers.color(color).rgbString());

      dataset.borderColor = dataset.backgroundColor;

      const updateChart =
        newChartOptions.scales.yAxes[0].scaleLabel.labelString !==
          this.state.chartOptions.scales.yAxes[0].scaleLabel.labelString ||
        (!this.state.stateTableData.data && !!dataset.data) ||
        (!!this.state.stateTableData.data && !dataset.data) ||
        this.state.stateTableData.data.length !== dataset.data.length ||
        this.state.stateTableData.data.some((data, index) => {
          const newData = dataset.data[index];
          return data !== newData;
        }) ||
        this.state.stateTableData.backgroundColor.length !==
          dataset.backgroundColor.length ||
        this.state.stateTableData.backgroundColor.some(
          (backgroundColor, index) => {
            const newBackgroundColor = dataset.backgroundColor[index];
            return backgroundColor !== newBackgroundColor;
          }
        );

      const updateTable =
        (!this.state.stateTableData.states && !!dataset) ||
        (!!this.state.stateTableData.states && !dataset.states) ||
        this.state.stateTableData.states.length !== dataset.states.length ||
        this.state.stateTableData.states.some((state, index) => {
          const newState = dataset.states[index];
          return !(
            state.id === newState.id &&
            state.label === newState.label &&
            state.start === newState.start &&
            state.end === newState.end &&
            state.minDuration === newState.minDuration
          );
        });

      if (updateChart || updateTable) {
        this.setState(
          {
            stateTableData: dataset,
            chartOptions: newChartOptions,
            pending: false,
            loading: false,
          },
          () => {
            if (!!this.chartRef && !!this.chartRef.chartInstance) {
              this.chartRef.chartInstance.update();
            }
          }
        );
      } else {
        this.setState(
          {
            pending: false,
            loading: false,
          },
          () => {
            if (!!this.chartRef && !!this.chartRef.chartInstance) {
              this.chartRef.chartInstance.update();
            }
          }
        );
      }
    }
  };

  componentWillUnmount = () => {
    // this is a HACK needed due to bug in react-chartjs-2
    if (!!this.state.stateTableData) {
      Object.keys(this.state.stateTableData._meta || {}).forEach((id) => {
        let meta = this.state.stateTableData._meta[id];
        if (meta.controller == null) {
          delete this.state.stateTableData._meta[id];
        }
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const oldTags = (prevProps.widget || {}).tags || [];
    const newTags = (this.props.widget || {}).tags || [];

    const settingsChanged =
      prevProps.widget?.options?.statereporting?.outputUnit !==
        this.props.widget?.options?.statereporting?.outputUnit ||
      oldTags.length !== newTags.length ||
      oldTags.some(
        (tag, index) =>
          tag.stack !== newTags[index].stack ||
          tag.attribute !== newTags[index].attribute ||
          !_isEqual(
            this._getTagStateProps(tag, prevProps),
            this._getTagStateProps(tag, this.props)
          )
      ) ||
      this.props.startTime !== prevProps.startTime ||
      this.props.endTime !== prevProps.endTime;

    this.populateData(settingsChanged);

    if (!!this.tableRef.current) {
      this.tableRef.current.forceUpdateGrid();
    }

    ChartService.refreshChart(
      this.props.widget,
      this.props.stacks,
      this.state.chartOptions,
      this.chartRef,
      prevProps
    );
  };

  populateData(settingsChanged) {
    const tags = (this.props.widget || {}).tags || [];
    const stacks = this.props.stacks || {};

    const newDatasets = TimeSeriesService.populateDataSets(this.props).map(
      (dataset) => {
        dataset.backgroundColor = dataset.coreColor;
        dataset.borderColor = dataset.coreColor;
        const stateProps = this._getTagStateProps(dataset.tag, this.props);
        dataset.missingDataCutoff =
          stateProps.statesNoDataMinDuration?.type === "set"
            ? (stateProps.statesNoDataMinDuration?.value ?? 0) * 1000
            : 0;
        if (stateProps.s_int) {
          dataset.eventInterval = Math.max(stateProps.s_int, 1) * 1000;
        }
        return dataset;
      }
    );

    if (this.props.options && this.props.options.scales) {
      const units = tags.reduce(
        (units, tag) => {
          const st = stacks[tag.stack] || {};
          const dconfig = st.data_config || {};
          if (st) {
            const a = tag.attribute;
            const aunits = (dconfig[a] || {}).unit || "-";
            units.unique.add(aunits);
            units.all.push(aunits);
          }
          return units;
        },
        { unique: new Set(), all: [] }
      );

      this.props.options.scales.yAxes[0].scaleLabel.labelString =
        ChartService.getYAxesLabels(units.unique, units.all);
    }

    if (this.props.options && this.props.options.title) {
      this.props.options.title.text =
        tags
          .map((t) => t.attribute[0].toUpperCase() + t.attribute.substr(1))
          .join(", ") || "untitled";
    }

    if (!!newDatasets && newDatasets.length > 0) {
      newDatasets.forEach((dataset) => {
        const stateProps = this._getTagStateProps(dataset.tag, this.props);
        const STATES_FOR_MACHINE = stateProps.states ?? [];
        const EDGES_ONLY = (stateProps.statesSeriesType || "full") === "edges";

        const datachanged =
          !!dataset.rawData &&
          dataset.rawData.length > 0 &&
          (!this.state.dataStart ||
            this.state.dataStart !== dataset.rawData[0].x ||
            !this.state.dataEnd ||
            this.state.dataEnd !==
              dataset.rawData[dataset.rawData.length - 1].x ||
            !this.state.dataCount ||
            this.state.dataCount !== dataset.rawData.length);

        const newStart =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData[0].x) ||
          0;
        const newEnd =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData[dataset.rawData.length - 1].x) ||
          0;
        const newCount =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData.length) ||
          0;

        if (datachanged || settingsChanged) {
          this.setState(
            {
              pending: true,
              dataStart: newStart,
              dataEnd: newEnd,
              dataCount: newCount,
            },
            () => {
              setTimeout(() => {
                if (this.state.pending) {
                  this.setState({
                    loading: true,
                  });
                }
              }, 250);
            }
          );
          this.worker.postMessage({
            dataset,
            startTime:
              (!!this.props.startTime && this.props.startTime.valueOf()) ||
              newStart,
            endTime:
              (!!this.props.endTime && this.props.endTime.valueOf()) || newEnd,
            states: STATES_FOR_MACHINE,
            edgesOnly: EDGES_ONLY,
          });
        }
      });
    } else if (this.state.pending) {
      this.setState({
        pending: false,
        loading: false,
      });
    }
  }

  showPrevious = () => {
    this.props.setTimeframe(
      TimeframeService.getPreviousTimeframe(
        this.props.timeframe,
        this.props.startTime,
        this.props.maxWindowInSeconds
      )
    );
  };

  showNext = () => {
    this.props.setTimeframe(
      TimeframeService.getNextTimeframe(
        this.props.timeframe,
        this.props.startTime,
        this.props.maxWindowInSeconds
      )
    );
  };

  reset = () => {
    if (!!this.props.timeframe) {
      const newTimeframe = ((this.props.widget || {}).options || {}).timeframe;
      this.props.setTimeframe(newTimeframe);
    }
  };

  exportToCSV = () => {
    const headers = ["State", "Begin", "End", "Duration in seconds"];
    const data = (this.state.stateTableData.states || []).map((state) => [
      state.label,
      moment(state.start).format(DATE_TIME_FORMAT),
      moment(state.end).format(DATE_TIME_FORMAT),
      (state.end - state.start) / 1000,
    ]);

    const filename = CSVExporter.formatFilename(
      this.props.widgetTitle || "State Table Data",
      this.props.startTime,
      this.props.endTime
    );

    CSVExporter.export(filename, [headers].concat(data));
  };

  render() {
    return (
      <Widget
        {...this.props}
        dataType="tags"
        maxTags={1}
        widgetTitle="State Table"
        exportToCSV={this.exportToCSV}
      >
        <Card style={{ height: "calc(100%)", marginTop: 0, marginBottom: 0 }}>
          <CardContent
            style={{ height: "calc(100% - 32px)", paddingBottom: 16 }}
          >
            <Backdrop
              style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
              open={this.state.loading || this.props.loading}
            >
              <Paper style={{ backgroundColor: "white", padding: 10 }}>
                Calculating...
              </Paper>
            </Backdrop>
            <div style={{ height: 19 }}>
              State Table{" "}
              {!!this.props.startTime
                ? " from " +
                  moment(this.props.startTime)
                    .local()
                    .format(DATE_TIME_FORMAT) +
                  " - " +
                  moment(this.props.endTime).local().format(DATE_TIME_FORMAT)
                : ""}
            </div>

            <Grid
              container
              style={{ height: 36 }}
              justifyContent="space-between"
            >
              <Grid item>
                <Button onClick={this.showPrevious}>
                  <NavigateBeforeIcon />
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.reset}
                  disabled={TimeframeService.disableReset(
                    this.props.timeframe,
                    ((this.props.widget || {}).options || {}).timeframe
                  )}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.showNext}
                  disabled={(this.props.timeframe || {}).type === "moving"}
                >
                  Next <NavigateNextIcon />
                </Button>
              </Grid>
            </Grid>
            <div style={{ height: 150 }}>
              <Bar
                ref={this.chartRef}
                data={{ labels: [], datasets: [this.state.stateTableData] }}
                options={this.state.chartOptions}
              />
            </div>

            <AutoSizer>
              {({ width, height }) => (
                <Table
                  ref={this.tableRef}
                  width={width}
                  height={height - 205}
                  headerHeight={20}
                  rowHeight={40}
                  rowCount={
                    !!this.state.stateTableData.states
                      ? this.state.stateTableData.states.length
                      : 0
                  }
                  rowGetter={({ index }) =>
                    this.state.stateTableData.states[index] || []
                  }
                  rowStyle={_rowStyle}
                >
                  <Column
                    key="label"
                    label="State"
                    dataKey="label"
                    width={75}
                    flexGrow={0}
                  />
                  <Column
                    key="start"
                    label="Begin"
                    dataKey="start"
                    width={75}
                    flexGrow={1}
                    cellRenderer={({ cellData, rowIndex }) => {
                      return moment(cellData).format(DATE_TIME_FORMAT);
                    }}
                  />
                  <Column
                    key="end"
                    label="End"
                    dataKey="end"
                    width={75}
                    flexGrow={1}
                    cellRenderer={({ cellData, rowIndex }) => {
                      return moment(cellData).format(DATE_TIME_FORMAT);
                    }}
                  />
                  <Column
                    key="duration"
                    label="Duration"
                    dataKey="duration"
                    width={75}
                    flexGrow={0}
                    style={{ textAlign: "right", paddingRight: 20 }}
                    cellRenderer={({ cellData, dataKey, rowData, rowIndex }) =>
                      convertTimeToLabel(rowData.end - rowData.start)
                    }
                  />
                </Table>
              )}
            </AutoSizer>
          </CardContent>
        </Card>
      </Widget>
    );
  }
}

export default StateTableWidget;
