import React from "react";
import { AutolubeWidget as AutolubeWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";

class AutolubeWidget extends React.Component {
  requestPending = false;

  render = () => (
    <AutolubeWidgetComponent
      {...this.props}
      setProps={this.setProps}
      setDataConfig={this.setDataConfig}
    />
  );

  setProps = (stackId, props) => {
    return QueryService.sendStackMessage(
      this.props.user.username,
      "",
      stackId,
      JSON.stringify(props),
      this.props?.stacks?.[stackId]?.access_key
    );
  };

  setDataConfig = (stackId, params) => {
    const { username, password } = this.props;
    return QueryService.updateDataConfig(username, password, stackId, params);
  };
}

export default connectWidget(AutolubeWidget);
