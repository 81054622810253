import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { default as _last } from "lodash/last";
import moment from "moment";
import { NotesWidget as NotesWidgetComponent } from "components/widgets";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import { TimeframeService } from "services/TimeframeService";
import QueryService from "services/QueryService";

const DEFAULT_TIMEFRAME_INTERVAL = 10; // "auto" no longer supported, use this instead
const NOTES_REFETCH_INTERVAL = 10000;

const NotesWidget = (props) => {
  const { widget = {} } = props;

  const [notes, setNotes] = useState([]);
  const lastCursor = useRef(null);
  const orgs = useSelector((state) => state.isx.orgs);

  const lastTag = useRef({});
  const lastTimeframe = useRef({});
  const startTime = useRef(null);
  const endTime = useRef(null);
  const refreshPeriod = useRef(); // in ms
  const lastDurationInSeconds = useRef(null);
  const maxWindowInSeconds = useRef(TimeframeService.MAX_EVENTS_WINDOW);

  const notesRefreshInterval = useRef(null);
  const lastNoteTime = useRef(null);
  const lastNotesRefresh = useRef(0);

  const tag = useMemo(
    () => (widget.tags && widget.tags.length > 0 && widget.tags[0]) ?? {},
    [widget.tags]
  );
  const stackid = tag.stack;

  // need to map users to emails
  const users = useMemo(() => {
    return Object.values(orgs).reduce((acc, org) => {
      org.users.reduce((acc, user) => {
        acc[user["userid"]] = user["email"];
        return acc;
      }, acc);
      return acc;
    }, {});
  }, [orgs]);

  // create note then immediately refresh notes
  const createNote = async (content) => {
    await QueryService.createNote(stackid, tag.attribute, content);
    lastNotesRefresh.current = Date.now();
    await updateNotes(lastNoteTime.current ? lastNoteTime.current + 1 : null);
  };

  useEffect(() => {
    // delete interval when component unmounts
    return () => {
      clearInterval(notesRefreshInterval.current);
    };
  }, []);

  useEffect(() => {
    // track last note time as source of cursor for fetching new notes
    lastNoteTime.current = _last(notes)?.[0];
  }, [notes]);

  // update with new notes moving forward in time
  const updateNotes = useCallback(
    async (start) => {
      const results = await QueryService.notesQuery(
        stackid,
        tag.attribute,
        null,
        null,
        start,
        null
      );
      if (tag !== lastTag.current) {
        console.warn(
          "current tag no longer matches, skipping notes update",
          tag,
          lastTag.current
        );
        return;
      }
      if (results.data.length > 0) {
        setNotes((notes) => {
          // possibility of duplicates, so prune existing notes
          const firstNewEntry = results.data[0];
          let idx = notes.length - 1;
          for (; idx >= 0; idx--) {
            if (notes[idx][0] < firstNewEntry[0]) {
              break;
            }
          }
          return [...notes.slice(0, idx + 1), ...results.data];
        });
      }
    },
    [stackid, tag]
  );

  // fetch notes moving backward in time
  const getNotes = useCallback(
    async (cursor, start, end, count, reset) => {
      const results = await QueryService.notesQuery(
        stackid,
        tag.attribute,
        count,
        cursor,
        start,
        end
      );
      if (tag !== lastTag.current) {
        console.debug(
          "current tag no longer matches, skipping notes update",
          tag,
          lastTag.current
        );
        return;
      }
      console.log({ results });
      // TODO: maybe should ensure no duplicates?
      if (reset) {
        setNotes(results.data);
      } else if (results.data.length > 0) {
        setNotes((notes) => [...results.data, ...notes]);
      }
      lastCursor.current = results.cursor;
      lastNotesRefresh.current = Date.now();
    },
    [stackid, tag]
  );

  // fetch next notes page (back in time) using cursor
  const fetchNextPage = useCallback(async () => {
    if (lastCursor.current !== null) {
      await getNotes(lastCursor.current);
    }
  }, [getNotes]);

  useEffect(() => {
    const options = widget.options ?? {};
    const timeframe = options.timeframe ?? {};

    const tagChanged = tag !== lastTag.current;
    if (tagChanged) {
      lastTag.current = tag;
    }

    const timeframeChanged = TimeframeService.timeframeChanged(
      timeframe,
      lastTimeframe.current ?? {}
    );
    if (timeframeChanged) {
      lastTimeframe.current = timeframe;
      lastDurationInSeconds.current = Math.min(
        TimeframeService.getDurationInSeconds(
          timeframe["length.value"],
          timeframe["length.units"]
        ),
        maxWindowInSeconds.current
      );
      if (lastTimeframe.current.type === "fixed") {
        startTime.current = moment.utc(timeframe.start);
        endTime.current = moment
          .utc(startTime.current)
          .add(lastDurationInSeconds.current, "seconds");
      }
      refreshPeriod.current =
        (lastTimeframe.current["interval.type"] !== "auto"
          ? TimeframeService.getDurationInSeconds(
              lastTimeframe.current["interval.value"],
              lastTimeframe.current["interval.units"]
            )
          : DEFAULT_TIMEFRAME_INTERVAL) * 1000;
    }

    if (tagChanged || timeframeChanged) {
      // need to start from beginning
      clearInterval(notesRefreshInterval.current);
      if (stackid && tag.attribute) {
        getNotes(null, null, null, null, true);
        notesRefreshInterval.current = setInterval(() => {
          const now = Date.now();
          if (now >= lastNotesRefresh.current + NOTES_REFETCH_INTERVAL * 0.9) {
            updateNotes(lastNoteTime.current ? lastNoteTime.current + 1 : null);
          } else {
            console.warn(
              "notes fetch defer - too early by",
              NOTES_REFETCH_INTERVAL - (now - lastNotesRefresh.current),
              "ms"
            );
          }
          lastNotesRefresh.current = now;
        }, NOTES_REFETCH_INTERVAL);
      }
    }
  }, [stackid, tag, widget, updateNotes, getNotes]);

  return (
    <NotesWidgetComponent
      {...props}
      tag={tag}
      disabled={!stackid || !tag}
      users={users}
      notes={notes}
      createNote={createNote}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default connectWidget(NotesWidget);
