import React, { useState, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import CancelIcon from "@material-ui/icons/Cancel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { ISXContext } from "services/Utils";
// import { FormControl, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const uuidv4 = require("uuid/v4");

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
});

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

//class MessagingOption extends React.Component {
const MessagingOption = (props) => {
  //render = () => {
  //const options = this.props.options;
  //const [attributes, setAttributes] = useState([]);
  const attributes = props.options.messagePayload || [];
  const isxContext = useContext(ISXContext);
  const buttonName =
    props.options.buttonName ||
    "Send Message to " + isxContext.labels.deviceCapitalized;
  //console.log("attributes are", attributes);
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);

  const handleAttributeTypeMenuOpen = (event) => {
    setTypeAnchorEl(event.currentTarget);
  };

  const handleAttributeTypeMenuClose = () => {
    setTypeAnchorEl(null);
  };

  //console.log("props are", props);
  //const count = this.props.options.count;
  //const messaging = this.props.options.messaging;
  const addAttribute = (type, initialval = null) => {
    let attrs = [...attributes];
    const guuid = uuidv4();
    attrs.push({
      guuid,
      type,
      key: "",
      value: initialval,
    });
    setAttributes(attrs);
    //props.setOptions({ messagePayload: attrs });
    handleAttributeTypeMenuClose();
  };

  const deleteAttribute = (i) => {
    let attrs = [...attributes];
    attrs.splice(i, 1);
    setAttributes(attrs);
    //props.setOptions({ messagePayload: attrs });
  };

  const setAttributes = (attrs) => {
    props.setOptions({ messagePayload: attrs });
  };

  const setButtonName = (name) => {
    props.setOptions({ buttonName: name });
  };
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ height: "100%", padding: 12 }}
    >
      <FormControl component="fieldset">
        <FormLabel
          style={{ fontSize: 14, margin: 0, marginBottom: 14, marginTop: 14 }}
          component="legend"
        >
          Display Options
        </FormLabel>
        <TextField
          id="button-name"
          label="Set Button Name"
          value={buttonName}
          onChange={(event) => {
            const value = event.target.value;
            setButtonName(value);
            props.setOptions({ buttonName: value });
          }}
          type="text"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          style={{ margin: 6, marginTop: 14, width: "50%" }}
        />
      </FormControl>
      <FormLabel
        style={{ fontSize: 14, margin: 0, marginBottom: 14, marginTop: 14 }}
        component="legend"
      >
        Add Message Parameters
      </FormLabel>
      {attributes.length === 0 && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Typography align="left">
              Click on the + Button to add a key/value of the message ------&gt;
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-owns={typeAnchorEl ? "attr-type-menu" : null}
              onClick={handleAttributeTypeMenuOpen}
              //disabled={props.readOnly || requestPending || !stackId}
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Table>
        <TableBody>
          {attributes.map((a, i) => {
            let rowValue;
            if (a.type === "Boolean") {
              rowValue = (
                <Select
                  value={a.value}
                  onChange={(event) => {
                    let attrs = [...attributes];
                    attrs[i].value = event.target.value;
                    setAttributes(attrs);
                  }}
                  style={{
                    fontSize: "inherit",
                    margin: 3,
                  }}
                >
                  <MenuItem value={true} key="attr-type-true">
                    True
                  </MenuItem>
                  <MenuItem value={false} key="attr-type-false">
                    False
                  </MenuItem>
                  )
                </Select>
              );
            } else if (a.type === "Null") {
              rowValue = (
                <Typography style={{ fontWeight: "bold", margin: 3 }}>
                  Null
                </Typography>
              );
            } else {
              rowValue = (
                <TextField
                  id={"select-value=" + a.guuid}
                  label={a.type + " Value"}
                  type={a.type}
                  margin="normal"
                  style={{ margin: 3, width: "100%" }}
                  value={a.value}
                  onChange={(event) => {
                    let attrs = [...attributes];
                    attrs[i].value = event.target.value;
                    setAttributes(attrs);
                  }}
                />
              );
            }
            return (
              <TableRow key={a.guuid}>
                <AttributeTableCell>
                  <TextField
                    id={"select-key-" + a.guuid}
                    label="Key"
                    margin="normal"
                    style={{ margin: 3, width: "100%" }}
                    value={a.key}
                    onChange={(event) => {
                      let attrs = [...attributes];
                      attrs[i].key = event.target.value;
                      setAttributes(attrs);
                    }}
                  />
                </AttributeTableCell>
                <AttributeTableCell>{rowValue}</AttributeTableCell>
                <AttributeTableCell>
                  <IconButton onClick={() => deleteAttribute(i)}>
                    <CancelIcon />
                  </IconButton>
                  {i === attributes.length - 1 && (
                    <IconButton
                      aria-owns={typeAnchorEl ? "attr-type-menu" : null}
                      onClick={handleAttributeTypeMenuOpen}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}
                </AttributeTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Menu
        id="attr-type-menu"
        anchorEl={typeAnchorEl}
        open={Boolean(typeAnchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleAttributeTypeMenuClose}
        transitionDuration={{ enter: 0, exit: 0 }}
      >
        <MenuItem disabled>
          <Typography>Select Type</Typography>
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Text", "")}>
          <ListItemText primary="Text" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Number", "0")}>
          <ListItemText primary="Number" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Boolean", true)}>
          <ListItemText primary="Boolean" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Null")}>
          <ListItemText primary="Null" />
        </MenuItem>
      </Menu>
    </Grid>
  );
};
//};

export default withStyles(styles)(MessagingOption);
