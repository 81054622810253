import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { WIDGET_COLORS_ARRAY } from "../../color/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

import ISXUtils from "services/Utils";

import _ from "lodash";

const uuidv4 = require("uuid/v4");

const DRVTAGDELIMITER = ":drv:";
const DRVTAGDISPLAYDELIMITER = ".";

const styles = (theme) => ({
  exit: {
    textAlign: "right",
    float: "right",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
  },
  cancel: {
    color: "red",
  },
  paper: {
    maxWidth: 1120,
  },
  colorChoiceMenuItem: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  colorChoice: {
    height: 16,
    width: 40,
  },
});

const IndicatorTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  body: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}))(TableCell);

const ColorChoice = styled.div`
  height: 16px;
  width: 40px;
  background-color: ${(props) => props.color};
  border-style: solid;
  border-width: 1px;
  border-color: darkgray;
`;

class IndicatorRow extends React.Component {
  allPropsDefined = () => {
    return (
      this.props.tag &&
      this.props.condition &&
      this.props.value != null &&
      this.props.value !== "" &&
      this.props.options.color
    );
  };

  setIndicatorProp = (event) => {
    this.props.setIndicatorProp(
      this.props.guuid,
      event.target.name,
      event.target.value
    );
  };

  setTagAndDerivedLabelProps = (tagid, derivedlabel) => {
    this.props.setIndicatorProp(this.props.guuid, "tag", tagid);
    this.props.setIndicatorProp(this.props.guuid, "derivedlabel", derivedlabel);
  };

  render = () => {
    const { tags, classes } = this.props;
    const color = this.props.options.color;
    const bgcolor = this.props.options.bgcolor;
    const icon = this.props.options.icon;
    const valuetext = this.props.options.valuetext;
    const indicatorColors =
      color && WIDGET_COLORS_ARRAY.indexOf(color) === -1
        ? [color, ...WIDGET_COLORS_ARRAY]
        : WIDGET_COLORS_ARRAY;
    const indicatorIcons = ["No Icon", "\u2b24", "\u2b25", "\u25a0", "\u25a7"];
    return (
      <TableRow>
        <IndicatorTableCell>
          {tags.length !== 1 ? (
            <Select
              value={
                this.props.tag.indexOf(DRVTAGDELIMITER) == -1
                  ? this.props.tag +
                    (this.props.derivedlabel
                      ? DRVTAGDELIMITER + this.props.derivedlabel
                      : "")
                  : this.props.tag
              }
              inputProps={{ name: "tag", id: "tag" }}
              onChange={this.setIndicatorProp}
              style={{ fontSize: "inherit" }}
            >
              {(tags || []).map((t) => {
                return (
                  <MenuItem
                    value={t.guuid}
                    key={t.guuid + (t.derivedlabel || "")}
                  >
                    {t.label}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Typography>{tags[0].label}</Typography>
          )}
        </IndicatorTableCell>
        <IndicatorTableCell>
          <Select
            value={this.props.condition}
            inputProps={{ name: "condition", id: "condition" }}
            onChange={this.setIndicatorProp}
            style={{ fontSize: "inherit" }}
          >
            <MenuItem value="=">equals</MenuItem>
            <MenuItem value=">">greater than</MenuItem>
            <MenuItem value=">=">greater than or equals</MenuItem>
            <MenuItem value="<">less than</MenuItem>
            <MenuItem value="<=">less than or equals</MenuItem>
          </Select>
        </IndicatorTableCell>
        <IndicatorTableCell>
          <TextField
            id="value"
            name="value"
            value={this.props.value}
            onChange={this.setIndicatorProp}
            type="text"
            margin="normal"
          />
        </IndicatorTableCell>
        <IndicatorTableCell>
          <Select
            value={color}
            inputProps={{ name: "color", id: "color" }}
            onChange={(event) => {
              const color = event.target.value;
              this.props.setIndicatorProp(
                this.props.guuid,
                "options.color",
                color
              );
            }}
            style={{ fontSize: "inherit" }}
          >
            {indicatorColors.map((color) => (
              <MenuItem
                key={color}
                value={color}
                className={classes.colorChoiceMenuItem}
              >
                <ColorChoice color={color} />
              </MenuItem>
            ))}
          </Select>
        </IndicatorTableCell>
        <IndicatorTableCell>
          <Select
            value={icon}
            inputProps={{ name: "icon", id: "icon" }}
            onChange={(event) => {
              const icon =
                event.target.value === "No Icon" ? "" : event.target.value;
              this.props.setIndicatorProp(
                this.props.guuid,
                "options.icon",
                icon
              );
            }}
            style={{ fontSize: "inherit" }}
          >
            {indicatorIcons.map((icon) => (
              <MenuItem
                key={icon}
                value={icon}
                className={classes.colorChoiceMenuItem}
              >
                {icon}
              </MenuItem>
            ))}
          </Select>
        </IndicatorTableCell>

        <IndicatorTableCell>
          <TextField
            id="valuetext"
            name="valuetext"
            placeholder="Type {} for Tag Value"
            value={valuetext}
            onChange={(event) => {
              const valuetext = event.target.value;
              this.props.setIndicatorProp(
                this.props.guuid,
                "options.valuetext",
                valuetext
              );
            }}
            type="text"
            margin="normal"
          />
        </IndicatorTableCell>
        <IndicatorTableCell>
          <Select
            value={bgcolor}
            inputProps={{ name: "bgcolor", id: "bgcolor" }}
            onChange={(event) => {
              const bgcolor = event.target.value;
              this.props.setIndicatorProp(
                this.props.guuid,
                "options.bgcolor",
                bgcolor
              );
            }}
            style={{ fontSize: "inherit" }}
          >
            {indicatorColors.map((bgcolor) => (
              <MenuItem
                key={bgcolor}
                value={bgcolor}
                className={classes.colorChoiceMenuItem}
              >
                <ColorChoice color={bgcolor} />
              </MenuItem>
            ))}
          </Select>
        </IndicatorTableCell>
        <IndicatorTableCell>
          <IconButton
            onClick={() => this.props.deleteIndicator(this.props.guuid)}
          >
            <CancelIcon />
          </IconButton>
          {this.props.addIndicator && (
            <IconButton
              onClick={this.props.addIndicator}
              disabled={!this.allPropsDefined()}
            >
              <AddCircleIcon />
            </IconButton>
          )}
        </IndicatorTableCell>
      </TableRow>
    );
  };
}
IndicatorRow = withStyles(styles)(IndicatorRow);

class WidgetIndicatorDialog extends React.Component {
  constructor(props) {
    super(props);
    const tags = (props.widget || {}).tags || [];
    let indicators = {};
    //let indicatorSource = (props.widget || {}).indicator_source || "tag";
    let indicatorSource = null;
    tags.forEach((t) => {
      const tagIndicators = t.indicators || [];
      if (t.indicator_source && indicatorSource === null) {
        indicatorSource = t.indicator_source;
      }
      tagIndicators.forEach((ti) => {
        const indicatorInfo = {
          ...ti,
          tag: t.guuid,
        };
        indicators[ti.guuid] = indicatorInfo;
      });
    });
    if (!indicatorSource) {
      if (_.isEmpty(indicators)) {
        indicatorSource = "tag";
      } else {
        indicatorSource = "custom";
      }
    }
    if (Object.keys(indicators).length === 0) {
      const indicator = this.createEmptyIndicator();
      indicators[indicator.guuid] = indicator;
    }
    this.state = {
      indicators: indicators,
      indicatorSource: indicatorSource,
    };
  }
  getTagInfo = (tags) => {
    const summaryUnitMap = (s) => {
      return (
        {
          count: " ( Transitions )",
          time: " ( time in s )",
          percent: " ( % time )",
        }[s || "percent"] || ""
      );
    };
    let tagList = [];
    tags.forEach((t) => {
      let thisstack = this.props.stacks[t.stack];
      let label =
        (thisstack?.name ||
          JSON.parse((thisstack || {}).dev_session || "{}")["stack.label"]) +
        ":" +
        ISXUtils.getTagDisplayText(t.attribute, thisstack.data_config);
      tagList.push({ guuid: t.guuid, label: label });
      if (
        this.props?.widget?.options?.["periods.tracking"]?.[t.guuid]
          ?.trackingType === "counts"
      ) {
        tagList.push({
          guuid: t.guuid + DRVTAGDELIMITER + "count",
          //guuid: t.guuid,
          label: label + " (Counts)",
          derivedlabel: "count",
          scale: 1,
        });
      } else {
        let labelSet = new Set(
          (thisstack.data_config?.[t.attribute]?.states || []).map(
            (s) => s.label
          )
        );
        labelSet.forEach((l) => {
          tagList.push({
            guuid: t.guuid + DRVTAGDELIMITER + l,
            //guuid: t.guuid,
            label:
              label +
              DRVTAGDISPLAYDELIMITER +
              l +
              summaryUnitMap(
                this.props?.widget?.options?.["periods.tracking"]?.[t.guuid]
                  ?.summaryUnit
              ),
            derivedlabel: l,
            scale:
              this.props?.widget?.options?.["periods.tracking"]?.[t.guuid]
                ?.summaryUnit === "time"
                ? 60
                : 1,
          });
        });
      }
    });
    return tagList;
  };
  setIndicatorProp = (guuid, prop, value) => {
    const indicators = this.state.indicators;
    let indicator = indicators[guuid];
    if (indicator) {
      if (prop.startsWith("options")) {
        indicator.options = {
          ...indicator.options,
          [prop.split(".")[1]]: value,
        };
      } else {
        indicator = {
          ...indicator,
          [prop]: value,
        };
      }
      this.setState({
        indicators: {
          ...indicators,
          [guuid]: indicator,
        },
      });
    }
  };

  createEmptyIndicator = () => {
    const guuid = uuidv4();
    const widget = this.props.widget;
    const tag = (widget.tags || []).length === 1 ? widget.tags[0].guuid : "";
    return {
      guuid: guuid,
      tag: tag,
      condition: "",
      value: "",
      options: {
        color: "",
        icon: "",
        valuetext: "",
        bgcolor: "",
      },
    };
  };

  addIndicator = () => {
    const indicator = this.createEmptyIndicator();
    this.setState({
      indicators: {
        ...this.state.indicators,
        [indicator.guuid]: indicator,
      },
    });
  };

  deleteIndicator = (guuid) => {
    const indicators = { ...this.state.indicators };
    delete indicators[guuid];
    if (Object.keys(indicators).length === 0) {
      const indicator = this.createEmptyIndicator();
      indicators[indicator.guuid] = indicator;
    }
    this.setState({ indicators: indicators });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.stacks !== prevProps.stacks) {
      this.setState({ stacks: this.props.stacks });
    }
  };

  allIndicatorPropsDefined = (indicator) => {
    return (
      indicator.tag &&
      indicator.condition &&
      indicator.value != null &&
      indicator.value !== "" &&
      indicator.options.color
    );
  };

  updateIndicatorSource = (value) => {
    this.setState({ indicatorSource: value });
  };

  updateWidget = () => {
    let indicators = Object.values(this.state.indicators);
    if (
      indicators.length > 0 &&
      !this.allIndicatorPropsDefined(_.last(indicators))
    ) {
      indicators.pop();
    }
    // now we have to assign indicators to proper tags
    const tags2indicators = {};
    indicators.forEach((i) => {
      let [tagid, derivedlabel] = i.tag.split(DRVTAGDELIMITER);
      if (derivedlabel === undefined && i.derivedlabel) {
        derivedlabel = i.derivedlabel;
      }
      let tagIndicators = tags2indicators[tagid];
      if (!tagIndicators) {
        tagIndicators = tags2indicators[tagid] = [];
      }
      tagIndicators.push({
        guuid: i.guuid,
        condition: i.condition,
        value: i.value,
        options: i.options,
        derivedlabel: derivedlabel ? derivedlabel : null,
      });
    });
    let tags = (this.props.widget.tags || []).slice();
    tags.forEach((t, i) => {
      const tagIndicators = tags2indicators[t.guuid];
      if (tagIndicators) {
        tags[i] = {
          ...t,
          indicators: tagIndicators,
          indicator_source: this.state.indicatorSource,
        };
      } else {
        const { indicators, ...updatedTag } = t;
        updatedTag.indicator_source = this.state.indicatorSource;
        tags[i] = updatedTag;
      }
    });
    const update = {
      tags: tags,
    };
    this.props.updateWidget(update);
    this.props.removeDialog();
  };

  render = () => {
    const { classes = {}, widget = {} } = this.props;
    const indicators = Object.values(this.state.indicators);
    return (
      <Dialog
        fullWidth={true}
        open={this.props.open}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={styles.dialogTitle}>
          Configure Widget Indicators
          <IconButton onClick={this.props.removeDialog} style={styles.exit}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-label="Timeframe Type"
            name="timeframe-type"
            value={this.state.indicatorSource}
            row={true}
            onChange={(event) => {
              const value = event.target.value;
              this.updateIndicatorSource(value);
            }}
          >
            <FormControlLabel
              value="tag"
              control={<Radio />}
              label="Use Tag Settings"
            />
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup>
          {this.state.indicatorSource === "custom" && (
            <Table>
              <TableHead>
                <TableRow>
                  <IndicatorTableCell>Tag</IndicatorTableCell>
                  <IndicatorTableCell>Condition</IndicatorTableCell>
                  <IndicatorTableCell>Trigger Value</IndicatorTableCell>
                  <IndicatorTableCell>Color</IndicatorTableCell>
                  <IndicatorTableCell>Display Icon</IndicatorTableCell>
                  <IndicatorTableCell>Display Text</IndicatorTableCell>
                  <IndicatorTableCell>Background Color</IndicatorTableCell>
                  <IndicatorTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {indicators.map((indicator, idx) => {
                  const addIndicator =
                    idx === indicators.length - 1 ? this.addIndicator : null;
                  /*const tags = (widget.tags || []).map((t) => {
                    return {
                      guuid: t.guuid,
                      stack: this.props.stacks[t.stack],
                      attribute: t.attribute,
                    };
                  });*/
                  return (
                    <IndicatorRow
                      key={indicator.guuid}
                      tags={this.getTagInfo(widget.tags || [])}
                      {...indicator}
                      setIndicatorProp={this.setIndicatorProp}
                      deleteIndicator={this.deleteIndicator}
                      addIndicator={addIndicator}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.removeDialog} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            onClick={this.updateWidget}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(styles)(WidgetIndicatorDialog);
