import React from "react";
import BaseSinglePointWidget from "../base-widgets/BaseSinglePointWidget";
import { DatapointWidget as DatapointWidgetComponent } from "../../../components/widgets";

const DatapointWidget = (props) => {
  return (
    <BaseSinglePointWidget {...props}>
      {(baseProps) => {
        const { color } = baseProps;
        return (
          <DatapointWidgetComponent {...baseProps} color={color || "black"} />
        );
      }}
    </BaseSinglePointWidget>
  );
};

export default DatapointWidget;
