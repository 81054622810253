import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  NewAlarmDialog,
  MessagesDialog,
  ResetPasswordDialog,
} from "containers";
import { InfoDialog } from "components";
import ISXUtils, { ISXContext } from "services/Utils";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import clsx from "clsx";
// for some reason, these don't work - menu fails to pop up
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import MailIcon from "@material-ui/icons/Mail";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PolicyIcon from "@material-ui/icons/Policy";
import SecurityIcon from "@material-ui/icons/Security";
import PersonIcon from "@material-ui/icons/Person";
import Badge from "@material-ui/core/Badge";
import { red } from "@material-ui/core/colors";

const appBarHeight = 64;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    height: appBarHeight,
    backgroundColor: "#ffffff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    background: "#2b323a",
    top: appBarHeight,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  profileButton: {
    height: appBarHeight,
    textTransform: "inherit",
  },
}));

const NavigationBar = (props) => {
  const navigate = useNavigate();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [alarmsDialogOpen, setAlarmsDialogOpen] = useState(false);
  const [messagesDialogOpen, setMessagesDialogOpen] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const isxContext = useContext(ISXContext);

  const classes = useStyles();

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const openInfoDialog = () => {
    setInfoDialogOpen(true);
    handleProfileMenuClose();
  };

  const openProfileDialog = () => {
    navigate("/profile");
    handleProfileMenuClose();
  };

  const closeInfoDialog = () => setInfoDialogOpen(false);

  const openAlarmsDialog = () => {
    setAlarmsDialogOpen(true);
    handleProfileMenuClose();
  };

  const closeAlarmsDialog = () => {
    setAlarmsDialogOpen(false);
  };

  const openMessagesDialog = () => {
    setMessagesDialogOpen(true);
    handleProfileMenuClose();
  };

  const closeMessagesDialog = () => {
    setMessagesDialogOpen(false);
  };

  const openResetPasswordDialog = () => {
    setResetPasswordDialogOpen(true);
    handleProfileMenuClose();
  };

  const closeResetPasswordDialog = () => {
    setResetPasswordDialogOpen(false);
  };

  const logout = () => {
    setAnchorEl(null);
    props.logout();
  };

  const showTermsOfUse = () => {
    window.open(isxContext.termsOfUse, "_blank");
    handleProfileMenuClose();
  };

  const showPrivacyPolicy = () => {
    window.open(isxContext.privacyPolicy, "_blank");
    handleProfileMenuClose();
  };

  const {
    isAuthenticated,
    username,
    user,
    messages,
    login,
    location,
    children,
  } = props;

  const numUserMessages = (messages && Object.keys(messages).length) || 0;

  return (
    <div
      style={{
        width: "100%",
        marginTop: appBarHeight,
        marginLeft: open ? 240 : 0,
      }}
    >
      <AppBar className={clsx(classes.appBar, open)}>
        <Toolbar style={{ overflow: "hidden" }}>
          <InfoDialog open={infoDialogOpen} onClose={closeInfoDialog} />
          {/* <NewAlarmDialog closeAlarmsDialog={closeAlarmsDialog} /> */}
          <MessagesDialog
            open={messagesDialogOpen}
            handleClose={closeMessagesDialog}
          />
          <Link
            href={
              (isxContext.logo && isxContext.logo.link) ||
              "https://www.interstacks.com/"
            }
          >
            <img
              src={
                (isxContext.logo && isxContext.logo.url) ||
                "https://www.interstacks.com/wp-content/themes/boxpress/assets/img/branding/interstacks-logo.png"
              }
              height={(isxContext.logo && isxContext.logo.height) || 72}
              alt="Interstacks Logo"
            />
          </Link>
          {isxContext.logo && isxContext.logo.isxLink && (
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 400,
                marginLeft: "8px",
                marginRight: "8px",
                color: "#E28C05",
                alignSelf: "flex-end",
                fontSize: "0.75rem",
              }}
            >
              <Link href="https://www.interstacks.com/" color="inherit">
                Powered by Interstacks
              </Link>
            </Typography>
          )}
          <Typography style={{ flex: 1 }} />
          {isAuthenticated && (
            <div>
              <Tooltip
                placement="bottom-end"
                title={
                  numUserMessages > 0
                    ? `You have ${numUserMessages} pending message${
                        numUserMessages > 1 ? "s" : ""
                      }`
                    : user.name || username
                }
              >
                <Button
                  className={clsx(classes.profileButton)}
                  aria-owns={anchorEl ? "profile-menu" : null}
                  aria-haspopup="true"
                  onClick={handleProfileMenu}
                >
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item>
                      <Typography variant="body2" component="p">
                        {user.name || username}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        style={{ opacity: 0.66 }}
                        component="p"
                      >
                        {`${isxContext.labels.organization} ${
                          ISXUtils.isUserAdmin(user) ? "Admin" : "User"
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Badge
                    overlap="circular"
                    badgeContent={
                      numUserMessages > 0 ? (
                        <MailIcon
                          color="error"
                          style={{ height: "1rem", color: red[500] }}
                        />
                      ) : (
                        0
                      )
                    }
                  >
                    <AccountCircle style={{ marginLeft: 6, fill: "#999" }} />
                  </Badge>
                </Button>
              </Tooltip>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleProfileMenuClose}
              >
                {numUserMessages > 0 && (
                  <MenuItem onClick={openMessagesDialog}>
                    <ListItemIcon>
                      <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      primary={`${numUserMessages} Message${
                        numUserMessages > 1 ? "s" : ""
                      }`}
                    />
                  </MenuItem>
                )}
                {/* {!user.readOnly && (
                  <MenuItem onClick={openAlarmsDialog}>
                    <ListItemIcon>
                      <AlarmAddIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Set Alerts" />
                  </MenuItem>
                )} */}
                <MenuItem onClick={openInfoDialog}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary={`About ${isxContext.labels.platform}`}
                  />
                </MenuItem>
                <MenuItem onClick={openProfileDialog}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText inset primary={"My Profile"} />
                </MenuItem>
                {isxContext.termsOfUse && (
                  <MenuItem onClick={showTermsOfUse}>
                    <ListItemIcon>
                      <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Terms of Use" />
                  </MenuItem>
                )}
                {isxContext.privacyPolicy && (
                  <MenuItem onClick={showPrivacyPolicy}>
                    <ListItemIcon>
                      <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Privacy Policy" />
                  </MenuItem>
                )}
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Logout" />
                </MenuItem>
              </Menu>
            </div>
          )}
          {/* {!isAuthenticated && location.pathname !== "/login" && (
            <Button
              variant="contained"
              onClick={login}
              style={{ color: "white" }}
            >
              Log In to My Account
            </Button>
          )} */}
        </Toolbar>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            {open && (
              <p
                style={{
                  color: "rgba(255, 255, 255, 0.54902)",
                  position: "absolute",
                  bottom: "72px",
                  left: "16px",
                  fontSize: "12px",
                }}
              >
                <span style={{ opacity: 0.75 }}>Copyright © 2018 </span>
                <span style={{ opacity: 1, fontWeight: 500 }}>Interstacks</span>
              </p>
            )}
          </Drawer>
        </Hidden>
      </AppBar>
      {children}
    </div>
  );
};

export default NavigationBar;
