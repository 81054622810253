import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

const errorMessages = {
  missingBinCount: "Please enter a value for the number of bins",
  outOfRangeBinCount:
    "Please enter a value for the number of bins between 2 and 100",
  missingBinStyle: "Please select uniform or dynamic bin widths",
  //  missingFilterToggle: "Please select whether to filter the values or not",
  missingCutoffValue:
    "If you select to filter the values, please provide a cutoff value"
};

const HisogramOption = props => {
  const options = { ...props.options.histogram };
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    validations();
  }, []);

  function validations() {
    const { binCount, binStyle, highPassFilter, highPassCutoff } = options;
    const newErrors = [];
    props.noErrorDetected("missingBinCount");
    props.noErrorDetected("outOfRangeBinCount");
    props.noErrorDetected("missingBinStyle");
    props.noErrorDetected("missingCutoffValue");

    if (!binCount) {
      newErrors.push(errorMessages.missingBinCount);
      props.errorDetected("missingBinCount");
    } else if (binCount < 2 || binCount > 100) {
      newErrors.push(errorMessages.outOfRangeBinCount);
      props.errorDetected("outOfRangeBinCount");
    }

    if (!binStyle) {
      newErrors.push(errorMessages.missingBinStyle);
      props.errorDetected("missingBinStyle");
    }

    if (
      (!!highPassFilter || highPassFilter === "true") &&
      (!highPassCutoff || highPassCutoff === "")
    ) {
      newErrors.push(errorMessages.missingCutoffValue);
      props.errorDetected("missingCutoffValue");
    }
    setErrors(newErrors);
  }

  function onChange(event) {
    const { name, value, checked } = event.target;
    const interpretedValue = name === "highPassFilter" ? checked : value;
    const newOptions = { ...options, ...{ [name]: interpretedValue } };

    options[name] = interpretedValue;
    validations();

    const filteredOptions = Object.entries(newOptions).reduce((opts, entry) => {
      if (typeof entry[1] !== "undefined" && entry[1] !== "") {
        opts[entry[0]] = entry[1];
      }
      return opts;
    }, {});

    props.setOptions({ histogram: filteredOptions });
  }

  const {
    binCount,
    binStyle,
    highPassFilter,
    highPassCutoff
  } = props.options.histogram;

  return (
    <React.Fragment>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        <FormLabel component="legend">Bin Number and Width</FormLabel>
      </FormControl>
      <FormControl
        style={{
          width: "calc(100% - 48px)",
          margin: 24,
          marginTop: 0,
          marginBottom: 0,
          flexDirection: "row"
        }}
      >
        <TextField
          aria-label="binCount"
          id="binCount"
          name="binCount"
          label="Number of Bins "
          value={binCount}
          onChange={onChange}
          type="number"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          style={{
            margin: 6,
            marginLeft: 0,
            marginRight: 48,
            width: "calc(50% - 48px)"
          }}
        />

        <RadioGroup
          aria-label="binStyle"
          name="binStyle"
          value={binStyle}
          onChange={onChange}
          style={{
            margin: 6,
            marginLeft: 0,
            marginRight: 48,
            width: "50%"
          }}
          row
        >
          <FormLabel
            component="label"
            style={{
              transform: "translate(0, 1.5px) scale(0.75)",
              transformOrigin: "top left",
              position: "absolute"
            }}
          >
            Width of Bins
          </FormLabel>
          <FormControlLabel
            value="variable"
            control={<Radio />}
            label="Dynamic"
            style={{ marginTop: 12 }}
            disabled
          />
          <FormControlLabel
            value="uniform"
            control={<Radio />}
            label="Uniform"
            style={{ marginTop: 12 }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        <FormLabel component="legend">Filter Values</FormLabel>
      </FormControl>
      <FormControl
        style={{
          width: "calc(100% - 48px)",
          margin: 24,
          marginTop: 0,
          flexDirection: "row"
        }}
      >
        <FormControlLabel
          key={highPassFilter}
          control={
            <Checkbox
              checked={highPassFilter}
              onChange={onChange}
              name="highPassFilter"
              value="true"
            />
          }
          label="Filter low values"
          style={{ width: "calc(50% - 48px)", marginRight: 48 }}
          row="true"
        />

        <TextField
          id="highPassCutoff"
          name="highPassCutoff"
          label="Cutoff Value "
          disabled={!highPassFilter}
          value={highPassCutoff || ""}
          onChange={onChange}
          type="number"
          InputLabelProps={{ shrink: true }}
          margin="normal"
          styles={{
            margin: 6,
            marginLeft: 48,
            marginTop: 0,
            width: "calc(50% - 48px)"
          }}
        />
      </FormControl>
      {errors.length > 0 &&
        errors.map((msg, index) => (
          <p
            key={"errormsg-" + index}
            style={{ color: "red", fontSize: "10px" }}
          >
            {msg}
          </p>
        ))}
    </React.Fragment>
  );
};

export default withStyles(styles)(HisogramOption);
