import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
// import {
//   RadioGroup,
//   Radio,
//   FormControl,
//   FormControlLabel,
//   FormLabel,
//   TextField
// } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50%",
  },
});

var errorMessages = {
  axisMinMax: "Please enter a minimum that is lower than the maximum",
  axisFieldCompletion: "Please enter both a minimum and a maximum",
};

class LinearFunction extends React.Component {
  errorMessage = () => {
    let errorFound = false;
    for (let error in this.props.errors) {
      if (error.includes("axis") && !errorFound) {
        errorFound = true;
        return (
          <p style={{ color: "red", fontSize: "10px" }}>
            {errorMessages[error]}
          </p>
        );
      }
    }
  };

  validations = (min, max, type) => {
    let yAxisOptions = this.props.options.yaxis;

    if (type === "auto") {
      this.props.noErrorDetected("axisError");
    } else {
      let currentMin =
        min != null ? Number(min) : Number(yAxisOptions["fixed.min"]);
      let currentMax =
        max != null ? Number(max) : Number(yAxisOptions["fixed.max"]);
      if (min === "") currentMin = null;
      if (max === "") currentMax = null;

      if (currentMin >= currentMax) {
        this.props.errorDetected("axisMinMax");
      } else this.props.noErrorDetected("axisMinMax");

      if (currentMax == null) {
        this.props.errorDetected("axisFieldCompletion");
      } else if (currentMin == null) {
        this.props.errorDetected("axisFieldCompletion");
      } else {
        this.props.noErrorDetected("axisFieldCompletion");
      }
    }
  };

  render = () => {
    const linearfn = this.props.options.linearfn;
    return (
      <React.Fragment>
        <FormControl
          fullWidth={true}
          style={{
            flexDirection: "row",
            margin: 24,
            width: "calc(100% - 48px)",
          }}
        >
          <FormControl style={{ margin: 6, flexDirection: "column" }}>
            <FormLabel
              style={{ fontSize: 14, fontStyle: "italic" }}
              component="legend"
            >
              Scale and Offset
            </FormLabel>
            <RadioGroup
              aria-label="linearfn"
              name="linearfn"
              value={linearfn.type}
              onChange={(event) => {
                const value = event.target.value;
                this.props.setOptions({
                  linearfn: { ...linearfn, type: value },
                });
                //this.validations(null, null, event.target.value);
              }}
            >
              <FormControlLabel value="raw" control={<Radio />} label="Raw" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>

          {linearfn.type === "custom" && (
            <FormControl style={{ margin: 6, flexDirection: "row" }}>
              <TextField
                className={this.props.classes.textField}
                id="slope"
                label="Scale Factor"
                value={linearfn.tagprop ? "" : linearfn["slope"] || "1.0"}
                disabled={linearfn.tagprop}
                onChange={(event) => {
                  const val = event.target.value;
                  this.props.setOptions({
                    linearfn: { ...linearfn, slope: val },
                  });
                  //this.validations(event.target.value, null, "fixed");
                }}
                type="number"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                styles={{ margin: 6 }}
              />

              <TextField
                id="intercept"
                label="Offset"
                value={linearfn.tagprop ? "" : linearfn["intercept"] || "0.0"}
                disabled={linearfn.tagprop}
                onChange={(event) => {
                  const val = event.target.value;
                  this.props.setOptions({
                    linearfn: { ...linearfn, intercept: val },
                  });
                  //this.validations(null, event.target.value, "fixed");
                }}
                type="number"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                styles={{ margin: 6 }}
              />

              <FormControlLabel
                name="defaults"
                label="Use Tag Settings"
                control={
                  <Checkbox
                    checked={linearfn.tagprop}
                    onChange={(event) => {
                      const value = event.target.checked;
                      this.props.setOptions({
                        linearfn: { ...linearfn, tagprop: value },
                      });
                      //this.validations(null, null, event.target.value);
                    }}
                    name="tagDefault"
                  />
                }
              />
            </FormControl>
          )}
        </FormControl>
      </React.Fragment>
    );
  };
}
export default withStyles(styles)(LinearFunction);
