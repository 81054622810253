import React, { useEffect, useRef } from "react";
import { TableWidget as TableWidgetComponent } from "components/widgets";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import ISXUtils from "services/Utils";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";

const TableWidget = (props) => {
  const lastTags = useRef(null);
  const indicators = useRef({});

  const resolveIndicators = (tag, value) => {
    const tindicators = indicators.current[tag] || [];
    let color = null;
    tindicators.forEach((op) => {
      color = op(value) || color;
    });
    return color;
  };

  useEffect(() => {
    const _buildIndicators = (tags) => {
      indicators.current = {};
      tags.forEach((t) => {
        let tindicators = [];
        if (t.indicator_source === "tag") {
          if (t.stack && t.stack in props.stacks) {
            tindicators = ISXUtils.getTagIndicators(
              props.stacks[t.stack]?.data_config?.[t.attribute] ?? {}
            );
          }
        } else {
          tindicators = t?.indicators;
        }
        tindicators = ISXUtils.buildIndicators(tindicators ?? []);
        if (tindicators.length > 0) {
          const key = `${t.stack}|${t.attribute}`;
          indicators.current[key] = tindicators;
        }
      });
    };

    const widget = props.widget ?? {};
    if (lastTags.current !== widget.tags) {
      lastTags.current = widget.tags;
      _buildIndicators(widget.tags ?? []);
    }
  }, [props.stacks, props.widget]);

  return (
    <BaseTimeSeriesWidget {...props}>
      {(baseProps) => (
        <TableWidgetComponent
          {...baseProps}
          isLoading={baseProps.loading}
          data={baseProps.streams}
          resolveIndicators={resolveIndicators}
        />
      )}
    </BaseTimeSeriesWidget>
  );
};

export default connectWidget(TableWidget);
