import React, { useState, useEffect } from "react";
import { Router, Route, Navigate, Routes } from "./services/Router";
import { useLocation } from "react-router-dom";
import {
  NavigationBar,
  Login,
  ResetPassword,
  Dashboard,
  LoginCallback,
  Profile,
  USBTool,
} from "./containers";
import { isxOperations } from "./store";
import { connect } from "react-redux";
import "react-virtualized/styles.css";

const PrivateRouteComponent = ({ refresh, isAuthenticated, children }) => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      await refresh();
      setLoading(false);
    })();
  }, [refresh]);

  if (loading) {
    return null;
  }

  if (isAuthenticated) {
    return children;
  }

  return (
    <Navigate to="/login" state={{ from: { pathname: location.pathname } }} />
  );
};

// class PrivateRoute extends React.Component {
//   state = {
//     loading: true,
//   };

//     await this.props.refresh();
//     this.setState({ loading: false });
//   }

//   render() {
//     const { component: Component, isAuthenticated, ...rest } = this.props;
//     const { loading } = this.state;

//     if (loading) {
//       return null;
//     }

//     const renderRoute = (props) => {
//       if (isAuthenticated) {
//         return <Component {...props} />;
//       }

//       return <Navigate to="/login" state={getLocation(props)} />;
//       //   <Route
//       //     path="/redirect"
//       //     element={<Navigate to="/login" state={getLocation(props)} />}
//       //   />
//       // );
//     };

//     return <React.Component {...rest} render={renderRoute} />;
//   }
// }

const PrivateRoute = connect(
  ({ isx: { isAuthenticated } }) => ({ isAuthenticated }),
  (dispatch) => ({
    refresh: () => dispatch(isxOperations.attemptRefresh()),
  })
)(PrivateRouteComponent);

const App = (props) => {
  return (
    <NavigationBar logo={props.logo}>
      <Routes>
        <Route path="/" element={<LoginCallback />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/changepass" element={<ResetPassword />} />
        <Route
          path="/dashboard/:dashboardGuuid"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/usbtool" element={<USBTool />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        {/* <PrivateRoute
              path="/dashboard/:dashboardGuuid"
              component={Dashboard}
            /> */}
        {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
      </Routes>
    </NavigationBar>
  );
};

export default App;
