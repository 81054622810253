import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";

import { ISXContext } from "services/Utils";

// const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const InfoDialog = (props) => {
  const isxContext = useContext(ISXContext);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle align="center">
        About {isxContext.labels.platform}
      </DialogTitle>
      <DialogContent dividers>
        <img
          src={
            (isxContext.logo && isxContext.logo.url) ??
            "https://www.interstacks.com/wp-content/themes/boxpress/assets/img/branding/interstacks-logo.png"
          }
          height={(isxContext.logo && isxContext.logo.height) ?? 72}
          alt="Interstacks Logo"
        />
        <DialogContentText variant="body1" align="center">
          Version {process.env.REACT_APP_VERSION}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default InfoDialog;
