import React, { useState, useContext, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";

import { ISXContext } from "services/Utils";

import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const GreenTypography = withStyles((theme) => ({
  primary: {
    light: "#757ce8",
    main: "#3f50b5",
    dark: "#002884",
    contrastText: "#fff",
  },
  secondary: {
    light: "#ff7961",
    main: "#f44336",
    dark: "#ba000d",
    contrastText: "#000",
  },
}))(Typography);

const QuickMessagingWidget = (props) => {
  //console.log("in QM", props);
  const isxContext = useContext(ISXContext);
  const attributes = props.widget.options.messagePayload || [];
  const buttonName =
    props.widget.options.buttonName ||
    "Send Message to " + isxContext.labels.deviceCapitalized;
  const widget = props.widget || {};
  const stackId =
    "tags" in widget && widget.tags.length
      ? widget.tags[0].stack
      : (widget.stacks || [])[0];
  const stacks = props.stacks || {};
  const stack = stacks[stackId] || {};
  const [msg, setMsg] = useState({
    content: null,
    status: "",
    lastSent: widget.options.lastSent || "---",
  });

  //console.log("attributes of msg to be sent are", stackId, attributes);
  if (stackId) {
    if (stack.c2dq && stack.c2dq.length > 0) {
      if (msg.status !== "Pending") {
        //setMsg({ status: "Pending" });
        msg.status = "Pending";
      }
    } else {
      if (msg.lastSttime !== stack.st_time) {
        if (msg.status === "Pending") {
          msg.lastSent = stack.st_time
            ? moment.utc(parseInt(stack.st_time)).local().format(DATE_FORMAT)
            : "---";
        }
        msg.status = "Ready";
      }
    }
  } else {
    //setMsg({ status: "---" });
    msg.status = "---";
  }
  //console.log("status and last sent is", msg);

  const [requestPending, setRequestPending] = useState(false);
  const saveAttrsTimeout = useRef(null);

  const haveValidAttributes = () => attributes.some((a) => !!a.key);

  const saveAttrs = () => {
    const canonicalValue = (type, val) => {
      if (type === "Number") {
        return Number(val);
      } else {
        return val;
      }
    };

    //const widget = props.widget || {};
    //const stackId = (widget.stacks || [])[0];
    msg.lastSttime = stack.st_time;
    msg.status = "Pending";
    if (stackId) {
      const attrs = attributes.reduce((acc, a) => {
        if (a.key) {
          acc[a.key] = canonicalValue(a.type, a.value);
        }
        return acc;
      }, {});
      if (Object.keys(attrs).length > 0) {
        // add the tag key and its value here.. such an hack!!
        if ("tags" in widget && widget.tags.length) {
          attrs["tag"] = widget.tags[0].attribute;
        }
        setRequestPending(true);
        props.setProps(stackId, attrs).then(
          // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
          (rv) => {
            clearTimeout(saveAttrsTimeout.current);
            if (rv.status === 0) {
              // ok
              msg.content =
                "Message sent. Please wait until the " +
                isxContext.labels.deviceCapitalized +
                " processes the message";
              setMsg(msg);
              saveAttrsTimeout.current = setTimeout(() => {
                msg.content = null;
                setMsg(msg);
                setRequestPending(false);
              }, 6000);
            } else {
              // error
              msg.content = `ERROR: ${
                rv.error || "Something went wrong with your request"
              }; please try again or contact support`;
              msg.severity = "erorr";
              setMsg(msg);
              setRequestPending(false);
            }
          }
        );
      }
    }
  };
  return (
    <Widget
      {...props}
      dataType="tags"
      maxStacks={1}
      maxTags={1}
      widgetTitle={`${isxContext.labels.deviceCapitalized} Messaging`}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        style={{ height: "100%", padding: 12 }}
      >
        <Grid item xs={8} style={{ textAlign: "center" }}>
          <Button
            onClick={saveAttrs}
            disabled={
              props.readOnly ||
              requestPending ||
              !haveValidAttributes() ||
              msg.status === "Pending"
            }
            variant="contained"
            color="primary"
          >
            {buttonName}
          </Button>
        </Grid>
        <Grid item xs={8} style={{ textAlign: "center" }}>
          <GreenTypography color={"primary"}>
            <Typography variant="body2">Status: {msg.status}</Typography>
          </GreenTypography>
          <Typography variant="body2" style={{ fontSize: 12 }}>
            Last Sent: {msg.lastSent}
          </Typography>
        </Grid>
      </Grid>
    </Widget>
  );
};

export default QuickMessagingWidget;
