import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { JsonEditor } from 'json-edit-react'
import styled from 'styled-components';

const StyledJsonEditor = styled(JsonEditor)`
  .jer-empty-string::after {
    content: "" !important
  }
`;

const USBTool = (props) => {
    const commstate = {}
    const [port, setPort] = useState();
    const [step, setStep] = useState(0);
    const [errorText, setErrorText] = useState("");
    const [stackInfo, setStackInfo] = useState({});
    const [debugLog, setDebugLog] = useState("");
    const [debugHidden, setDebugHidden] = useState(true);
    const [revert, setRevert] = useState(undefined);    
    const sendStack = async function(data) {
        const epn = port.configuration.interfaces[0].alternate.endpoints[1].endpointNumber
        const chunkSize = 32;
        for(var i=0; i<data.length; i+=chunkSize) {
            console.log("snd",data)
            await port.transferOut(epn, data)
            //do we need await somewhere here?
        }
    }
    const logStyle = {
        width: '100%',
        height: '80vh',
        padding: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        overflowY: 'auto',
        whiteSpace: 'pre-wrap', // Ensures text wraps properly
        fontFamily: 'monospace', // Gives it a log-like appearance
    };
    function escapeHtml(str) {
        return str.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };    
    function appendDebugLog(s) {
        setDebugLog( a => a + "\n" + s )
    }
    //this is a bit odd but b/c I copied it from somewhere else
    //todo: could be re-modularized
    function defineCBuf() {
           function toUTF8Array(str) {
             var utf8 = [];
             for (var i = 0; i < str.length; i++) {
               var charcode = str.charCodeAt(i);
               if (charcode < 0x80) utf8.push(charcode);
               else if (charcode < 0x800) {
                 utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
               } else if (charcode < 0xd800 || charcode >= 0xe000) {
                 utf8.push(
                   0xe0 | (charcode >> 12),
                   0x80 | ((charcode >> 6) & 0x3f),
                   0x80 | (charcode & 0x3f)
                 );
               }
               // surrogate pair
               else {
                 i++;
                 // UTF-16 encodes 0x10000-0x10FFFF by
                 // subtracting 0x10000 and splitting the
                 // 20 bits of 0x0-0xFFFFF into two halves
                 charcode =
                   0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
                 utf8.push(
                   0xf0 | (charcode >> 18),
                   0x80 | ((charcode >> 12) & 0x3f),
                   0x80 | ((charcode >> 6) & 0x3f),
                   0x80 | (charcode & 0x3f)
                 );
               }
             }
             return utf8;
           }
           
           const ToBase64 = function (u8) {
             return btoa(String.fromCharCode.apply(null, u8));
           }
           const FromBase64 = function (str) {
             return atob(str).split('').map(function (c) { return c.charCodeAt(0); });
           }

           const CBuf = function(buffer, start, len) {
             this.buffer = buffer;
             this.s = start || 0;
             this.len = len || buffer.length;
             this.o = 0;
           }
           CBuf.prototype.byte = function() {
             this.o += 1;
             return this.buffer[this.s+this.o-1];
           }
           CBuf.prototype.readEint = function() {
             const e = this.byte();
             if(e < 0xfc) return e;
             let l = 0;
             if(e === 0xfc) l = 2;
             else if(e === 0xfd) l = 4;
             else if(e === 0xfe) l = 8;
             else l = this.readEint();
             let val = 0;
             while(l--) {
               val = val * 256 + this.byte();
             }
             return val;
           }
           CBuf.prototype.readString = function(l) {
             function utf8_to_str(a) {
               for(var i=0, s=''; i<a.length; i++) {
                 var h = a[i].toString(16)
                 if(h.length < 2) h = '0' + h
                 s += '%' + h
               }
               return decodeURIComponent(s)
             }
             const a = utf8_to_str(this.buffer.slice(this.s+this.o, this.s+this.o+l))
             this.o += l;
             return a;
           }
           CBuf.prototype.readBinary = function(l) {
             const a = ToBase64(this.buffer.slice(this.s+this.o,this.s+this.o+l));
             this.o += l;
             return a;
           }
           CBuf.prototype.readVSMF = function(l) {
             const backup = this.o;
             const typ = this.byte();
             const abc = typ >> 5;
             const defgh = typ & 0x1f;
             let tl, kind, id;
             if(abc === 6) {//complex
               const d = defgh >> 4;
               const e = (defgh >> 3) & 1;
               const fgh = defgh & 7;
               tl = (d === 0) ? -1 : this.readEint();
               kind = (e === 0) ? 1 : this.readEint();
               id = (fgh !== 7) ? fgh : this.readBinary(this.readEint());
             } else {
               if(abc === 7) {
                 tl = this.readEint();
               } else {
                 tl = [0,1,2,4,8,-1][abc];
               }
               kind = 0;
               if(defgh !== 0x1f) {
                 id = defgh;
               } else {
                 id = this.readBinary(this.readEint());
               }
             }
             if(kind !== 0) throw "yikes"; //not yet support HOMAR STRUCT
             if(tl === -1) tl = this.readEint();
             const end = this.o + tl;
             switch(id) {
               case 2: //array
                 {
                   const ret = [];
                   while(this.o < end) {
                     ret.push(this.readVSMF());
                   }
                   return ret;
                 }
               case 3: //UUID
                 return { '':['UUID', this.readBinary(tl)] };
               case 5: //string
                 return this.readString(tl);
               case 6: //eform
                 {
                   const ret = {};
                   while(this.o < end) {
                     const k = this.readString(this.readEint());
                     const vl = this.readEint();
                     const v = this.readVSMF(vl);
                     ret[k] = v;
                   }
                   return ret;
                 }
               case 8: //lsb int
                 {
                   if(tl === 0) return null;
                   let ret = 0, mult = 1, tw = 2**(tl*8);
                   let neg = 0;
                   while(tl--) {
                     let b = this.byte();
                     neg = b & 128;
                     ret += b*mult;
                     mult *= 256;
                   }
                   if(neg) ret = -(tw - ret);
                   return ret;
                 }
               case 9: //msb int
                 {
                   if(tl === 0) return true; //legacy
                   let ret = this.byte(), neg = ret & 128, tw = 2**(tl*8);
                   while(--tl) {
                     ret = ret*256 + this.byte();
                   }
                   if(neg) ret = -(tw - ret);
                   return ret;
                 }
               case 10: //Bool
                 if(tl === 0) return false;
                 let b = 0;
                 while(tl--) {
                   b |= this.byte();
                 }
                 return b ? true : false;
               case 15: //Bin
                 return {'':['Binary',this.readBinary(tl)]};
               default:
                 console.log("UNKTYPE",typ, id);
                 appendDebugLog("Stack parse error:"+typ+" "+id);
                 const ret = {'':["VSMF", this.buffer.slice(this.start + backup, this.start + this.o + tl)]};
                 this.o += tl;
                 return ret;
             }
           }
           
           function toVSMF(o) {
             const writeEint = function(n) {
               if(n < 0xfc) return [n];
               if(n < 0xffff) return [0xfc, n>>8, n&255 ];
               if(n < 0xffffffff) return [0xfd,(n>>24)&255,(n>>16)&255,(n>>8)&255, n&255 ];
               throw "too big";
             }
             const varType = function (id, data, fix=false) {
               const l = data.length;
               let x;
               if(l <= 8 && fix && (x=[0x00,0x20,0x40,-1,0x60,-1,-1,-1,0x80][l]) !== -1) return [(id + x), ...data]
               return [(id + 0xa0), ...writeEint(l), ...data];
             }
             switch(typeof(o)) {
               case 'boolean': {
                 return [0x2a, o ? 1 : 0];
               }
               case 'number': {
                 if(!Number.isNaN(o) && Number.isInteger(o)) {
                   const buf = new ArrayBuffer(8);
                   if(o >= -0x8000 && o <= 0x07fff) {
                     new DataView(buf).setInt16(0,o)
                     const ret = [73]
                     ret.push(...(new Uint8Array(buf,0,2)));
                     return ret
                   } else if(o >= -2147483648 && o <= 0x07fffffff) {
                     new DataView(buf).setBigInt32(0,o)                     
                     const ret = [105]
                     ret.push(...(new Uint8Array(buf,0,4)));
                     return ret
                   } else {
                     new DataView(buf).setBigInt64(0,o)
                     const ret = [137]
                     ret.push(...(new Uint8Array(buf,0,8)));
                     return ret
                   }
                 }
                 console.log("float not supported");
                 throw("float not supported");
               }
               case 'string': {
                 return varType(5,toUTF8Array(o));
               }
               case 'object': {
                 if(o === null || o === undefined) {
                   return [8]    
                 } else if(Array.isArray(o)) {
                   const elems = o.map(toVSMF).reduce((a, e) => [...a, ...e], []);
                   return varType(2,elems)
                 } else {
                   const keys = Object.keys(o);
                   if(keys.length === 1 && keys[0] === '') { //special
                     const special = o[''];
                     if(Array.isArray(special) && special.length > 1) {
                       switch(special[0]) {
                         case "Binary":
                           return varType(15,FromBase64(special[1]))
                         case "UUID":
                           return varType(3,FromBase64(special[1]))
                         case "VSMF":
                           //todo();
                       }
                     }
                   } else {
                     const ret = []
                     keys.sort();
                     keys.reduce((ret, k)=> {
                       const k8 = toUTF8Array(k);
                       ret.push(...writeEint(k8.length));
                       ret.push(...k8)
                       const vv = toVSMF(o[k]);
                       ret.push(...writeEint(vv.length));
                       ret.push(...vv)
                       return ret;
                     }, ret);
                     return varType(6,ret);
                   }
                 }
             }}
             console.log("cannot convert",o);
             throw("Cannot convert",o);
           }
        return [CBuf, toVSMF];
    }
    const [CBuf, toVSMF] = defineCBuf();

    function infoChanged(data) {
        setStackInfo(data)
    }
    
    function sendInfo(data) {
        appendDebugLog("Sending...")                
        const config = toVSMF(data);
        //append message to base terminal 13
        const header = [0xfe, 0, 0xfd, 13];
        const whole = [...header, ...config, 0xfe, 250];
        sendStack(new Uint8Array(whole));
    }
    
    function resetCommState() {
        commstate.iesc = 0;
        commstate.module = 0;
        commstate.selected = {};
        commstate.inState = {};
        commstate.pendingWrite = 0;
        commstate.syncbuf = 0;
    }
    function getSelected(m) {
        if(!commstate.selected[m] || commstate.selected[m].length === 0) {
            commstate.selected[m] = [0];
        }
        return commstate.selected[m];
    }
    function setSelected(m,s) {
        commstate.selected[m] = [s];
    }
    function addSelected(m,s) {
        commstate.selected[m].push(s);
    }

    function handleMessage(ky, msg) {
        if(msg) {
            const cBuf = new CBuf(msg);
            const result = cBuf.readVSMF();
            console.log("msg",ky,result)
            if(ky === "0:12") {
                const v = (result == null) ? {} : result;
                setStackInfo(v)
                if(revert === undefined) setRevert(v)
            } else {
                appendDebugLog("msg:"+ky+" "+JSON.stringify(result))
            }
        } else {            
            console.log("reset",ky)
            appendDebugLog("Got reset")
        }
    }
    function processInput(data) {
        for(let i = 0; i < data.length; i++) {               
            let c = data[i];
            if(c === 0xff) {
                resetCommState();
                continue;
            } else if(commstate.iesc === 254) {
                commstate.iesc = 0;
                if(c < 128) {
                    commstate.module = c;
                    continue;
                } else if(c > 251) {
                    c += 1;
                } else if(c === 251) {
                    commstate.iesc = 2000;
                    continue;
                } else if(c === 250) {
                    c = -1;
                } else {
                    console.log("Protocol violation!");
                    resetCommState();
                }
            } else if(commstate.iesc === 253) {
                commstate.iesc = 0;
                if(c < 128) {
                    setSelected(commstate.module, c);
                } else if(c < 254) {
                    addSelected(commstate.module,c-128);
                } else {
                    commstate.iesc = 1000;
                }
                continue;
            } else if(commstate.iesc === 1000) {
                commstate.iesc = 0;
                getSelected(commstate.module).push(c-252+125)
                continue;
            } else if(commstate.iesc === 2000) {
                commstate.oob_len = c
                if(c > 0) {
                    commstate.iesc = 2001;
                    commstate.oob = [];
                 } else {
                     commstate.iesc = 0;
                 }
                continue;
            } else if(commstate.iesc === 2001) {
                commstate.oob.push(c);
                commstate.oob_len -= 1;
                if(commstate.oob_len === 0) {
                    console.log("OOB:",commstate.oob);
                    if(commstate.oob === "Isx01") {
                        //a reset happened
                        commstate.syncbuf = 1;
                        handleMessage('');  //reset
                    }
                    commstate.iesc = 0;
                }
                continue;
            } else if(c === 254 || c === 253) {
                commstate.iesc = c;
                continue;
            }
            //reading a msg to module/selected
            let KY = commstate.module+":"+getSelected(commstate.module).join('-')
            let ST = commstate.inState[KY];
            if(c === -1) { //EOM
                if(ST) {
                    handleMessage(KY,ST[1]);
                    ST[0] = 0;
                    ST[1] = [];
                }
            } else {
                if(!ST) {
                    ST = [0,[]];
                    commstate.inState[KY] = ST;
                }
                if(commstate.syncbuf) {
                    commstate.syncbuf = 0;
                    ST[1] = [];
                    ST[0] = 0;
                }
                ST[1].push(c);
                ST[0] += 1;
            }
        }
    }
           
    
    const readLoop = function(device, endpointNumber, onReceive, onError) {
        device.transferIn(endpointNumber, 64).then(result => {
            onReceive(result.data,device);
            readLoop(device,endpointNumber, onReceive, onError);
        }, error => {
            onError(error);
            device.close();
        });
    }
    function reset() {
        appendDebugLog("Reset...")        
        sendStack(new Uint8Array([0xff]));
    }
    function getInfo() {
        sendStack(new Uint8Array([0xfe,0,0xfd,12,0xa5,1,'x',0xfe,250]));
    }
    const onReceive = function(data) {
        console.log("got",data)
        processInput(new Uint8Array(data.buffer));
    }
    const onError = function(err,device) {
        console.log("err",err)
        appendDebugLog("Read Error:" + err)
        setPort(null)
    }
    const disconnect = async function() {
        await port.close();
        setPort(null)
        setRevert(undefined)
    }
    const revertNow = async function() {
        if(revert) 
            setStackInfo(revert)
    }
    const connect = async function() {
        try {
            const device = await navigator.usb.requestDevice({ 'filters': [{ 'vendorId': 0x0925, 'productId': 0x9099 }]})
            if(device) {
                setErrorText("")
                appendDebugLog("Contacting base...")
                try {
                    await device.open()
                    //await device.reset() // not necessary and doesn't work
                    console.log(device)
                    if(device.configuration === null) {
                        await device.selectConfiguration(1);
                    }
                    await device.claimInterface(0)
                } catch (err) {
                    console.log("open err",err)
                    device.close()
                    throw err
                }
                const epn = device.configuration.interfaces[0].alternate.endpoints[1].endpointNumber
                resetCommState()
                readLoop(device, epn, onReceive, onError)
                appendDebugLog("Connected.")
                setPort(device)
            } else {
                appendDebugLog("Failed to conect ot port...")
            }
        } catch(err) {
            setErrorText(""+err)
            appendDebugLog("Error: "+err)
        }
    }

  return (
      <div>
          <b>Warning</b>: Use this tool carefully!  It could corrupt the configuration of the stack if misused.  Please use in collaboration with Interstacks support.
          <br/>
          <br/>

          { !port &&
            <div>
                Connect the base module to a USB port on the same computer as this web browser and press:
                <br/>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={connect}
                >
                    Connect to Stack
                </Button>
                { !!errorText && <pre style={{ color: 'red' }} > { errorText } </pre> }
            </div>
          }
          { port &&
            <div>
                Press GetInfo to fetch from stack (or verify configuration) and Save to save after changes:<br/>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={getInfo}
                >
                    GetInfo
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={ a => sendInfo(stackInfo) }
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={revertNow}
                >
                    Revert
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={disconnect}
                >
                    Disconnect
                </Button>
                <StyledJsonEditor
                    rootName="stack configuration"
                    enableClipboard={false}
                    showStringQuotes={false}
                    keySort="true"
                    restrictDelete={ a=> a.level > 0 }
                    defaultValue=""
                    data={ stackInfo }
                    onUpdate={ ({newData} ) => {
                        infoChanged(newData)
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={reset}
                >
                    Reset
                </Button> sends a reset command to the stack for debugging.
            </div>
          }
          <hr/>
          <Button onClick={ (a) => setDebugHidden(!debugHidden) } >
              { debugHidden ? "Show Log" : "Hide Log" } 
          </Button><br/>
          <div style={{ display: debugHidden ? 'none' : 'block' }}>
          <Button onClick={ (a) => setDebugLog("") } >
              Clear Log
          </Button><br/>
          <div style={logStyle}>
              {debugLog}
          </div>
          </div>                       
      </div>
  );
}

export default USBTool;

