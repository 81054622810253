import React from "react";
import { StackMessagingWidget as StackMessagingWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";

class StackMessagingWidget extends React.Component {
  requestPending = false;

  render = () => (
    <StackMessagingWidgetComponent {...this.props} setProps={this.setProps} />
  );

  setProps = (stackId, props) => {
    const { username, password } = this.props;
    return QueryService.sendStackMessage(
      username,
      password,
      stackId,
      JSON.stringify(props)
    );
  };
}

export default connectWidget(StackMessagingWidget);
