import React, { useState, useContext, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import StackManagementDialog from "components/widgets/stacks-management-widget/StackManagementDialog";
import WidgetConfigureDialog from "..//WidgetConfigureDialog";
import { ISXContext } from "services/Utils";

const USE_STACK_CONFIG = true;

const useStyles = makeStyles({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
});

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const StackDetails = (props) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { selectedStack } = props;

  return (
    <>
      <InfoTooltip
        title="Click to view/modify"
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <OpenInNewIcon fontSize="small" className={classes.openIcon} />
      </InfoTooltip>
      {open && (
        <StackManagementDialog
          detailsOpen={open}
          closeDetails={() => setOpen(false)}
          selectedStack={selectedStack}
          readOnly={false}
        />
      )}
    </>
  );
};

const WidgetStacksData = (props) => {
  const { maxStacks, stacksSelected } = props;

  const stacks = props.stacks
    ? Object.values(props.stacks).sort((a, b) =>
        a?.name ||
        JSON.parse((a || {}).dev_session || "{}")["stack.label"] < b?.name ||
        JSON.parse((b || {}).dev_session || "{}")["stack.label"]
          ? -1
          : 1
      )
    : [];

  return (
    <FormControl>
      {maxStacks !== 1 ? (
        <FormGroup>
          {stacks.map((st) => (
            <div
              key={st.guuid}
              style={{ display: "flex", alignItems: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stacksSelected[st.guuid]}
                    onChange={(() => (event) => {
                      props.selectStack(st.guuid, event.target.checked);
                    })()}
                    value={st.guuid}
                  />
                }
                label={
                  st?.name || JSON.parse(st.dev_session || "{}")["stack.label"]
                }
              />
              {USE_STACK_CONFIG && <StackDetails selectedStack={st.guuid} />}
            </div>
          ))}
        </FormGroup>
      ) : (
        <RadioGroup aria-label="Stack" value={stacksSelected || ""}>
          {stacks.map((st) => (
            <div
              key={st.guuid}
              style={{ display: "flex", alignItems: "center" }}
            >
              <FormControlLabel
                value={st.guuid}
                control={<Radio />}
                label={
                  st?.name || JSON.parse(st.dev_session || "{}")["stack.label"]
                }
                onChange={(() => (event) => {
                  props.selectStack(st.guuid);
                })()}
              />
              {USE_STACK_CONFIG && <StackDetails selectedStack={st.guuid} />}
            </div>
          ))}
        </RadioGroup>
      )}
    </FormControl>
  );
};

const WidgetStacksConfigureDialog = (props) => {
  const [stacksSelected, setStacksSelected] = useState();
  const [initialized, setInitialized] = useState(false);
  const dirty = useRef(false);
  const isxContext = useContext(ISXContext);

  const { widget, stacks = {}, maxStacks = Number.MAX_SAFE_INTEGER } = props;

  useEffect(() => {
    if (!initialized) {
      const wstackIds = widget.stacks || [];
      const initialStacksSelected =
        maxStacks !== 1
          ? Object.values(stacks).reduce((acc, st) => {
              acc[st.guuid] = wstackIds.includes(st.guuid);
              return acc;
            }, {})
          : wstackIds[0] || null;
      setStacksSelected(initialStacksSelected);
      setInitialized(true);
    }
  }, [initialized, maxStacks, stacks, widget.stacks]);

  const selectStack = (guuid, selected = true) => {
    if (props.maxStacks !== 1) {
      let newStacksSelected = { ...stacksSelected };
      newStacksSelected[guuid] = selected;
      setStacksSelected(newStacksSelected);
    } else {
      setStacksSelected(guuid);
    }
    dirty.current = true;
  };

  const prepareStacksForWriteback = () => {
    const stackIds =
      props.maxStacks !== 1
        ? Object.entries(stacksSelected)
            .filter((entry) => entry[1])
            .map((entry) => entry[0])
        : stacksSelected
        ? [stacksSelected]
        : null;
    return { stacks: stackIds };
  };

  const areStacksValid = () => {
    const data = prepareStacksForWriteback();
    return data.stacks && data.stacks.length > 0;
  };

  const titleBuilder = (data) => {
    const stackIds = data.stacks;
    let title;
    if (stackIds.length === 1) {
      const stackid = stackIds[0];
      const stack = props.stacks[stackid] || {};
      const stackTitle =
        stack?.name || JSON.parse(stack.dev_session || "{}")["stack.label"];
      title = stackTitle;
    } else {
      title = props.widgetTitle || "Widget";
    }
    return title;
  };

  return (
    initialized && (
      <WidgetConfigureDialog
        {...props}
        dataProperty="stacks"
        stacksSelected={stacksSelected}
        dataLabel={
          props.maxStacks !== 1
            ? isxContext.labels.devicePlural
            : isxContext.labels.device
        }
        prepareDataForWriteback={prepareStacksForWriteback}
        isDataValid={areStacksValid}
        titleBuilder={titleBuilder}
        dataDirty={dirty.current}
      >
        <WidgetStacksData
          {...props}
          stacksSelected={stacksSelected}
          selectStack={selectStack}
        />
      </WidgetConfigureDialog>
    )
  );
};

export default WidgetStacksConfigureDialog;
