import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { WIDGET_COLORS_ARRAY } from "components/color/colors";

import { ErrorContext } from "services/Utils";
import { Input } from "@material-ui/core";

var Chart = require("chart.js");

const ERROR_MESSAGES = {
  missingSeriesType: "Please enter a series type",
  missingOutputUnit: "Please enter an output unit",
  missingStates: "Please enter at least 2 states",
  missingValues:
    "Please enter a lower bound, upper bound, and minimum duration for each state",
  missingDisplay: "Please enter a default dispaly state for each state",
  missingColor: "Please enter a color for each state",
  missingNoDataColor: "Please enter a color for the Missing Data setting",
  // missingNoDataMinDuration:
  //   "Please enter a minimum duration for the Missing Data setting",
  missingLabel: "Please enter a label for each state",
};

// const SmallerInputLabel = withStyles((theme) => ({
//   root: { fontSize: "0.75rem" },
// }))(InputLabel);

const StyledTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "center",
  },
  body: {
    paddingLeft: 12,
    paddingRight: 12,
    borderTop: "none",
    textAlign: "center",
  },
}))(TableCell);

const StateEntryLabelCell = ({
  id,
  label,
  statesSeriesType,
  rowIndex,
  onChangeState,
  colSpan,
}) => (
  <StyledTableCell
    key={"row-" + rowIndex + "-label"}
    colSpan={colSpan}
    style={{ paddingBottom: "1.25rem", paddingLeft: 0 }}
  >
    <FormControl
      style={{
        width: statesSeriesType === "full" ? "auto" : "100%",
      }}
    >
      <TextField
        aria-label="label"
        id={`label-${id}`}
        name="label"
        label={
          "State Label: " +
          (statesSeriesType !== "full"
            ? rowIndex < 2
              ? "(for state " + rowIndex + ")"
              : "(unused)"
            : "")
        }
        value={label}
        onChange={onChangeState(id)}
        required
        style={{
          margin: 6,
        }}
      />
    </FormControl>
  </StyledTableCell>
);

const StatesTagProperties = (props) => {
  const [errors, setErrors] = useState([]);

  const errorContext = useContext(ErrorContext);

  const { tagId, tagProperties, handleTagPropChange, readOnly } = props;

  useEffect(() => {
    const validation = ({
      states,
      statesNoDataColor,
      // statesNoDataMinDuration,
      statesSeriesType,
    }) => {
      // need to check for errors, but only if config begun, e.g. at least one
      // state property is populated
      const newErrors = [];
      if (
        states ||
        statesNoDataColor ||
        // statesNoDataMinDuration ||
        statesSeriesType
      ) {
        if (!statesSeriesType) {
          newErrors.push(ERROR_MESSAGES.missingSeriesType);
        }
        if (statesSeriesType === "full") {
          if (
            states?.some(
              (state) =>
                !state.lowerBound || !state.upperBound || !state.minDuration
            )
          ) {
            newErrors.push(ERROR_MESSAGES.missingValues);
          }
          if (states?.some((state) => !state.color || state.color === "")) {
            newErrors.push(ERROR_MESSAGES.missingColor);
          }
        } else if (statesSeriesType === "edges") {
          if ((states ?? []).length < 2) {
            newErrors.push(ERROR_MESSAGES.missingStates);
          }
          if (states?.some((state) => !state.label)) {
            newErrors.push(ERROR_MESSAGES.missingLabel);
          }
          if (states?.some((state) => !state.color)) {
            newErrors.push(ERROR_MESSAGES.missingColor);
          }
        }
        if (!statesNoDataColor) {
          newErrors.push(ERROR_MESSAGES.missingNoDataColor);
        }
        // if (!statesNoDataMinDuration) {
        //   newErrors.push(ERROR_MESSAGES.missingNoDataMinDuration);
        // }
      }
      setErrors(newErrors);
      errorContext.setErrorById(tagId, newErrors.length > 0);
    };

    validation(tagProperties);
  }, [tagProperties, tagId, errorContext]);

  const addState = () => {
    const states = tagProperties.states ?? [];
    const newId =
      states.reduce((maxId, { id }) => (id > maxId ? id : maxId), 0) + 1;
    const newStates = [
      ...states,
      {
        id: newId,
        label: "",
        lowerBound: "",
        upperBound: "",
        minDuration: "",
        color: "",
        display: "on",
      },
    ];
    handleTagPropChange(tagId, "states", newStates);
  };

  const deleteState = (id) => {
    const newStates = (tagProperties.states ?? {}).filter(
      (state) => state.id !== id
    );
    handleTagPropChange(tagId, "states", newStates);
  };

  const onChangeState = (id) => (event) => {
    const { name, value } = event.target;

    const newStates = tagProperties.states.map((state) =>
      state.id !== id ? state : { ...state, [name]: value }
    );
    handleTagPropChange(tagId, "states", newStates);
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControl style={{ width: "100%", margin: 0, marginBottom: 0 }}>
        <FormLabel component="legend">
          Series type
          {!tagProperties.statesSeriesType && <span> (please select)</span>}
        </FormLabel>
      </FormControl>
      <FormControl style={{ width: "100%" }}>
        <RadioGroup
          aria-label="seriesType"
          name="seriesType"
          value={tagProperties.statesSeriesType ?? ""}
          onChange={(event) =>
            handleTagPropChange(tagId, "statesSeriesType", event.target.value)
          }
          row
          disabled={readOnly}
        >
          <FormControlLabel
            value="full"
            control={<Radio />}
            label="Continuous"
            disabled={readOnly}
          />
          <FormControlLabel
            value="edges"
            control={<Radio />}
            label="Discrete"
            disabled={readOnly}
          />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item>
          <FormControl style={{ width: "33%", minWidth: 300 }}>
            <InputLabel id="noDataColor-label">
              Color for Missing/Unclassified Data
            </InputLabel>
            <Select
              aria-label="noDataColor"
              id="noDataColor"
              name="noDataColor"
              labelId="noDataColor-label"
              value={tagProperties.statesNoDataColor ?? ""}
              onChange={(event) =>
                handleTagPropChange(
                  tagId,
                  "statesNoDataColor",
                  event.target.value
                )
              }
              required
              disabled={readOnly || !tagProperties.statesSeriesType}
            >
              {WIDGET_COLORS_ARRAY.map((color, index) => {
                const alphaColor = Chart.helpers.color(color).rgbString();

                return (
                  <MenuItem key={"no-data-color-" + index} value={alphaColor}>
                    <div
                      style={{
                        height: 16,
                        width: 20,
                        backgroundColor: alphaColor,
                        border: "1px solid darkgray",
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormLabel
            component="legend"
            style={{ fontSize: 14, fontStyle: "italic" }}
          >
            Min. Duration for Missing/Unclassified Data (in seconds)
          </FormLabel>
          <div>
            <FormControl>
              <RadioGroup
                aria-label="seriesType"
                name="seriesType"
                value={tagProperties.statesNoDataMinDuration?.type ?? "auto"}
                onChange={(event) =>
                  handleTagPropChange(tagId, "statesNoDataMinDuration", {
                    ...tagProperties.statesNoDataMinDuration,
                    type: event.target.value,
                  })
                }
                row
                disabled={readOnly}
              >
                <FormControlLabel
                  value="auto"
                  control={<Radio />}
                  label="Auto"
                  disabled={readOnly}
                />
                <FormControlLabel
                  value="set"
                  control={<Radio />}
                  label="Set"
                  disabled={readOnly}
                />
              </RadioGroup>
            </FormControl>
            {tagProperties.statesNoDataMinDuration?.type === "set" && (
              <FormControl variant="standard">
                <Input
                  id="label-no-data-min-duration"
                  aria-label="noDataMinDuration"
                  name="noDataMinDuration"
                  // label="Min. Duration for Missing/Unclassified Data (in seconds)"
                  value={tagProperties.statesNoDataMinDuration?.value ?? 0}
                  onChange={(event) =>
                    handleTagPropChange(tagId, "statesNoDataMinDuration", {
                      ...tagProperties.statesNoDataMinDuration,
                      value: event.target.value,
                    })
                  }
                  required
                  type="number"
                />
              </FormControl>
            )}
          </div>
        </Grid>
      </Grid>
      <div style={{ width: "100%", marginTop: 32 }}>
        <Table>
          {tagProperties.states?.length > 0 && (
            <TableHead>
              <TableRow>
                <StyledTableCell
                  colSpan={1}
                  style={{ paddingLeft: 0 }}
                ></StyledTableCell>
                {tagProperties.statesSeriesType === "full" && (
                  <>
                    <StyledTableCell style={{ paddingLeft: 0 }}>
                      Lower Bound
                    </StyledTableCell>
                    <StyledTableCell>Upper Bound</StyledTableCell>
                    <StyledTableCell>
                      Min. Duration (in seconds)
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell>Color</StyledTableCell>
                {/* <StyledTableCell>Display on Load</StyledTableCell> */}
                <StyledTableCell style={{ paddingRight: 0 }}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {tagProperties.states?.map((state, rowIndex) => {
              const { id, label, lowerBound, upperBound, minDuration, color } =
                state;

              return (
                <React.Fragment key={"row-" + rowIndex + "-fragment"}>
                  <TableRow key={"row-" + rowIndex + "-bottom"}>
                    {tagProperties.statesSeriesType === "full" ? (
                      <>
                        <StateEntryLabelCell
                          {...{ id, label, rowIndex, onChangeState }}
                          statesSeriesType={tagProperties.statesSeriesType}
                          // colSpan={5}
                        />
                        <StyledTableCell
                          key={"row-" + rowIndex + "-lowerBound"}
                          style={{ paddingLeft: 0 }}
                        >
                          <TextField
                            aria-label="lowerBound"
                            id={`lowerBound-${id}`}
                            name="lowerBound"
                            value={lowerBound}
                            onChange={onChangeState(id)}
                            type="number"
                            style={{
                              margin: 6,
                            }}
                            disabled={readOnly}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          key={"row-" + rowIndex + "-upperBound"}
                        >
                          <TextField
                            aria-label="upperBound"
                            id="upperBound"
                            name="upperBound"
                            value={upperBound}
                            onChange={onChangeState(id)}
                            type="number"
                            style={{
                              margin: 6,
                            }}
                            disabled={readOnly}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          key={"row-" + rowIndex + "-minDuration"}
                        >
                          <TextField
                            aria-label="minDuration"
                            id={"minDuration-" + id}
                            name="minDuration"
                            value={minDuration}
                            onChange={onChangeState(id)}
                            type="number"
                            style={{
                              margin: 6,
                            }}
                            disabled={readOnly}
                          />
                        </StyledTableCell>
                      </>
                    ) : (
                      <StateEntryLabelCell
                        {...{ id, label, rowIndex, onChangeState }}
                        statesSeriesType={tagProperties.statesSeriesType}
                        // colSpan={1}
                      />
                    )}
                    <StyledTableCell key={"row-" + rowIndex + "-color"}>
                      <FormControl>
                        <Select
                          aria-label="color"
                          id={`color-${id}`}
                          name="color"
                          value={color || ""}
                          onChange={onChangeState(id)}
                          required
                          style={{
                            margin: 6,
                          }}
                          disabled={readOnly}
                        >
                          {WIDGET_COLORS_ARRAY.map((color, index) => {
                            const alphaColor = Chart.helpers
                              .color(color)
                              .rgbString();

                            return (
                              <MenuItem
                                key={`row-${id}-color-${index}`}
                                value={alphaColor}
                              >
                                <div
                                  style={{
                                    height: 16,
                                    width: 20,
                                    backgroundColor: alphaColor,
                                    border: "1px solid darkgray",
                                  }}
                                />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell
                      key={"row-" + rowIndex + "-cancel"}
                      style={{ paddingRight: 0 }}
                    >
                      <IconButton onClick={() => deleteState(id)}>
                        <CancelIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow>
              <StyledTableCell
                colSpan={tagProperties.statesSeriesType !== "full" ? 4 : 6}
                style={{ textAlign: "left" }}
              >
                <Button
                  onClick={addState}
                  startIcon={<AddCircleIcon />}
                  color="primary"
                  disabled={readOnly || !tagProperties.statesSeriesType}
                >
                  Add State
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {errors.length > 0 &&
          errors.map((msg, index) => (
            <p key={"err-" + index} style={{ color: "red", fontSize: "10px" }}>
              {msg}
            </p>
          ))}
      </div>
    </div>
  );
};

export default StatesTagProperties;
