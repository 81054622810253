import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import TagRolePropertiesDialog from "components/TagRolePropertiesDialog";
import { STATES_ROLE } from "services/RolesService";
import ISXUtils from "services/Utils";

import { default as _pick } from "lodash/pick";

const useStyles = makeStyles({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
});

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const PeriodsTrackingTagEntry = ({
  tag,
  trackingOptions,
  setTagTrackingOption,
  stacks,
}) => {
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);

  const classes = useStyles();

  const stack = stacks[tag.stack];
  const trackingType = trackingOptions?.trackingType ?? "states";
  const summaryUnit = trackingOptions?.summaryUnit ?? "percent";
  return (
    <Grid item container spacing={1} xs={12}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }}>
          {stack?.name || JSON.parse(stack?.dev_session ?? "{}")["stack.label"] +
            ":" +
            ISXUtils.getTagDisplayText(tag.attribute, stack.data_config)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="select-periods-tracking"
          name="trackingType"
          select
          label="Tracking"
          value={trackingType}
          onChange={(event) => setTagTrackingOption(event)}
        >
          <MenuItem key="counts" value="counts">
            Counts
          </MenuItem>
          <MenuItem key="states" value="states">
            States
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={8}>
        {trackingType === "states" && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="caption"
                style={{ fontSize: "0.66rem", fontStyle: "italic" }}
              >
                The state levels are configured in the Device Tags properties.
              </Typography>
              <InfoTooltip
                title="Click to view/modify states properties"
                onClick={(e) => {
                  setRoleDialogOpen(true);
                }}
              >
                <OpenInNewIcon fontSize="small" className={classes.openIcon} />
              </InfoTooltip>
            </div>

            <RadioGroup
              aria-label="summaryUnit"
              name="summaryUnit"
              value={summaryUnit}
              onChange={(event) => setTagTrackingOption(event)}
              row
            >
              <FormControlLabel
                value="percent"
                control={<Radio />}
                label="Percentage of time"
              />
              <FormControlLabel
                value="time"
                control={<Radio />}
                label="Total time"
              />
              <FormControlLabel
                value="count"
                control={<Radio />}
                label="Transitions"
              />
            </RadioGroup>
            {roleDialogOpen && (
              <TagRolePropertiesDialog
                open={roleDialogOpen}
                setOpen={setRoleDialogOpen}
                tag={tag}
                role={STATES_ROLE}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const PeriodsTrackingOption = (props) => {
  const {
    options = {},
    setOptions,
    // widget = {},
    tagsSelected,
    stacks = {},
  } = props;
  const { "periods.tracking": tracking = {} } = options;
  const tags = Object.values(tagsSelected ?? {});
  // const { tags = [] } = widget;

  const setTagTrackingOption = (guuid) => (event) => {
    const { name, value } = event.target;
    let trackingEntry = tracking[guuid];
    trackingEntry = trackingEntry ? { ...trackingEntry } : {};
    trackingEntry[name] = value;
    // filter out stale tags
    const newTracking = _pick(
      tracking,
      tags.map((t) => t.guuid)
    );
    newTracking[guuid] = trackingEntry;
    setOptions({ "periods.tracking": newTracking });
  };

  return (
    <FormControl
      margin="dense"
      style={{ width: "calc(100% - 48px)", margin: 24 }}
    >
      <FormLabel component="legend">Tracking Options</FormLabel>
      <div style={{ marginTop: 16 }}>
        <Grid container spacing={3}>
          {tags.map((t) => (
            <PeriodsTrackingTagEntry
              key={t.guuid}
              tag={t}
              stacks={stacks}
              trackingOptions={tracking[t.guuid]}
              setTagTrackingOption={setTagTrackingOption(t.guuid)}
            />
          ))}
        </Grid>
      </div>
    </FormControl>
  );
};

export default PeriodsTrackingOption;
