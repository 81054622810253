import React, { useState, useRef, useEffect, useCallback } from "react";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from "@material-ui/lab/TreeItem";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import {
  MUI_TABLE_THEME,
  DEFAULT_POLICIES,
  OWNER_POLICY,
  CREATE_MUI_DATATABLE_BASE_COLUMNS,
  CREATE_MUI_DATATABLE_BASE_OPTIONS,
  DATE_FORMAT,
} from "./OrganizationManagementWidgetConstants";
import OrganizationManagementShowUserLoginsDialog from "./OrganizationManagementShowUserLoginsDialog";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { blue } from "@material-ui/core/colors";
const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

class OrganizationManagementOrganizationUsers extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [];
    this.options = {};

    this.state = {
      users: [],
      expanded: [],
      searchText: "",
      showDialog: false,
      currentUser: null,
    };

    this.removeUsersDialogRef = React.createRef();
  }

  openShowUserLoginsDialog = (userid) => {
    this.setState({ currentUser: userid });
    this.setState({ showDialog: true });
  };

  closeUserLoginsDialog = () => {
    this.setState({ currentUser: null });
    this.setState({ showDialog: false });
  };

  componentDidMount() {
    this.columns = CREATE_MUI_DATATABLE_BASE_COLUMNS(
      this.props.organization.guuid,
      (value, tableMeta, updateValue) =>
        tableMeta.rowData[5] === "sent" ||
        this.state.users[tableMeta.rowIndex].userid ===
          this.props.currentUserId,
      this.props.toggleRowSelect
    ).concat([
      {
        name: "userid",
        options: {
          display: false,
        },
      },
      {
        name: "email",
        label: "Email",
      },
      {
        name: "permissions",
        label: "Permissions",
      },
      {
        name: "userid",
        label: "Recent Activity",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <InfoTooltip title="Click to view recent logins">
              <InfoIcon
                fontSize="small"
                style={{ color: blue[500] }}
                onClick={(event) => {
                  event.preventDefault();
                  this.openShowUserLoginsDialog(value);
                }}
              />
            </InfoTooltip>
          ),
        },
      },
      {
        name: "status",
        label: "Last Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "sent") {
              return (
                <div
                  style={{
                    color: "grey",
                  }}
                >
                  Pending
                </div>
              );
            } else if (value === "Verified User") {
              return (
                <div
                  style={{
                    color: "blue",
                  }}
                >
                  Verified User
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    color: "grey",
                  }}
                >
                  Unknown Status
                </div>
              );
            }
          },
        },
      },
    ]);

    this.options = CREATE_MUI_DATATABLE_BASE_OPTIONS(
      this,
      (rowData, rowMeta) => {
        return (
          <TableRow style={{ background: "#eeeeee" }}>
            <TableCell
              style={{
                paddingTop: 0,
                paddingBottom: 12,
                paddingLeft: 12,
                paddingRight: 12,
              }}
              colSpan={this.columns.length + 1}
            >
              <Card>
                <CardContent>
                  <span>TBD</span>
                </CardContent>
              </Card>
            </TableCell>
          </TableRow>
        );
      }
    );

    this.populateUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    this.populateUsers();
  }

  populateUsers = () => {
    const policies = DEFAULT_POLICIES.concat(OWNER_POLICY);

    const selectedUsersForThisOrg = this.props.selectedUsers
      .filter((item) => item.startsWith(this.props.organization.guuid))
      .map((item) => item.substring(this.props.organization.guuid.length + 1));

    const users = this.props.organization.users.map((user) => {
      const policyNames = user.policies
        .filter(
          (userPolicyId) =>
            !!policies.find((policy) => policy.policyid === userPolicyId)
        )
        .map(
          (userPolicyId) =>
            policies.find((policy) => policy.policyid === userPolicyId).name
        );

      return {
        email: user.email,
        userid: user.userid,
        orgid: this.props.organization.guuid,
        org: this.props.organization.name,
        permissions: policyNames,
        last_login: user.last_login, //This is always blank
        status: user.status || "Verified User",
      };
    });

    const pendingUsers =
      (this.props.organization.hasOwnProperty("pendingusers") &&
        this.props.organization.pendingusers
          .filter((user) => user.status === "sent")
          .map((item) => {
            const policyNames = item.policies
              .filter(
                (userPolicyId) =>
                  !!policies.find((policyId) => policyId === userPolicyId)
              )
              .map(
                (userPolicyId) =>
                  policies.find((policyId) => policyId === userPolicyId).name
              );

            return {
              email: item.invitee,
              userid: item.guuid,
              orgid: this.props.organization.guuid,
              org: this.props.organization.name,
              permissions: policyNames,
              last_login: item.tm_sent,
              status: item.status || "Unknown Status",
            };
          })) ||
      [];

    const allUsers = users.concat(pendingUsers).map((user) => {
      user.selected = !!selectedUsersForThisOrg.includes(user.userid);
      return user;
    });

    if (
      allUsers.length !== this.state.users.length ||
      allUsers.some((user, index) => {
        const compUser = this.state.users[index];
        return (
          user.email !== compUser.email ||
          user.selected !== compUser.selected ||
          user.permissions.join() !== compUser.permissions.join() ||
          (!!user.last_login ? user.last_login.valueOf() : null) !==
            (!!compUser.last_login ? compUser.last_login.valueOf() : null) ||
          user.status !== compUser.status
        );
      })
    ) {
      this.setState({ users: allUsers });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TreeItem
          nodeId={this.props.organization.guuid + "-users"}
          label="Users"
        >
          <div style={{ marginRight: 24 }}>
            <MuiThemeProvider theme={MUI_TABLE_THEME}>
              <MUIDataTable
                data={this.state.users}
                columns={this.columns}
                options={this.options}
              />
            </MuiThemeProvider>
          </div>
        </TreeItem>
        <OrganizationManagementShowUserLoginsDialog
          isOpen={this.state.showDialog}
          handleClose={this.closeUserLoginsDialog}
          user={this.state.currentUser}
          /*textFieldRef={createUsersDialogRef}
          currentUserId={props.currentUserId}
          organizationId={createUserDialogOrganizationId}
          isOpen={openCreateUsersConfirmDialog}
          handleClose={(update = false) =>
            () => {
              closeCreateUserDialog();
              if (update) {
                props.populateOrganizationTree();
              }
            }}
          onRenderCallback={() => {
            createUsersDialogRef.current.focus();
          }}
          createOrUpdateUsers={props.createOrUpdateUsers}*/
        />
      </React.Fragment>
    );
  }
}

export default OrganizationManagementOrganizationUsers;
