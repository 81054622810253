import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import TableHead from "@material-ui/core/TableRow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import {
  READ_WRITE_POLICY,
  DEFAULT_POLICIES,
} from "./OrganizationManagementWidgetConstants";
import CSVExporter, { CSV_TIMESTAMP_HEADERS } from "services/CSVExporter";

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

const newUser = () => {
  return {
    email: "",
    policies: ["~0100282a02ec5111e98f329393ccd6e13a"],
  };
};

const OrganizationManagementCreateUsersDialog = (props) => {
  const [newUsers, setNewUsers] = useState([newUser()]);
  const [message, setMessage] = useState({});
  const [newUsersTimeout, setNewUsersTimeout] = useState({});
  const [results, setResults] = useState([]);
  const [resultsDialog, setResultsDialog] = useState(false);
  const [orgName, setOrgName] = useState(props.organizationId);

  const closeMainDialog = () => {
    setNewUsers([newUser()]);
    props.handleClose(true)();
  };
  const resultsHandleClose = () => {
    setMessage({});
    setResultsDialog(false);
  };

  const getPolicyName = (policyid) => {
    if (!policyid) {
      return "";
    }

    const matchedpol = DEFAULT_POLICIES.filter((p) => p.policyid === policyid);
    if (matchedpol.length === 1) {
      return matchedpol[0].name;
    }
    return "";
  };

  const saveCSV = () => {
    const rows = [].concat(
      [["username", "password", "rights", "remarks"]],
      results.map((a) => {
        return [
          a?.email,
          a?.password || "",
          a?.policies ? getPolicyName(a?.policies[0] || null) : "",
          a?.result || a?.error,
        ];
      })
    );

    const filename = "CreateUpdateUsersForOrg_" + orgName + ".csv";
    CSVExporter.export(filename, rows);
  };
  const createUsers = () => {
    if (Object.keys(newUsers).length > 0) {
      props.createOrUpdateUsers(props.organizationId, newUsers).then((rv) => {
        clearTimeout(newUsersTimeout);
        //console.log("rv is", rv);
        if (rv.status === 0) {
          if (props?.user?.item_access_key) {
            props.logUserEvent(
              props?.user?.userid,
              props?.user?.item_access_key,
              {
                evcat: "user",
                user: props?.user?.username,
                data: JSON.stringify(
                  rv.results.map((a) => {
                    return [
                      a?.email,
                      a?.result || a?.error,
                      a?.policies ? getPolicyName(a?.policies[0] || null) : "",
                    ];
                  })
                ),
                evtype: "admin",
              }
            );
          }
          setResults(rv.results.sort((a, b) => a.restype - b.restype));
          setNewUsers([newUser()]);
          setMessage({
            content:
              "Once this window is closed, new user passwords cannot be accessed. Copy or save as CSV file",
          });
          setOrgName(props.organizationName);
          props.handleClose(true)();
          setResultsDialog(true);
          /*setNewUsersTimeout(
            setTimeout(() => {
              setNewUsers([newUser()]);
              setMessage({});
              props.handleClose(true)();
            }, 1000)
          );*/
        } else {
          // error
          setMessage({
            content: `ERROR: ${
              rv.error || "Something went wrong with your request"
            }; please try again or contact support`,
            severity: "error",
          });
          setNewUsersTimeout(
            setTimeout(() => {
              setNewUsers([newUser()]);
              setMessage({});
              props.handleClose(true)();
            }, 5000) //close after 5 seconds of showing the error
          );
        }
      });
    }
  };

  const addUser = () => {
    setNewUsers(newUsers.concat([newUser()]));
  };

  const deleteUser = (i) => {
    const nUsers = newUsers.filter((user, index) => index !== i);
    setNewUsers(nUsers.length === 0 ? [newUser()] : nUsers);
  };

  return (
    <Grid>
      <Dialog
        open={props.isOpen}
        onClose={closeMainDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Create/Update Users</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            //wrap="nowrap"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Grid container direction="row" wrap="nowrap">
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {`Create new users or add/update existing users to ` +
                      props.organizationName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
              <Table id="userinputs">
                <TableBody>
                  {newUsers.map((a, i) => {
                    return (
                      <TableRow key={"newuser-row-" + i}>
                        <AttributeTableCell>
                          <TextField
                            id={"select-email-" + a.email}
                            inputRef={props.textFieldRef}
                            label="Email"
                            margin="normal"
                            style={{ margin: 3, width: "100%" }}
                            value={a.email}
                            onChange={(event) => {
                              const tempv = newUsers.slice();
                              tempv[i].email = event.target.value;
                              setNewUsers(tempv);
                            }}
                          />
                        </AttributeTableCell>
                        <AttributeTableCell>
                          <Select
                            label="Policy"
                            value={a.policies[0]}
                            onChange={(event) => {
                              const tempv = newUsers.slice();
                              tempv[i].policies[0] = event.target.value;
                              setNewUsers(tempv);
                            }}
                            style={{
                              fontSize: "inherit",
                              margin: 3,
                            }}
                          >
                            {DEFAULT_POLICIES.map((attr) => {
                              return (
                                <MenuItem
                                  value={attr.policyid}
                                  key={attr.policyid}
                                >
                                  {attr.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </AttributeTableCell>
                        <AttributeTableCell>
                          <IconButton onClick={() => deleteUser(i)}>
                            <CancelIcon />
                          </IconButton>
                          {i === newUsers.length - 1 && (
                            <IconButton
                              aria-owns="attr-type-menu"
                              onClick={addUser}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          )}
                        </AttributeTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="nowrap">
                <Grid item xs={9}>
                  <Typography variant="body2" color={message.severity}>
                    {message.content}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMainDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={createUsers}
            disabled={newUsers[0].email.length === 0}
            color="primary"
          >
            Create/Update Users
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={resultsDialog} onClose={resultsHandleClose} maxWidth="lg">
        <DialogTitle id="form-dialog-title">
          Results for Create/Update Users
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            style={{ height: "100%" }}
          >
            <Grid item style={{ overflowY: "auto" }}>
              <Table id="results">
                <TableBody>
                  <TableRow>
                    <AttributeTableCell>Username</AttributeTableCell>
                    <AttributeTableCell>Password</AttributeTableCell>
                    <AttributeTableCell>Rights</AttributeTableCell>
                    <AttributeTableCell>Remarks</AttributeTableCell>
                  </TableRow>
                  {results.map((a, i) => {
                    return (
                      <TableRow key={"results-row-" + i}>
                        <AttributeTableCell>{a.email}</AttributeTableCell>
                        <AttributeTableCell>{a?.password}</AttributeTableCell>
                        <AttributeTableCell>
                          {a?.policies
                            ? getPolicyName(a?.policies[0] || null)
                            : ""}
                        </AttributeTableCell>
                        <AttributeTableCell>
                          {a?.result || a?.error}
                        </AttributeTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="nowrap">
                <Grid item xs={9} style={{ overflowY: "auto", paddingTop: 12 }}>
                  <Typography variant="body1" color={message.severity}>
                    {message.content}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveCSV} color="primary">
            Save CSV
          </Button>
          <Button onClick={resultsHandleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default OrganizationManagementCreateUsersDialog;
