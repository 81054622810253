import React, { useState } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";

import QueryService from "services/QueryService";
import { getItem } from "store/operations";

const AddNewTagDialog = ({ stackId, tagType, open, onClose, callback }) => {
  const [name, setName] = useState("");

  const dispatch = useDispatch();

  const addNewTag = async () => {
    const tag_id = await QueryService.createTopicTag(stackId, name);
    dispatch(getItem(stackId)).then((rv) => {
      if (callback) {
        callback(tag_id);
      }
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Name the new {tagType}
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={addNewTag} color="primary" disabled={!name}>
          Create
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewTagDialog;
