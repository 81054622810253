import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50%",
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
}));

const errorMessages = {
  metricSampleSize: "Please enter an integer greater than or equal to 10",
};

const MetricOption = (props) => {
  const { options, setOptions } = props;
  const classes = useStyles();

  const errorMessage = () => {
    let errorFound = false;
    for (let error in props.errors) {
      if (error.includes("metric") && !errorFound) {
        errorFound = true;
        return (
          <p style={{ color: "red", fontSize: "10px" }}>
            {errorMessages[error]}
          </p>
        );
      }
    }
  };

  const validations = (sampleSize, metricType) => {
    if (metricType === "snapshot") {
      props.noErrorDetected("metricSampleSize");
      return;
    }
    let currentSampleSize =
      sampleSize != null ? Number(sampleSize) : Number(options.metric.samples);
    if (sampleSize === "") {
      currentSampleSize = null;
    }
    if (sampleSize < 10 || !Number.isInteger(currentSampleSize)) {
      props.errorDetected("metricSampleSize");
    } else {
      props.noErrorDetected("metricSampleSize");
    }
  };

  return (
    <FormControl
      fullWidth={true}
      style={{
        flexDirection: "column",
        margin: 24,
        width: "calc(100% - 48px)",
      }}
    >
      <FormLabel
        style={{ fontSize: 14, fontStyle: "italic" }}
        component="legend"
      >
        Metric
      </FormLabel>
      <FormControl
        fullWidth={true}
        style={{ margin: 6, flexDirection: "row", alignItems: "flex-end" }}
      >
        <TextField
          className={classes.textField}
          id="select-metric-type"
          label="Metric Type"
          select
          value={options.metric.type}
          onChange={(event) => {
            setOptions({
              metric: {
                ...options.metric,
                type: event.target.value,
              },
            });
            validations(options.metric.samples, event.target.value);
          }}
          margin="normal"
          variant="filled"
          SelectProps={{
            className: classes.selectInput,
          }}
        >
          <MenuItem key="metric-snapshot" value="snapshot">
            Snapshot
          </MenuItem>
          {/* <MenuItem key="metric-average" value="average">
            Average
          </MenuItem> */}
          <MenuItem key="metric-maximum" value="max">
            Maximum
          </MenuItem>
          <MenuItem key="metric-minimum" value="min">
            Minimum
          </MenuItem>
        </TextField>
        {options.metric.type !== "snapshot" && (
          <TextField
            className={classes.textField}
            id="metric-samples"
            label="Over number of samples"
            value={options.metric.samples}
            onChange={(event) => {
              setOptions({
                metric: {
                  ...options.metric,
                  samples: event.target.value,
                },
              });
              validations(event.target.value, options.metric.type);
            }}
            type="number"
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
        )}
      </FormControl>
      {errorMessage()}
    </FormControl>
  );
};

export default MetricOption;
