import React, { useRef, useContext, useState } from "react";
import { getRole, QUANTITY_ROLE, STATES_ROLE } from "services/RolesService";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { AlertTemplateDialog } from "containers";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { default as _omit } from "lodash/omit";
import { ISXContext } from "services/Utils";
import TagRoleProperties, { TagGeneralAttributes } from "./TagRoleProperties";

const useStyles = makeStyles({
  outerContainer: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  innerContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
  },
  tagLabel: {
    fontWeight: 500,
  },
  tagHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tagTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
});

// need full set of "protected props"
const PROTECTED_TAG_PROPS = ["alarms", "s_int", "roles"];

const TagConfig = ({
  stack,
  tagId,
  tagProperties = {},
  handleTagPropChange,
  handleTagPropsChange,
  showStackLabel = false,
  showCheck = true,
  checked = false,
  onCheckChange,
  readOnly = true,
}) => {
  const SHOWOTHERPROPS = false;
  const classes = useStyles();
  const isxContext = useContext(ISXContext);
  const [openAlertTemplateDialog, setOpenAlertTemplateDialog] = useState(false);
  const stackLabel = useRef(
    stack?.name || JSON.parse(stack.dev_session)["stack.label"] || "<Unnamed>"
  );

  let leftoverTagProperties = SHOWOTHERPROPS
    ? _omit(tagProperties ?? {}, PROTECTED_TAG_PROPS)
    : {};
  let roleIds = tagProperties.roles ?? [];
  if (roleIds.indexOf(QUANTITY_ROLE.guuid) === -1) {
    roleIds = [QUANTITY_ROLE.guuid, ...roleIds];
  }

  if (roleIds.indexOf(STATES_ROLE.guuid) === -1) {
    roleIds = [...roleIds, STATES_ROLE.guuid];
  }

  const roles = roleIds.map((rid) => getRole(rid)).filter(Boolean);
  roles.forEach((r) => {
    leftoverTagProperties = _omit(
      leftoverTagProperties,
      Object.keys(r.attributes ?? {})
    );
  });

  const handleAlertTemplateDialogOpen = (event) => {
    //setAnchorEl(null);
    setOpenAlertTemplateDialog(true);
  };

  const handleAlertTemplateDialogClose = (event) => {
    setOpenAlertTemplateDialog(false);
  };

  const leftoverTagPropsMetadata = Object.entries(leftoverTagProperties).map(
    ([p, v]) => {
      const metadata = { exposed: true };
      const vtype = typeof v;
      if (vtype === "string" || vtype === "number") {
        metadata.type = vtype;
      }
      return [p, metadata];
    }
  );

  return (
    <div id="TagConfig">
      <Grid container className={classes.outerContainer}>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          wrap="wrap"
          justifyContent="space-around"
          className={classes.innerContainer}
        >
          <Grid item xs={12}>
            <div className={classes.tagHeaderContainer}>
              <div className={classes.tagTitleContainer}>
                {showCheck && (
                  <Checkbox checked={checked} onChange={onCheckChange} />
                )}
                <Typography>Tag ID:&nbsp;</Typography>
                <Typography className={classes.tagLabel}>
                  {showStackLabel ? `${stackLabel.current}:${tagId}` : tagId}
                </Typography>
              </div>
              {isxContext.alertTemplates && (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleAlertTemplateDialogOpen}
                  disabled={readOnly}
                >
                  Auto Set Alerts
                </Button>
              )}
            </div>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            {roles.map((r, idx) => {
              return (
                <Grid key={r.guuid} item container xs={12}>
                  {idx === 0 ? (
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="overline">{r.name}</Typography>
                      </Grid>
                      <TagRoleProperties
                        {...{
                          tagId,
                          tagProperties,
                          role: r,
                          handleTagPropChange,
                          handleTagPropsChange,
                          readOnly,
                          accordion: idx !== 0,
                          expanded: idx === 0,
                        }}
                      />
                    </div>
                  ) : (
                    <Accordion
                      square
                      defaultExpanded={idx === 0}
                      style={{ boxShadow: "unset", width: "100%" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${r.guuid}-role-panel-content`}
                        id={`${r.guuid}-role-panel-header`}
                        style={{
                          flexDirection: "row-reverse",
                          paddingLeft: 0,
                        }}
                      >
                        <Typography variant="overline">{r.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TagRoleProperties
                          {...{
                            tagId,
                            tagProperties,
                            role: r,
                            handleTagPropChange,
                            handleTagPropsChange,
                            readOnly,
                            accordion: idx !== 0,
                            expanded: idx === 0,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Grid>
              );
            })}
            {leftoverTagPropsMetadata.length > 0 && (
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="overline">Other Properties</Typography>
                </Grid>
                <TagGeneralAttributes
                  attributes={leftoverTagPropsMetadata}
                  tagId={tagId}
                  tagProperties={tagProperties}
                  handleTagPropChange={handleTagPropChange}
                  readOnly={readOnly}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AlertTemplateDialog
        tag={tagId}
        stack={stack}
        open={openAlertTemplateDialog}
        templates={isxContext.alertTemplates}
        handleClose={handleAlertTemplateDialogClose}
      />
    </div>
  );
};

export default TagConfig;
