import React, { useRef } from "react";
import BaseSinglePointWidget from "../base-widgets/BaseSinglePointWidget";
import { LevelWidget as LevelWidgetComponent } from "../../../components/widgets";
import { default as uniq } from "lodash/uniq";
import ISXUtils from "services/Utils";

const LevelWidget = (props) => {
  const lastTag = useRef();
  const lastRange = useRef();
  const config = useRef([]);

  const buildLabels = (indicators, range) => {
    const numberOrString = (val) => (isNaN(val) ? val : Number(val));

    const min = range.min ? parseFloat(range.min) : 0;
    const max = range.max ? parseFloat(range.max) : 1000;

    const points =
      indicators.length > 0
        ? [
            min,
            ...uniq(indicators.map((x) => numberOrString(x.value))).sort(
              (a, b) => a - b
            ),
            max,
          ]
        : [min, (max + min) / 2, max];
    const labels = points.map((val) => {
      return { label: val, bar: val !== min };
    });
    return { labels: labels, range: range };
  };

  return (
    <BaseSinglePointWidget {...props}>
      {(baseProps) => {
        const { widget = {}, tag, stacks } = baseProps;

        // need to rebuild labels if tag or range has changed
        const range = (widget.options || {}).range;
        if (lastTag.current !== tag || lastRange.current !== range) {
          lastTag.current = tag;
          lastRange.current = range;

          const newrange =
            range?.type === "tag"
              ? ISXUtils.getTagRange(
                  //stacks[tag.stack]["data_config"][tag.attribute] || {}
                  stacks?.[tag.stack]?.["data_config"]?.[
                    tag.attribute.split("|")[0]
                  ] || {}
                )
              : range;
          //const tagIndicators = (tag && tag.indicators) || [];
          let tagIndicators = [];
          if (tag.indicator_source === "tag") {
            if (tag.stack && tag.stack in stacks) {
              //if (stack && attr.current) {
              tagIndicators = ISXUtils.getTagIndicators(
                //stacks[tag.stack]["data_config"][tag.attribute] || {}
                stacks?.[tag.stack]?.["data_config"]?.[
                  tag.attribute.split("|")[0]
                ] || {}
              );
            }
          } else {
            tagIndicators = (tag && tag.indicators) || [];
          }
          config.current = buildLabels(tagIndicators, newrange || {});
        }

        return <LevelWidgetComponent {...baseProps} config={config.current} />;
      }}
    </BaseSinglePointWidget>
  );
};

export default LevelWidget;
