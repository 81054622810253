import React, { useState, useRef, useEffect } from "react";
import StackManagementDialog from "./StackManagementDialog";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";

import MUIDataTable, { TableToolbar, TableFilterList } from "mui-datatables";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { default as _last } from "lodash/last";

import { STATUS_COLORS } from "../../color/colors";

import Widget from "../../widget/Widget";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";
const DEFAULT_COLUMNS = [
  "label",
  "last_updated",
  "status",
  "location",
  "owner",
];

const useStyles = makeStyles({
  filterSelected: {
    marginLeft: 0,
    marginRight: 0,
  },
  filterSelectedLabel: {
    marginRight: 6,
  },
  infoIcon: {
    color: blue[500],
    cursor: "pointer",
  },
  statusCell: {
    height: "1em",
    width: "3em",
  },
});

const tableMuiTheme = (theme) =>
  createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: {
          display: "flex",
          flexFlow: "column",
          height: "100%",
        },
      },
      MuiIconButton: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "1em",
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
        },
      },
    },
  });

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const getStatusFromLastUpdated = (now, lastUpdated, updateInterval) => {
  const diff = now.diff(moment(lastUpdated), "seconds");
  updateInterval = parseInt(updateInterval || "10");
  const intervals = diff / updateInterval;
  if (intervals < 4) {
    //changes from < 2 to < 4. < 2 was too aggressive in changing state to warning when even when there's a slight delay in updates.
    return "good";
  } else if (intervals < 10) {
    return "warning";
  } else {
    return "bad";
  }
};

// for better performance, make component "pure" (rerender only on shallow prop changes)
const MemoizedTable = React.memo(MUIDataTable);

const ReadonlyToolbar = (props) => {
  return (
    <Tooltip
      disableFocusListener
      title="You will need to unlock the dashboard to sort, filter or search"
      // placement="top"
    >
      <div style={{ pointerEvents: "auto", opacity: 0.5 }}>
        <div style={{ pointerEvents: "none" }}>
          <TableToolbar {...props} />
          <div style={{ height: 0, width: 0, overflow: "hidden" }}>
            <input autoFocus></input>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const ReadonlyChip = (props) => {
  const { label } = props;
  return (
    <Tooltip title="You will need to unlock the dashboard to change filtering">
      <Chip label={label} onDelete={undefined} />
    </Tooltip>
  );
};

const ReadonlyTableFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={ReadonlyChip} />;
};

const StacksManagementWidget = (props) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  const { widget = {}, updateWidgetOptions, stacks, readOnly } = props;
  const widgetOptions = widget.options || {};

  const selected = useRef(
    (widgetOptions.selected || []).reduce((acc, s) => {
      acc[s] = true;
      return acc;
    }, {})
  );

  const readOnlyRef = useRef(false);
  const searchText = useRef(null);
  const searchTextTimeout = useRef(null);
  const lastWidgetOptions = useRef(null);
  const selectedStack = useRef(null);
  const tableRef = useRef();

  const classes = useStyles();

  const toggleRowSelect = (guuid) => {
    if (readOnlyRef.current) {
      return;
    }
    const checked = !selected.current[guuid];
    const updatedSelected = { ...selected.current, [guuid]: checked };
    selected.current = updatedSelected;
    // force table rerender
    setForceRender((f) => !f);
    const updatedSelectedOption = Object.entries(updatedSelected)
      .filter((s) => s[1])
      .map((s) => s[0]);
    updateWidgetOptions({ selected: updatedSelectedOption });
  };

  const columns = useRef(
    [
      {
        name: "",
        label: "",
        options: {
          empty: true,
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => (
            <InfoTooltip
              title="Click to see device details"
              onClick={(e) => {
                const stackId = _last(tableMeta.rowData);
                selectedStack.current = stackId;
                setDetailsOpen(true);
              }}
            >
              <OpenInNewIcon fontSize="small" className={classes.infoIcon} />
            </InfoTooltip>
          ),
        },
      },
      {
        name: "selected",
        id: "selected",
        label: "\u2713",
        options: {
          sort: false,
          filterType: "custom",
          filterList: [],
          customFilterListOptions: {
            render: (v) => {
              return v.length ? "selected only" : false;
            },
          },
          filterOptions: {
            names: [],
            logic: (selected, filters) => {
              return filters.length ? !selected : false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!filterList[index].length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          filterList[index].push("selected");
                        } else {
                          filterList[index].length = 0;
                        }
                        onChange(filterList[index], index, column);
                      }}
                    />
                  }
                  classes={{
                    root: classes.filterSelected,
                    label: classes.filterSelectedLabel,
                  }}
                  label="Selected Only"
                  labelPlacement="start"
                />
              );
            },
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Checkbox
                checked={value}
                onChange={() => toggleRowSelect(_last(tableMeta.rowData))}
              />
            );
          },
        },
      },
    ]
      .concat(
        (DEFAULT_COLUMNS || []).map((attr, idx) => {
          return {
            name: attr,
            label: attr.replace("_", " "),
            id: attr,
            options: {
              filterList: [],
              customBodyRender: (value, tableMeta, updateValue) => {
                if (attr === "last_updated") {
                  return value ? moment(value).format(DATE_FORMAT) : "---";
                } else if (attr === "status") {
                  return (
                    <div
                      className={classes.statusCell}
                      style={{
                        backgroundColor: STATUS_COLORS[value],
                      }}
                    />
                  );
                }
                return value;
              },
            },
          };
        })
      )
      .concat([
        {
          name: "guuid",
          id: "guuid",
          options: {
            display: "excluded",
            filter: false,
          },
        },
      ])
  );

  const options = useRef({
    elevation: 0,
    responsive: "standard",
    filter: true,
    search: true,
    filterType: "dropdown",
    selectableRows: "none",
    expandableRows: false,
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    rowHover: false,
    searchText: "",
    sortOrder: {},
    textLabels: {
      body: {
        toolTip: "Sort",
      },
    },
    setRowProps: (row, dataIndex) => {
      if (selectedStack.current && _last(row) === selectedStack.current) {
        return {
          style: { backgroundColor: "darkgray" },
        };
      }
      return null;
    },
    onSearchChange: (text) => {
      options.current.searchText = text;
      clearTimeout(searchTextTimeout.current);
      searchTextTimeout.current = setTimeout(() => {
        updateWidgetOptions({
          search: text || null,
        });
      }, 1000);
    },
    onSearchClose: () => {
      searchText.current = "";
      updateWidgetOptions({ search: null });
    },
    onColumnSortChange: (colname, sortdir) => {
      // options.current.sortOrder = {
      //   name: colname,
      //   direction: sortdir,
      // }
      if (readOnlyRef.current) {
        const [colname, sortdir] = lastWidgetOptions.current.sort || [
          "timestamp",
          "desc",
        ];
        // options.current.sortOrder = {
        //   name: colname,
        //   direction: sortdir,
        // }
        options.current = {
          ...options.current,
          sortOrder: {
            name: colname,
            direction: sortdir,
          },
        };
        setForceRender((f) => !f);
      } else {
        updateWidgetOptions({
          sort: [colname, sortdir],
        });
      }
    },
    onFilterChange: (col, filterList) => {
      const colIdx = columns.current.findIndex(
        (element) => element.name === col
      );
      if (colIdx !== -1) {
        columns.current[colIdx].options.filterList = filterList[colIdx];
      }
      const filters = columns.current.reduce((acc, col) => {
        if (col.options.filter !== false) {
          acc[col.name] = col.options.filterList;
        }
        return acc;
      }, {});
      updateWidgetOptions({ filters });
    },

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,

    // setFilterChipProps: (colIndex, colName, data) => {
    //   return {
    //     disabled: readOnly,
    //   };
    // }
  });

  useEffect(() => {
    // options.current.textLabels.body.toolTip = readOnly ? "You will need to unlock dashboard to sort" : "Sort";
    // options.current.setFilterChipProps = (colIndex, colName, data) => {
    //   return {
    //     disabled: readOnly,
    //   };
    // }
    readOnlyRef.current = readOnly;
    setForceRender((f) => !f);
  }, [readOnly]);

  const stackIds = Object.keys(stacks || {}).sort();

  if (lastWidgetOptions.current !== widgetOptions) {
    lastWidgetOptions.current = widgetOptions;
    const newWidgetOptions = widgetOptions || {};

    const wfilters = newWidgetOptions.filters || {};
    columns.current.forEach((col) => {
      if (col.options.filter !== false) {
        col.options.filterList = wfilters[col.name] || [];
      }
    });

    const [colname, sortdir] = newWidgetOptions.sort || ["timestamp", "desc"];
    options.current.sortOrder = {
      name: colname,
      direction: sortdir,
    };
    // columns.current.forEach(
    //   (col) =>
    //     (col.options.sortDirection = col.name === colname ? sortdir : "none")
    // );

    options.current.searchText = newWidgetOptions.search || "";
  }

  const now = moment();
  const data = stackIds.map((guuid) => {
    const st = stacks[guuid] || {};
    const lastUpdated = parseInt(st.st_time);
    const lastBPUpdateTime =
      st.lastbpuptime != null ? parseInt(st.lastbpuptime) : null;
    const status = getStatusFromLastUpdated(now, lastUpdated, st.upd_int);

    const devSession = JSON.parse(st.dev_session || "{}");
    return {
      guuid: st.guuid,
      selected: !!selected.current[guuid],
      label: st?.name || devSession["stack.label"] || "<Unnamed>",
      location: st?.location || devSession["stack.location"] || "<Unknown>",
      last_updated: lastUpdated,
      status,
      owner: st.g_owner,
      last_bp_update: lastBPUpdateTime,
      stack: st,
    };
  });

  const closeDetails = () => {
    selectedStack.current = null;
    setDetailsOpen(false);
  };

  return (
    <>
      <Widget
        {...props}
        configurable={false}
        dataType="stacks"
        widgetTitle="Stacks Management"
      >
        <MuiThemeProvider theme={(theme) => tableMuiTheme(theme)}>
          <MemoizedTable
            data={data}
            columns={columns.current}
            options={options.current}
            forceRender={forceRender}
            components={{
              TableToolbar: readOnly ? ReadonlyToolbar : TableToolbar,
              TableFilterList: readOnly
                ? ReadonlyTableFilterList
                : TableFilterList,
            }}
          />
        </MuiThemeProvider>
      </Widget>
      <StackManagementDialog
        {...props}
        detailsOpen={detailsOpen}
        closeDetails={closeDetails}
        selectedStack={selectedStack.current}
        tableRef={tableRef}
      />
    </>
  );
};

export default StacksManagementWidget;
