import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { WIDGET_COLORS_ARRAY } from "../../../../color/colors";
import { withStyles } from "@material-ui/core/styles";

var Chart = require("chart.js");

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  body: {
    paddingLeft: 12,
    paddingRight: 12,
    borderTop: "none",
  },
}))(TableCell);

const errorMessages = {
  missingSeriesType: "Please enter a series type",
  missingOutputUnit: "Please enter an output unit",
  missingStates: "Please enter at least 2 states",
  missingValues:
    "Please enter a lower bound, upper bound, and minimum duration for each state",
  missingDisplay: "Please enter a default dispaly state for each state",
  missingColor: "Please enter a color for each state",
  missingNoDataColor: "Please enter a color for the Missing Data setting",
  missingLabel: "Please enter a label for each state",
};
const StateHistoryReportingOptions = (props) => {
  const IS_HISTORY_WIDGET =
    ((props.widget || {}).type || "") === "StateHistoryChart";

  const [chartOptions, setChartOptions] = useState({
    seriesType: props.options.statereporting.seriesType || "full",
    outputUnit: props.options.statereporting.outputUnit || "percent",
    noDataColor:
      props.options.statereporting.noDataColor || "rgb(158, 158, 158)",
    states: props.options.statereporting.states || [],
  });

  const [errors, setErrors] = useState([]);

  function processNewOptions(newOptions) {
    setChartOptions(newOptions);
    props.setOptions({
      statereporting: newOptions,
    });

    validation(newOptions);
  }

  useEffect(() => {
    const newStates = chartOptions.states.slice().map((state, index) => {
      const newState = { ...state };
      if (!newState.color) {
        newState.color = Chart.helpers
          .color(WIDGET_COLORS_ARRAY[index % WIDGET_COLORS_ARRAY.length])
          .rgbString();
      }
      if (!newState.display) {
        newState.display = "on";
      }
      return newState;
    });
    processNewOptions({ ...chartOptions, states: newStates });
  }, []);

  function validation({ states, noDataColor, seriesType, outputUnit }) {
    const newErrors = [];
    Object.keys(errorMessages).forEach((key) => props.noErrorDetected(key));

    if (
      typeof seriesType === "undefined" ||
      seriesType === null ||
      !seriesType
    ) {
      newErrors.push(errorMessages.missingSeriesType);
      props.errorDetected("missingSeriesType");
    }

    if (
      typeof outputUnit === "undefined" ||
      outputUnit === null ||
      !outputUnit
    ) {
      newErrors.push(errorMessages.missingOutputUnit);
      props.errorDetected("missingOutputUnit");
    }

    if (seriesType === "full") {
      if (!!states) {
        if (states.length < 2) {
          newErrors.push(errorMessages.missingStates);
          props.errorDetected("missingStates");
        }

        if (
          states.some(
            (state) =>
              !state.lowerBound || !state.upperBound || !state.minDuration
          )
        ) {
          newErrors.push(errorMessages.missingValues);
          props.errorDetected("missingValues");
        }

        if (IS_HISTORY_WIDGET) {
          if (states.some((state) => !state.display || state.display === "")) {
            newErrors.push(errorMessages.missingDisplay);
            props.errorDetected("missingDisplay");
          }
        }

        if (states.some((state) => !state.color || state.color === "")) {
          newErrors.push(errorMessages.missingColor);
          props.errorDetected("missingColor");
        }
      }

      if (!!noDataColor) {
        if (noDataColor === "") {
          newErrors.push(errorMessages.missingNoDataColor);
          props.errorDetected("missingNoDataColor");
        }
      }
    } else if (seriesType === "edges") {
      if (states.some((state) => !state.label || state.label === "")) {
        newErrors.push(errorMessages.missingLabel);
        props.errorDetected("missingDisplay");
      }
      if (states.some((state) => !state.color || state.color === "")) {
        newErrors.push(errorMessages.missingColor);
        props.errorDetected("missingColor");
      }
    }
    setErrors(newErrors);
  }

  function onNoDataColorChange(event) {
    processNewOptions({ ...chartOptions, noDataColor: event.target.value });
  }

  function onChange(index) {
    return (event) => {
      const { name, value } = event.target;

      const newStates = chartOptions.states.slice().map((state) => {
        if (state.id !== index) {
          return state;
        }

        const newState = { ...state };
        newState[name] = value;
        return newState;
      });

      processNewOptions({ ...chartOptions, states: newStates });
    };
  }

  function onChangeType(event) {
    processNewOptions({ ...chartOptions, seriesType: event.target.value });
  }

  function onChangeOutputUnit(event) {
    processNewOptions({ ...chartOptions, outputUnit: event.target.value });
  }

  function addState() {
    const newId =
      chartOptions.states.reduce(
        (maxId, { id }) => (id > maxId ? id : maxId),
        0
      ) + 1;
    const newStates = chartOptions.states.slice().concat({
      id: newId,
      label: "",
      lowerBound: "",
      upperBound: "",
      minDuration: "",
      color: "",
      display: "on",
    });
    processNewOptions({ ...chartOptions, states: newStates });
  }

  function deleteState(idToDelete) {
    const newStates = chartOptions.states
      .slice()
      .filter(({ id }) => id !== idToDelete);
    processNewOptions({ ...chartOptions, states: newStates });
  }

  //Used in display
  const formDisabled = chartOptions.seriesType !== "full";

  return (
    <React.Fragment>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        <FormLabel component="legend">
          {props.title || "Chart Settings"}
        </FormLabel>
      </FormControl>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        <RadioGroup
          aria-label="seriesType"
          name="seriesType"
          value={chartOptions.seriesType}
          onChange={onChangeType}
          row
        >
          <FormControlLabel
            value="full"
            control={<Radio />}
            label="Continuous"
          />
          <FormControlLabel
            value="edges"
            control={<Radio />}
            label="Discrete"
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        {IS_HISTORY_WIDGET && <FormLabel>Data to return</FormLabel>}
        {!IS_HISTORY_WIDGET && <FormLabel>Summary data unit</FormLabel>}
        <RadioGroup
          aria-label="outputUnit"
          name="outputUnit"
          value={chartOptions.outputUnit}
          onChange={onChangeOutputUnit}
          row
        >
          <FormControlLabel
            value="percent"
            control={<Radio />}
            label="Percentage of time"
          />
          <FormControlLabel
            value="time"
            control={<Radio />}
            label="Total time"
          />
          <FormControlLabel value="count" control={<Radio />} label="Counts" />{" "}
        </RadioGroup>
      </FormControl>
      <FormControl
        style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}
      >
        {IS_HISTORY_WIDGET && (
          <InputLabel id="noDataColor-label">Color for Missing Data</InputLabel>
        )}
        {!IS_HISTORY_WIDGET && (
          <InputLabel id="noDataColor-label">
            Color for Missing / Unclassified Data
          </InputLabel>
        )}
        <Select
          aria-label="noDataColor"
          id="noDataColor"
          name="noDataColor"
          labelId="noDataColor-label"
          value={chartOptions.noDataColor || ""}
          onChange={onNoDataColorChange}
          required
          disabled={formDisabled}
        >
          {WIDGET_COLORS_ARRAY.map((color, index) => {
            const alphaColor = Chart.helpers.color(color).rgbString();

            return (
              <MenuItem key={"no-data-color-" + index} value={alphaColor}>
                <div
                  style={{
                    height: 16,
                    width: 20,
                    backgroundColor: alphaColor,
                    border: "1px solid darkgray",
                  }}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div style={{ width: "calc(100% - 48px)", margin: 24, marginBottom: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ paddingLeft: 0 }}>
                Lower Bound
              </StyledTableCell>
              <StyledTableCell>Upper Bound</StyledTableCell>
              <StyledTableCell>Min. Duration (in seconds)</StyledTableCell>
              <StyledTableCell>Color</StyledTableCell>
              <StyledTableCell>Display on Load</StyledTableCell>
              <StyledTableCell style={{ paddingRight: 0 }}>
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartOptions.states.map((state, rowIndex) => {
              const {
                id,
                label,
                lowerBound,
                upperBound,
                minDuration,
                color,
                display,
              } = state;

              return (
                <React.Fragment key={"row-" + rowIndex + "-fragment"}>
                  <TableRow key={"row-" + rowIndex + "-top"}>
                    <StyledTableCell
                      key={"row-" + rowIndex + "-label"}
                      colSpan={6}
                      style={{ borderBottom: "none", paddingLeft: 0 }}
                    >
                      <FormControl>
                        <TextField
                          aria-label="label"
                          id={"label-" + id}
                          name="label"
                          label={
                            "State Label: " +
                            (formDisabled ? "(for " + rowIndex + ")" : "")
                          }
                          value={label}
                          onChange={onChange(id)}
                          required
                          style={{
                            margin: 6,
                          }}
                          //disabled={false}
                        />
                      </FormControl>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={"row-" + rowIndex + "-bottom"}>
                    <StyledTableCell
                      key={"row-" + rowIndex + "-lowerBound"}
                      style={{ paddingLeft: 0 }}
                    >
                      <TextField
                        aria-label="lowerBound"
                        id={"lowerBound-" + id}
                        name="lowerBound"
                        value={lowerBound}
                        onChange={onChange(id)}
                        type="number"
                        style={{
                          margin: 6,
                        }}
                        disabled={formDisabled}
                      />
                    </StyledTableCell>
                    <StyledTableCell key={"row-" + rowIndex + "-upperBound"}>
                      <TextField
                        aria-label="upperBound"
                        id="upperBound"
                        name="upperBound"
                        value={upperBound}
                        onChange={onChange(id)}
                        type="number"
                        style={{
                          margin: 6,
                        }}
                        disabled={formDisabled}
                      />
                    </StyledTableCell>
                    <StyledTableCell key={"row-" + rowIndex + "-minDuration"}>
                      <TextField
                        aria-label="minDuration"
                        id={"minDuration-" + id}
                        name="minDuration"
                        value={minDuration}
                        onChange={onChange(id)}
                        type="number"
                        style={{
                          margin: 6,
                        }}
                        disabled={formDisabled}
                      />
                    </StyledTableCell>

                    <StyledTableCell key={"row-" + rowIndex + "-color"}>
                      <Select
                        aria-label="color"
                        id={"color-" + id}
                        name="color"
                        value={color || ""}
                        onChange={onChange(id)}
                        required
                        style={{
                          margin: 6,
                        }}
                        //disabled={false}
                      >
                        {WIDGET_COLORS_ARRAY.map((color, index) => {
                          const alphaColor = Chart.helpers
                            .color(color)
                            .rgbString();

                          return (
                            <MenuItem
                              key={"row-" + id + "-color-" + index}
                              value={alphaColor}
                            >
                              <div
                                style={{
                                  height: 16,
                                  width: 20,
                                  backgroundColor: alphaColor,
                                  border: "1px solid darkgray",
                                }}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </StyledTableCell>

                    {IS_HISTORY_WIDGET && (
                      <StyledTableCell key={"row-" + rowIndex + "-display"}>
                        <Select
                          aria-label="display"
                          id={"display-" + id}
                          name="display"
                          value={display || ""}
                          onChange={onChange(id)}
                          required
                          style={{
                            margin: 6,
                          }}
                          disabled={formDisabled}
                        >
                          <MenuItem value="on">On</MenuItem>
                          <MenuItem value="off">Off</MenuItem>
                        </Select>
                      </StyledTableCell>
                    )}

                    <StyledTableCell
                      key={"row-" + rowIndex + "-cancel"}
                      style={{ paddingRight: 0 }}
                    >
                      <IconButton
                        onClick={() => deleteState(id)}
                        disabled={formDisabled}
                      >
                        <CancelIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow>
              <StyledTableCell colSpan={4}>
                <IconButton
                  onClick={addState}
                  style={{ fontSize: "1rem" }}
                  disabled={formDisabled}
                >
                  <AddCircleIcon /> Add State
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {errors.length > 0 &&
          errors.map((msg, index) => (
            <p key={"err-" + index} style={{ color: "red", fontSize: "10px" }}>
              {msg}
            </p>
          ))}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(StateHistoryReportingOptions);
