import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { getDashboard } from "store/operations";
import QueryService from "services/QueryService";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  lockButton: {
    width: 40,
    height: 40,
    padding: 0,
  },
  accessHeldByOther: {
    opacity: 0.7,
  },
});

const ForceWriteAccessDialog = (props) => {
  const { username, user, open, onClose, forceAccess } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Force Transfer of Write Access</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {username !== user.username
            ? `Write access is currently held by ${username}. Do you wish to transfer write access to your control?`
            : "You already hold write access in another browser, window or tab, or on another device. Do you wish to transfer write acess here?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={forceAccess} color="primary">
          Transfer Write Access Here
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UnlockFailDialog = (props) => {
  const { username, user, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {username
          ? username !== user.username
            ? "Write Access Held by Another"
            : "Write Access Already Held"
          : "Write Access Denied"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {username
            ? username !== user.username
              ? `Write access is currently held by ${username}.`
              : "You already hold write access in another browser window or tab, or another device."
            : "Write access to this dashboard has been denied."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DashboardLock = (props) => {
  const [accessHeldByOther, setAccessHeldByOther] = useState(true);
  const [lockDisabled, setLockDisabled] = useState(false);
  const [forceAccessDialogOpen, setForceAccessDialogOpen] = useState(false);
  const [unlockFailDialogOpen, setUnlockFailDialogOpen] = useState(false);

  const initialized = useRef(false);

  const dashboards = useSelector((state) => state.isx.dashboards);
  const user = useSelector((state) => state.isx.user);

  const [cookies] = useCookies(["ISX-user-session"]);

  const { guuid, locked, setLocked } = props;
  const guuidRef = useRef(guuid);
  const lockedRef = useRef(locked);

  const dashboard = dashboards[guuid];

  const dispatch = useDispatch();

  // unlock is combination of unlock request and
  // re-fetch of dashboard; also disable/enable lock
  // icon
  const unlockDashboard = useCallback(
    async (force = false) => {
      setLockDisabled(true);
      await QueryService.unlockDashboard(guuid, force);
      dispatch(getDashboard(guuid)).then(() => {
        setLockDisabled(false);
      });
    },
    [guuid, dispatch]
  );

  // try unlock of dashboard, but may fail due to race condition
  const tryUnlockDashboard = useCallback(
    async (showFailed = true) => {
      try {
        await unlockDashboard();
      } catch (e) {
        // could fail on race condition
        // if (e.message.startsWith("UnauthorizedError: "))
        dispatch(getDashboard(guuid)).then(() => {
          if (showFailed) {
            setUnlockFailDialogOpen(true);
          }
          setLockDisabled(false);
        });
      }
    },
    [guuid, unlockDashboard, dispatch]
  );

  const tryLockDashboard = async () => {
    try {
      setLockDisabled(true);
      await QueryService.lockDashboard(guuid);
      dispatch(getDashboard(guuid)).then(() => {
        setLockDisabled(false);
      });
    } catch (e) {
      // shouldn't fail, should show error to user
      setLockDisabled(false);
    }
  };

  useEffect(() => {
    guuidRef.current = guuid;
  }, [guuid]);

  useEffect(() => {
    lockedRef.current = locked;
  }, [locked]);

  // make sure to release write access (lock dashboard)
  // on unmount due to change of dashboard or reload of page,
  // and also on tab/window close
  useEffect(() => {
    const lockBeforeLeaving = async () => {
      if (!lockedRef.current) {
        try {
          await QueryService.lockDashboard(guuidRef.current);
        } catch (e) {
          // swallow error
        }
      }
    };

    window.onbeforeunload = () => {
      lockBeforeLeaving();
    };
    return () => {
      lockBeforeLeaving();
    };
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      if (user && dashboard && !dashboard.acu_groups?.length) {
        // only for not shared dashboard - alternatively could
        // just unlock if owner
        tryUnlockDashboard(false);
      }
      initialized.current = true;
    }
  }, [locked, dashboard, user, guuid, dispatch, tryUnlockDashboard]);

  const write_access_state = dashboard?.write_access_state;
  const userid = user?.userid;
  useEffect(() => {
    if (!write_access_state) {
      setLocked(true);
      setAccessHeldByOther(false);
    } else {
      const hasAccess =
        write_access_state.userid === userid &&
        write_access_state.session === cookies?.["ISX-user-session"];
      setLocked(!hasAccess);
      setAccessHeldByOther(!hasAccess);
    }
  }, [write_access_state, userid, cookies, setLocked]);

  const classes = useStyles();

  const toggleDashboardLock = async () => {
    if (accessHeldByOther) {
      // show dialog for force take write access
      setForceAccessDialogOpen(true);
    } else if (locked) {
      tryUnlockDashboard();
    } else {
      tryLockDashboard();
    }
  };

  const forceAccess = async () => {
    try {
      await unlockDashboard(true);
    } catch (e) {
      // should show error to user
      setLockDisabled(false);
    } finally {
      setForceAccessDialogOpen(false);
    }
  };

  return (
    <>
      <Tooltip
        title={
          accessHeldByOther
            ? `Write access is currently held by ${
                dashboard?.write_access_state?.username ?? "another"
              }`
            : locked
            ? "Click to unlock and gain write access"
            : "Click to lock"
        }
      >
        <span>
          <IconButton
            className={clsx(
              classes.lockButton,
              accessHeldByOther && classes.accessHeldByOther
            )}
            onClick={toggleDashboardLock}
            disabled={lockDisabled}
          >
            {locked ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <ForceWriteAccessDialog
        open={forceAccessDialogOpen}
        onClose={() => setForceAccessDialogOpen(false)}
        user={user}
        username={dashboard?.write_access_state?.username}
        forceAccess={forceAccess}
      />
      <UnlockFailDialog
        open={unlockFailDialogOpen}
        onClose={() => setUnlockFailDialogOpen(false)}
        user={user}
        username={dashboard?.write_access_state?.username}
      />
    </>
  );
};

export default DashboardLock;
