import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import ReactSpeedometer from "react-d3-speedometer";
import { JsonToTable } from "react-json-to-table";
import QueryService from "../../../services/QueryService";
import CSVExporter from "../../../services/CSVExporter";
const uuidv4 = require("uuid/v4");

const haveSettingsChanged = (previousSettings, newSettings) => {
  const oldOeeSBD = (previousSettings || {}).shortBreakDuration;
  const newOeeSBD = (newSettings || {}).shortBreakDuration;
  const oldOeeSBN = (previousSettings || {}).shortBreakNumber;
  const newOeeSBN = (newSettings || {}).shortBreakNumber;
  const oldOeeMBD = (previousSettings || {}).mealBreakDuration;
  const newOeeMBD = (newSettings || {}).mealBreakDuration;
  const oldOeeMBN = (previousSettings || {}).mealBreakNumber;
  const newOeeMBN = (newSettings || {}).mealBreakNumber;
  const oldOeeRejected = (previousSettings || {}).rejectedPieces;
  const newOeeRejected = (newSettings || {}).rejectedPieces;
  const oldOeeTolerance = (previousSettings || {}).tolerance;
  const newOeeTolerance = (newSettings || {}).tolerance;
  const oldOeeStart = (previousSettings || {}).start;
  const newOeeStart = (newSettings || {}).start;
  return (
    oldOeeSBD !== newOeeSBD ||
    oldOeeSBN !== newOeeSBN ||
    oldOeeMBD !== newOeeMBD ||
    oldOeeMBN !== newOeeMBN ||
    oldOeeRejected !== newOeeRejected ||
    oldOeeTolerance !== newOeeTolerance ||
    oldOeeStart !== newOeeStart
  );
};

class OEEWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // attributes: Array.from({ length: PROPERTY_ROWS }, () => {
      //   return { property: null, value: null };
      // }),
      attributes: [],
      requestPending: false,
      msg: { content: null },
      typeAnchorEl: null,
      reportJson: {},
      reportKeys: [
        "name",
        "overall_oee",
        "availability_oee",
        "performance_oee",
        "quality_oee",
        "operating_time",
        "down_time",
        "start",
        "end",
        "shift_length",
        "planned_production_time",
        "break_time",
        "short_break_interval",
        "short_break_number",
        "meal_break_interval",
        "meal_break_number",
        "rejected_pieces",
        "good_pieces",
        "total_pieces",
        "cycle_time",
        "time_unit",
        "actual_piece_rate",
        "ideal_piece_rate",
        "alternative_down_time",
        "alternative_up_time",
      ],
      status: { content: "" },
    };
    //console.log("state and props are", this.state, this.props);
    this.processOEE();
  }

  processOEE = () => {
    //console.log(this.props);
    let request = {};
    if (this.props.widget.stacks.length > 0) {
      request["guuid"] = this.props.widget.stacks[0];
      if (!(request.guuid in this.props.stacks)) {
        console.log("stack data not available", request.guuid);
        return;
      }
      request["access_key"] = this.props.stacks[request.guuid].access_key;
      request["op"] = "process";

      let oee = this.props.widget.options.oee;
      let params = { name: "compute_oee" };
      params["short_break_interval"] = oee.shortBreakDuration;
      params["short_break_number"] = oee.shortBreakNumber;
      params["meal_break_interval"] = oee.mealBreakDuration;
      params["meal_break_number"] = oee.mealBreakNumber;
      params["rejected"] = oee.rejectedPieces;
      params["tolerance"] = oee.tolerance / 100.0;
      params["day"] = oee.start; //12:00 A.M Local time
      // use shift_begin and shift_end in default params for the triffer in lambda. Get only the 12.00 A.M local time in day for now, until we have a requirement to let users specify start and end time each time in UI
      //params["start"] = oee.start + 6 * 3600 * 1000; //start is at 6 a.m.
      //params["end"] = params["start"] + 12 * 3600 * 1000; //12 hours time from start
      request["params"] = params;
    } else {
      console.log("no stack selected to process");
      return;
    }
    /*let request = {
      guuid: "~01d3449199af58cc360c39f068938eb4d3",
      access_key: "ucyOkLqYcxjQsnktMahptPrcctwtLMjT",
      op: "process",
      params: { name: "compute_oee" },
    };*/
    //console.log("requet is", request);
    QueryService.sendProcessMessage(request).then((result) => {
      //console.log("result after making query is", result);
      if (result.status === 0) {
        let newreport = {};
        let res = JSON.parse(result.result);
        this.state.reportKeys.forEach((key) => {
          newreport[key] = res[key];
        });
        this.setState({
          reportJson: newreport,
          status: res.status
            ? { content: " - " + res.status, severity: "error" }
            : { content: "" },
        });
      }
      //console.log("state is", this.state);
    });
    /*let response = this.props.setProps({
      guuid: "~01d3449199af58cc360c39f068938eb4d3",
      access_key: "ucyOkLqYcxjQsnktMahptPrcctwtLMjT",
      op: "process",
      params: { name: "compute_oee" },
    });
    console.log("response is", response);*/
  };

  componentDidUpdate = (prevProps, prevState) => {
    //console.log("in comp update", this.props);
    const oldTags = (this.props.widget || {}).tags || [];
    const newTags = (prevProps.widget || {}).tags || [];

    const settingsChanged =
      !prevProps ||
      !prevProps.widget ||
      !prevProps.widget.options.oee ||
      !this.props ||
      !this.props.widget ||
      !this.props.widget.options.oee ||
      haveSettingsChanged(
        prevProps.widget.options.oee,
        this.props.widget.options.oee
      ) ||
      oldTags.length !== newTags.length ||
      oldTags.some(
        (tag, index) =>
          tag.stack !== newTags[index].stack ||
          tag.attribute !== newTags[index].attribute
      ) ||
      this.props.startTime !== prevProps.startTime ||
      this.props.endTime !== prevProps.endTime;
    console.log("settings changed state is ", settingsChanged);
    if (settingsChanged) {
      this.processOEE();
    }
  };

  handleAttributeTypeMenuOpen = (event) => {
    this.setState({
      typeAnchorEl: event.currentTarget,
    });
  };

  handleAttributeTypeMenuClose = () => {
    this.setState({ typeAnchorEl: null });
  };

  haveValidAttributes = () => this.state.attributes.some((a) => !!a.key);

  saveAttrsTimeout = null;
  saveAttrs = () => {
    const canonicalValue = (type, val) => {
      if (type === "Number") {
        return Number(val);
      } else {
        return val;
      }
    };

    const widget = this.props.widget || {};
    const stackId = (widget.stacks || [])[0];
    if (stackId) {
      const attrs = this.state.attributes.reduce((acc, a) => {
        if (a.key) {
          acc[a.key] = canonicalValue(a.type, a.value);
        }
        return acc;
      }, {});
      if (Object.keys(attrs).length > 0) {
        this.setState({ requestPending: true }, () =>
          this.props.setProps(stackId, attrs).then(
            // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
            (rv) => {
              clearTimeout(this.saveAttrsTimeout);
              if (rv.status === 0) {
                // ok
                this.setState(
                  { msg: { content: "Request successfully submitted" } },
                  () => {
                    this.saveAttrsTimeout = setTimeout(
                      () => this.setState({ msg: { content: null } }),
                      3000
                    );
                  }
                );
              } else {
                // error
                this.setState({
                  msg: {
                    content: `ERROR: ${
                      rv.error || "Something went wrong with your request"
                    }; please try again or contact support`,
                    severity: "error",
                  },
                });
              }
              this.setState({ requestPending: false });
            }
          )
        );
      }
    }
  };

  addAttribute = (type, initialval = null) => {
    let attributes = [...this.state.attributes];
    const guuid = uuidv4();
    attributes.push({
      guuid,
      type,
      key: "",
      value: initialval,
    });
    this.setState({ attributes });
    this.handleAttributeTypeMenuClose();
  };

  deleteAttribute = (i) => {
    let attributes = [...this.state.attributes];
    attributes.splice(i, 1);
    this.setState({ attributes });
  };

  exportToCSV = () => {
    //const OUTPUT_UNIT = (this.props.widget.options.statereporting || {}).outputUnit || "percent";
    //const headers = ["Timestamp ISO 8601", "Period Start", "", "Counts"];
    let data = [];
    if (Object.keys(this.state.reportJson).length > 0) {
      data = [
        this.state.reportKeys.map((key) => {
          return this.state.reportJson[key];
        }),
      ];
    }
    const filename = this.state.reportJson.name || "OEE Report";

    CSVExporter.export(filename, [this.state.reportKeys].concat(data));
  };

  render = () => {
    const widget = this.props.widget || {};
    const value1 = 67;
    //const lastSize = useRef(null);
    //const lastConfig = useRef(null);
    //const lastWidget = useRef(null);
    //const lastNeedleColor = useRef(null);
    const width = 200;
    const height = 100;
    let value = value1;

    const range = (widget.options || {}).range || {};
    const min = range.min ? parseFloat(range.min) : 0;
    const max = range.max ? parseFloat(range.max) : 100;
    const ringWidth = Math.max(Math.min(height / 2, width / 4), 1) || 1;

    const needleHeightRatio = ringWidth < 50 ? 0.7 : 0.9;
    const labelFontSize = Math.max(Math.min((16 * width) / 600, 14), 9);
    // expect a number
    if (typeof value === "string") {
      value = Number(value);
    }

    let needleColor;

    let forceRender = false;

    /*if (lastNeedleColor.current !== needleColor) {
      lastNeedleColor.current = needleColor;
      forceRender = true;
    }*/

    // need to make value string, since we will override speedometer component display
    if (typeof value === "number" && !Number.isInteger(value)) {
      value = value.toFixed(1); // if not integer, 1 digit after decimal
    } else {
      value = value != null ? "" + value : "";
    }

    const { points: customSegmentStops, colors: segmentColors } =
      this.props.config || {};

    return (
      <Widget
        {...this.props}
        dataType="stacks"
        maxStacks={1}
        widgetTitle="OEE"
        exportToCSV={this.exportToCSV}
      >
        <Card style={{ height: "calc(100%)", marginTop: 0, marginBottom: 0 }}>
          <CardContent
            style={{ height: "calc(100% - 32px)", paddingBottom: 16 }}
          >
            <Backdrop
              style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
              open={this.state.loading || this.props.loading}
            >
              <Paper style={{ backgroundColor: "white", padding: 10 }}>
                Calculating...
              </Paper>
            </Backdrop>
            <div style={{ height: 19 }}>
              <Typography variant="subtitle2">
                {this.state.reportJson.name
                  ? this.state.reportJson.name
                  : "Configure widget settings for OEE report"}
              </Typography>
            </div>
            <Grid container direction="row">
              <Grid item>
                <div style={{ height: 100 }}>
                  <ReactSpeedometer
                    style={{ overflow: "visible" }}
                    minValue={min}
                    maxValue={max}
                    width={width * 1.2}
                    height={height * 1.5}
                    forceRender={forceRender}
                    value={
                      this.state.reportJson.overall_oee == null
                        ? 0
                        : this.state.reportJson.overall_oee
                    }
                    currentValueText={
                      "Overall OEE: " +
                      (this.state.reportJson.overall_oee || 0.0).toFixed(1)
                    }
                    needleTransitionDuration={forceRender ? 0 : 500}
                    needleHeightRatio={needleHeightRatio}
                    needleColor={needleColor}
                    customSegmentStops={customSegmentStops}
                    segmentColors={segmentColors}
                    ringWidth={ringWidth}
                    //valueTextFontSize={`${valueFontSize}px`}
                    labelFontSize={`${labelFontSize}px`}
                    //valueTextFontSize={`${labelFontSize * 1.5}px`}
                    paddingHorizontal={20}
                  ></ReactSpeedometer>
                </div>
              </Grid>
              <Grid item>
                <div>
                  <ReactSpeedometer
                    style={{ overflow: "visible" }}
                    minValue={min}
                    maxValue={max}
                    width={width * 1.2}
                    height={height * 1.5}
                    forceRender={forceRender}
                    value={
                      this.state.reportJson.availability_oee == null
                        ? 0
                        : this.state.reportJson.availability_oee
                    }
                    currentValueText={
                      "Availability: " +
                      (this.state.reportJson.availability_oee || 0.0).toFixed(1)
                    }
                    needleTransitionDuration={forceRender ? 0 : 500}
                    needleHeightRatio={needleHeightRatio}
                    needleColor={needleColor}
                    customSegmentStops={customSegmentStops}
                    segmentColors={segmentColors}
                    ringWidth={ringWidth}
                    labelFontSize={`${labelFontSize}px`}
                  ></ReactSpeedometer>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item>
                <div style={{ height: 100 }}>
                  <ReactSpeedometer
                    style={{ overflow: "visible" }}
                    minValue={min}
                    maxValue={max}
                    width={width * 1.2}
                    height={height * 1.5}
                    forceRender={forceRender}
                    value={
                      this.state.reportJson.performance_oee == null
                        ? 0
                        : this.state.reportJson.performance_oee
                    }
                    currentValueText={
                      "Performance: " +
                      (this.state.reportJson.performance_oee || 0.0).toFixed(1)
                    }
                    currentV
                    needleTransitionDuration={forceRender ? 0 : 500}
                    needleHeightRatio={needleHeightRatio}
                    needleColor={needleColor}
                    customSegmentStops={customSegmentStops}
                    segmentColors={segmentColors}
                    ringWidth={ringWidth}
                    //valueTextFontSize={`${valueFontSize}px`}
                    labelFontSize={`${labelFontSize}px`}
                    paddingHorizontal={20}
                  ></ReactSpeedometer>
                </div>
              </Grid>
              <Grid item>
                <div>
                  <ReactSpeedometer
                    style={{ overflow: "visible" }}
                    minValue={min}
                    maxValue={max}
                    width={width * 1.2}
                    height={height * 1.5}
                    forceRender={forceRender}
                    value={
                      this.state.reportJson.quality_oee == null
                        ? 0
                        : this.state.reportJson.quality_oee
                    }
                    currentValueText={
                      "Quality: " +
                      (this.state.reportJson.quality_oee || 0.0).toFixed(1)
                    }
                    needleTransitionDuration={forceRender ? 0 : 500}
                    needleHeightRatio={needleHeightRatio}
                    needleColor={needleColor}
                    customSegmentStops={customSegmentStops}
                    segmentColors={segmentColors}
                    ringWidth={ringWidth}
                    //valueTextFontSize={`${valueFontSize}px`}
                    labelFontSize={`${labelFontSize}px`}
                  ></ReactSpeedometer>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item>
                <Typography
                  variant="subtitle2"
                  style={{ margin: 6, marginTop: 24, width: "100%" }}
                  color={this.state.status.severity}
                >
                  {"OEE Report Summary " + this.state.status.content || ""}
                </Typography>
                <JsonToTable json={this.state.reportJson} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Widget>
    );
  };
}

export default OEEWidget;
