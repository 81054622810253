import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const OrganizationManagementRemoveUserDialog = (props) => {
  const [error, setError] = useState();

  useEffect(() => {
    if (props.isOpen) {
      setError(null);
    }
  }, [props.isOpen]);

  const removeUsers = () => {
    const removalMap = props.users
      .filter(
        (user) => user.substring(user.indexOf("-") + 1) !== props.currentUserId
      )
      .reduce((orgsToUsers, user) => {
        const orgId = user.substring(0, user.indexOf("-"));
        const userId = user.substring(user.indexOf("-") + 1);

        if (orgsToUsers.has(orgId)) {
          orgsToUsers.set(orgId, orgsToUsers.get(orgId).concat(userId));
        } else {
          orgsToUsers.set(orgId, [userId]);
        }
        return orgsToUsers;
      }, new Map());

    Promise.all(
      [...removalMap.entries()].map(([orgId, users]) =>
        props.removeUsers(orgId, users)
      )
    ).then((results) => {
      let currentError;
      results.forEach((rv) => {
        if (rv.status !== 0) {
          const newError = rv.error || rv.info || "Unknown error";
          if (!currentError) {
            currentError = newError;
          } else if (newError !== currentError) {
            currentError = "Multiple errors on user removals";
          }
        }
      });
      setError(currentError);
      if (!currentError) {
        props.handleClose(true)();
      }
    });
  };

  const organizationCount = props.users.reduce((orgIds, user) => {
    orgIds.add(user.substring(0, user.indexOf("-")));
    return orgIds;
  }, new Set()).size;

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose(false)}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        {`Are you sure that you want to remove the selected ${
          !!props.users
            ? Object.entries(props.users).filter((entry) => entry[1]).length
            : 0
        } User(s) from ${organizationCount} Group(s)?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          Press the confirm button to finalize the removal of the selected
          users.
        </DialogContentText>
        {error && (
          <DialogContentText variant="subtitle2" color="error">
            {error}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={removeUsers} color="primary" autoFocus>
          Confirm
        </Button>
        <Button onClick={props.handleClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationManagementRemoveUserDialog;
