import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { blue } from "@material-ui/core/colors";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
}));

const WindowLabel = (props) => {
  const { showWarning = true } = props;
  return (
    <FormLabel
      component="legend"
      style={{
        fontSize: 14,
        fontStyle: "italic",
        marginTop: 12,
      }}
    >
      {!showWarning ? (
        "Window"
      ) : (
        <>
          Window (maximum)
          <Tooltip title="Based upon the event rates, you may not be able to use the full window requested.">
            <InfoIcon fontSize="small" style={{ color: blue[500] }} />
          </Tooltip>
        </>
      )}
    </FormLabel>
  );
};

const TimeDurationField = (props) => {
  const { timeframe, fieldId, errors, setOptions, validations, validate } =
    props;
  return (
    <TextField
      error={errors["timeDurationError"]}
      id={fieldId}
      label="Length"
      value={timeframe["length.value"]}
      onChange={(event) => {
        setOptions({
          timeframe: {
            ...timeframe,
            "length.value": event.target.value,
          },
        });
        if (validate) {
          validations(event, timeframe, "number");
        }
      }}
      type="number"
      InputLabelProps={{ shrink: true }}
      margin="normal"
      style={{ width: "50%" }}
    />
  );
};

const TimeUnitsField = (props) => {
  const classes = useStyles();
  const { timeframe, fieldId, setOptions, validations, validate } = props;
  return (
    <TextField
      id={fieldId}
      select
      label="Units"
      value={timeframe["length.units"]}
      onChange={(event) => {
        setOptions({
          timeframe: {
            ...timeframe,
            "length.units": event.target.value,
          },
        });
        if (validate) {
          validations(event, timeframe, "units");
        }
      }}
      margin="normal"
      variant="filled"
      style={{ marginLeft: 6, marginTop: 8, width: "50%" }}
      SelectProps={{
        className: classes.selectInput,
      }}
    >
      <MenuItem key="minutes" value="minutes">
        Minutes
      </MenuItem>
      <MenuItem key="hours" value="hours">
        Hours
      </MenuItem>
      {!validate && (
        <MenuItem key="days" value="days">
          Days
        </MenuItem>
      )}
    </TextField>
  );
};

const TimeframeOption = (props) => {
  const classes = useStyles();

  const convertToMinutes = (units) => {
    if (units === "minutes") {
      return 1;
    } else if (units === "hours") {
      return 60;
    } else if (units === "days") {
      return 60 * 24;
    }
  };

  const errorMessages = {
    timeDurationError: {
      minutes: "Please enter a value between 1 and 1440 minute(s)",
      hours: "Please enter a value between 1 and 24 hour(s)",
    },
  };

  const errorMessage = (units) => {
    let errorFound = false;
    for (let error in props.errors) {
      if (error.includes("time") && !errorFound) {
        errorFound = true;
        return (
          <p style={{ color: "red", fontSize: "10px" }}>
            {errorMessages[error][units]}
          </p>
        );
      }
    }
  };

  const validations = (event, timeframe, type) => {
    let id;
    let units;
    let value;

    if (type === "units") {
      id = "timeDurationError";
      units = event.target.value;
      value = timeframe["length.value"];
    } else if (type === "number") {
      id = "timeDurationError";
      units = timeframe["length.units"];
      value = event.target.value;
    }
    let valueAdjustedtoMinutes = convertToMinutes(units) * value;
    if (valueAdjustedtoMinutes <= 1440 && valueAdjustedtoMinutes >= 0) {
      props.noErrorDetected(id);
    } else {
      props.errorDetected(id);
    }
  };

  const timeframe = props.options.timeframe;
  const timezone = props.options.timezone;

  return (
    <FormControl style={{ width: "calc(100% - 48px)", margin: 24 }}>
      <FormLabel component="legend">Time Frame</FormLabel>
      <RadioGroup
        aria-label="Timeframe Type"
        name="timeframe-type"
        value={timeframe.type}
        row={true}
        onChange={(event) => {
          const value = event.target.value;
          let newOptions = { ...timeframe, type: value };
          if (value === "fixed" && timeframe.start == null) {
            newOptions.start = moment();
          }
          props.setOptions({ timeframe: newOptions });
        }}
      >
        <FormControlLabel value="moving" control={<Radio />} label="Moving" />
        <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
      </RadioGroup>

      {timeframe.type === "fixed" && (
        <div style={{ float: "right" }}>
          <Grid container direction="column">
            <Grid item>
              <DateTimePicker
                key={timezone || "local"}
                error={false}
                id="starttime"
                label="Start"
                value={
                  timezone
                    ? moment.tz(timeframe.start, timezone)
                    : timeframe.start
                }
                onChange={(dt) => {
                  props.setOptions({
                    timeframe: { ...timeframe, start: dt.valueOf() },
                  });
                }}
                style={{ width: "100%", maxWidth: "100%", margin: 6 }}
              />
            </Grid>
            <Grid item>
              <WindowLabel showWarning={props.showWarning} />
              <FormControl
                style={{ flexDirection: "row", margin: 6, width: "100%" }}
              >
                <TimeDurationField
                  timeframe={timeframe}
                  fieldId={"select-fixed-length"}
                  errors={props.errors}
                  setOptions={props.setOptions}
                  validate={props.validate}
                  validations={validations}
                />
                <TimeUnitsField
                  timeframe={timeframe}
                  fieldId={"select-fixed-units"}
                  setOptions={props.setOptions}
                  validate={props.validate}
                  validations={validations}
                />
              </FormControl>

              {errorMessage(timeframe["length.units"])}
            </Grid>
          </Grid>
        </div>
      )}
      {timeframe.type === "moving" && (
        <FormControl>
          <FormControl>
            <WindowLabel showWarning={props.showWarning} />
            <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
              <TimeDurationField
                timeframe={timeframe}
                fieldId={"select-moving-length"}
                errors={props.errors}
                setOptions={props.setOptions}
                validate={props.validate}
                validations={validations}
              />
              <TimeUnitsField
                timeframe={timeframe}
                fieldId={"select-moving-units"}
                setOptions={props.setOptions}
                validate={props.validate}
                validations={validations}
              />
              {errorMessage(timeframe["length.units"])}
            </FormControl>
          </FormControl>
        </FormControl>
      )}
      <FormControl>
        <FormControl
          fullWidth={true}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <FormControl
            component="fieldset"
            className={classes.formControl}
            style={{ marginLeft: 0, marginRight: 0, width: "100%" }}
          >
            <FormLabel
              component="legend"
              style={{ fontSize: 14, fontStyle: "italic" }}
            >
              Refresh Interval
            </FormLabel>
            <RadioGroup
              aria-label="Interval Type"
              name="interval-type"
              value={timeframe["interval.type"] || "set"}
              row={true}
              onChange={(event) => {
                const value = event.target.value;
                let newOptions = {
                  ...timeframe,
                  "interval.type": value,
                };
                props.setOptions({ timeframe: newOptions });
              }}
            >
              <FormControlLabel value="auto" control={<Radio />} label="Auto" />
              <FormControlLabel value="set" control={<Radio />} label="Set" />
            </RadioGroup>
            <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
              {timeframe["interval.type"] !== "auto" &&
                timeframe["interval.units"] === "seconds" && (
                  <TextField
                    id="select-interval-seconds"
                    select
                    label="Length"
                    value={timeframe["interval.value"]}
                    style={{ marginTop: 0, width: "50%" }}
                    onChange={(event) =>
                      props.setOptions({
                        timeframe: {
                          ...timeframe,
                          "interval.value": event.target.value,
                        },
                      })
                    }
                    margin="normal"
                    variant="filled"
                    SelectProps={{
                      className: classes.selectInput,
                    }}
                  >
                    <MenuItem key="s5" value="5">
                      5
                    </MenuItem>
                    <MenuItem key="s10" value="10">
                      10
                    </MenuItem>
                    <MenuItem key="s20" value="20">
                      20
                    </MenuItem>
                    <MenuItem key="s30" value="30">
                      30
                    </MenuItem>
                    <MenuItem key="s40" value="40">
                      40
                    </MenuItem>
                    <MenuItem key="s50" value="50">
                      50
                    </MenuItem>
                  </TextField>
                )}
              {timeframe["interval.type"] !== "auto" &&
                timeframe["interval.units"] !== "seconds" && (
                  <TextField
                    id="select-interval-nonSeconds"
                    label="Refresh interval"
                    value={
                      timeframe["interval.value"] //changed id so error works, make sure nothing else breaks
                    }
                    onChange={(event) => {
                      props.setOptions({
                        timeframe: {
                          ...timeframe,
                          "interval.value": event.target.value,
                        },
                      });
                    }}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    style={{ width: "50%", marginTop: 8 }}
                  />
                )}
              {timeframe["interval.type"] !== "auto" && (
                <TextField
                  id="select-update-time-units"
                  select
                  label="Units"
                  value={timeframe["interval.units"]}
                  onChange={(event) => {
                    const val = event.target.value;
                    let update = {
                      ...timeframe,
                      "interval.units": val,
                    };
                    if (
                      val === "seconds" &&
                      timeframe["interval.value"] % 10 > 0
                    ) {
                      update["interval.value"] = "10";
                    }
                    return props.setOptions({ timeframe: update });
                  }}
                  margin="normal"
                  variant="filled"
                  style={{ marginLeft: 6, marginTop: 0, width: "50%" }}
                  SelectProps={{
                    className: classes.selectInput,
                  }}
                >
                  <MenuItem key="seconds" value="seconds">
                    Seconds
                  </MenuItem>
                  <MenuItem key="minutes" value="minutes">
                    Minutes
                  </MenuItem>
                  <MenuItem key="hours" value="hours">
                    Hours
                  </MenuItem>
                </TextField>
              )}
            </FormControl>
          </FormControl>
        </FormControl>
      </FormControl>
    </FormControl>
  );
};

export default TimeframeOption;
