import LubeTagProperties from "components/TagConfig/bespoke/LubeTagProperties";
import StatesTagProperties from "components/TagConfig/bespoke/StatesTagProperties";

const ISX_TAG_ROLE = {
  guuid: "597b1e27-964f-46b4-a88d-971e0e19553d",
  name: "Tag Properties",
  attributes: {
    isx_tag_user_writable: {
      label: "User Writable",
      required: false,
      type: "boolean",
      editable: false,
    },
    isx_tag_type: {
      label: "Tag Type",
      required: false,
      type: "string",
      editable: false,
    },
  },
};

const QUANTITY_ROLE = {
  guuid: "aec7acfa-be95-46ee-a9c4-b6cb65435fdd",
  name: "Standard Properties",
  attributes: {
    name: {
      label: "Name",
      required: false,
      type: "string",
      editable: true,
    },
    unit: {
      label: "Units",
      required: false,
      type: "string",
      editable: true,
      defaultValue: "",
    },
  },
  impliedRoles: [],
};

const NUMERIC_MEASUREMENT_ROLE = {
  guuid: "1d560741-a619-42cc-9d30-f13bc9e03930",
  name: "Numeric Measurement Properties",
  attributes: {
    "numeric.ema.window": {
      label: "Moving Average Window",
      required: false,
      type: "number",
      editable: true,
      defaultValue: 10,
    },
    "numeric.max": {
      label: "Maximum",
      required: false,
      type: "number",
      editable: true,
    },
    "numeric.min": {
      label: "Minimum",
      required: false,
      type: "number",
      editable: true,
    },
    "numeric.linearfn.slope": {
      label: "Scale Factor",
      required: false,
      type: "number",
      editable: true,
      default: 1.0,
    },
    "numeric.linearfn.intercept": {
      label: "Offset",
      required: false,
      type: "number",
      editable: true,
      default: 0.0,
    },
  },
  impliedRoles: [],
};

const ASSET_ROLE = {
  guuid: "cb28cf96-094c-4b34-bfbd-c14360de000a",
  name: "Asset Properties",
  attributes: {
    "asset.id": {
      label: "Asset ID",
      required: false,
      type: "string",
      editable: true,
    },
    "asset.class": {
      label: "Equipment Class",
      required: false,
      type: "string",
      editable: true,
    },
  },
  impliedRoles: [],
};

const THRESHOLD_ROLE = {
  guuid: "a2fe6658-ebd9-47ac-ad9a-9ea24720b928",
  name: "Range Properties",
  attributes: {
    "threshold.level1.high": {
      label: "Notify High",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.level2.high": {
      label: "Warning High",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.level3.high": {
      label: "Critical High",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.level1.low": {
      label: "Notify Low",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.level2.low": {
      label: "Warning Low",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.level3.low": {
      label: "Critical Low",
      required: false,
      type: "number",
      editable: true,
    },
    "threshold.baseline": {
      label: "Baseline",
      required: false,
      type: "number",
      editable: true,
    },
  },
  impliedRoles: [],
};

const LUBRICATION_ROLE = {
  guuid: "5907b666-0ba5-469f-a81b-0ccd216f820a",
  name: "Lubrication Properties",
  attributes: {
    devicetype: {
      label: "Cartridge Type",
      type: "string",
    },
    capacity: {
      label: "Cartridge Pouch Capacity (cc)",
      type: "number",
    },
    unitvolume: {
      label: "Dispense Amount / Interval (cc)",
      type: "number",
    },
    baseline: {
      label: "Baseline Friction Level (dB)",
      type: "number",
    },
    grease: {
      label: "Lubricant Type",
      type: "string",
      defaultValue: "NLGI#2",
    },
    calcgreaseinterval: {
      label: "Calc Grease Interval (days)",
      required: true,
      type: "number",
      range: { min: 1, max: 365 },
      precision: 0,
      editable: true,
    },
    calcgreaseamount: {
      label: "Calc Grease Amount (cc)",
      required: true,
      type: "number",
      precision: 1,
      editable: true,
    },
    autolube: {
      label: "Auto Lube",
      required: true,
      type: "boolean",
      editable: true,
    },
    autoluberesetinterval: {
      label: "Auto Lube Reset Interval (days)",
      required: true,
      type: "number",
      range: { min: 1, max: 365 },
      precision: 0,
      editable: true,
    },
  },
};

const STATES_ROLE = {
  guuid: "1d7b9852-1076-4291-b666-334cbea4c1ec",
  name: "States Properties",
  attributes: {
    statesSeriesType: {
      label: "Series Type",
      type: "string",
    },
    statesOutputUnit: {
      label: "Output Unit",
      type: "string",
    },
    statesNoDataColor: {
      label: "No Data Color",
      type: "string",
    },
    states: {
      label: "States",
      type: "array",
    },
  },
};

const UES_850FS_ROLE = {
  guuid: "61f7fca6-0ed6-4752-aa2c-108d2e69df6b",
  name: "UES 850FS Properties",
  attributes: {
    "850fs.batt": {
      label: "Battery",
      type: "string",
      readOnly: true,
    },
    "850fs.pkpk": {
      label: "Peak-Peak",
      type: "string",
      readOnly: true,
    },
    "850fs.crest": {
      label: "Crest Factor",
      type: "string",
      readOnly: true,
    },
    "850fs.ss": {
      label: "Sensor sensitivity: dB",
      type: "string",
      readOnly: true,
    },
    "850fs.vrms": {
      label: "V RMS",
      type: "string",
      readOnly: true,
    },
    "850fs.spot": {
      label: "Sensor Potentiometer",
      type: "string",
      readOnly: true,
    },
    "850fs.rmsoff": {
      label: "RMS dB Offset",
      type: "string",
    },
    "850fs.carrfreq": {
      label: "Sensor Carrier Frequency",
      type: "string",
    },
  },
};

const KNOWN_ROLES = {
  [LUBRICATION_ROLE.guuid]: LUBRICATION_ROLE,
  [QUANTITY_ROLE.guuid]: QUANTITY_ROLE,
  [NUMERIC_MEASUREMENT_ROLE.guuid]: NUMERIC_MEASUREMENT_ROLE,
  [THRESHOLD_ROLE.guuid]: THRESHOLD_ROLE,
  [ASSET_ROLE.guuid]: ASSET_ROLE,
  [STATES_ROLE.guuid]: STATES_ROLE,
  [UES_850FS_ROLE.guuid]: UES_850FS_ROLE,
  [ISX_TAG_ROLE.guuid]: ISX_TAG_ROLE,
};

const ROLE_COMPONENTS = {
  // the value will be the component to handle lube role
  [LUBRICATION_ROLE.guuid]: LubeTagProperties,
  // the value will be the component to handle states role
  [STATES_ROLE.guuid]: StatesTagProperties,
};

const getRole = (guuid) => KNOWN_ROLES[guuid];

const getRoleComponent = (guuid) => ROLE_COMPONENTS[guuid];

export {
  getRole,
  getRoleComponent,
  ISX_TAG_ROLE as TAG_ROLE,
  QUANTITY_ROLE,
  LUBRICATION_ROLE,
  STATES_ROLE,
  NUMERIC_MEASUREMENT_ROLE,
  THRESHOLD_ROLE,
  ASSET_ROLE,
};
