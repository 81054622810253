import * as types from "./types";

export const refreshResponse = (response) => {
  return {
    type: types.REFRESH_RESPONSE,
    response,
  };
};

export const loginResponse = (response) => {
  return {
    type: types.LOGIN_RESPONSE,
    response: response,
  };
};

export const logoutResponse = (success) => {
  return {
    type: types.LOGOUT_RESPONSE,
    success,
  };
};

export const getAllItemsResponse = (items) => {
  return {
    type: types.GET_ALL_ITEMS_RESPONSE,
    items: items,
  };
};

export const getItemResponse = (item) => {
  return {
    type: types.GET_ITEM_RESPONSE,
    item: item,
  };
};

export const getItemsResponse = (items) => {
  return {
    type: types.GET_ITEMS_RESPONSE,
    items: items,
  };
};

export const getOrgsResponse = (orgs) => {
  return {
    type: types.GET_ORGS_RESPONSE,
    orgs,
  };
};

export const updateCalibrationResponse = (guuid, params, status) => {
  return {
    type: types.UPDATE_CALIBRATION_RESPONSE,
    guuid: guuid,
    params: params,
    status: status,
  };
};

export const setStateTable = (guuid, statetable) => {
  return {
    type: types.SET_STATE_TABLE,
    guuid: guuid,
    statetable: statetable,
  };
};

export const setHourlyAggregates = (guuid, aggregates) => {
  return {
    type: types.SET_HOURLY_AGGREGATES,
    guuid: guuid,
    aggregates: aggregates,
  };
};

export const setDatasetContent = (guuid, dataset, content) => {
  return {
    type: types.SET_DATASET_CONTENT,
    guuid: guuid,
    dataset: dataset,
    content: content,
  };
};

export const initializeStream = (key) => {
  return {
    type: types.INITIALIZE_STREAM,
    key: key,
  };
};

export const updateUserResponse = (props) => {
  return {
    type: types.UPDATE_USER,
    props: props,
  };
};

export const updateItemResponse = (guuid, props, status) => {
  return {
    type: types.UPDATE_ITEM,
    guuid: guuid,
    props: props,
    status: status,
  };
};

export const getDashboardResponse = (guuid, dashboard) => {
  return {
    type: types.GET_DASHBOARD_RESPONSE,
    guuid: guuid,
    dashboard: dashboard,
  };
};

export const getDashboardsResponse = (dashboards) => {
  return {
    type: types.GET_DASHBOARDS_RESPONSE,
    dashboards: dashboards,
  };
};

export const createDashboardResponse = (dashboard) => {
  return {
    type: types.CREATE_DASHBOARD_RESPONSE,
    dashboard: dashboard,
  };
};

export const updateDashboardResponse = (guuid, properties, status) => {
  return {
    type: types.UPDATE_DASHBOARD_RESPONSE,
    guuid: guuid,
    properties: properties,
    status: status,
  };
};

export const removeDashboardResponse = (guuid, status) => {
  return {
    type: types.REMOVE_DASHBOARD_RESPONSE,
    guuid: guuid,
    status: status,
  };
};

export const updateDashboardsResponse = (operations) => {
  return {
    type: types.UPDATE_DASHBOARDS_RESPONSE,
    operations,
  };
};

export const updateWidgetsLayoutResponse = (guuid, layout, version, status) => {
  return {
    type: types.UPDATE_WIDGETS_LAYOUT_RESPONSE,
    guuid: guuid,
    layout: layout,
    version: version,
    status: status,
  };
};

export const addWidgetResponse = (guuid, widget, status) => {
  return {
    type: types.ADD_WIDGET_RESPONSE,
    guuid: guuid,
    widget: widget,
    status: status,
  };
};

export const updateWidgetResponse = (guuid, properties, status) => {
  return {
    type: types.UPDATE_WIDGET_RESPONSE,
    guuid: guuid,
    properties: properties,
    status: status,
  };
};

export const removeWidgetResponse = (dguuid, wguuid, status) => {
  return {
    type: types.REMOVE_WIDGET_RESPONSE,
    dashboardGuuid: dguuid,
    widgetGuuid: wguuid,
    status: status,
  };
};

export const getUserMessagesResponse = (messages) => {
  return {
    type: types.GET_USER_MESSAGES_RESPONSE,
    messages,
  };
};

export const createUserMessageResponse = (message) => {
  return {
    type: types.CREATE_USER_MESSAGE_RESPONSE,
    message,
  };
};

export const updateUserMessageResponse = (guuid, properties, status) => {
  return {
    type: types.UPDATE_USER_MESSAGE_RESPONSE,
    guuid,
    properties,
    status,
  };
};

export const removeUserMessageResponse = (guuid, status) => {
  return {
    type: types.REMOVE_USER_MESSAGE_RESPONSE,
    guuid,
    status,
  };
};

export const updateSubscription = (subscriptionId, data) => {
  return {
    type: types.UPDATE_SUBSCRIPTION_RESPONSE,
    subscriptionId,
    data,
  };
};

export const updateStreams = (streamData) => {
  return {
    type: types.UPDATE_STREAMS,
    streamData,
  };
};

export const removeStreams = (tags) => {
  return {
    type: types.REMOVE_STREAMS,
    tags,
  };
};

export const clearStreams = () => {
  return {
    type: types.CLEAR_STREAMS,
  };
};

export const updateTagEventIntervals = (eventIntervals) => {
  return {
    type: types.UPDATE_TAG_EVENT_INTERVALS,
    eventIntervals,
  };
};

export const removeTagEventIntervals = (tags) => {
  return {
    type: types.REMOVE_TAG_EVENT_INTERVALS,
    tags,
  };
};

export const clearTagEventIntervals = () => {
  return {
    type: types.CLEAR_TAG_EVENT_INTERVALS,
  };
};
