import React from "react";
import QueryService from "services/QueryService";
import { AlertTemplateDialog as AlertTemplateDialogComponent } from "components";
import {
  getItem,
  getAllItems,
} from "store/operations";
import { useDispatch, useSelector } from "react-redux";
export default (props) => {

  const dispatch = useDispatch();
  async function send(template,sendtos) {
    // construct dashboard content as template invite message payload
    //console.log("props and values",props,template,sendtos);
    const msgtosend = {
      "access_key": props.stack.access_key,
      "guuid": props.stack.guuid, 
      "op": "update", 
      "process":[
        {
          "name":"set_alerts",
          "tag":props.tag,
          "template":template,
          "sendto":sendtos,
          "enabled":true,
        }
      ]
    }
    const results = await QueryService.sendProcessMessage(
      msgtosend
    );
    reloadItem();
    //reloadAllItems();
    return results;
  }

  const reloadItem = React.useCallback(() => {
    dispatch(getItem(props.stack.guuid)).then((rv) => {
      //console.log("rv after item reload is",rv)
    });
  }, [dispatch, props.stack.guuid]);

  const reloadAllItems = React.useCallback(() => {
    dispatch(getAllItems()).then((rv) => {
      //console.log("rv after item reload is",rv)
    });
  }, [dispatch]);

  return <AlertTemplateDialogComponent {...props} send={send} />;
};
