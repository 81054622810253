import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { Checkbox } from "@material-ui/core";

import Widget from "../../widget/Widget";
import { ISXContext } from "services/Utils";
import QueryService from "services/QueryService";
import LubeDashboardService from "services/DashboardService/bespoke/LubeDashboardService";

import { getDashboards, updateItem as updateItemOp } from "store/operations";
import ISXUtils from "services/Utils";
import { default as _cloneDeep } from "lodash/cloneDeep";
import { default as _head } from "lodash/head";
import { default as _set } from "lodash/set";
import { default as _range } from "lodash/range";
import { default as _isEqual } from "lodash/isEqual";
import { default as _isEmpty } from "lodash/isEmpty";
import { default as _uniq } from "lodash/uniq";
import { default as _pick } from "lodash/pick";

import ChipInput from "components/ChipInput";

const MAX_SUPPORTED_GROUPS = 12;

const MAX_INVITES = 25;

const SENSOR_TYPES = ["raw", "750", "850"];

const SENSOR_DEFAULT_MIN_VALUE = 0; //default min value in dB
const SENSOR_DEFAULT_MAX_VALUE = 60; //default max value in dB

const ASSIST_MINIMUM_DB_DELTA = 2; //dB difference between baseline and current before assist/auto will kick in

//stuck value check params
const DEFAULT_OFF_REF = 0; //refernce for Off state
const DEFAULT_OFF_DELTA = 2; //+/- delta around off ref to be considered as being in Off mode
const DEFAULT_OFF_STUCK_DURATION = 86400; //time limit to consider a sensor as being stuck when in off state - in seconds
const DEFAULT_SENSOR_DELTA = 0.05; //delta around the last reference value to be considered as stuck
const DEFAULT_NORMAL_STUCK_DURATION = 3600; //time limit to consider a sensor as being stuck when in normal mode - in seconds

const AUTO_LUBE_SETTINGS = [
  [0, "Disabled"],
  [1, "Instantaneous"],
  [300, "5 Minutes"],
  [1800, "30 Minutes"],
  [3600, "1 Hour"],
  [3600 * 3, "3 Hours"],
  [3600 * 5, "5 Hours"],
  [3600 * 10, "10 Hours"],
];

const LUBRICATION_DEVICES = {
  none: {
    value: "none",
    name: "No Luber",
    capacity: null,
    unitvolume: null,
    cycletime: "",
  },
  jack: {
    value: "jack",
    name: "Jack",
    capacity: ["125", "250"],
    unitvolume: ["0.5"],
    cycletime: "60",
  },
  ultimate: {
    value: "ultimate",
    name: "Ultimate",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.5"],
    cycletime: "60",
  },
  "m-ue": {
    value: "m-ue",
    name: "M-UE",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.333", "0.666", "1.0", "1.333", "1.666", "2.0"],
    cycletime: "300",
  },
  "bt-ue": {
    value: "bt-ue",
    name: "BT-UE",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.333", "0.666", "1.0", "1.333", "1.666", "2.0"],
    cycletime: "300",
  },
};

const LUBE_TAG_VALIDATION_PROPS = [
  // "devicetype",
  "maxlubeamount",
  "unitvolume",
  "baseline",
  // "grease",
  "capacity",
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "lightgray",
    // color: theme.palette.common.white,
  },
  body: {},
  root: {
    fontSize: "0.75rem",
    border: "1px solid darkgray",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
}))(TableCell);

const StyledCompactHeaderCell = withStyles({
  root: {
    width: 1,
  },
})(StyledTableCell);

const StyledGroupNameHeaderCell = withStyles({
  root: {
    width: "8rem",
    minWidth: "5.5rem",
  },
})(StyledTableCell);

const StyledTagNameHeaderCell = withStyles({
  root: {
    width: "10rem",
    minWidth: "7rem",
  },
})(StyledTableCell);

const TagTextField = (props) => {
  const {
    tagId,
    prop,
    readOnly,
    tagProperties,
    handleChange,
    defaultValue = "",
    ...rest
  } = props;
  return (
    <TextField
      size="small"
      InputProps={{
        style: { fontSize: "0.75rem" },
        disableUnderline: true,
      }}
      fullWidth
      value={
        prop.split(".").reduce((o, i) => o?.[i], tagProperties[tagId]) ||
        defaultValue
      }
      onChange={(event) => handleChange(tagId, prop, event.target.value)}
      disabled={readOnly}
      {...rest}
    />
  );
};

const SensorConfigTextField = (props) => {
  const {
    sensorId,
    prop,
    value,
    readOnly,
    propNotDefined,
    //sensorConfig,
    handleChange,
    //defaultValue = "",
    ...rest
  } = props;
  return (
    <TextField
      size="small"
      InputProps={{
        style: { fontSize: "0.75rem" },
        disableUnderline: true,
      }}
      fullWidth
      //value={sensorConfig?.sensorId?.prop || defaultValue}
      value={value}
      onChange={(event) => handleChange(sensorId, prop, event.target.value)}
      //disabled={readOnly || sensorConfig?.sensorId === undefined || sensorConfig[sensorId]?.prop === undefined}
      disabled={readOnly || propNotDefined}
      {...rest}
    />
  );
};

const TagRow = (props) => {
  const {
    idx,
    tagProperties,
    handleTagPropChange,
    handleBaseLinePropChange,
    sensorConfig,
    backwardCompatibleSensorType,
    getSensorIdFromSensorConfig,
    handleSensorConfigChange,
    handleTagPropsChange,
    readOnly,
    dashboardGroups,
    setDashboardGroups,
    tagDashboardGroups,
    setTagDashbordGroups,
    showAdvanced,
  } = props;

  //console.log("TP", tagProperties);

  const handleDevicePropChange = (tagId, prop, value) => {
    const updates = {
      devicetype: value,
      capacity: "250",
      cycletime: LUBRICATION_DEVICES[value].cycletime,
      unitvolume:
        value === "jack" ||
        value === "ultimate" ||
        value === "m-ue" ||
        value === "bt-ue"
          ? LUBRICATION_DEVICES[value].unitvolume[0]
          : null,
    };
    handleTagPropsChange(tagId, updates);
  };

  const tagId = `sensor${(idx + "").padStart(2, "0")}`;
  const lubeTagId = `lube_${tagId}`;
  const exists = !!tagProperties[tagId];
  const sensorId = exists && getSensorIdFromSensorConfig(tagId);
  const getSensorConfigPropValue = (prop) => sensorConfig?.[sensorId]?.[prop];
  const tagNotInSensorConfig = !sensorConfig?.[sensorId];
  const lubeTagNotInSensorConfig =
    getSensorIdFromSensorConfig(lubeTagId) === "";
  const showStuckSensorValues = showAdvanced;
  const showAssistDelta = showAdvanced;
  const showAutoMode = showAdvanced;
  //console.log("sensorid and prop and value",sensorId,sensorConfig?.[sensorId],getSensorConfigPropValue("stype"));
  const tagDashboardGroupId = tagDashboardGroups[tagId];
  const capacities =
    exists &&
    LUBRICATION_DEVICES[tagProperties[lubeTagId]?.devicetype]?.capacity;
  const unitVolumes =
    exists &&
    LUBRICATION_DEVICES[tagProperties[lubeTagId]?.devicetype]?.unitvolume;
  return (
    <TableRow key={idx}>
      <StyledTableCell style={{ backgroundColor: "lightgray" }}>
        {idx}
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          size="small"
          type="number"
          min="1"
          step="1"
          InputProps={{
            style: { fontSize: "0.75rem" },
            disableUnderline: true,
          }}
          fullWidth
          value={tagDashboardGroupId ?? ""}
          onChange={(event) => {
            const value = event.target.value;
            if (value) {
              setTagDashbordGroups((tagDashboardGroups) => ({
                ...tagDashboardGroups,
                [tagId]: value,
              }));
            } else {
              setTagDashbordGroups((tagDashboardGroups) => {
                const { [tagId]: removedTagId, ...rest } = tagDashboardGroups;
                return rest;
              });
            }
          }}
          disabled={readOnly}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          size="small"
          InputProps={{
            style: { fontSize: "0.75rem" },
            disableUnderline: true,
          }}
          fullWidth
          value={dashboardGroups[tagDashboardGroupId]?.name ?? ""}
          onChange={(event) => {
            const value = event.target.value;
            setDashboardGroups((dashboardGroups) => ({
              ...dashboardGroups,
              [tagDashboardGroupId]: {
                ...dashboardGroups[tagDashboardGroupId],
                name: value,
              },
            }));
          }}
          disabled={readOnly || !tagDashboardGroupId}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TagTextField
          {...{
            tagId,
            tagProperties,
            readOnly,
          }}
          handleChange={handleTagPropChange}
          prop="name"
        />
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={tagId}
            prop="numeric.min"
            type="number"
            defaultValue={SENSOR_DEFAULT_MIN_VALUE}
          />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={tagId}
            prop="numeric.max"
            type="number"
            defaultValue={SENSOR_DEFAULT_MAX_VALUE}
          />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <SensorConfigTextField
            {...{
              sensorId,
              readOnly,
            }}
            handleChange={handleSensorConfigChange}
            prop="stype"
            value={
              getSensorConfigPropValue("stype") || backwardCompatibleSensorType
            }
            propNotDefined={tagNotInSensorConfig}
            select
          >
            {SENSOR_TYPES.map((st) => (
              <MenuItem value={st} key={st}>
                {st || "- none -"}
              </MenuItem>
            ))}
          </SensorConfigTextField>
        )}
      </StyledTableCell>
      {showAssistDelta && (
        <StyledTableCell>
          {exists && (
            <SensorConfigTextField
              {...{
                sensorId,
                readOnly,
              }}
              handleChange={handleSensorConfigChange}
              prop="assistdelta"
              value={
                getSensorConfigPropValue("assistdelta") ||
                ASSIST_MINIMUM_DB_DELTA
              }
              propNotDefined={tagNotInSensorConfig}
              type="number"
              min="1"
              max="50"
            ></SensorConfigTextField>
          )}
        </StyledTableCell>
      )}
      {showStuckSensorValues && (
        <>
          <StyledTableCell>
            {exists && (
              <SensorConfigTextField
                {...{
                  sensorId,
                  readOnly,
                }}
                handleChange={handleSensorConfigChange}
                prop="offref"
                value={getSensorConfigPropValue("offref") || DEFAULT_OFF_REF}
                propNotDefined={tagNotInSensorConfig}
                type="number"
                min="0"
                max="50"
              ></SensorConfigTextField>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {exists && (
              <SensorConfigTextField
                {...{
                  sensorId,
                  readOnly,
                }}
                handleChange={handleSensorConfigChange}
                prop="offdelta"
                value={
                  getSensorConfigPropValue("offdelta") || DEFAULT_OFF_DELTA
                }
                propNotDefined={tagNotInSensorConfig}
                type="number"
                min="0"
                max="50"
              ></SensorConfigTextField>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {exists && (
              <SensorConfigTextField
                {...{
                  sensorId,
                  readOnly,
                }}
                handleChange={handleSensorConfigChange}
                prop="offstuckdur"
                value={
                  getSensorConfigPropValue("offstuckdur") ||
                  DEFAULT_OFF_STUCK_DURATION
                }
                propNotDefined={tagNotInSensorConfig}
                //type="number"
                //min="0"
              ></SensorConfigTextField>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {exists && (
              <SensorConfigTextField
                {...{
                  sensorId,
                  readOnly,
                }}
                handleChange={handleSensorConfigChange}
                prop="stuckdelta"
                value={
                  getSensorConfigPropValue("stuckdelta") || DEFAULT_SENSOR_DELTA
                }
                propNotDefined={tagNotInSensorConfig}
                type="number"
                min="0"
              ></SensorConfigTextField>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {exists && (
              <SensorConfigTextField
                {...{
                  sensorId,
                  readOnly,
                }}
                handleChange={handleSensorConfigChange}
                prop="normalstuckdur"
                value={
                  getSensorConfigPropValue("normalstuckdur") ||
                  DEFAULT_NORMAL_STUCK_DURATION
                }
                propNotDefined={tagNotInSensorConfig}
                type="number"
                min="0"
              ></SensorConfigTextField>
            )}
          </StyledTableCell>
        </>
      )}
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleBaseLinePropChange}
            tagId={lubeTagNotInSensorConfig ? tagId : lubeTagId}
            prop={lubeTagNotInSensorConfig ? "threshold.baseline" : "baseline"}
            type="number"
            min="0"
            max="50"
            step="1"
          />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleDevicePropChange}
            tagId={lubeTagId}
            prop="devicetype"
            select
            disabled={lubeTagNotInSensorConfig}
          >
            {Object.keys(LUBRICATION_DEVICES).map((ld) => (
              <MenuItem value={LUBRICATION_DEVICES[ld]?.value || ld} key={ld}>
                {LUBRICATION_DEVICES[ld]?.name || ld}
              </MenuItem>
            ))}
          </TagTextField>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={lubeTagId}
            prop="devicemac"
            placeholder={
              tagProperties[lubeTagId]?.devicetype !== "bt-ue"
                ? "Not required"
                : "Type MAC address"
            }
            disabled={tagProperties[lubeTagId]?.devicetype !== "bt-ue"}
          ></TagTextField>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && capacities && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={lubeTagId}
            prop="capacity"
            select
          >
            {capacities.map((cap) => (
              <MenuItem value={cap} key={cap}>
                {cap}
              </MenuItem>
            ))}
          </TagTextField>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && unitVolumes && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={lubeTagId}
            prop="unitvolume"
            select
          >
            {unitVolumes.map((cap) => (
              <MenuItem value={cap} key={cap}>
                {cap}
              </MenuItem>
            ))}
          </TagTextField>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={lubeTagId}
            prop="maxlubeamount"
            type="number"
            min="1"
            step="1"
            disabled={lubeTagNotInSensorConfig}
          />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {exists && (
          <TagTextField
            {...{
              tagProperties,
              readOnly,
            }}
            handleChange={handleTagPropChange}
            tagId={lubeTagId}
            prop="grease"
            defaultValue="NLGI#2"
            disabled={lubeTagNotInSensorConfig}
          />
        )}
      </StyledTableCell>
      {showAutoMode && (
        <>
          <StyledTableCell>
            {exists && (
              <TagTextField
                {...{
                  tagProperties,
                  readOnly,
                }}
                handleChange={handleTagPropChange}
                tagId={lubeTagId}
                prop="autolube"
                defaultValue={0}
                select
                disabled={lubeTagNotInSensorConfig}
              >
                {AUTO_LUBE_SETTINGS.map((val) => (
                  <MenuItem value={val[0]} key={val[0]}>
                    {val[1]}
                  </MenuItem>
                ))}
              </TagTextField>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {exists && (
              <TagTextField
                {...{
                  tagProperties,
                  readOnly,
                }}
                handleChange={handleTagPropChange}
                tagId={lubeTagId}
                prop="luberesetinterval"
                type="number"
                min="1"
                step="1"
                max="365"
                disabled={lubeTagNotInSensorConfig}
              />
            )}
          </StyledTableCell>
        </>
      )}
    </TableRow>
  );
};

const ConfigureDialog = React.memo((props) => {
  const {
    devDirty,
    validateChanges,
    createChanges,
    generateChangeMessages,
    commitChanges,
    open,
    handleClose,
    processStatus,
    setProcessStatus,
  } = props;
  const validationErrors = open ? validateChanges() : [];
  const changes = open && validationErrors.length === 0 && createChanges();
  const changeMessages = changes ? generateChangeMessages(changes) : [];
  const commitChangesAndFinish = async () => {
    const success = await commitChanges(changes);
    setProcessStatus(null);
    if (success) {
      handleClose();
    } else {
      // show error message
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="template-dialog-title"
    >
      <DialogTitle id="template-dialog-title">
        {validationErrors.length > 0
          ? "The following issues must be fixed before you can continue:"
          : changeMessages.length || devDirty === true
          ? "The following changes will be made: "
          : ""}
      </DialogTitle>
      {processStatus === null ? (
        <DialogContent>
          <ul>
            {(validationErrors.length > 0
              ? validationErrors
              : changeMessages
            ).map((entry, idx) => (
              <li key={idx}>
                {entry.category}{" "}
                <span style={{ fontWeight: 600 }}>{entry.subject}</span>{" "}
                {entry.description}
              </li>
            ))}
            {
              //handling change messages for Alerts. TODO - individual alerts. For now, all alerts are updated all the time
              validationErrors.length === 0 ? (
                <li key={999}>
                  {"Alerts"}{" "}
                  <span style={{ fontWeight: 600 }}>{"for all tags"}</span>{" "}
                  {"will be updated"}
                </li>
              ) : (
                ""
              )
            }
            {devDirty === true ? (
              <li key={1000}>
                <span style={{ fontWeight: 600 }}>{"Device Settings"}</span>{" "}
                {"changes will take effect via OTA update within"}{" "}
                <span style={{ fontWeight: 600 }}>{"25 - 50 minutes"}</span>
              </li>
            ) : (
              ""
            )}
          </ul>
        </DialogContent>
      ) : (
        <DialogContent>
          <span style={{ fontWeight: 600 }}>{processStatus}</span>
        </DialogContent>
      )}

      {processStatus === null && (
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            onClick={commitChangesAndFinish}
            color="primary"
            disabled={
              validationErrors.length > 0 // || (changeMessages.length === 0 && devDirty === false) //allow no changes to still cause alerts to be regenerated
            }
          >
            Continue
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
});

const getTagsByGroup = (groupIds, tagDashboardGroups) => {
  const groupTags = [...groupIds].reduce((acc, groupId) => {
    acc[groupId] = new Set();
    return acc;
  }, new Set());
  Object.entries(tagDashboardGroups).forEach(([tagId, groupId]) => {
    if (groupIds.has("" + groupId)) {
      groupTags[groupId].add(tagId);
    }
  });
  return groupTags;
};

const DashboardConfigurationWidget = (props) => {
  const orgs = useSelector((state) => state.isx.orgs);

  const [initialized, setInitialized] = useState(false);
  const [numRows, setNumRows] = useState(16);
  // const [group, setGroup] = useState(null);

  // dashboard group id => name
  const [dashboardGroups, setDashboardGroups] = useState({});
  // tag id => dashboard group id
  const [tagDashboardGroups, setTagDashbordGroups] = useState({});

  const [client, setClient] = useState("");
  const [location, setLocation] = useState("");
  const [area, setArea] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const sensorConfigNumberProps = new Set([
    "assistdelta",
    "offref",
    "offdelta",
    "offstuckdur",
    "stuckdelta",
    "normalstuckdur",
  ]);
  // const [organizationId, setOrganizationId] = useState("");
  const [overviewDashboardId, setOverviewDashboardId] = useState();

  const [stackNames, setStackNames] = useState(null);

  const [tagProperties, setTagProperties] = useState({});

  //   const [rootTagInfo, setRootTagInfo] = useState([]);
  //   const [tagIds, setTagIds] = useState([]);

  const [dirty, setDirty] = useState({});

  const [sensorConfig, setSensorConfig] = useState({});
  const [backwardCompatibleSensorType, setBackwardCompatibleSensorType] =
    useState("");
  const [devDirty, setDevDirty] = useState(false);

  const [changes, setChanges] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  //const {sendTo, setSendTo} = useContext(AlertsAddressBookContext)
  const [sendTo, setSendTo] = useState([]);
  //console.log("sendto at init",sendTo,tagProperties);
  const lastStackId = useRef(null);
  const lastDashboardsState = useRef({});
  const [processStatus, setProcessStatus] = useState(null);
  const isxContext = useContext(ISXContext);

  const dispatch = useDispatch();

  const { readOnly, widget, user, updateWidget, createOrUpdateDashboards } =
    props;

  // need organization (group) first; it is a widget option in order to
  // persist, but also need to ensure that user still member of org
  const organization = useMemo(
    () => orgs[widget?.options?.organization],
    [orgs, widget?.options?.organization]
  );

  // select options for organization
  const orgSelectOptions = useMemo(
    () => Object.values(orgs).sort((a, b) => (a.name < b.name ? -1 : 1)) ?? [],
    [orgs]
  );

  // once group is defined get only stacks associated with group
  const stacks = useMemo(
    () =>
      props.stacks && organization
        ? _pick(props.stacks, organization.devices ?? [])
        : {},
    [props.stacks, organization]
  );

  // select options for stack
  const stackSelectOptions = useMemo(() => {
    return stackNames && organization?.devices
      ? _uniq(
          organization.devices.sort((a, b) =>
            stackNames[a.guuid] < stackNames[b.guuid] ? -1 : 1
          )
        )
      : [];
  }, [organization?.devices, stackNames]);

  // stack is persisted on widget, but need to make sure user has access
  const stack = useMemo(
    () => stacks[widget?.stacks?.[0]],
    [stacks, widget?.stacks]
  );
  const stackName = stackNames?.[stack?.guuid] ?? "";

  const getStackSendTos = useCallback(() => {
    for (const t of stack?.data_config?.dsitems.filter((prop) =>
      prop.startsWith("sensor")
    )) {
      //console.log("in getstacksendtos",t,t in stack?.data_config,stack?.data_config[t],stack?.data_config[t]?.alarms);
      if (t in stack?.data_config && stack?.data_config[t]?.alarms) {
        for (const a of stack?.data_config[t]?.alarms) {
          if (
            a?.tmpid === "a068dd74-6f8e-41a9-b492-9083ba7ae54d" &&
            a?.sendto
          ) {
            //console.log("sendto from stack",a?.sendto);
            return a?.sendto.filter((x) => typeof x === "string"); //temp fix to fill only regular email/phone string sendtos. Not dictioaries.
          }
        }
      }
    }
    return [];
  }, [stack]);

  /*useEffect(() => {
    console.log("in use effect");
    //if (stack?.guuid !== lastStackId.current) {
      if (stack) {
        //lastStackId.current = stack.guuid;
      setSendTo(getStackSendTos);
    }
    else {
      setSendTo([]);
    }
  //}
  },[stack,getStackSendTos]);*/

  useEffect(() => {
    if (!stackNames && props.stacks && Object.keys(props.stacks).length > 0) {
      setStackNames(
        Object.values(props.stacks).reduce((acc, stack) => {
          acc[stack.guuid] =
            stack?.name || JSON.parse(stack.dev_session ?? "{}")["stack.label"];
          return acc;
        }, {})
      );
    }
  }, [props.stacks, stackNames]);

  const getSensorConfig = useCallback(() => {
    return JSON.parse(stack.dev_session ?? "{}")?.sensorconfig || {};
  }, [stack?.dev_session]);

  const getBackwardCompatibleSensorType = useCallback(() => {
    const ds = JSON.parse(stack.dev_session ?? "{}");
    return ds?.sendraw === true
      ? "raw"
      : ds?.currentsource === true
      ? "750"
      : "850";
  }, [stack?.dev_session]);

  const getTagPropertiesFromConfig = useCallback(
    (tagIds) =>
      tagIds.reduce((acc, tid) => {
        acc[tid] = _cloneDeep(stack.data_config?.[tid] ?? {});
        return acc;
      }, {}),
    [stack?.data_config]
  );

  const getDashProps = () => {
    const dashProps = {};
    for (const [key, value] of Object.entries(tagProperties || {})) {
      dashProps[key] = {
        idx: getSensorIdFromSensorConfig(key),
        name: value?.name || key,
        devicetype: value?.devicetype || "none",
      };
    }
    //console.log("dashprops are",dashProps);
    return dashProps;
  };

  const selectStack = (guuid) => {
    setOverviewDashboardId(); //clear overviewdashboard for previous stack
    updateWidget({ stacks: [guuid] });
  };

  const selectOrganization = (guuid) => {
    updateWidget({ options: { ...widget.options, organization: guuid } });
  };

  const handleTagPropChange = (tagId, prop, value) => {
    const propPath = prop.split(".");
    const headProp = _head(propPath);
    setTagProperties((tagProperties) => {
      return {
        ...tagProperties,
        [tagId]: _set({ ...tagProperties[tagId] }, propPath, value),
      };
    });
    setDirty((dirty) => {
      return { ...dirty, [tagId]: { ...dirty[tagId], [headProp]: true } };
    });
  };

  const getSensorIdFromSensorConfig = (tagId) => {
    //console.log("sensorconfig is",sensorConfig,tagId,backwardCompatibleSensorType);
    for (const [key, value] of Object.entries(sensorConfig || {})) {
      if ("name" in value && value.name === tagId) {
        return key;
      }
    }
    return "";
  };

  const handleSensorConfigChange = (sId, prop, value) => {
    if (sId in (sensorConfig || {})) {
      sensorConfig[sId][prop] = sensorConfigNumberProps.has(prop)
        ? parseFloat(value)
        : value;
      setSensorConfig({ ...sensorConfig });
      setDevDirty((devDirty) => true);
    }
  };

  const addDefaultsIfNotPresent = (tagprops) => {
    const sdefaults = { numeric: { max: 60, min: 0 } };
    const ldefaults = { baseline: 28 };
    const thoffsets = {
      "threshold.baseline": 0,
      "threshold.level1.high": 8,
      "threshold.level2.high": 16,
      "threshold.level3.high": 35,
    };
    const dirty = {};
    Object.keys(tagprops).forEach((tid) => {
      const props = tagprops[tid];
      const dprops = {};
      if (tid.startsWith("sensor")) {
        if (!props?.numeric) {
          props.numeric = { ...sdefaults?.numeric };
          dprops.numeric = true;
        } else {
          if (props?.numeric?.min === undefined) {
            props.numeric.min = sdefaults?.numeric?.min;
            dprops.numeric = true;
          }
          if (props?.numeric?.max === undefined) {
            props.numeric.max = sdefaults?.numeric?.max;
            dprops.numeric = true;
          }
        }
        const lbaseline = parseInt(
          tagprops["lube_" + tid]?.baseline ||
            props?.threshold?.baseline ||
            ldefaults?.baseline
        );
        if (parseInt(props?.threshold?.baseline) !== lbaseline) {
          dprops.threshold = true;
          Object.keys(thoffsets).forEach((k) => {
            props.threshold = {
              baseline: lbaseline,
              level1: { high: lbaseline + 8 },
              level2: { high: lbaseline + 16 },
              level3: { high: lbaseline + 35 },
            };
          });
        }
      }
      if (Object.keys(dprops).length > 0) {
        dirty[tid] = dprops;
      }
    });
    setDirty(dirty);
  };

  const handleBaseLinePropChange = (tagId, prop, value) => {
    //console.log("BaseLine props",tagId,prop,value);
    let stagid = null;
    if (tagId.startsWith("lube_")) {
      stagid = tagId.split("_")[1];
      handleTagPropChange(tagId, prop, value);
    } else {
      stagid = tagId;
    }
    if (stagid !== null) {
      //const stagid = tagId.split("_")[1];
      const thprops = [
        "threshold.baseline",
        "threshold.level1.high",
        "threshold.level2.high",
        "threshold.level3.high",
      ];
      const tprops = {};
      [0, 8, 16, 35].forEach((v, vindex) => {
        tprops[thprops[vindex]] = (parseInt(value) + v).toString();
      });
      handleTagPropsChange(stagid, tprops);
      //handleTagPropsChange(sprop,new Map([0,8,16,35].map((v,vindex) => [thprops[vindex],(parseInt(value)+v).toString()])));
    }
  };

  const handleTagPropsChange = (tagId, tagProps) => {
    const propUpdates = {};
    const dirtyProps = {};
    Object.entries(tagProps).forEach(([prop, value]) => {
      const propPath = prop.split(".");
      const headProp = _head(propPath);
      _set(propUpdates, propPath, value);
      dirtyProps[headProp] = true;
    }, {});
    //console.log("propUpdates", propUpdates, dirtyProps);
    setTagProperties((tagProperties) => {
      return {
        ...tagProperties,
        [tagId]: {
          ...tagProperties[tagId],
          ...propUpdates,
        },
      };
    });
    setDirty((dirty) => ({
      ...dirty,
      [tagId]: { ...dirty[tagId], ...dirtyProps },
    }));
  };

  const parseSendTos = (sendTos) => {
    return sendTos.map((s) => {
      if (typeof s === "string") {
        return s;
      } else if (typeof s === "object" && "label" in s) {
        return s.label;
      } else {
        return s.toString();
      }
    });
  };

  //const validate = (chip) => sendTo.length < MAX_INVITES;

  const handleAddInviteeChip = (chip) => {
    //console.log("chip is",chip,sendTo);
    setSendTo([...sendTo, chip]);
  };

  const handleDeleteInviteeChip = (chip, index) => {
    const updatedsendto = [...sendTo];
    updatedsendto.splice(index, 1);
    setSendTo(updatedsendto);
  };

  const getAlertTemplates = (tagId) => {
    if (tagId.startsWith("sensor")) {
      return [
        "a068dd74-6f8e-41a9-b492-9083ba7ae54d",
        "f57b25d6-7b99-49b9-a914-b2b9a14ec02f",
      ];
    } else if (tagId.startsWith("lube_")) {
      return ["a516c834-0b2f-4d71-a42b-c434483b8f8a"];
    } else if (tagId.startsWith("vol_")) {
      return ["ca2012e0-42d0-494f-b3d3-26f6b163730b"];
    } else if (tagId.startsWith("isxreserved|connected")) {
      return ["2a89f671-d351-45cd-a11c-38229e58f0da"];
    } else if (tagId.startsWith("isxreserved|events")) {
      return ["4a9b48c0-8b07-4b2b-9478-8c458d2766ae"];
    }

    return [];
  };
  const updateAlerts = useCallback(async () => {
    const filteredProps = Object.keys(tagProperties).filter(
      (prop) => prop.startsWith("lube_") || prop.startsWith("vol_")
    );
    let pmsg = filteredProps.map((t) => {
      return {
        name: "set_alerts",
        tag: t,
        template: getAlertTemplates(t)[0],
        sendto: [
          {
            label: "Everyone@" + organization?.name,
            type: "org",
            value: organization?.guuid,
          },
        ],
        enabled: true,
      };
    });
    //add sendTos to sensor tags.. currently only support new type of sensorids that start with 'sensor'
    const stags = Object.keys(tagProperties).filter((prop) =>
      prop.startsWith("sensor")
    );
    pmsg = pmsg.concat(
      stags
        .map((t) => {
          return [
            {
              name: "set_alerts",
              tag: t,
              template: getAlertTemplates(t)[0],
              sendto: sendTo,
              enabled: true,
            }, //this is for the 3 alert threshold
            {
              name: "set_alerts",
              tag: t,
              template: getAlertTemplates(t)[1],
              sendto: sendTo,
              enabled: tagProperties["lube_" + t]?.autolube ? true : false,
              duration:
                tagProperties["lube_" + t]?.autolube > 1
                  ? tagProperties["lube_" + t]?.autolube
                  : null,
            }, //this one is for auto luber trigger. duration and enabled are derived from the luber tag props
          ];
        })
        .flat()
    );
    //add connection event alert
    pmsg.push({
      name: "set_alerts",
      tag: "isxreserved|connected",
      template: getAlertTemplates("isxreserved|connected")[0],
      sendto: [
        {
          label: "Everyone@" + organization?.name,
          type: "org",
          value: organization?.guuid,
        },
      ],
      enabled: true,
    });
    // add other event alerts
    pmsg.push({
      name: "set_alerts",
      tag: "isxreserved|events",
      template: getAlertTemplates("isxreserved|events")[0],
      //sendto will be filled in my the template for now
      enabled: true,
    });
    const msgtosend = {
      access_key: stack?.access_key,
      guuid: stack?.guuid,
      op: "update",
      process: pmsg,
    };
    //console.log("devDirty is",devDirty,sensorConfig);
    const resp = [];
    if (devDirty === true) {
      msgtosend["su"] = { sensorconfig: sensorConfig };
      resp.push("device settings");
    }
    //console.log("alert process messages are",devDirty,msgtosend);
    const results = await QueryService.sendProcessMessage(msgtosend);
    console.log("results from updating alerts are", results);
    setDevDirty((devDirty) => false);
    resp.push("alerts");
    return resp;
  }, [
    devDirty,
    organization?.guuid,
    organization?.name,
    sendTo,
    sensorConfig,
    stack?.access_key,
    stack?.guuid,
    tagProperties,
  ]);

  const saveTagPropertiesToDB = useCallback(async () => {
    const update = Object.entries(dirty).reduce((acc, [tid, props]) => {
      const tagIdEscaped = ISXUtils.escapeTagPeriods(tid);
      return Object.entries(props).reduce((acci, [prop, flag]) => {
        if (flag) {
          const val = (tagProperties[tid] ?? {})[prop];
          const key = `data_config.${tagIdEscaped}.${prop}`;
          acci[key] = val;
        }
        return acci;
      }, acc);
    }, {});
    //console.log("dirty and update are",dirty,update);
    if (!_isEmpty(update)) {
      dispatch(updateItemOp(stack.guuid, update)).then(() => {
        setDirty({});
      });
      return ["tags"];
    }
    return [];
  }, [dirty, dispatch, stack?.guuid, tagProperties]);

  const validate = useCallback(() => {
    const validationErrors = [];
    Object.entries(tagProperties).forEach(([tagId, props]) => {
      if (tagId.startsWith("lube_")) {
        if (props.devicetype && props.devicetype !== "none") {
          LUBE_TAG_VALIDATION_PROPS.forEach((pname) => {
            if (!props[pname]) {
              validationErrors.push({
                category: "Tag",
                subject: `${tagId}: ${pname}`,
                description: "does not have a valid value",
              });
            }
          });
        }
      }
    });

    const dashboardGroupEntries = Object.entries(dashboardGroups);
    if (dashboardGroupEntries.length > MAX_SUPPORTED_GROUPS) {
      validationErrors.push({
        category: "Group Count of",
        subject: dashboardGroupEntries.length,
        description: `exceeds maximum number of ${MAX_SUPPORTED_GROUPS} permitted`,
      });
    }

    // validate all groups have a valid name
    dashboardGroupEntries.forEach(([groupId, groupProps]) => {
      if (!(groupProps.name ?? "").trim()) {
        validationErrors.push({
          category: "Group",
          subject: groupId,
          description: "does not have a valid name",
        });
      }
    });

    // validate that groups have tags consistent with device type
    const tagGroups = getTagsByGroup(
      new Set(Object.keys(dashboardGroups)),
      tagDashboardGroups
    );
    Object.entries(tagGroups).forEach(([groupId, tagIds]) => {
      if (tagIds.size > 0) {
        const tagIdsArray = Array.from(tagIds).map((tid) => `lube_${tid}`);
        // distinguish between tags where device type is defined vs not
        const firstTagDeviceType = tagProperties[tagIdsArray[0]]?.devicetype;
        const match = tagIdsArray
          .slice(1)
          .every(
            (tagId) =>
              (tagProperties[tagId]?.devicetype && firstTagDeviceType) ||
              (!tagProperties[tagId]?.devicetype && !firstTagDeviceType)
          );

        if (!match) {
          validationErrors.push({
            category: "Group",
            subject: groupId,
            description: "tags must all have cartridge type assigned or not",
          });
        }
      }
    });

    return validationErrors;
  }, [dashboardGroups, tagDashboardGroups, tagProperties]);

  const createChanges = useCallback(() => {
    // WIP on determining what dashboard changes will be needed
    // compare current dashboards state to last dashboards contents
    // step 1: compare client, location, area
    const prefixUpdate =
      lastDashboardsState.current.client !== client ||
      lastDashboardsState.current.location !== location ||
      lastDashboardsState.current.area !== area;
    // if (prefixUpdate) {
    //   console.log("Dashboards prefix changed");
    // }

    // step 2: compare dashboard groups
    const currentGroupIds = new Set(Object.keys(dashboardGroups));
    const groupTags = getTagsByGroup(currentGroupIds, tagDashboardGroups);
    const lastGroupIds = new Set(
      Object.keys(lastDashboardsState.current.dashboardGroups ?? {})
    );
    const groupsToAdd = [...currentGroupIds].reduce((acc, groupId) => {
      if (!lastGroupIds.has(groupId)) {
        acc.add(groupId);
      }
      return acc;
    }, new Set());
    const groupsToRemove = [...lastGroupIds].reduce((acc, groupId) => {
      if (!currentGroupIds.has(groupId) || groupTags[groupId].size === 0) {
        acc.add(groupId);
      }
      return acc;
    }, new Set());
    let groupsPersisting = [...currentGroupIds].reduce((acc, groupId) => {
      if (lastGroupIds.has(groupId) && groupTags[groupId].size > 0) {
        acc.add(groupId);
      }
      return acc;
    }, new Set());

    // step 3A: for persisting groups, check it tags have been added/removed or tag names have changed since we need to update widget titles
    const lastGroupTags = lastDashboardsState.current.groupTags;
    const groupsToUpdateTagsChanged = [...groupsPersisting].reduce(
      (acc, groupId) => {
        const tagsForGroup = groupTags[groupId];
        tagsForGroup.forEach((tagId) => {
          if (dirty[tagId]?.name) {
            acc.add(groupId);
          }
        });
        const lastTagsForGroup = lastGroupTags[groupId];
        if (!_isEqual(tagsForGroup, lastTagsForGroup)) {
          acc.add(groupId);
        }
        return acc;
      },
      new Set()
    );

    // step 3B: for remaining persisting groups, see which have changed tag
    // cartridge type
    groupsPersisting = [...groupsPersisting].filter(
      (gid) => !groupsToUpdateTagsChanged.has(gid)
    );
    const groupsToUpdateTagsDeviceTypeChanged = [...groupsPersisting].reduce(
      (acc, groupId) => {
        const tagsForGroup = groupTags[groupId];
        tagsForGroup.forEach((rootTagId) => {
          const tagId = `lube_${rootTagId}`;
          if (dirty[tagId]?.devicetype) {
            acc.add(groupId);
          }
        });
        return acc;
      },
      new Set()
    );

    // step 3C: for any still remaining, see if group name changed
    groupsPersisting = [...groupsPersisting].filter(
      (gid) => !groupsToUpdateTagsDeviceTypeChanged.has(gid)
    );
    const groupsToUpdateNameChangeOnly = [...groupsPersisting].reduce(
      (acc, groupId) => {
        if (
          prefixUpdate ||
          dashboardGroups[groupId].name !==
            lastDashboardsState.current.dashboardGroups?.[groupId]?.name
        ) {
          acc.add(groupId);
        }
        return acc;
      },
      new Set()
    );

    groupsPersisting = [...groupsPersisting].filter(
      (gid) => !groupsToUpdateNameChangeOnly.has(gid)
    );

    // console.log("groups to add", groupsToAdd);
    // console.log("groups to remove", groupsToRemove);
    // console.log(
    //   "groups to update tags added/removed",
    //   groupsToUpdateTagsChanged
    // );
    // console.log(
    //   "groups to update tag device types changed",
    //   groupsToUpdateTagsDeviceTypeChanged
    // );
    // console.log(
    //   "groups to update name change only",
    //   groupsToUpdateNameChangeOnly
    // );
    // console.log("groups persisting", groupsPersisting);

    // see if overview db needs to be updated which includes any name changes to the tags as well
    let overviewTagsChanged = false;
    if (overviewDashboardId) {
      const overviewTags = new Set(Object.keys(tagDashboardGroups));
      const lastOverviewTags = new Set(
        Object.keys(lastDashboardsState.current.tagDashboardGroups ?? {})
      );
      overviewTagsChanged =
        !_isEqual(overviewTags, lastOverviewTags) ||
        Object.keys(dirty).some((x) => "name" in dirty[x]);
    }

    const sharedGroupChanged = false;

    const changes = {
      added: groupsToAdd,
      removed: groupsToRemove,
      tagsChanged: groupsToUpdateTagsChanged,
      deviceTypesChanged: groupsToUpdateTagsDeviceTypeChanged,
      nameChanged: groupsToUpdateNameChangeOnly,
      overviewNameChanged: prefixUpdate,
      overviewTagsChanged,
      sharedGroupChanged,
    };
    return changes;
  }, [
    area,
    client,
    dashboardGroups,
    dirty,
    location,
    overviewDashboardId,
    tagDashboardGroups,
  ]);

  const generateChangeMessages = useCallback(
    (changes) => [
      ...[...changes.added].map((groupId) => ({
        category: "Dashboard",
        subject: `${client}.${location}.${area}.${stackName}.${dashboardGroups[groupId].name}`,
        description: "will be created",
      })),
      ...[...changes.removed].map((groupId) => ({
        category: "Dashboard",
        subject: `${client}.${location}.${area}.${stackName}.${lastDashboardsState.current.dashboardGroups[groupId].name}`,
        description: "will be removed",
      })),
      ...[...changes.tagsChanged].map((groupId) => ({
        category: "Dashboard",
        subject: `${client}.${location}.${area}.${stackName}.${dashboardGroups[groupId].name}`,
        description: "will be updated",
      })),
      ...[...changes.deviceTypesChanged].map((groupId) => ({
        category: "Dashboard",
        subject: `${client}.${location}.${area}.${stackName}.${dashboardGroups[groupId].name}`,
        description: "will be updated",
      })),
      ...[...changes.nameChanged].map((groupId) => ({
        category: "Dashboard",
        subject: `${client}.${location}.${area}.${stackName}.${dashboardGroups[groupId].name}`,
        description: "name change only",
      })),
      ...(!overviewDashboardId
        ? [
            {
              category: "Dashboard",
              subject: `${client}.${location}.${area}.${stackName}.Overview`,
              description: "will be created",
            },
          ]
        : []),
      ...(overviewDashboardId && changes.overviewTagsChanged
        ? [
            {
              category: "Dashboard",
              subject: `${client}.${location}.${area}.${stackName}.Overview`,
              description: "will be updated",
            },
          ]
        : []),
      ...(overviewDashboardId &&
      !changes.overviewTagsChanged &&
      changes.overviewNameChanged
        ? [
            {
              category: "Dashboard",
              subject: `${client}.${location}.${area}.${stackName}.Overview`,
              description: "name change ony",
            },
          ]
        : []),
      ...Object.keys(dirty).map((tagId) => ({
        category: "Tag",
        subject: tagId,
        description: "properties will be updated",
      })),
      ...(changes.sharedGroupChanged
        ? [
            {
              category: "Dashboard",
              subject: "group shared",
              description: "will be changed",
            },
          ]
        : []),
    ],
    [
      area,
      client,
      dashboardGroups,
      dirty,
      location,
      overviewDashboardId,
      stackName,
    ]
  );

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const commitChanges = useCallback(
    async (changes) => {
      setProcessStatus("Gathering data to process");
      const groups = Object.entries(dashboardGroups).reduce(
        (
          acc,
          [groupId, { dashboardId: groupDashboardId, name: groupName }]
        ) => {
          acc[groupId] = {
            id: groupId,
            dashboardId: groupDashboardId,
            name: groupName,
            tags: Object.entries(tagDashboardGroups)
              .filter(([, tagGroupId]) => groupId === tagGroupId)
              .map(([tagId]) => tagId),
          };
          return acc;
        },
        {}
      );
      const operations = [];
      const dservice = new LubeDashboardService(
        stack,
        user,
        groups,
        `${client}.${location}.${area}`,
        overviewDashboardId,
        getDashProps(tagProperties)
      );
      if (!overviewDashboardId) {
        // no overview dashboard yet, so create now
        const db = dservice.createOrUpdateOverviewDashboard();
        setOverviewDashboardId(db.guuid);
        operations.push({
          op: "create",
          dashboard: db,
        });
      } else if (changes.overviewTagsChanged) {
        // update overview dashboard
        const db = dservice.createOrUpdateOverviewDashboard();
        operations.push({
          op: "update",
          dashboard: db,
        });
      } else if (changes.overviewNameChanged) {
        operations.push({
          op: "update",
          dashboard: {
            guuid: overviewDashboardId,
            title: dservice.getOverviewDashboardTitle("Overview"),
          },
        });
      }

      const groupUpdates = {};
      changes.added.forEach((groupId) => {
        // create group dashboard
        const group = dservice.groups[groupId];
        const db = dservice.createOrUpdateGroupDashboard(group);
        operations.push({
          op: "create",
          dashboard: db,
        });
        groupUpdates[groupId] = {
          dashboardId: db.guuid,
          name: group.name,
        };
      });
      changes.removed.forEach((groupId) => {
        const group = dservice.groups[groupId];
        operations.push({
          op: "delete",
          dashboard: { guuid: group.dashboardId },
        });
      });
      changes.tagsChanged.forEach((groupId) => {
        // update dashboard
        const group = dservice.groups[groupId];
        const db = dservice.createOrUpdateGroupDashboard(group);
        operations.push({
          op: "update",
          dashboard: db,
        });
      });
      changes.deviceTypesChanged.forEach((groupId) => {
        // update dashboard
        const group = dservice.groups[groupId];
        const db = dservice.createOrUpdateGroupDashboard(group);
        operations.push({
          op: "update",
          dashboard: db,
        });
      });
      changes.nameChanged.forEach((groupId) => {
        // update dashboard name only
        const group = dservice.groups[groupId];
        operations.push({
          op: "update",
          dashboard: {
            guuid: group.dashboardId,
            title: dservice.getGroupDashboardTitle(group.name),
          },
        });
      });
      const actions = [];
      if (operations.length) {
        const payload = {
          operations,
          share_groups: organization?.guuid ? [organization.guuid] : [],
        };
        if (changes.sharedGroupChanged) {
          payload.unshare_groups = [lastDashboardsState.current.organizationId];
        }
        // console.log(changes, payloads, groupUpdates);
        setProcessStatus("Updating Dashboards");
        const success = await createOrUpdateDashboards(payload);
        actions.push("dashboards");
        // WIP keep track of last dashboard state commited
        if (success && Object.keys(groupUpdates).length > 0) {
          // any group updates, update state to capture dashboard id changes
          const newDashboardGroups = { ...dashboardGroups, ...groupUpdates };
          setDashboardGroups(newDashboardGroups);
          // stash so prop update does not trigger recompute
          lastDashboardsState.current.dashboardGroups = newDashboardGroups;
          // lastDashboardsState.current.organizationId = organizationId;
        }
      }
      // writeback tag prop updates
      if (Object.keys(dirty).length) {
        setProcessStatus("Updating Tag Propeties");
        await sleep(2000);
        const st = await saveTagPropertiesToDB();
        actions.push(st);
      }
      setProcessStatus("Updating Alerts");
      await sleep(2000);
      const al = await updateAlerts();
      actions.push(al);
      setProcessStatus("Logging Actions");
      //const actions = [].concat(dsupdate,al)
      if (actions.length) {
        //send summary of actions completed to isxreserved|events
        const msgtosend = {
          access_key: stack?.access_key,
          guuid: stack?.guuid,
          op: "update",
          st: {
            "isxreserved|events": [
              [
                Date.now(),
                {
                  evcat: "user",
                  user: user.username,
                  data: { updated: actions.flat() },
                  evtype: "config",
                },
              ],
            ],
          },
        };
        const results = await QueryService.sendProcessMessage(msgtosend);
        return true;
      }
      return false;
    },
    [
      dashboardGroups,
      stack,
      user,
      client,
      location,
      area,
      overviewDashboardId,
      organization?.guuid,
      createOrUpdateDashboards,
      saveTagPropertiesToDB,
      updateAlerts,
      tagDashboardGroups,
      processStatus,
    ]
  );

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = useCallback(() => setDialogOpen(false), []);

  useEffect(() => {
    setInitialized(false);
  }, [stack?.guuid]);

  useEffect(() => {
    const getConfigFromDashboards = () => {
      const _getBestAlternative = (alternatives) => {
        const counted = alternatives.reduce((acc, val) => {
          acc[val] = (acc[val] ?? 0) + 1;
          return acc;
        }, {});
        return Object.entries(counted).sort((a, b) => b[1] - a[1])[0]?.[0];
      };
      if (props.dashboards) {
        const dashboardGroups = {};
        const tagDashboardGroups = {};
        const clientAlternatives = [];
        const locationAlternatives = [];
        const areaAlternatives = [];
        const dashboardsToFetch = new Set();

        Object.values(props.dashboards).forEach((d) => {
          const agStackId = d.autogenerated_stack;
          const agDashboardId = d.autogenerated_dashboard;
          const agGroupId = d.autogenerated_group;
          const agTitle = d.autogenerated_title;
          const organizationIds = new Set(d.acu_groups ?? []);

          // need to match this stack; also check if dashboard GUUID matches to
          // guard against using a dashboard copy
          if (
            stack?.guuid === agStackId &&
            d.guuid === agDashboardId &&
            organization?.guuid &&
            organizationIds.has(organization.guuid)
          ) {
            if (!d._version) {
              // console.log("need to fetch dashboard!", d.guuid);
              dashboardsToFetch.add(d.guuid);
            } else {
              // parse title to get group name and client/location/factory
              const titleParts = (d.title ?? agTitle ?? "").split(".");
              const [client, location, area] = titleParts.slice(0, 3);
              // 4th element should be stack name, skip
              const groupName = titleParts.slice(4).join(".");
              if (client && location && area && groupName) {
                clientAlternatives.push(client);
                locationAlternatives.push(location);
                areaAlternatives.push(area);
                if (agGroupId) {
                  dashboardGroups[agGroupId] = {
                    dashboardId: d.guuid,
                    name: groupName,
                  };
                  // find tags within widgets; only consider tags that are of the form
                  // "sensor<d>"
                  d.widgets.forEach((widgetId) => {
                    const widget = props.widgets?.[widgetId];
                    (widget.tags ?? []).forEach((wtag) => {
                      const { attribute, stack: wstackId } = wtag;
                      // only tags from our stack!
                      if (stack?.guuid === wstackId) {
                        // need to parse tag attribute since it may have other info on it
                        const rootAttr = attribute.split("|")[0];
                        if (rootAttr.match(/^sensor\d+$/)) {
                          // found match - this tag considered to be part of group
                          // (perhaps should also match widget type?); also possiblity
                          // tag may be in multiple groups if dashboard edited by
                          // hand...
                          tagDashboardGroups[rootAttr] = agGroupId;
                        }
                      }
                    });
                  });
                } else {
                  // overview dashboard
                  setOverviewDashboardId(d.guuid);
                }
              }
            }
          }
        });

        if (dashboardsToFetch.size === 0) {
          const client = _getBestAlternative(clientAlternatives) ?? "";
          const location = _getBestAlternative(locationAlternatives) ?? "";
          const area = _getBestAlternative(areaAlternatives) ?? "";

          const groupTags = getTagsByGroup(
            new Set(Object.keys(dashboardGroups)),
            tagDashboardGroups
          );

          const newDashboardsState = {
            client,
            location,
            area,
            dashboardGroups,
            tagDashboardGroups,
            groupTags,
          };
          if (
            lastStackId.current !== stack?.guuid ||
            !_isEqual(lastDashboardsState.current, newDashboardsState)
          ) {
            setClient(client);
            setLocation(location);
            setArea(area);
            setDashboardGroups(dashboardGroups);
            setTagDashbordGroups(tagDashboardGroups);

            lastDashboardsState.current = newDashboardsState;
          }
          setInitialized(true);
        } else {
          // console.log("fetching dashboards", dashboardsToFetch);
          dispatch(getDashboards(null, Array.from(dashboardsToFetch)));
        }
      }
    };

    getConfigFromDashboards();
  }, [
    dispatch,
    organization?.guuid,
    props.dashboards,
    props.widgets,
    stack?.guuid,
  ]);

  useEffect(() => {
    if (stack?.guuid !== lastStackId.current) {
      if (stack) {
        lastStackId.current = stack.guuid;
        const rootTagAttrs =
          stack.data_config?.dsitems?.filter((attr) =>
            attr.startsWith("sensor")
          ) ?? [];

        const tagIds = rootTagAttrs.flatMap((attr) => [
          attr,
          `lube_${attr}`,
          `vol_${attr}`,
        ]);
        const tagProperties = getTagPropertiesFromConfig(tagIds);
        addDefaultsIfNotPresent(tagProperties);
        setTagProperties(tagProperties);
        setSendTo(getStackSendTos);
        setSensorConfig(getSensorConfig);
        setBackwardCompatibleSensorType(getBackwardCompatibleSensorType);
      } else {
        lastStackId.current = null;
        setTagProperties({});
        setSendTo([]);
        setSensorConfig({});
        setBackwardCompatibleSensorType("");
      }
    }
  }, [stack, getTagPropertiesFromConfig]);

  const dashboardsEditDisabled =
    !initialized || readOnly || !stack || !organization;

  return (
    <Widget
      {...props}
      maxStacks={1}
      configurable={false}
      widgetTitle="Configuration"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid
          item
          container
          xs={12}
          spacing={3}
          style={{ margin: 12, flex: "1 1 auto" }}
        >
          <Grid item container xs={8} spacing={3}>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                variant="outlined"
                size="small"
                value={organization?.guuid ?? ""}
                label={`Select Group for ${isxContext.labels.deviceCapitalized}`}
                style={{ width: "100%" }}
                onChange={(event) => selectOrganization(event.target.value)}
                disabled={!initialized || readOnly}
                required
              >
                {orgSelectOptions.map((org) => (
                  <MenuItem value={org.guuid} key={org.guuid}>
                    {org.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                variant="outlined"
                size="small"
                value={stack?.guuid ?? ""}
                label={`Select ${isxContext.labels.deviceCapitalized}`}
                onChange={(event) => selectStack(event.target.value)}
                disabled={
                  !initialized || readOnly || stackSelectOptions.length === 0
                }
                required
              >
                {stackSelectOptions.map((guuid) => (
                  <MenuItem key={guuid} value={guuid}>
                    {stackNames[guuid] ?? ""}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                size="small"
                value={client}
                onChange={(event) => setClient(event.target.value)}
                label="Client"
                disabled={dashboardsEditDisabled}
                style={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                size="small"
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                label="Location"
                disabled={dashboardsEditDisabled}
                style={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                size="small"
                value={area}
                onChange={(event) => setArea(event.target.value)}
                label="Factory/Area"
                disabled={dashboardsEditDisabled}
                style={{ width: "100%" }}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                paddingBottom: 12,
                marginBottom: -12,
                paddingTop: 12,
                marginTop: -12,
                overflow: "hidden",
              }}
            >
              <ChipInput
                label="Recipients for Multilevel Ultrasonic Sensor Alerts"
                placeholder="Type valid email address and hit Enter to add"
                value={sendTo}
                onAdd={(chip) => handleAddInviteeChip(chip)}
                onDelete={(chip, index) => handleDeleteInviteeChip(chip, index)}
                variant="outlined"
                fullWidthInput
                allowDuplicates={false}
                type="email"
                limit={MAX_INVITES}
                disabled={dashboardsEditDisabled}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  //classes={{ label: classes.inputLabels }}
                  name="enabled"
                  checked={showAdvanced}
                  onClick={(event) => {
                    setShowAdvanced(!showAdvanced);
                  }}
                  control={<Checkbox />}
                  //disabled={readOnly || alarm.tag === ""}
                  label="Show Advanced Properties"
                />
              </FormGroup>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            overflow: "auto",
            width: "100%",
            height: "100%",
          }}
        >
          <Table
            stickyHeader
            style={{ fontSize: "0.875rem", borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                <StyledCompactHeaderCell>id</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>
                  Dashboard Group #
                </StyledCompactHeaderCell>
                <StyledGroupNameHeaderCell>
                  Dashboard Group Name
                </StyledGroupNameHeaderCell>
                <StyledTagNameHeaderCell>Tag Name</StyledTagNameHeaderCell>
                <StyledCompactHeaderCell>Minimum</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>Maximum</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>Sensor Type</StyledCompactHeaderCell>
                {showAdvanced && (
                  <>
                    <StyledCompactHeaderCell>
                      Assist Delta
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      Stuck Off Ref
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      Stuck Off Delta
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      Stuck Off Time
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      Stuck Signal Delta
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      Stuck Signal Time
                    </StyledCompactHeaderCell>
                  </>
                )}
                <StyledCompactHeaderCell>Baseline</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>Luber Type</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>
                  Luber MAC Address
                </StyledCompactHeaderCell>
                <StyledCompactHeaderCell>Capacity</StyledCompactHeaderCell>
                <StyledCompactHeaderCell>
                  Dispense Amount
                </StyledCompactHeaderCell>
                <StyledCompactHeaderCell>
                  Max Bearing Capacity
                </StyledCompactHeaderCell>
                <StyledCompactHeaderCell>
                  Lubricant Type
                </StyledCompactHeaderCell>
                {showAdvanced && (
                  <>
                    <StyledCompactHeaderCell>
                      AutoMode On/Off
                    </StyledCompactHeaderCell>
                    <StyledCompactHeaderCell>
                      AutoMode Max Grease Cap Days Limit
                    </StyledCompactHeaderCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_range(1, numRows + 1).map((idx) => (
                <TagRow
                  key={idx}
                  idx={idx}
                  readOnly={dashboardsEditDisabled}
                  {...{
                    tagProperties,
                    handleTagPropChange,
                    handleBaseLinePropChange,
                    sensorConfig,
                    backwardCompatibleSensorType,
                    getSensorIdFromSensorConfig,
                    handleSensorConfigChange,
                    handleTagPropsChange,
                    dashboardGroups,
                    setDashboardGroups,
                    tagDashboardGroups,
                    setTagDashbordGroups,
                    showAdvanced,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <div style={{ display: "flex", justifyContent: "center", margin: 6 }}>
          <Button
            variant="outlined"
            color="primary"
            disabled={
              readOnly || !client || !location || !area || !organization
            }
            onClick={handleDialogOpen}
          >
            Configure
          </Button>
        </div>
      </div>
      <ConfigureDialog
        open={dialogOpen}
        devDirty={devDirty}
        handleClose={handleDialogClose}
        validateChanges={validate}
        createChanges={createChanges}
        generateChangeMessages={generateChangeMessages}
        commitChanges={commitChanges}
        processStatus={processStatus}
        setProcessStatus={setProcessStatus}
      />
    </Widget>
  );
};

export default DashboardConfigurationWidget;
