class QueueService {
  queue = [];

  constructor(delay) {
    this.delay = delay;
  }

  async wait() {
    let queue = this.queue;
    let promise = new Promise((resolve, reject) => {
      let pending = queue[queue.length - 1] || Promise.resolve();
      pending.finally(() => {
        setTimeout(() => {
          queue.shift();
          resolve();
        }, this.delay);
      });
    });
    queue.push(promise);
    return promise;
  }
}

export default QueueService;
