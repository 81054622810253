import QueryService from "./QueryService";
import QueueService from "./QueueService";
import { default as _uniq } from "lodash/uniq";
import { default as _defaults } from "lodash/defaults";

var qservice = new QueueService(100);

const RangeQueryService = {
  query: (
    username,
    password,
    guuid,
    attrs,
    start,
    end,
    maxWindowInSeconds = 86400,
    orgids = null
  ) => {
    const maxWindowInMilliseconds = maxWindowInSeconds * 1000;
    if (end - start <= maxWindowInMilliseconds) {
      return qservice.wait().then(() => {
        return QueryService.rangequery(
          username,
          password,
          guuid,
          attrs,
          start,
          end,
          orgids
        );
      });
    } else {
      return qservice.wait().then(() => {
        let promises = [];
        if (!isNaN(start) && !isNaN(end)) {
          while (start < end) {
            promises.push(
              QueryService.rangequery(
                username,
                password,
                guuid,
                attrs,
                start,
                Math.min(end, start + maxWindowInMilliseconds),
                orgids
              )
            );
            start += maxWindowInMilliseconds;
          }
        }
        return Promise.all(promises).then((results) => {
          let rv = {
            status: 0,
            items: {
              ds: {},
              start: start,
              end: end,
              guuid: guuid,
            },
          };
          if (typeof attrs === "string") {
            attrs = [attrs];
          }
          // uniquify just to be sure, if duplicates will break
          attrs = _uniq(attrs);
          attrs.forEach((a) => {
            rv.items.ds[a] = [];
          });
          results.forEach((r) => {
            const items = r.items || {};
            const ds = items.ds || {};
            attrs.forEach((a) => {
              rv.items.ds[a].push(ds[a] || []);
            });
          });
          attrs.forEach((a) => {
            rv.items.ds[a] = rv.items.ds[a].flat();
          });
          return rv;
        });
      });
    }
  },
  queryLatestOverPeriod: (guuid, attrs, period, orgids = null) =>
    qservice
      .wait()
      .then(() =>
        QueryService.rangequeryLatestOverPeriod(guuid, attrs, period, orgids)
      ),
};

export const RangeQueriesService = {
  query: async (
    tags,
    start,
    end,
    maxWindowInSeconds = 86400,
    orgids = null
  ) => {
    const maxWindowInMilliseconds = maxWindowInSeconds * 1000;
    const createTagKeyFromStackAndAttr = (guuid, attr) => `${guuid}|${attr}`;
    if (end - start <= maxWindowInMilliseconds) {
      return qservice.wait().then(() => {
        return QueryService.rangequeries(tags, start, end, orgids);
      });
    } else {
      await qservice.wait();
      let promises = [];
      if (!isNaN(start) && !isNaN(end)) {
        while (start < end) {
          promises.push(
            QueryService.rangequeries(
              tags,
              start,
              Math.min(end, start + maxWindowInMilliseconds),
              orgids
            )
          );
          start += maxWindowInMilliseconds;
        }
      }
      const results = await Promise.all(promises);
      const tagsMap = {};
      results.forEach((result) => {
        result.forEach((r) => {
          const seen = new Set();
          if (r) {
            const { guuid, attr, data, is_finalized } = r;
            const key = createTagKeyFromStackAndAttr(guuid, attr);
            if (!(key in seen)) {
              seen.add(key);
              const existing = _defaults(tagsMap, {
                [key]: {
                  guuid,
                  attr,
                  data: [],
                },
              })[key];
              existing.data.push(data);
              existing.is_finalized = is_finalized;
            }
          }
        });
      });
      const rv = Object.values(tagsMap);
      rv.forEach((entry) => (entry.data = entry.data.flat()));
      return rv;
    }
  },
  queryLatestOverPeriod: (tags, period, orgids = null) =>
    qservice
      .wait()
      .then(() =>
        QueryService.rangequeriesLatestOverPeriod(tags, period, orgids)
      ),
};

export default RangeQueryService;
