import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const OrganizationManagementCreateOrganizationDialog = (props) => {
  const [newOrganization, setNewOrganization] = useState({});
  const [message, setMessage] = useState({});
  const [createTimeout, setCreateTimeout] = useState({});

  const createOrganization = () => {
    newOrganization.parent = props.organizationId;
    props.createOrganization(newOrganization).then((rv) => {
      clearTimeout(createTimeout);

      if (rv.status === 0) {
        setNewOrganization({ name: "" });
        setMessage({ content: "Organization created successfully" });
        setCreateTimeout(
          setTimeout(() => {
            setMessage({});
            props.handleClose(true)();
          }, 1000)
        );
      } else {
        // error
        setMessage({
          content: `ERROR: ${
            rv.error || "Something went wrong with your request"
          }; please try again or contact support`,
          severity: "error",
        });
      }
    });
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Sub Organization</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ height: "100%", padding: 12 }}
        >
          <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
            <TextField
              autoFocus
              inputRef={props.textFieldRef}
              id={"neworg-name"}
              label="Name"
              margin="normal"
              style={{ margin: 0, width: "100%" }}
              value={newOrganization.name}
              onChange={(event) => {
                setNewOrganization({
                  ...newOrganization,
                  ...{ name: event.target.value },
                });
              }}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap">
              <Grid item xs={9}>
                <Typography variant="body2" color={message.severity}>
                  {message.content}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button
          disabled={newOrganization.name === ""}
          onClick={createOrganization}
          color="primary"
        >
          Create Org
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationManagementCreateOrganizationDialog;
