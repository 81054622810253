const countsProcessingWorker = () => {
  /*eslint-disable-next-line no-restricted-globals*/
  self.addEventListener("message", (e) => {
    if (!e) return;

    const {
      dataset,
      startTime,
      endTime,
      segmentByHours,
      countType,
      match,
      key,
    } = e.data;
    const { result, total } = getCountsInPeriods(
      dataset,
      startTime,
      endTime,
      segmentByHours,
      //  "samples"
      countType,
      match
    );
    // console.log("before postmessage", key, total);
    postMessage({ dataset, result, total, key });
  });

  function getStartOfSegmentFromTime(time, segmentDuration, tz = 0) {
    return Math.floor((time - tz) / segmentDuration) * segmentDuration + tz;
  }

  function diffCounts(dataset, countMap, maximumLengthForDuration, tz = 0) {
    let beginStartSegmentTime = getStartOfSegmentFromTime(
      dataset.rawData[0]["x"],
      maximumLengthForDuration,
      tz
    );
    let segmentStartIndex = 0;
    let segmentStartValue =
      dataset.rawData[0]["y"] === 0 ? 1 : dataset.rawData[0]["y"];
    //let segmentEndValue = segmentStartValue;

    for (let i = 0; i < dataset.rawData.length; i++) {
      let st = getStartOfSegmentFromTime(
        dataset.rawData[i]["x"],
        maximumLengthForDuration,
        tz
      );
      let v = dataset.rawData[i]["y"];
      if (st !== beginStartSegmentTime) {
        //console.log("beginstarttime and st", beginStartSegmentTime, st);
        countMap[beginStartSegmentTime] =
          v - segmentStartValue < 0
            ? v +
              (dataset.rawData
                .slice(segmentStartIndex, i)
                .map((v) => v.y)
                .reduce(function (a, b) {
                  return Math.max(a, b);
                }) -
                segmentStartValue)
            : Math.max(0, v - segmentStartValue);
        beginStartSegmentTime = st;
        segmentStartValue = v;
        segmentStartIndex = i;
      }
    }
    //fill in the last segment value
    countMap[
      getStartOfSegmentFromTime(
        dataset.rawData[dataset.rawData.length - 1]["x"],
        maximumLengthForDuration,
        tz
      )
    ] = Math.max(
      0,
      dataset.rawData[dataset.rawData.length - 1]["y"] -
        segmentStartValue +
        (segmentStartValue > 0 ? 1 : 0)
    );

    //console.log("cmap is", countMap);
    return countMap;
  }

  // not currently being used
  // function getTotalCount(countMap) {
  //   return Object.values(countMap).reduce((a, b) => a + b, 0);
  // }

  function matchvalue(value, condition, conditionValue) {
    switch (condition) {
      case "any":
        return true;
      case "equals":
        return value === conditionValue; //equality check strict or flexible to deal with matching string values?
      case "notequals":
        return value !== conditionValue; //equality check strict or flexible to deal with matching string values?
      case "lessthan":
        return value < conditionValue;
      case "greaterthan":
        return value > conditionValue;
      default:
        return true;
    }
  }
  function countSamples(
    dataset,
    countMap,
    maximumLengthForDuration,
    match,
    tz = 0,
    sumvalues = false,
    nanvalue = 1
  ) {
    //console.log("in countmap", tz, maximumLengthForDuration, countMap, dataset);
    for (let i = 0; i < dataset.rawData.length; i++) {
      let v = dataset.rawData[i]["y"];
      if (matchvalue(v, match[0], match[1])) {
        let bs = getStartOfSegmentFromTime(
          dataset.rawData[i]["x"],
          maximumLengthForDuration,
          tz
        );
        /*console.log(
          "bucket val",
          dataset.rawData[i]["x"],
          dataset.rawData[i]["x"] - tz,
          bs
        );*/
        countMap[bs] += sumvalues ? (isNaN(v) ? nanvalue : parseFloat(v)) : 1;
      }
    }

    //console.log("cmap is", countMap);
    return countMap;
  }

  function getCountsInPeriods(
    dataset,
    startTime,
    endTime,
    segmentByHours,
    countType,
    match
  ) {
    const maximumLengthForDuration = segmentByHours ? 3600000 : 86400000;

    let countMap = {};
    let totalcount = 0;
    let mytz = segmentByHours ? 0 : new Date().getTimezoneOffset() * 60 * 1000;
    let temporaryStart = getStartOfSegmentFromTime(
      startTime,
      maximumLengthForDuration,
      mytz
    );
    //console.log("temporary start", startTime, startTime - mytz, temporaryStart);
    while (temporaryStart < endTime) {
      countMap[temporaryStart] = 0;
      temporaryStart += maximumLengthForDuration;
    }
    //console.log("countMap before is", countMap, mytz);
    if (dataset.rawData.length === 0) {
      return { result: countMap, total: totalcount }; //nothing to process here.. so just return countMap
    }
    if (countType === "samples") {
      countMap = countSamples(
        dataset,
        countMap,
        maximumLengthForDuration,
        match,
        mytz
      );
    } else if (countType === "sum") {
      countMap = countSamples(
        dataset,
        countMap,
        maximumLengthForDuration,
        match,
        mytz,
        true
      );
    } else if (countType === "diff") {
      countMap = diffCounts(dataset, countMap, maximumLengthForDuration, mytz);
      //totalcount = parseInt(dataset.rawData[dataset.rawData.length - 1]['y']);
    }
    totalcount = Object.values(countMap).reduce((a, b) => a + b, 0);
    //console.log("countMap after is", countMap, totalcount);
    return { result: countMap, total: totalcount };
  }
};

export default countsProcessingWorker;
