import React from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  MUI_TABLE_THEME,
  CREATE_MUI_DATATABLE_BASE_COLUMNS,
  CREATE_MUI_DATATABLE_BASE_OPTIONS,
  DATE_FORMAT
} from "./OrganizationManagementWidgetConstants";

class OrganizationManagementOrganizationDevices extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [];
    this.options = {};

    this.state = {
      devices: this.props.organization.devices || [],
      selectedList: {},
      expanded: [],
      searchText: ""
      //filters: props.filters
    };
  }
  componentDidMount() {
    this.columns = CREATE_MUI_DATATABLE_BASE_COLUMNS(
      this.props.organization.guuid,
      (value, tableMeta, updateValue) => true,
      this.toggleRowSelect
    ).concat([
      {
        name: "guuid",
        options: {
          display: false
        }
      },
      {
        name: "dev_session",
        label: "Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            !!value ? JSON.parse(value)["stack.label"] : "---"
        }
      },
      {
        name: 'data_config["ds.items"]',
        label: "Tags",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            !!value ? value.join() : "---"
        }
      },
      {
        name: "lastbpuptime",
        label: "Last BP Update",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            !!value
              ? moment(parseInt(value, 10)).isValid()
                ? moment(parseInt(value, 10)).format(DATE_FORMAT)
                : "Not valid: " + value + " " + typeof value
              : "---"
        }
      },
      {
        name: "lctime",
        label: "Last Connect",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            !!value
              ? moment(parseInt(value, 10)).isValid()
                ? moment(parseInt(value, 10)).format(DATE_FORMAT)
                : "Not valid: " + value + " " + typeof value
              : "---"
        }
      },
      {
        name: "st_errors",
        label: "Errors",
        options: {
          customBodyRender: (value, tableMeta, updateValue) =>
            !!value ? JSON.parse(value).length : "---"
        }
      }
    ]);

    this.options = CREATE_MUI_DATATABLE_BASE_OPTIONS(
      this,
      (rowData, rowMeta) => {
        const device = this.state.devices[rowMeta.dataIndex];

        return (
          <TableRow style={{ background: "#eeeeee" }}>
            <TableCell
              style={{
                paddingTop: 0,
                paddingBottom: 12,
                paddingLeft: 12,
                paddingRight: 12
              }}
              colSpan={this.columns.length + 1}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">GUUID:&nbsp;</Typography>
                      <Typography>{device.guuid}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption">Errors:&nbsp;</Typography>
                      <Typography>
                        {!!device.st_errors
                          ? JSON.parse(device.st_errors)
                              .filter(
                                err => !!err.errormsg && err.errormsg !== ""
                              )
                              .map(err => err.errormsg)
                          : "---"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TableCell>
          </TableRow>
        );
      }
    );

    this.populateDevices();
  }

  componentDidUpdate(prevProps, prevState) {
    this.populateDevices();
  }

  populateDevices = () => {
    const devices = this.props.organization.devices.map(dev => {
      const existingDevice =
        this.state.devices.find(exDev => exDev.guuid === dev.guuid) || {};
      dev.selected =
        typeof existingDevice.selected === "undefined"
          ? false
          : existingDevice.selected;
      return dev;
    });

    if (
      this.state.devices.length !== devices.length ||
      this.state.devices.some(
        (device, devIndex) =>
          device.guuid !== devices[devIndex].guuid ||
          device.st_time !== devices[devIndex].st_time ||
          device.lastbpuptime !== devices[devIndex].lastbpuptime
      )
    ) {
      this.setState({ devices });
    }
  };

  toggleRowSelect = idx => event => {
    const guuid = this.state.devices[idx]["guuid"];
    const checked = !this.state.selectedList[guuid];

    // shallow copy to update users
    const devices = this.state.devices.slice();
    const match = devices.findIndex(device => guuid === device.guuid);

    // should always have match, but check to be sure
    if (match !== -1) {
      // copy user object as well, don't want to update
      // existing state in place
      devices[match] = { ...devices[match], selected: checked };
    }
    const selectedList = { ...this.state.selectedList };
    selectedList[guuid] = checked;

    this.setState({
      selectedList: selectedList,
      devices
    });
  };

  render() {
    return (
      <TreeItem
        nodeId={this.props.organization.guuid + "-devices"}
        label="Devices"
      >
        <div
          style={{
            //border: "2px solid rgba(224, 224, 224, 1)",
            marginRight: 24
          }}
        >
          <MuiThemeProvider theme={MUI_TABLE_THEME}>
            <MUIDataTable
              data={this.state.devices}
              columns={this.columns}
              options={this.options}
            />
          </MuiThemeProvider>
        </div>
      </TreeItem>
    );
  }
}

export default OrganizationManagementOrganizationDevices;
