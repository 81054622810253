import React, { useEffect, useState, useContext } from "react";
import { AlertsAddressBookContext } from "services/Utils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ISXUtils from "services/Utils";
import { makeStyles } from "@material-ui/core/styles";

import ChipInput from "components/ChipInput";

const MAX_INVITES = 25;

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    spacing: 2,
  },
  innerContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
  },
  email: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  emailChipInput: {
    width: "100%",
  },
  timePicker: {
    width: "100%",
    maxWidth: "100%",
    margin: 6,
  },
  inputs: {
    fontSize: 12,
  },
  inputsContainer: {
    height: "100%",
  },
  inputLabels: {
    fontSize: 12,
  },
  tagLabel: {
    fontWeight: 400,
    paddingRight: 4,
  },
}));

const AlertTemplateDialog = (props) => {
  // as email addresses
  const { sendTo, setSendTo } = useContext(AlertsAddressBookContext);
  const [requestPending, setRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState(
    typeof props.templates === "object" && props.templates !== null
      ? ISXUtils.filterAlertTemplates(props.tag, props.templates)
      : []
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    filteredTemplates.length === 1 ? filteredTemplates[0] : {}
  );

  const parseSendTos = (sendTos) => {
    return sendTos.map((s) => {
      if (typeof s === "string") {
        return s;
      } else if (typeof s === "object" && "label" in s) {
        return s.label;
      } else {
        return s.toString();
      }
    });
  };

  const getCurrentRecipients = () => {
    //console.log("stack dc",selectedTemplate,props.stack?.data_config?.[props.tag]?.alarms || []);
    return [
      ...new Set(
        (props.stack?.data_config?.[props.tag]?.alarms || [])
          .map((a) => {
            if ("tmpid" in a && a.tmpid === selectedTemplate?.id) {
              return parseSendTos(a.sendto || []);
            }
            return [];
          })
          .flat()
      ),
    ];
  };

  const classes = useStyles();
  useEffect(() => {
    if (props.open) {
      setErrorMessage("");
    }
  }, [props.open]);

  async function setAlertTemplate() {
    // clear any existing error messages
    setErrorMessage("");
    setRequestPending(true);
    if (!("id" in selectedTemplate)) {
      setErrorMessage("No Alert Template available for this tag");
      setRequestPending(false);
      return;
    }
    const results = await props.send(selectedTemplate.id, sendTo);
    if (results.error) {
      setErrorMessage(results.error);
    } else {
      props.handleClose();
    }
    setRequestPending(false);
  }

  const handleAddInviteeChip = (chip) => {
    setSendTo([...sendTo, chip]);
  };

  const handleTemplateChange = (event) => {
    if (event.target.value) {
      console.log("template changed", event.target.value);
    }
  };

  const handleDeleteInviteeChip = (chip, index) => {
    const updatedsendto = [...sendTo];
    updatedsendto.splice(index, 1);
    setSendTo(updatedsendto);
  };

  return (
    <Dialog open={props.open} fullWidth aria-labelledby="template-dialog-title">
      <DialogTitle id="template-dialog-title">Auto Set Alerts</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <Typography className={classes.tagLabel}>Auto Set :</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.tagLabel}>
                {selectedTemplate.name}
              </Typography>
            </Grid>
          </div>
        </Grid>
        <Grid container direction="row">
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Grid item xs={4}>
              <Typography className={classes.tagLabel}>
                Current Recipients :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ChipInput
                fullWidth
                placeholder=""
                value={getCurrentRecipients()}
                disabled
                readOnly
                allowDuplicates={false}
              />
            </Grid>
          </div>
        </Grid>
        <Grid container direction="row"></Grid>
        <Grid container direction="row">
          <ChipInput
            fullWidth
            autoFocus
            type="email"
            placeholder="Enter Alert Recipients Emails to Override Current Recipients"
            value={sendTo}
            onAdd={(chip) => handleAddInviteeChip(chip)}
            onDelete={(chip, index) => handleDeleteInviteeChip(chip, index)}
            allowDuplicates={false}
            disabled={requestPending}
            limit={MAX_INVITES}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          {sendTo.length === MAX_INVITES && (
            <DialogContentText style={{ color: "red" }}>
              {`You are at the maximum of ${MAX_INVITES} alert email recipients`}
            </DialogContentText>
          )}
          {errorMessage && (
            <DialogContentText style={{ color: "red" }}>
              {errorMessage}
            </DialogContentText>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button
          disabled={sendTo.length === 0 || requestPending}
          onClick={setAlertTemplate}
          color="primary"
        >
          Add Alerts
        </Button>
        {requestPending && <CircularProgress size={20} color="primary" />}
      </DialogActions>
    </Dialog>
  );
};

export default AlertTemplateDialog;
