import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";

import OrganizationManagementOrganization from "./OrganizationManagementOrganization";

class OrganizationManagementOrganizationOrganizations extends React.Component {
  render() {
    return (
      <TreeItem
        nodeId={this.props.organization.guuid + "-organizations"}
        label="Groups"
      >
        {this.props.organization.orgs.map((org) => {
          return (
            <OrganizationManagementOrganization
              {...this.props}
              key={"spo-" + org.guuid}
              organization={org}
            />
          );
        })}
      </TreeItem>
    );
  }
}

export default OrganizationManagementOrganizationOrganizations;
