import React, { useContext } from "react";
import { SizeMe } from "react-sizeme";
import Widget from "../../widget/Widget";

import ISXUtils, { ISXContext } from "services/Utils";

import cyan from "@material-ui/core/colors/cyan";
import grey from "@material-ui/core/colors/grey";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    bottom: 10,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
})(Backdrop);

const enableViewPrevious = false; //flag that allows the overlay backdrop to be cleared by clicking on it

const LevelWidget = (props) => {
  const value = Number(props.value);

  const { labels, range } = props.config || {};
  //const range = (widget.options || {}).range || {};
  const min = range.min ? parseFloat(range.min) : 0;
  const max = range.max ? parseFloat(range.max) : 1000;

  const isxContext = useContext(ISXContext);
  const [showBackdrop, setShowBackdrop] = React.useState(true);
  if ("status" in props && !showBackdrop && props.status) {
    setShowBackdrop(true);
  }
  const viewPrevious = () => {
    if (enableViewPrevious) {
      setShowBackdrop(false);
    }
  };
  return (
    <Widget {...props} dataType="tags" maxTags={1} widgetTitle="Level">
      <SizeMe monitorHeight monitorWidth>
        {({ size }) => {
          let vbwidth, vbheight;
          const ratio = size.height / size.width || 1;
          vbwidth = 400;
          if (ratio < 0.5) {
            vbwidth = 200 / ratio;
            vbheight = 200;
          } else {
            vbwidth = 400;
            vbheight = 400 * ratio;
          }
          size = { width: vbwidth, height: vbheight };
          const tankHeight = size.height - 48 || 28;
          const lipHeight = 12;
          const tankWidth = size.width - 120 || 8;
          const tankTop = 20;
          const tankLeft = 60;
          const tankBottom = tankTop + tankHeight;
          const tankRight = tankLeft + tankWidth;
          const fillTop = tankTop + lipHeight;
          const fillBottom = tankBottom - 4;
          const fillLeft = tankLeft + 4;
          const fillRight = tankRight - 4;
          const fillHeight = fillBottom - fillTop;
          const fillWidth = fillRight - fillLeft;

          const range = max - min;
          let level;
          if (value > max) {
            level = fillHeight + lipHeight + 4;
          } else if (value < min) {
            level = -25;
          } else {
            const plottedValue = Math.max(Math.min(value, max), min) || 0;
            level = ((plottedValue - min) / range) * fillHeight;
          }

          const shape = (
            <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
              <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${vbwidth} ${vbheight}`}
              >
                <g>
                  <path
                    d={`M${tankLeft},${tankTop} v${tankHeight - 5} q0,5 5,5 h${
                      tankWidth - 10
                    } q5,0 5,-5 v-${tankHeight - 5}`}
                    fill="white"
                    stroke="black"
                    strokeWidth={3}
                  ></path>
                  {level >= 2.5 ? (
                    <path
                      d={`M${fillLeft},${fillBottom - level} v${
                        level - 2.5
                      } q0,2.5 2.5,2.5 h${fillWidth - 5} q2.5,0 2.5,-2.5 v-${
                        level - 2.5
                      } z`}
                      width={fillWidth}
                      height={level}
                      fill={props.color || cyan["200"]}
                    ></path>
                  ) : (
                    <rect
                      x={fillLeft}
                      y={fillBottom - level}
                      width={fillWidth}
                      height={level}
                      fill={props.color || cyan["200"]}
                    ></rect>
                  )}
                  <g className="labeledTicks">
                    {labels.map((label, i) => {
                      const ypos =
                        fillBottom - ((label.label - min) / range) * fillHeight;
                      return (
                        <g key={i}>
                          {label.bar && (
                            <line
                              x1={tankLeft}
                              y1={ypos}
                              x2={tankRight}
                              y2={ypos}
                              stroke={grey["500"]}
                              strokeWidth={1.5}
                              strokeDasharray="4 8"
                            ></line>
                          )}
                          <text
                            x={tankLeft - 10}
                            y={ypos}
                            fill={grey["500"]}
                            textAnchor="end"
                            dy=".4em"
                          >
                            {ISXUtils.formattedValue(label.label, isxContext)}
                          </text>
                        </g>
                      );
                    })}
                  </g>
                  )
                  <g
                    transform={`translate(0, ${
                      fillBottom - Math.min(level, fillHeight)
                    })`}
                    stroke="black"
                  >
                    <text
                      x={(tankLeft + tankRight) / 2}
                      y={-4}
                      fontSize={20}
                      textAnchor="middle"
                    >
                      {ISXUtils.formattedValue(value, isxContext)}
                    </text>
                  </g>
                </g>
              </svg>
            </div>
          );
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LimitedBackdrop
                open={props.status ? false : showBackdrop}
                onClick={(e) => viewPrevious()}
              >
                <Typography variant="button">Not Available</Typography>
              </LimitedBackdrop>
              {shape}
            </div>
          );
        }}
      </SizeMe>
    </Widget>
  );
};

export default LevelWidget;
