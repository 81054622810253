const NAMESPACE = process.env.REACT_APP_API_NAMESPACE;
const LOGIN_ROUTE = NAMESPACE + "/login";
const LOGINSSO_ROUTE = NAMESPACE + "/loginsso";
const REFRESH_ROUTE = NAMESPACE + "/refresh";
const LOGOUT_ROUTE = NAMESPACE + "/logout";
const VERIFY_ROUTE = NAMESPACE + "/verify";
const VERIFY_EMAIL_ROUTE = NAMESPACE + "/verifyemail";
const RESETPASS_ROUTE = NAMESPACE + "/resetpass";
const CHANGEPASS_ROUTE = NAMESPACE + "/changepass";
const CHANGE_EMAIL_ROUTE = NAMESPACE + "/changeemail";
const REGISTER_ROUTE = NAMESPACE + "/register";
const PROFILE_ROUTE = NAMESPACE + "/users/me/profile";
// How much leeway before attempting a refresh before the access token
// expiration should we have in milliseconds, i.e. attempt refresh at:
// `accessTokenExpiration - REFRESH_LEEWAY`
const REFRESH_LEEWAY = 60000; // 1 minute
// Number of attempts to refresh the token before giving up
const REFRESH_RETRIES = 3;

/** Authentication service to manage the access token and provide API access */
class Auth {
  /**
   * Create the service.
   */
  constructor() {
    /** The `setTimeout` ID for updating the access token */
    this.tick = null;
    /** The current access token */
    this.accessToken = null;
    /** The current verify token */
    this.verifyToken = null;
    /** Time at which verify token expires */
    this.verifyTokenExpiry = 0;
  }

  /**
   * Attempt to get a new access token with the refresh token, and also update
   * the refresh token.
   *
   * This method should be called before attempting to utilize the service.
   *
   * @param {boolean} [withRetry=false] - `true` if the refresh should be
   * attempted up to the number of retries specified by `REFRESH_RETRIES`. If
   * `false`, only attempt once.
   * @return {Promise<boolean>} `true` if successfully refreshed the tokens,
   * `false` if otherwise.
   */
  async refresh(withRetry = false) {
    let retries = withRetry ? REFRESH_RETRIES : 0;
    do {
      console.info("Attempting to refresh access token.");
      const response = await fetch(REFRESH_ROUTE, {
        method: "POST",
        credentials: "include",
        redirect: "error",
      });

      try {
        const auth = await this._processResponse(response);
        if (auth || !withRetry) {
          if (!auth) console.info("Failed to refresh access token 😢.");

          return auth;
        }
      } catch (e) {
        // failed, just swallow it for now
      }
      retries -= 1;
    } while (retries > 0);

    console.info("Failed to refresh access token 😢.");
    return false;
  }

  /**
   * Authenticate a user via username and password.
   *
   * This should only be required if `refresh` returns `false` or `fetch` throws
   * a login required `Error`.
   *
   * @param {string} username - The user's username.
   * @param {string} password - The user's password.
   * @return {Promise<boolean>} `true` if successfully logged in with `username`
   * and `password`, `false` if the `username` or `password` was incorrect.
   */
  async login(username, password, mfactor, msecure) {
    const response = await fetch(LOGIN_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        msecure,
        mfactor
      }),
      redirect: "error",
    });

    return await this._processResponse(response);
  }

  /**
   * Lookup valid Auth methods for user
   *
   * @param {string} username - The user's username.
   * @return [{auth_method}] if user present (defaults to interstacks passwd if no user)
   */
  async loginType(username) {
    const response = await fetch(LOGIN_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username }),
      redirect: "error",
    });
    console.log(response);
    if (response.ok) {
      return await response.json();
    }
    const error = await response.json();
    if(error.Message && error.Message.includes("not provided")) {
      //detect old server and default to non-SSO
      return { auth_methods: {name: 'interstacks', method: 'password'} }
    }
    throw error;
  }

    /**
     * Authenticate a user via SSO token
     *
     * @param {dict} token data
     * @return {boolean} `true` if successfully logged in, `false` if not
     */
    async loginSSO(tokenData) {
      const response = await fetch(LOGINSSO_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tokenData),
      redirect: "error",
    });
    return await this._processResponse(response);
  }
    
  async resetpass(username) {
    const response = await fetch(RESETPASS_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
      }),
      redirect: "error",
    });

    if (response.ok) {
      const msg = await response.json();
      return msg;
    } else {
      const error = await response.json();
      throw error;
    }
  }

  async changepass(username, password, mfactor, newpassword) {
    const response = await fetch(CHANGEPASS_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
          "Content-Type": "application/json",
          Authorization: this.accessToken     
      },
      body: JSON.stringify({
        username,
        password,
        mfactor,
        newpassword,
      }),
      redirect: "error",
    });

    if (response.ok) {
      const msg = await response.json();
      return msg;
    } else {
      const error = await response.json();
      throw error;
    }
  }

  async changeemail(newemail) {
    const response = await fetch(CHANGE_EMAIL_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.accessToken,          
      },
      body: JSON.stringify({
        newemail,
      }),
      redirect: "error",
    });

    return await this._processResponse(response);
  }

  //passing phone verifies phone instead - email still required
  //TODO: Probably should verify that token matches user here....
  async verifyemail(mfactor, email, phone) {
    const body = {
        mfactor,
        email,
    }
    if(phone) body['phone'] = phone
    const response = await fetch(VERIFY_EMAIL_ROUTE, {
      method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Authorization: this.accessToken,
        },
        body: JSON.stringify(body),
        redirect: "error",
    });
    return await this._processResponse(response);
  }

  async register(username, password, email, name, mfa) {
    const response = await fetch(REGISTER_ROUTE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        email,
        name,
        mfa,
      }),
      redirect: "error",
    });

    return await this._processResponse(response);
  }

  /**
   * Logout a user; really a request to clear token cookie.
   *
   * @return {Promise<boolean>} `true` if successfully logged out, `false` if
   * otherwise.
   */
  async logout() {
    const response = await fetch(LOGOUT_ROUTE, {
      method: "POST",
      redirect: "error",
    });

    return this._processLogoutResponse(response);
  }

  async userProfile() {
    if (this.accessToken === null) {
      throw new Error(
        "You must first call `init` before using the this service."
      );
    }
    const response = await fetch(PROFILE_ROUTE, {
      method: "GET",
      headers: {
        Authorization: this.accessToken,
        "Content-Type": "application/json",
      },
        mode: "cors"
    });
    return response.ok ? response.json() : false
  }
    
  async patchUserProfile(attrs) {
    if (this.accessToken === null) {
      throw new Error(
        "You must first call `init` before using the this service."
      );
    }
      console.log("SEND", attrs)
    const response = await fetch(PROFILE_ROUTE, {
      method: "PATCH",
      headers: {
        Authorization: this.accessToken,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(attrs)
    });
    return response.ok;
  }
    
  async verify(password) {
    if (this.accessToken === null) {
      throw new Error(
        "You must first call `init` before using the this service."
      );
    }
    const response = await fetch(VERIFY_ROUTE, {
      method: "POST",
      headers: {
        Authorization: this.accessToken,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        password,
      }),
    });

    return await this._processVerifyResponse(response);
  }

  /**
   * Process the API responses from `login` and `refresh`. This function will
   * set the `accessToken` if response was successful, and set the next
   * expiration based refresh operation.
   *
   * @private
   * @param {Response} response - A `fetch` response object from accessing
   * either the login or refresh route.
   * @return {Promise<boolean>} `true` if response was successful and an access
   * token and token expiration was provided. `false` if otherwise.
   */
  async _processResponse(response) {
    if (response.ok) {
      const { access_token: accessToken, expires } = await response.json();

      if (accessToken && expires) {
        this.accessToken = accessToken;

        clearTimeout(this.tick);
        this.tick = setTimeout(
          () => this.refresh(true),
          expires * 1000 - REFRESH_LEEWAY
        );
        return true;
      } else {
        console.info("No access token found in response 😭.");
        return false;
      }
    }

    console.info("Error processing response 😭.");
    const error = await response.json();
    throw error;
  }

  async _processVerifyResponse(response) {
    if (response.ok) {
      const { verify_token: verifyToken, expires } = await response.json();
      if (verifyToken && expires) {
        this.verifyToken = verifyToken;
        this.verifyTokenExpiry =
          new Date().getTime() + expires * 1000 - REFRESH_LEEWAY;
        return true;
      }
    }
    this.verifyToken = null;
    this.verifyTokenExpiry = 0;
    console.info("No verify token found in response 😭.");
    return false;
  }

  /**
   * Process the API response from `logout`.
   * This function will clear the `accessToken` and remove any existing
   * refresh timeout
   *
   * @private
   * @param {Response} response - A `fetch` response object from accessing
   * either the logout route.
   * @return {boolean} `true` if response was successful, `false` if otherwise.
   */
  _processLogoutResponse(response) {
    if (response.ok) {
      this.accessToken = null;
      this.verifyToken = null;
      this.verifyTokenExpiry = 0;
      clearTimeout(this.tick);
      return true;
    }

    console.info("Logout not successful 😭.");
    return false;
  }

  /**
   * Provides the current state of authentication (whether a user is "logged
   * in" or not).
   *
   * This is not a fail proof check, this really only gives an indicator of if
   * `Auth` service was successfully setup or not. The only way to get a true
   * indicator of if the user is "logged in" is to attempt a `fetch` against
   * the API.
   *
   * @return {boolean} `true` if logged in, `false` otherwise.
   */
  isAuthenticated() {
    return !!this.accessToken;
  }

  isVerified() {
    return !!this.verifyToken && new Date().getTime() < this.verifyTokenExpiry;
  }

  /**
   * A drop in replacement for `window.fetch` which manages the access tokens
   *
   * Behind the scenes this method will refresh the acccess token with the
   * refresh token before the access token expires. In addition to refreshing
   * based on the token expiration, it will also attempt to refresh the token
   * if a call to this function results in an forbidden response from the
   * API--the assumption being that somehow we missed the refresh window on
   * the timebased refresh).
   *
   * @param {(USVString|Request)} resource - See the fetch documentation here
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch}
   * @param {Object} [init={}] - See the fetch documentation here
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch}
   * @return {Promise<Response>} - See the fetch documentation here
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch}
   * @throws {Error} Will throw a login required error if the token cannot be
   * refreshed or if the refreshed token does not work either.
   * @throws {Error} Will throw in the case of a invalid request, i.e. if the
   * server responds with a non-200 status code (which is different from the
   * default `fetch` behavior)
   */
  async fetch(resource, init = {}, ...args) {
    if (this.accessToken === null) {
      throw new Error(
        "You must first call `init` before using the this service."
      );
    }

    // Set headers
    if ("headers" in init && typeof init === "object") {
      init["headers"]["Authorization"] = this.accessToken;
    } else {
      init["headers"] = { Authorization: this.accessToken };
    }

    // Make sure cors is used
    init["mode"] = "cors";

    // Make the request
    let response = await fetch(resource, init, ...args);

    // If we received a forbidden error, attempt to refresh and then retry.
    if (response.status === 403) {
      if (await this.refresh(true)) {
        // Update authorization header
        init["headers"]["Authorization"] = this.accessToken;

        // Repeat the request
        response = await fetch(resource, init, ...args);

        if (response.status === 403) {
          throw new Error("login required");
        }
      } else {
        throw new Error("login required");
      }
    }

    if (!response.ok && response.status !== 304) {
      let responseBody;
      try {
        responseBody = await response.json();
        console.log("responsebody is", responseBody);
      } catch (error) {
        throw new Error(response.statusText || response.status);
      }

      throw new Error(
        responseBody.Message ||
          responseBody.Code ||
          response.statusText ||
          response.status
      );
    }

    return response;
  }
}

// Create singleton from Auth service
const auth = new Auth();
export default auth;
