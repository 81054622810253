import React from "react";
import Typography from "@material-ui/core/Typography";
import Widget from "../../widget/Widget";

const UnsupportedWidget = props => (
  <Widget
    widget={props.widget}
    removeWidget={props.removeWidget}
    configurable={false}
    widgetTitle="Unsupported"
  >
    <Typography>
      {`The widget type "${props.widget.type}" is unsupported in this release.`}
    </Typography>
  </Widget>
);

export default UnsupportedWidget;
