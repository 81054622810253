import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import ChipInput from "components/ChipInput";

const MAX_INVITES = 25;

const ShareTemplateDialog = (props) => {
  // as email addresses
  const [recipients, setRecipients] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.open) {
      setRecipients([]);
      setErrorMessage("");
    }
  }, [props.open]);

  async function shareAsTemplate() {
    // clear any existing error messages
    setErrorMessage("");
    setRequestPending(true);
    const results = await props.send(recipients);
    if (results.error) {
      setErrorMessage(results.error);
    } else if (results.failed.length > 0) {
      setErrorMessage(
        `Could not send to one or more invalid recipients: ${results.failed.join(
          ", "
        )}`
      );
      setRecipients([]);
    } else {
      props.handleClose();
    }
    setRequestPending(false);
  }

  const handleAddInviteeChip = (chip) => {
    setRecipients([...recipients, chip]);
  };

  const handleDeleteInviteeChip = (chip, index) => {
    const updatedRecipients = [...recipients];
    updatedRecipients.splice(index, 1);
    setRecipients(updatedRecipients);
  };

  return (
    <Dialog open={props.open} fullWidth aria-labelledby="template-dialog-title">
      <DialogTitle id="template-dialog-title">Share as Template</DialogTitle>
      <DialogContent>
        <ChipInput
          fullWidth
          placeholder="Enter e-mails of users to invite to share"
          value={recipients}
          onAdd={(chip) => handleAddInviteeChip(chip)}
          onDelete={(chip, index) => handleDeleteInviteeChip(chip, index)}
          allowDuplicates={false}
          autoFocus
          type="email"
          limit={MAX_INVITES}
          disabled={requestPending}
        />
        {recipients.length === MAX_INVITES && (
          <DialogContentText style={{ color: "red" }}>
            {`You are at the maximum of ${MAX_INVITES} invites`}
          </DialogContentText>
        )}
        {errorMessage && (
          <DialogContentText style={{ color: "red" }}>
            {errorMessage}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button
          disabled={recipients.length === 0 || requestPending}
          onClick={shareAsTemplate}
          color="primary"
        >
          Send Invites
        </Button>
        {requestPending && <CircularProgress size={20} color="primary" />}
      </DialogActions>
    </Dialog>
  );
};

export default ShareTemplateDialog;
