import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// import { FormControl, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
});

class OeeOption extends React.Component {
  render = () => {
    //const options = this.props.options;
    const oee = this.props.options.oee;
    return (
      <FormControl
        fullWidth={true}
        style={{
          flexDirection: "column",
          margin: 24,
          width: "calc(100% - 48px)",
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: 14, fontStyle: "italic" }}
            component="legend"
          >
            OEE Options
          </FormLabel>
          <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
            <DatePicker
              error={false}
              id="starttime"
              label="Start"
              value={oee.start}
              onChange={(dt) => {
                let newOptions = {
                  ...oee,
                  start: dt.startOf("day").valueOf(),
                };
                this.props.setOptions({ oee: newOptions });
              }}
              style={
                { width: "100%", maxWidth: "100%", margin: 6 } // onBlur={this.props.handleBlur}
              }
            />
          </FormControl>
          <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
            <TextField
              id="short-breaks-duration"
              select
              label="Short Breaks Duration(Miutes)"
              value={oee.shortBreakDuration}
              onChange={(event) => {
                const value = event.target.value;
                let newOptions = {
                  ...oee,
                  shortBreakDuration: value,
                };
                /*if (value === "any" && "conditionValue" in newOptions) {
                  delete newOptions["conditionValue"];
                }*/
                this.props.setOptions({ oee: newOptions });
              }}
              margin="normal"
              variant="filled"
              style={{ margin: 6, width: "50%" }}
              SelectProps={{
                className: this.props.classes.selectInput,
              }}
            >
              <MenuItem key="any" value={5}>
                5
              </MenuItem>
              <MenuItem key="equals" value={10}>
                10
              </MenuItem>
              <MenuItem key="notequals" value={15}>
                15
              </MenuItem>
              <MenuItem key="lessthan" value={20}>
                20
              </MenuItem>
              <MenuItem key="greaterthan" value={30}>
                30
              </MenuItem>
              <MenuItem key="greaterthan" value={60}>
                60
              </MenuItem>
            </TextField>
            <TextField
              id="short-breaks-number"
              label="Number of breaks "
              value={oee.shortBreakNumber}
              onChange={(event) => {
                const value = Math.max(0, parseInt(event.target.value)); //value greater than  or equal to 0
                let newOptions = {
                  ...oee,
                  shortBreakNumber: value,
                };
                this.props.setOptions({ oee: newOptions });
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              style={{ margin: 6, marginTop: 14, width: "50%" }}
            />
          </FormControl>
          <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
            <TextField
              id="meal-breaks-duration"
              select
              label="Meal Break Duration(Minutes)"
              value={oee.mealBreakDuration}
              onChange={(event) => {
                const value = event.target.value;
                let newOptions = {
                  ...oee,
                  mealBreakDuration: value,
                };
                this.props.setOptions({ oee: newOptions });
              }}
              margin="normal"
              variant="filled"
              style={{ margin: 6, width: "50%" }}
              SelectProps={{
                className: this.props.classes.selectInput,
              }}
            >
              <MenuItem key="any" value={5}>
                5
              </MenuItem>
              <MenuItem key="equals" value={10}>
                10
              </MenuItem>
              <MenuItem key="notequals" value={15}>
                15
              </MenuItem>
              <MenuItem key="lessthan" value={20}>
                20
              </MenuItem>
              <MenuItem key="greaterthan" value={30}>
                30
              </MenuItem>
              <MenuItem key="greaterthan" value={60}>
                60
              </MenuItem>
            </TextField>
            <TextField
              id="meal-break-number"
              label="Number of Breaks"
              value={oee.mealBreakNumber}
              onChange={(event) => {
                const value = Math.max(0, parseInt(event.target.value)); //value greater than  or equal to 0
                let newOptions = {
                  ...oee,
                  mealBreakNumber: value,
                };
                this.props.setOptions({ oee: newOptions });
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              style={{ margin: 6, marginTop: 14, width: "50%" }}
            />
          </FormControl>
          <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
            <TextField
              id="rejected-pieces"
              label="Rejected Pieces"
              value={oee.rejectedPieces}
              onChange={(event) => {
                const value = Math.max(0, parseInt(event.target.value)); //value greater than  or equal to 0
                let newOptions = {
                  ...oee,
                  rejectedPieces: value,
                };
                this.props.setOptions({ oee: newOptions });
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              style={{ margin: 6, marginTop: 14, width: "50%" }}
            />
            <TextField
              id="tolerance"
              label="Job Time Variance Tolerance(%)"
              value={oee.tolerance}
              onChange={(event) => {
                const value = Math.min(
                  Math.max(parseInt(event.target.value), 0),
                  100
                ); //range between 0 and 100%
                let newOptions = {
                  ...oee,
                  tolerance: value,
                };
                this.props.setOptions({ oee: newOptions });
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              style={{ margin: 6, marginTop: 14, width: "50%" }}
            />
          </FormControl>
        </FormControl>
      </FormControl>
    );
  };
}

export default withStyles(styles)(OeeOption);
