import React from "react";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";
import { CountsWidget as CountsWidgetComponent } from "../../../components/widgets";

const CountsWidget = (props) => {
  return (
    <BaseTimeSeriesWidget {...props}>
      {(baseProps) => {
        return (
          <CountsWidgetComponent {...baseProps} data={baseProps.streams} />
        );
      }}
    </BaseTimeSeriesWidget>
  );
};

export default CountsWidget;
