import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/SaveAlt";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import AlarmItem from "../AlarmItem";
import TagConfig from "components/TagConfig";
import ISXUtils, {
  ErrorProvider,
  ErrorContext,
  AlertsAddressBookContext,
} from "services/Utils";
import { updateItem as updateItemOp } from "store/operations";
import { STATUS_COLORS } from "../../../color/colors";

import { UUID } from "@senn/jsutil";
import moment from "moment";
import { default as _head } from "lodash/head";
import { default as _cloneDeep } from "lodash/cloneDeep";
import { default as _set } from "lodash/set";
import { default as _isEmpty } from "lodash/isEmpty";
const uuidv4 = require("uuid/v4");

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
  },
  dialogPaper: {
    width: "100%",
    height: "100%",
    maxWidth: 960,
  },
  dialogTitleHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTabContents: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
  detailStatusCell: {
    height: "1rem",
    width: "4rem",
  },
  alertsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  alertsActionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  alertsAction: {
    marginLeft: 3,
    marginRight: 3,
  },
  alertItemsContainer: {
    height: "100%",
    paddingLeft: 4,
    paddingRight: 4,
    overflow: "auto",
    borderTop: "1px dotted lightgray",
  },
  statusCell: {
    height: "1em",
    width: "3em",
  },
  propertyNameCell: {
    width: "30%",
    fontWeight: "bold",
  },
  propertyValueCell: {
    width: "70%",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const getStatusFromLastUpdated = (now, lastUpdated, updateInterval) => {
  const diff = now.diff(moment(lastUpdated), "seconds");
  updateInterval = parseInt(updateInterval || "10");
  const intervals = diff / updateInterval;
  if (intervals < 4) {
    //changes from < 2 to < 4. < 2 was too aggressive in changing state to warning when even when there's a slight delay in updates.
    return "good";
  } else if (intervals < 10) {
    return "warning";
  } else {
    return "bad";
  }
};

const StackDetails = ({ stack, readOnly, setCurrentTabDirty, updateItem }) => {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const devSession = JSON.parse(stack?.dev_session ?? {});
  const [stackName, setStackName] = useState(
    stack?.name || devSession["stack.label"] || ""
  );
  const [stackLocation, setStackLocation] = useState(
    stack?.location || devSession["stack.location"] || ""
  );
  const enableEdit = () => {
    setEditing(true);
  };

  async function saveChangesToDB() {
    setDirty(false);
    setCurrentTabDirty(false);
    await updateItem(stack.guuid, { name: stackName, location: stackLocation });
    setToastOpen(true);
  }
  const handleNameChange = (event) => {
    setStackName(event.target.value);
    setDirty(true);
    setCurrentTabDirty(true);
  };

  const handleLocationChange = (event) => {
    setStackLocation(event.target.value);
    setDirty(true);
    setCurrentTabDirty(true);
  };

  const reset = () => {
    setDirty(false);
    setCurrentTabDirty(false);
    setEditing(false);
    setStackName(stack?.name || devSession["stack.label"] || "");
    setStackLocation(stack?.location || devSession["stack.location"] || "");
  };

  const closeToast = () => {
    setToastOpen(false);
    setEditing(false);
  };

  if (stack) {
    const now = moment();
    const bps = devSession["stack.blueprints"];
    const bpsAsStrings = (bps || []).map((bpuu) => new UUID(bpuu).toString());
    const lastUpdated = parseInt(stack.st_time);
    const status = getStatusFromLastUpdated(now, lastUpdated, stack.upd_int);
    return (
      <>
        {!readOnly && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.alertsHeader}>
                <div className={classes.alertsActionsContainer}></div>
                {dirty ? (
                  <div>
                    <Button
                      className={classes.alertsAction}
                      disabled={toastOpen}
                      color="primary"
                      onClick={saveChangesToDB}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                    <Tooltip
                      title="Current changes will be discarded"
                      placement="top"
                    >
                      <span>
                        <Button
                          className={classes.alertsAction}
                          disabled={toastOpen}
                          style={{ color: "red" }}
                          onClick={reset}
                        >
                          Cancel
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  <Button
                    disabled={editing}
                    className={classes.alertsAction}
                    color="primary"
                    onClick={enableEdit}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        <div className={classes.detailsContainer}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>Name</TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {editing ? (
                    <Tooltip title="Type Name" placement="top">
                      <FormControl fullWidth={true}>
                        <TextField
                          //label="Description"
                          value={stackName}
                          onChange={handleNameChange}
                          InputLabelProps={{ className: classes.inputLabels }}
                          InputProps={{ className: classes.inputs }}
                          disabled={readOnly}
                        />
                      </FormControl>
                    </Tooltip>
                  ) : (
                    stackName
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Location
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {editing ? (
                    <Tooltip title="Type Location" placement="top">
                      <FormControl fullWidth={true}>
                        <TextField
                          //label="Description"
                          value={stackLocation}
                          onChange={handleLocationChange}
                          InputLabelProps={{ className: classes.inputLabels }}
                          InputProps={{ className: classes.inputs }}
                          disabled={readOnly}
                        />
                      </FormControl>
                    </Tooltip>
                  ) : (
                    stackLocation
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  GUUID
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {stack.guuid}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Last Data Received
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {lastUpdated
                    ? moment(lastUpdated).format(DATE_FORMAT)
                    : "---"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Status
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  <div
                    className={classes.detailStatusCell}
                    style={{
                      backgroundColor: STATUS_COLORS[status],
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Owner
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {stack.g_owner}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Blueprints
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  <div className={classes.chipsContainer}>
                    {bpsAsStrings.map((bp) => (
                      <Chip key={bp} size="small" label={bp} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>
                  Last Blueprint Update
                </TableCell>
                <TableCell className={classes.propertyValueCell}>
                  {stack.lastbpuptime
                    ? moment(parseInt(stack.lastbpuptime)).format(DATE_FORMAT)
                    : "---"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.propertyNameCell}>Tags</TableCell>
                <TableCell className={classes.propertyValueCell}>
                  <div className={classes.chipsContainer}>
                    {((stack.data_config || {}).dsitems || []).map((tag) => (
                      <Chip key={tag} size="small" label={tag} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={toastOpen}
            autoHideDuration={3000}
            onClose={closeToast}
            message="Updates saved"
          />
        </div>
      </>
    );
  } else {
    return null;
  }
};

const StackAlerts = ({ stack, updateItem, setCurrentTabDirty, readOnly }) => {
  const [alerts, setAlerts] = useState([]);
  const [removed, setRemoved] = useState(new Set());
  const [dirty, setDirty] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const lastStackId = useRef(null);

  // const bottomRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (stack && stack.guuid !== lastStackId.current) {
      lastStackId.current = stack.guuid;
      const alerts = ((stack.data_config || {}).dsitems || [])
        .map((tagName) => (stack.data_config[tagName] || {}).alarms || [])
        .filter(Boolean)
        .flat();
      setAlerts(alerts);
    }
  }, [stack]);

  const saveThisAlert = (guuid, alert) => {
    if (guuid === stack.guuid) {
      // the GUUIDs should always match in this use case
      const idx = alerts.findIndex((a) => a.guuid === alert.guuid);
      if (idx !== -1) {
        const updatedAlerts = [...alerts];
        updatedAlerts[idx] = alert;
        setAlerts(updatedAlerts);
        setDirty(true);
        setCurrentTabDirty(true);
      }
    }
  };

  const reset = () => {
    const alerts = ((stack.data_config || {}).dsitems || [])
      .map((tagName) => (stack.data_config[tagName] || {}).alarms || [])
      .filter(Boolean)
      .flat();
    setAlerts(alerts);
    setDirty(false);
    setCurrentTabDirty(false);
  };

  const createAlert = () => {
    // default alert properties
    const newAlert = {
      guuid: uuidv4(),
      stackId: stack.guuid,
      almszd: 60,
      cdn: [1, 0],
      enabled: false,
      sendto: [],
      schedule: {
        period: "none",
        start: 0,
        end: 0,
      },
      tagName: "",
      derivedtag: "sample",
    };
    setAlerts([...alerts, newAlert]);
    setDirty(true);
    setCurrentTabDirty(true);
  };

  const deleteThisAlert = (guuid, alarmGuuidToDelete) => {
    if (guuid === stack.guuid) {
      const removedKey = `data_config.${ISXUtils.escapeTagPeriods(
        alerts.filter((a) => a.guuid === alarmGuuidToDelete)[0].tagName
      )}.alarms`;
      const newAlerts = alerts.filter((a) => a.guuid !== alarmGuuidToDelete);
      setAlerts(newAlerts);
      setRemoved((prev) => new Set(prev.add(removedKey)));
      setDirty(true);
      setCurrentTabDirty(true);
    }
  };

  const closeToast = () => {
    setToastOpen(false);
  };

  async function saveAlertsToDB() {
    /*let alertsByTag = (stack?.data_config?.dsitems ?? []).reduce(
      (acc, tagName) => {
        const tagNameEscaped = ISXUtils.escapeTagPeriods(tagName);
        const key = `data_config.${tagNameEscaped}.alarms`;
        acc[key] = [];
        return acc;
      },
      {}
    );*/
    let alertsByTag = {};
    alertsByTag = alerts.reduce((acc, a) => {
      if (a.tagName) {
        const tagNameEscaped = ISXUtils.escapeTagPeriods(a.tagName);
        const key = `data_config.${tagNameEscaped}.alarms`;
        let tagAlerts = acc[key];
        if (!tagAlerts) {
          tagAlerts = acc[key] = [];
        }
        tagAlerts.push(a);
      }
      return acc;
    }, alertsByTag);
    for (let removeKey of removed) {
      if (!(removeKey in alertsByTag)) {
        alertsByTag[removeKey] = [];
      }
    }
    setDirty(false);
    setCurrentTabDirty(false);
    await updateItem(stack.guuid, alertsByTag);
    setToastOpen(true);
  }

  if (stack) {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.alertsHeader}>
              <div className={classes.alertsActionsContainer}>
                <Typography
                  variant="overline"
                  className={classes.alertsAction}
                >{`${alerts.length} configured`}</Typography>
                {!readOnly && (
                  <Button
                    className={classes.alertsAction}
                    color="primary"
                    onClick={createAlert}
                    startIcon={<AddCircleIcon />}
                  >
                    Create New
                  </Button>
                )}
              </div>
              {dirty && (
                <div>
                  <Button
                    className={classes.alertsAction}
                    disabled={toastOpen}
                    color="primary"
                    onClick={saveAlertsToDB}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                  <Tooltip
                    title="Current changes will be discarded"
                    placement="top"
                  >
                    <span>
                      <Button
                        className={classes.alertsAction}
                        disabled={toastOpen}
                        style={{ color: "red" }}
                        onClick={reset}
                      >
                        Cancel
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <div className={classes.alertItemsContainer}>
          <Grid container>
            {alerts.length > 0 ? (
              alerts.map((alert) => (
                <div key={alert.guuid}>
                  <AlarmItem
                    deleteThisAlarm={deleteThisAlert}
                    saveThisAlarm={saveThisAlert}
                    stacks={[stack]}
                    thisStack={stack}
                    stackId={stack.guuid}
                    alarm={alert}
                    readOnly={readOnly}
                  />
                </div>
              ))
            ) : (
              <div></div>
            )}
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={toastOpen}
            autoHideDuration={3000}
            onClose={closeToast}
            message="Alerts saved"
          />
        </div>
      </>
    );
  } else {
    return null;
  }
};

const DeviceTagsConfig = ({
  stack,
  updateItem,
  setCurrentTabDirty,
  readOnly,
}) => {
  const [tagProperties, setTagProperties] = useState({});
  const [tagIds, setTagIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dirty, setDirty] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const lastStackId = useRef(null);

  const errorContext = useContext(ErrorContext);

  const classes = useStyles();

  const getTagPropertiesFromConfig = useCallback(
    (tagIds) =>
      tagIds.reduce((acc, tid) => {
        acc[tid] = _cloneDeep(stack.data_config?.[tid] ?? {});
        return acc;
      }, {}),
    [stack.data_config]
  );

  useEffect(() => {
    if (stack && stack.guuid !== lastStackId.current) {
      lastStackId.current = stack.guuid;
      const tagIds = (stack.data_config?.dsitems ?? []).sort();
      // parallel arrays
      setTagIds(tagIds);
      setSelected(tagIds.map(() => false));
      const tagProperties = getTagPropertiesFromConfig(tagIds);
      setTagProperties(tagProperties);
    }
  }, [stack, getTagPropertiesFromConfig]);

  const handleTagPropsChange = (tagId, tagProps) => {
    setTagProperties((tagProperties) => {
      return {
        ...tagProperties,
        [tagId]: { ...tagProperties[tagId], ...tagProps },
      };
    });
    setDirty((dirty) => {
      const dirtyProps = Object.keys(tagProps).reduce((acc, p) => {
        acc[p] = true;
        return acc;
      }, {});
      return { ...dirty, [tagId]: { ...dirty[tagId], ...dirtyProps } };
    });
    setCurrentTabDirty(true);
  };

  const handleTagPropChange = (tagId, prop, value) => {
    const propPath = prop.split(".");
    const headProp = _head(propPath);
    setTagProperties((tagProperties) => {
      return {
        ...tagProperties,
        [tagId]: _set({ ...tagProperties[tagId] }, propPath, value),
      };
    });
    setDirty((dirty) => {
      return { ...dirty, [tagId]: { ...dirty[tagId], [headProp]: true } };
    });
    setCurrentTabDirty(true);
  };

  async function saveTagPropertiesToDB() {
    const update = Object.entries(dirty).reduce((acc, [tid, props]) => {
      const tagIdEscaped = ISXUtils.escapeTagPeriods(tid);
      return Object.entries(props).reduce((acci, [prop, flag]) => {
        if (flag) {
          const val = (tagProperties[tid] ?? {})[prop];
          const key = `data_config.${tagIdEscaped}.${prop}`;
          acci[key] = val;
        }
        return acci;
      }, acc);
    }, {});

    if (!_isEmpty(update)) {
      // console.log("UPDATE", update);
      await updateItem(stack.guuid, update);
    }
    setDirty({});
    setCurrentTabDirty(false);
    setToastOpen(true);
  }

  const reset = () => {
    const lastTagProperties = getTagPropertiesFromConfig(tagIds);
    setTagProperties(lastTagProperties);
    setDirty({});
    setCurrentTabDirty(false);
  };

  const closeToast = () => {
    setToastOpen(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.alertsHeader} style={{ paddingLeft: 3 }}>
            <div className={classes.alertsActionsContainer}>
              <Checkbox
                indeterminate={selected.some(
                  (s) => s && selected.some((s) => !s)
                )}
                onChange={() =>
                  setSelected((selected) => {
                    const flag = !selected.some((s) => s);
                    return selected.map(() => flag);
                  })
                }
                checked={selected.some((s) => s)}
              />
              <Typography
                variant="caption"
                color="primary"
                style={{
                  fontStyle: "italic",
                  opacity: selected.some((s) => s) ? 1.0 : 0.5,
                }}
              >
                Placeholder for future tag actions
              </Typography>
            </div>
            {Object.values(dirty).some(Boolean) && (
              <div>
                <Button
                  className={classes.alertsAction}
                  // disabled={toastOpen}
                  color="primary"
                  onClick={saveTagPropertiesToDB}
                  startIcon={<SaveIcon />}
                  disabled={
                    readOnly || Object.values(errorContext.errors).some(Boolean)
                  }
                >
                  Save
                </Button>
                <Tooltip
                  title="Current changes will be discarded"
                  placement="top"
                >
                  <Button
                    className={classes.alertsAction}
                    // disabled={toastOpen}
                    style={{ color: "red" }}
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <div className={classes.alertItemsContainer}>
        <Grid container>
          {tagIds.map((tagId, idx) => (
            <Grid key={tagId} item xs={12}>
              {/* <div style={{ display: "flex", alignItems: "center" }}> */}
              {/* <div>
                    <Checkbox
                      checked={selected[idx] ?? false}
                      onChange={(event) =>
                        setSelected((selected) => {
                          const updated = [...selected];
                          updated[idx] = event.target.checked;
                          return updated;
                        })
                      }
                    />
                  </div> */}
              <TagConfig
                stack={stack}
                tagId={tagId}
                checked={selected[idx] ?? false}
                onCheckChange={(event) =>
                  setSelected((selected) => {
                    const updated = [...selected];
                    updated[idx] = event.target.checked;
                    return updated;
                  })
                }
                tagProperties={tagProperties[tagId]}
                handleTagPropChange={handleTagPropChange}
                handleTagPropsChange={handleTagPropsChange}
                readOnly={readOnly}
                // handleTagPropAdd={handleTagPropAdd}
                // handleTagPropRemove={handleTagPropRemove}
              ></TagConfig>
              {/* </div> */}
            </Grid>
          ))}
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={toastOpen}
          autoHideDuration={3000}
          onClose={closeToast}
          message="Updated tag properties saved"
        />
      </div>
    </>
  );
};

const StackManagementDialog = (props) => {
  const [tab, setTab] = useState(0);
  const [currentTabDirty, setCurrentTabDirty] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const stacks = useSelector((state) => state.isx.stacks);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [sendTo, setSendTo] = useState([]);
  const value = useMemo(() => ({ sendTo, setSendTo }), [sendTo]);
  const { selectedStack, detailsOpen, closeDetails, readOnly } = props;

  const stack = stacks[selectedStack];

  const updateItem = (guuid, contents) => {
    dispatch(updateItemOp(guuid, contents));
  };

  const handleTabChange = (event, newTab) => {
    if (!currentTabDirty) {
      setTab(newTab);
    } else {
      setToastOpen(true);
    }
  };

  const handleClose = () => {
    if (!currentTabDirty) {
      closeDetails();
      setTab(0);
    } else {
      setToastOpen(true);
    }
  };

  if (stack) {
    const devSession = JSON.parse(stack.dev_session);
    const label = stack?.name || devSession["stack.label"] || "<Unnamed>";
    return (
      <ErrorProvider>
        <AlertsAddressBookContext.Provider value={value}>
          <Dialog
            open={detailsOpen}
            onClose={handleClose}
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle>
              <div className={classes.dialogTitleHeader}>
                {label}
                <IconButton onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
              </div>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Properties" />
                <Tab label="Alerts" />
                <Tab label="Tags" />
                <Tab label="Configure" />
              </Tabs>
            </DialogTitle>
            <DialogContent className={classes.dialogTabContents}>
              {tab === 0 && (
                <StackDetails
                  stack={stack}
                  readOnly={readOnly}
                  setCurrentTabDirty={setCurrentTabDirty}
                  updateItem={updateItem}
                />
              )}
              {tab === 1 && (
                <StackAlerts
                  stack={stack}
                  setCurrentTabDirty={setCurrentTabDirty}
                  readOnly={readOnly}
                  updateItem={updateItem}
                />
              )}
              {tab === 2 && (
                <DeviceTagsConfig
                  stack={stack}
                  updateItem={updateItem}
                  setCurrentTabDirty={setCurrentTabDirty}
                  readOnly={readOnly}
                />
              )}
              {tab === 3 && (
                <div style={{ textAlign: "center" }}>
                  <Typography variant="h4">Coming Soon</Typography>
                </div>
              )}
            </DialogContent>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={toastOpen}
              autoHideDuration={3000}
              onClose={() => setToastOpen(false)}
              classes={{
                root: classes.error,
              }}
              message="Updates not saved. Please save or cancel."
            />
          </Dialog>
        </AlertsAddressBookContext.Provider>
      </ErrorProvider>
    );
  } else {
    return null;
  }
};

export default StackManagementDialog;
