import React, { useRef } from "react";
import { NavigationBar as NavigationBarComponent } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout as _logout, getUserMessages } from "store/operations";
import { useEffect } from "react";

const GET_MESSAGES_INTERVAL = 30000;

const NavigationBar = (props) => {
  const isAuthenticated = useSelector((state) => state.isx.isAuthenticated);
  const username = useSelector((state) => state.isx.username);
  const user = useSelector((state) => state.isx.user);
  const messages = useSelector((state) => state.isx.messages);

  const interval = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserMessages()).catch((e) => {
        console.warn("getUserMessages exception", e);
      });
      interval.current = setInterval(() => {
        dispatch(getUserMessages()).catch((e) => {
          console.warn("getUserMessages exception", e);
        });
      }, GET_MESSAGES_INTERVAL);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }
    // on unmount
    return () => {
      clearInterval(interval.current);
    };
  }, [isAuthenticated, dispatch]);

  const logout = () => {
    dispatch(_logout());
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <NavigationBarComponent
      {...props}
      isAuthenticated={isAuthenticated}
      username={username}
      user={user}
      messages={messages}
      logout={logout}
      login={login}
    />
  );
};

export default NavigationBar;
