import React, { useContext, useState, useMemo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TreeItem from "@material-ui/lab/TreeItem";

import OrganizationManagementOrganizationUsers from "./OrganizationManagementOrganizationUsers";
import OrganizationManagementOrganizationOrganizations from "./OrganizationManagementOrganizationOrganizations";
import OrganizationManagementOrganizationDevices from "./OrganizationManagementOrganizationDevices";
import {
  OWNER_POLICY,
  ADMIN_POLICY,
} from "./OrganizationManagementWidgetConstants";
import { ISXContext } from "services/Utils";

const OrganizationManagementOrganization = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOrgOwner, setIsOrgOwner] = useState(false);

  const isxContext = useContext(ISXContext);

  const showInviteDisplay = useMemo(
    () => isxContext.useOrganizationInvites,
    [isxContext.useOrganizationInvites]
  );

  useEffect(() => {
    const currentUserPolicies = (
      props.organization.users.find(
        (user) => user.userid === props.currentUserId
      ) || {}
    ).policies;

    //Set is current user the owner
    const isOrgOwner =
      props.organization.g_owner === props.currentUserId ||
      (!!currentUserPolicies &&
        currentUserPolicies.some(
          (policyId) => policyId === OWNER_POLICY.policyid
        ));

    //Set is current user an admin
    const isAdmin =
      isOrgOwner ||
      (!!currentUserPolicies &&
        currentUserPolicies.some(
          (policyId) => policyId === ADMIN_POLICY.policyid
        ));

    setIsAdmin(isAdmin);
    setIsOrgOwner(isOrgOwner);
  }, [
    props.currentUserId,
    props.organization.g_owner,
    props.organization.users,
  ]);

  return (
    <TreeItem
      key={props.organization.guuid}
      nodeId={props.organization.guuid}
      label={
        <React.Fragment>
          <span>{props.organization.name}</span>
          {isOrgOwner &&
          !!props.expandedOrgs &&
          !!props.expandedOrgs.find((orgId) => {
            return orgId === props.organization.guuid;
          }) ? (
            <React.Fragment>
              <Button
                color="primary"
                style={{
                  margin: 0,
                  padding: "0px 10px",
                  fontSize: "0.75rem",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  props.openCreateOrganizationDialog(props.organization.guuid);
                }}
              >
                Create Group
              </Button>
            </React.Fragment>
          ) : (
            []
          )}

          {isAdmin &&
          !!props.expandedOrgs &&
          !!props.expandedOrgs.find((orgId) => {
            return orgId === props.organization.guuid;
          }) ? (
            <Button
              disabled={true}
              color="primary"
              style={{
                margin: 0,
                padding: "0px 10px",
                fontSize: "0.75rem",
              }}
              onClick={(event) => {
                event.preventDefault();
                props.openAddDeviceDialog(props.organization.guuid);
              }}
            >
              Add Devices
            </Button>
          ) : (
            []
          )}

          {isAdmin &&
          !!props.expandedOrgs &&
          !!props.expandedOrgs.find((orgId) => {
            return orgId === props.organization.guuid;
          }) ? (
            <Button
              color="primary"
              style={{
                margin: 0,
                padding: "0px 10px",
                fontSize: "0.75rem",
              }}
              onClick={(event) => {
                event.preventDefault();
                props.openCreateUserDialog(
                  props.organization.guuid,
                  props.organization.name
                );
              }}
            >
              Create/Update Users
            </Button>
          ) : (
            []
          )}

          {showInviteDisplay &&
          isAdmin &&
          !!props.expandedOrgs &&
          !!props.expandedOrgs.find((orgId) => {
            return orgId === props.organization.guuid;
          }) ? (
            <Button
              color="primary"
              style={{
                margin: 0,
                padding: "0px 10px",
                fontSize: "0.75rem",
              }}
              onClick={(event) => {
                event.preventDefault();
                props.openInviteUserDialog(props.organization.guuid);
              }}
            >
              Invite Users
            </Button>
          ) : (
            []
          )}
        </React.Fragment>
      }
    >
      {(props.organization.orgs || []).length > 0 ? (
        <OrganizationManagementOrganizationOrganizations
          {...props}
          isOrgOwner={isOrgOwner}
          isAdmin={isAdmin}
        />
      ) : (
        []
      )}
      {(props.organization.devices || []).length > 0 ? (
        <OrganizationManagementOrganizationDevices
          currentUserId={props.currentUserId}
          isOrgOwner={isOrgOwner}
          isAdmin={isAdmin}
          organization={props.organization}
          filters={props.filters}
          toggleRowSelect={props.toggleRowSelect}
          selectedDevices={props.selectedDevices}
        />
      ) : (
        []
      )}
      {(props.organization.users || []).length > 0 ||
      (props.organization.pending || []).length > 0 ? (
        <OrganizationManagementOrganizationUsers
          currentUserId={props.currentUserId}
          isOrgOwner={isOrgOwner}
          isAdmin={isAdmin}
          organization={props.organization}
          filters={props.filters}
          toggleRowSelect={props.toggleRowSelect}
          selectedUsers={props.selectedUsers}
        />
      ) : (
        []
      )}
    </TreeItem>
  );
};

export default OrganizationManagementOrganization;
