import React from "react";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";
import { LubeHistoryWidget as LubeHistoryWidgetComponent } from "../../../components/widgets";

const DEFAULT_TIMEFRAME = {
  type: "moving",
  "length.value": 180,
  "length.units": "days",
  "interval.type": "set",
  "interval.value": 30,
  "interval.units": "seconds",
};

const LubeHistoryWidget = (props) => {
  return (
    <BaseTimeSeriesWidget
      {...props}
      defaultTimeframe={DEFAULT_TIMEFRAME}
      fixedWindow={true}
    >
      {(baseProps) => (
        <LubeHistoryWidgetComponent
          {...baseProps}
          defaultTimeframe={DEFAULT_TIMEFRAME}
        />
      )}
    </BaseTimeSeriesWidget>
  );
};

export default LubeHistoryWidget;
