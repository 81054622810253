import React, { useState } from "react";
import { Navigate } from "services/Router";
import { continueLogin } from "services/AuthUtil";
import auth from "services/Auth";

//this has a simple purpose -- notice that we have ?code in URL and finish the login process
//This is necessary because our login re-direct can't have a # in it (OIDC/OAuth standard) and we are using
// HashRouter - so we need some (hack) at the top level to detect the URL coming back from login re-direct
const LoginCallback = (props) => {

    const [loaded, setLoaded] = useState();
    
    function codeStateFromLocation() {
        let h = window.location.search.substr(1);
        if (h.startsWith('/')) h = h.substr(1); //extra slash due to HashRouter
        let code = h.split(/[&?#]/).filter((a) => a.startsWith('code='))[0];
        if (code) code = code.substr(5);
        let state: any = h.split(/[&?#]/).filter((a) => a.startsWith('state='))[0];
        if (state) {
            state = decodeURIComponent(state.substr(6));
            try {
                state = JSON.parse(state);
            } catch {}
        }
        return { code, state };
    }
    function removeCodeState() {
        const url = new URL(window.location);
        url.searchParams.delete('code');
        url.searchParams.delete('state');
        url.searchParams.delete('session_state'); 
        window.history.replaceState(null, null, url)
    }

    const codeState = codeStateFromLocation();
    if(!loaded && codeState?.code) {
        removeCodeState();
        continueLogin(codeState).then((tokenData) => {
            if(tokenData) {
                auth.loginSSO(tokenData).then((status) => {
                    if(status) {
                        //this will force refresh/redirect
                        setLoaded(true); 
                    }
                });
            } else {
                console.log("no token data");
                setLoaded(true);                 
            }
        })
        return <div> Continuing login... please <a href="/">click here if your browser gets stuck</a></div>;
    } else {
        return <Navigate to="/dashboard" />
    }
}
export default LoginCallback;
