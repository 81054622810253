import React from "react";
import { NewWidgetDialog as NewWidgetDialogComponent } from "../../components";
import { connect } from "react-redux";
import { isxOperations } from "../../store";

class NewWidgetDialog extends React.Component {
  render() {
    return (
      <NewWidgetDialogComponent {...this.props} addWidget={this.addWidget} />
    );
  }

  addWidget = widget => {
    this.props.addWidget(this.props.guuid, widget);
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addWidget: (guuid, widget) =>
      dispatch(isxOperations.addWidget(guuid, widget))
  };
};

export default connect(null, mapDispatchToProps)(NewWidgetDialog);
