import React, { useState, useContext, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import ISXUtils, { ISXContext } from "services/Utils";
import Auth from "services/Auth";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import moment from "moment";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const AutolubeWidget = (props) => {
  //console.log("in QM", props);
  const isxContext = useContext(ISXContext);
  const widget = props.widget || {};
  const stackId =
    "tags" in widget && widget.tags.length
      ? widget.tags[0].stack
      : (widget.stacks || [])[0];
  const tag =
    "tags" in widget && widget.tags.length ? widget.tags[0].attribute : null;
  const stacks = props.stacks || {};
  const stack = stacks[stackId] || {};
  const tagdc =
    tag && "data_config" in stack && tag in stack["data_config"]
      ? stack["data_config"][tag]
      : {};
  const currentcycle =
    tagdc && tagdc.currentcycle
      ? tagdc["currentcycle"]
      : { count: 0, mode: null };
  const [msg, setMsg] = useState({
    status: "Ready",
    start: false,
    end: false,
    lastSent: "---",
    count: currentcycle["count"] ? currentcycle["count"] : 0,
    mode: currentcycle["mode"] ? currentcycle["mode"] : "---  ",
    begin: currentcycle
      ? moment
          .utc(parseInt(currentcycle["begintime"]))
          .local()
          .format(DATE_FORMAT)
      : "Not available",
    processStatus: "No Active Lubrication Cycle",
  });
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [verifyAction, setVerifyAction] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const userCred = React.createRef();
  const saveAttrsTimeout = useRef(null);
  const [counter, setCounter] = React.useState(0);
  const [downCounter, setDownCounter] = React.useState(0);
  const downCounterUpdate = 2; //refresh rate of down counter in seconds
  const defaultLubeInterval = 300; //60; //seconds before another lube attempt can be made
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter + 2), 2000);
    return () => clearInterval(timer);
  }, [counter]);

  React.useEffect(() => {
    const timer =
      downCounter > 0 &&
      setInterval(
        () => setDownCounter(downCounter - downCounterUpdate),
        downCounterUpdate * 1000
      );
    return () => clearInterval(timer);
  }, [downCounter]);
  //const haveValidAttributes = () => attributes.some((a) => !!a.key);
  const haveValidAttributes = () => true;
  const [requestPending, setRequestPending] = useState(false);
  const [resetLubeCyclePending, setResetLubeCyclePending] = useState(false);

  const resetLubricationCycle = () => {
    setResetLubeCyclePending(true);
    const resetMsg = {};
    resetMsg[
      `data_config.${ISXUtils.escapeTagPeriods(tag)}.currentcycle.endtime`
    ] = Date.now();
    props.setDataConfig(stackId, resetMsg).then((rv) => {
      //clearTimeout(saveAttrsTimeout.current);
      if (rv.status === 0) {
        // ok
        //console.log("after resetLubeCycle", rv);
        /*const newMsg = { ...msg };
          newMsg.status = "Processing";
          newMsg.cycleInitTime = Date.now();
          setCounter(1);
          newMsg.processStatus = "Initiating Lubrication Cycle...";
          setMsg(newMsg);*/
      } else {
        // error
        const newMsg = { ...msg };
        newMsg.content = `ERROR: ${
          rv.error || "Something went wrong with your request"
        }; please try again or contact support`;
        newMsg.severity = "erorr";
        setMsg(newMsg);
        setResetLubeCyclePending(false);
      }
    });
  };
  //console.log("attributes of msg to be sent are", stackId, attributes);
  if (stackId && currentcycle.mode) {
    if (msg.cycleInitTime) {
      //console.log("msg now is", msg, currentcycle);
      let mchanged = false;
      const newMsg = { ...msg };
      if ("endtime" in currentcycle) {
        if (currentcycle.endtime > msg.cycleInitTime || msg.end) {
          console.log(
            "ending cycle now",
            currentcycle.endtime,
            msg.cycleInitTime
          );
          //done with the cycle
          //const newMsg = { ...msg };
          newMsg.status = "Ready";
          newMsg.start = false;
          newMsg.end = false;
          newMsg.cycleInitTime = null;
          newMsg.processStatus = "No Active Lubrication Cycle";
          if ("reason" in currentcycle) {
            console.log("reason for end is", currentcycle.reason);
            newMsg.processStatus = "Cycle Ended - " + currentcycle.reason;
          }
          setCounter(0);
          setDownCounter(0);
          mchanged = true;
          //setMsg(newMsg);
          setRequestPending(false);
          setResetLubeCyclePending(false);
        }
      } else {
        if (msg.status === "Processing") {
          newMsg.lastSent = msg.cycleInitTime
            ? moment
                .utc(parseInt(msg.cycleInitTime))
                .local()
                .format(DATE_FORMAT)
            : "---";
          if (msg.end) {
            newMsg.status = "Ending Cycle";
          } else {
            setCounter(0);
            if (!msg.start) {
              newMsg.start = true;
              mchanged = true;
            }
            if (msg.mode.toLowerCase() === "manual") {
              newMsg.status = "Lubricating";
              mchanged = true;
            } else if (msg.mode.toLowerCase() === "assist") {
              newMsg.status = "Lubricating";
              mchanged = true;
              setRequestPending(false);
            }
          }
        } else if (
          msg.mode.toLowerCase() === "manual" &&
          msg.status === "Lubricating"
        ) {
          if (
            Date.now() >
            Math.max(currentcycle.begintime, msg.cycleInitTime) +
              parseFloat(
                tagdc.cycletime ? tagdc.cycletime : defaultLubeInterval
              ) *
                1000
          ) {
            newMsg.status = "Ready";
            mchanged = true;
            setRequestPending(false);
          }
        } else if (
          msg.mode.toLowerCase() === "assist" &&
          msg.status === "Lubricating"
        ) {
          console.log(
            "now, cycleinit, begintime and lastlube",
            Date.now(),
            msg.cycleInitTime,
            currentcycle["begintime"],
            msg.lastLubeTime
          );
          if (
            msg.count <= 1 &&
            msg.lastLubeTime &&
            Date.now() > currentcycle["begintime"] + 60000
          ) {
            console.log("initial waiting for 60 seconds priming");
            newMsg.status = "Waiting";
            mchanged = true;
            setRequestPending(false);
            setDownCounter(
              parseFloat(
                tagdc.cycletime ? tagdc.cycletime : defaultLubeInterval
              )
            );
          } else if (
            msg.count > 1 &&
            msg.lastLubeTime &&
            Date.now() > msg.lastLubeTime + 10000
          ) {
            newMsg.status = "Waiting";
            mchanged = true;
            setRequestPending(false);
            setDownCounter(
              parseFloat(
                tagdc.cycletime ? tagdc.cycletime : defaultLubeInterval
              )
            );
          }
        }
      }
      if (
        msg.count !== currentcycle["count"] ||
        msg.mode !== currentcycle["mode"] ||
        msg.begin !==
          moment
            .utc(parseInt(currentcycle["begintime"]))
            .local()
            .format(DATE_FORMAT)
      ) {
        newMsg.count = currentcycle["count"];
        newMsg.mode = currentcycle["mode"];
        newMsg.begin = moment
          .utc(parseInt(currentcycle["begintime"]))
          .local()
          .format(DATE_FORMAT);
        newMsg.lastLubeTime = Date.now();
        if (
          currentcycle["mode"].toLowerCase() === "assist" &&
          newMsg.status !== "Ready"
        ) {
          newMsg.status = "Lubricating";
          setDownCounter(0);
          //console.log("old msg and newMsg is now", msg, newMsg);
        }
        mchanged = true;
      }
      if (mchanged) {
        //console.log("finally newMsg is", newMsg);
        setMsg(newMsg);
      }
    } else {
      if ("endtime" in currentcycle) {
        if (msg.status !== "Ready") {
          const newMsg = { ...msg };
          newMsg.status = "Ready";
          newMsg.processStatus = "No Active Lubrication Cycle";
          setMsg(newMsg);
        }
      } else {
        if ("source" in currentcycle && currentcycle.source === widget.guuid) {
          if (msg.status !== "Reset?") {
            const newMsg = { ...msg };
            newMsg.status = "Reset?";
            newMsg.processStatus =
              "Network Connection with Device was Interrupted - Start Over?";
            setMsg(newMsg);
            //resetLubricationCycle();
          }
        } else if (msg.status !== "Locked") {
          const newMsg = { ...msg };
          newMsg.status = "Locked";
          newMsg.processStatus =
            "Lubrication Cycle Initiated by " +
            ("source" in currentcycle &&
            currentcycle.source.toLowerCase() === "auto"
              ? "Auto Smart Lube"
              : "Another User");
          setMsg(newMsg);
        }
      }
    }
  }

  const handleVerifyDialog = (action) => {
    setOpenVerifyDialog(true);
    setVerifyAction(action);
  };

  const removeVerifyDialog = () => {
    setVerifyStatus("");
    setVerifyAction("");
    setOpenVerifyDialog(false);
  };

  const handleConfirmDialog = () => setOpenConfirmDialog(true);

  const removeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const verifyUser = (action) => {
    //console.log("action to be invoked is", verifyAction);
    //console.log("usercred", userCred.current.value);
    Auth.verify(userCred.current.value).then((rv) => {
      //console.log("rv is", rv);
      if (rv) {
        //console.log("verified");
        setVerifyStatus("User Verified");
        //console.log("continue action", verifyAction);
        if (verifyAction.toLowerCase() === "manual") {
          sendManual();
        } else if (verifyAction.toLowerCase() === "assist") {
          handleConfirmDialog();
          //sendAssist();
        } else if (verifyAction.toLowerCase() === "endcycle") {
          sendEndCycle();
        } else {
          console.log("unknown action invoked after verify", verifyAction);
        }
        removeVerifyDialog();
        //setVerifyAction("");
        //console.log("verifyAction now is", verifyAction);
      } else {
        //console.log("error on verification");
        setVerifyStatus("Unable to Verify, Try Again!");
      }
    });
  };

  const sendManual = () => {
    const lubeMsg = {
      tag: widget.tags[0].attribute,
      autolube: "manual",
      source: widget.guuid,
      dashboard: widget.dashboard,
      user: props.user.username,
      devicetype: tagdc.devicetype ? tagdc.devicetype : "jack",
      baseline: parseFloat(tagdc.baseline ? tagdc.baseline : 0),
      cycletime: parseFloat(
        tagdc.cycletime ? tagdc.cycletime : defaultLubeInterval
      ),
      limit: Math.floor(
        parseFloat(tagdc.maxlubeamount ? tagdc.maxlubeamount : 0) /
          (tagdc.unitvolume ? parseFloat(tagdc.unitvolume) : 0.5)
      ),
      available: Math.max(
        0,
        Math.floor(
          (tagdc.capacity ? tagdc.capacity : 0) /
            (tagdc.unitvolume ? parseFloat(tagdc.unitvolume) : 0.5)
        ) - (tagdc.totalcount ? parseInt(tagdc.totalcount) : 0)
      ),
    };
    setRequestPending(true);
    props.setProps(stackId, lubeMsg).then(
      // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
      (rv) => {
        clearTimeout(saveAttrsTimeout.current);
        if (rv.status === 0) {
          // ok
          const newMsg = { ...msg };
          newMsg.status = "Processing";
          newMsg.cycleInitTime = Date.now();
          setCounter(1);
          newMsg.processStatus = "Initiating Lubrication Cycle...";
          setMsg(newMsg);
        } else {
          // error
          const newMsg = { ...msg };
          newMsg.content = `ERROR: ${
            rv.error || "Something went wrong with your request"
          }; please try again or contact support`;
          newMsg.severity = "erorr";
          setMsg(newMsg);
          setRequestPending(false);
        }
      }
    );
  };

  const sendAssist = () => {
    removeConfirmDialog();
    const lubeMsg = {
      tag: widget.tags[0].attribute,
      autolube: "assist",
      source: widget.guuid,
      dashboard: widget.dashboard,
      user: props.user.username,
      devicetype: tagdc.devicetype ? tagdc.devicetype : "jack",
      baseline: parseFloat(tagdc.baseline ? tagdc.baseline : 0),
      cycletime: parseFloat(
        tagdc.cycletime ? tagdc.cycletime : defaultLubeInterval
      ),
      limit: Math.floor(
        parseFloat(tagdc.maxlubeamount ? tagdc.maxlubeamount : 0) /
          (tagdc.unitvolume ? parseFloat(tagdc.unitvolume) : 0.5)
      ),
      available: Math.max(
        0,
        Math.floor(
          (tagdc.capacity ? tagdc.capacity : 0) /
            (tagdc.unitvolume ? parseFloat(tagdc.unitvolume) : 0.5)
        ) - (tagdc.totalcount ? parseInt(tagdc.totalcount) : 0)
      ),
    };
    setRequestPending(true);
    props.setProps(stackId, lubeMsg).then(
      // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
      (rv) => {
        clearTimeout(saveAttrsTimeout.current);
        if (rv.status === 0) {
          const newMsg = { ...msg };
          newMsg.status = "Processing";
          newMsg.cycleInitTime = Date.now();
          setCounter(1);
          newMsg.processStatus = "Initiating Lubrication Cycle...";
          setMsg(newMsg);
        } else {
          // error
          const newMsg = { ...msg };
          newMsg.content = `ERROR: ${
            rv.error || "Something went wrong with your request"
          }; please try again or contact support`;
          newMsg.severity = "erorr";
          setMsg(newMsg);
          setRequestPending(false);
        }
      }
    );
  };

  const sendEndCycle = () => {
    const lubeMsg = {
      tag: widget.tags[0].attribute,
      autolube: msg.mode,
      source: widget.guuid,
      dashboard: widget.dashboard,
      user: props.user.username,
      end: true,
    };
    setRequestPending(true);
    props.setProps(stackId, lubeMsg).then(
      // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
      (rv) => {
        clearTimeout(saveAttrsTimeout.current);
        if (rv.status === 0) {
          // ok
          const newMsg = { ...msg };
          newMsg.status = "Processing";
          if (msg.status === "Reset?") {
            newMsg.processStatus =
              "Waiting For Ontrak To Acknowledge Reset Request";
          }
          newMsg.cycleInitTime = Date.now();
          newMsg.end = true;
          setMsg(newMsg);
          /*saveAttrsTimeout.current = setTimeout(() => {
            msg.end = true;
            setMsg(msg);
          }, 6000);*/
        } else {
          // error
          const newMsg = { ...msg };
          newMsg.content = `ERROR: ${
            rv.error || "Something went wrong with your request"
          }; please try again or contact support`;
          newMsg.severity = "erorr";
          setMsg(newMsg);
          setRequestPending(false);
        }
      }
    );
  };

  const printCountDown = () => {
    let minutes = "0" + Math.floor(downCounter / 60);
    let seconds = "0" + (downCounter - minutes * 60);
    return minutes.substr(-2) + ":" + seconds.substr(-2);
  };
  return (
    <Widget
      {...props}
      dataType="tags"
      maxStacks={1}
      maxTags={1}
      showDerivedTags={false}
      widgetTitle={`${isxContext.labels.deviceCapitalized} Lubrication`}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ overflowY: "auto", height: "100%", padding: 12 }}
      >
        <Grid
          container
          spacing={1}
          //style={{ paddingLeft: 12 }}
        >
          <Grid item xs={5} style={{ paddingTop: 6 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ paddingBottom: 8 }}
            >
              <Button
                onClick={
                  Auth.isVerified()
                    ? sendManual
                    : () => handleVerifyDialog("manual")
                }
                disabled={
                  !tag ||
                  props.readOnly ||
                  requestPending ||
                  !haveValidAttributes() ||
                  msg.status !== "Ready" ||
                  !tagdc.capacity ||
                  !tagdc.maxlubeamount ||
                  !tagdc.baseline ||
                  (msg.start && msg.mode.toLowerCase() === "assist")
                }
                variant="contained"
                color="primary"
                style={{ fontSize: "10px" }}
              >
                SmartLube Manual
              </Button>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ paddingBottom: 8 }}
            >
              <Button
                onClick={
                  Auth.isVerified()
                    ? handleConfirmDialog
                    : () => handleVerifyDialog("assist")
                }
                //disabled
                disabled={
                  !tag ||
                  props.readOnly ||
                  requestPending ||
                  !haveValidAttributes() ||
                  msg.status !== "Ready" ||
                  !tagdc.capacity ||
                  !tagdc.maxlubeamount ||
                  !tagdc.baseline ||
                  msg.start
                }
                variant="contained"
                color="primary"
                style={{ fontSize: "10px" }}
              >
                SmartLube Assist
              </Button>
            </Grid>

            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="body2">
                {msg.status}
                {downCounter ? " ( " + printCountDown() + " )" : ""}
              </Typography>
              <Typography variant="body2" style={{ fontSize: 9 }}>
                Last Sent: {msg.lastSent}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ paddingTop: 8 }}
            >
              <Button
                onClick={
                  Auth.isVerified()
                    ? sendEndCycle
                    : () => handleVerifyDialog("endcycle")
                }
                disabled={
                  props.readOnly ||
                  requestPending ||
                  !haveValidAttributes() ||
                  !msg.start ||
                  (msg.mode.toLowerCase() === "manual" &&
                    msg.status !== "Ready")
                }
                variant="contained"
                color="primary"
                style={{ fontSize: "10px" }}
              >
                End Lubrication Cycle
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={7} style={{ paddingTop: 2 }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%", width: "100%" }}
            >
              {msg.start === true ? (
                <>
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Volume of Lubricant Used:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {Number.parseFloat(
                          msg.count * parseFloat(tagdc.unitvolume)
                        ).toFixed(2)}{" "}
                        cc
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Cycle Started:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{msg.begin}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Mode Used:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {msg.mode.charAt(0).toUpperCase() + msg.mode.slice(1)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Baseline Friction Level:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {currentcycle.baseline ? currentcycle.baseline : 0} dB
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Starting Friction Level:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {currentcycle.beginvalue ? currentcycle.beginvalue : 0}{" "}
                        dB
                      </Typography>
                    </Grid>
                  </Grid>
                  {"lastintervalvalue" in currentcycle && (
                    <Grid container direction="row" alignItems="left">
                      <Grid item>
                        <Typography variant="subtitle2">
                          Last Interval Friction Level:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {currentcycle.lastintervalvalue
                            ? currentcycle.lastintervalvalue
                            : 0}{" "}
                          dB
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container direction="row" alignItems="left">
                    <Grid item>
                      <Typography variant="subtitle2">
                        Current Friction Level:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {currentcycle.endvalue ? currentcycle.endvalue : 0} dB
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <Typography variant="subtitle2">
                    {tagdc.capacity && tagdc.maxlubeamount && tagdc.baseline
                      ? msg.processStatus
                      : "Please Set Valid Tag Properties"}
                    {counter ? "(" + counter + ")" : ""}
                  </Typography>
                  {msg.status === "Reset?" && (
                    <Button
                      onClick={sendEndCycle}
                      variant="contained"
                      color="primary"
                      style={{ fontSize: "10px" }}
                      disabled={requestPending}
                    >
                      Reset Lubrication Cycle?
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openConfirmDialog}
        onClose={removeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Enable Smart Assist Lubrication?"}
        </DialogTitle>
        <DialogContent justify="center">
          <DialogContentText id="confirm-dialog-description">
            Please make sure the device in ready and online
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={sendAssist}
            color="primary"
            autoFocus
            disabled={props.readOnly || requestPending}
          >
            Confirm
          </Button>
          <Button onClick={removeConfirmDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openVerifyDialog}
        onClose={removeVerifyDialog}
        aria-labelledby="verify-dialog-title"
        aria-describedby="verify-dialog-description"
      >
        <DialogTitle id="verify-dialog-title">
          {"Enter Password to Verify Action"}
        </DialogTitle>
        <DialogContent justify="center">
          <DialogContentText id="verify-dialog-description">
            Please Enter your Account password to verify before proceeding with
            the action
          </DialogContentText>
          <TextField
            type="password"
            inputRef={userCred}
            InputProps={{
              inputProps: {
                placeholder: "Password",
              },
            }}
          />
          {verifyStatus && <Typography>{verifyStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={verifyUser}
            color="primary"
            autoFocus
            //disabled={props.readOnly || requestPending}
          >
            Confirm
          </Button>
          <Button onClick={removeVerifyDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Widget>
  );
};

export default AutolubeWidget;
