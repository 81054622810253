import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
// import {
//   RadioGroup,
//   Radio,
//   FormControl,
//   FormControlLabel,
//   FormLabel,
//   TextField
// } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

var errorMessages = {
  axisMinMax: "Please enter a minimum that is lower than the maximum",
  axisFieldCompletion: "Please enter both a minimum and a maximum",
};

class YAxisOption extends React.Component {
  constructor(props) {
    super(props);
    const yaxis = this.props.options.yaxis;
    if (yaxis?.tagprop === undefined) {
      yaxis.tagprop = true;
      this.props.setOptions({
        yaxis: { ...yaxis, tagprop: true },
      });
    }
  }
  errorMessage = () => {
    let errorFound = false;
    for (let error in this.props.errors) {
      if (error.includes("axis") && !errorFound) {
        errorFound = true;
        return (
          <p style={{ color: "red", fontSize: "10px" }}>
            {errorMessages[error]}
          </p>
        );
      }
    }
  };

  validations = (min, max, type) => {
    let yAxisOptions = this.props.options.yaxis;

    if (type === "auto") {
      //this.props.noErrorDetected("axisError");
      this.props.noErrorDetected("axisMinMax");
      this.props.noErrorDetected("axisFieldCompletion");
    } else {
      let currentMin =
        min != null ? Number(min) : Number(yAxisOptions["fixed.min"]);
      let currentMax =
        max != null ? Number(max) : Number(yAxisOptions["fixed.max"]);
      if (min === "") currentMin = null;
      if (max === "") currentMax = null;

      if (currentMin >= currentMax) {
        this.props.errorDetected("axisMinMax");
      } else this.props.noErrorDetected("axisMinMax");

      if (currentMax == null) {
        this.props.errorDetected("axisFieldCompletion");
      } else if (currentMin == null) {
        this.props.errorDetected("axisFieldCompletion");
      } else {
        this.props.noErrorDetected("axisFieldCompletion");
      }
    }
  };

  render = () => {
    const yaxis = this.props.options.yaxis;

    return (
      <FormControl
        component="fieldset"
        className={this.props.classes.formControl}
      >
        <FormLabel component="legend">Y-Axis</FormLabel>
        <RadioGroup
          aria-label="Y-Axis"
          name="yaxis"
          row={true}
          value={yaxis.type}
          onChange={(event) => {
            const value = event.target.value;
            this.props.setOptions({ yaxis: { ...yaxis, type: value } });
            this.validations(null, null, event.target.value);
          }}
        >
          <FormControlLabel value="auto" control={<Radio />} label="Auto" />
          <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
        </RadioGroup>
        {yaxis.type === "fixed" && (
          <FormControl style={{ margin: 6, flexDirection: "row" }}>
            <TextField
              className={this.props.classes.textField}
              id="min-standard-number"
              label="Minimum"
              value={yaxis["fixed.min"] || ""}
              disabled={yaxis.tagprop}
              onChange={(event) => {
                const val = event.target.value;
                this.props.setOptions({
                  yaxis: { ...yaxis, "fixed.min": val },
                });
                this.validations(event.target.value, null, "fixed");
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              styles={{ margin: 6 }}
            />

            <TextField
              id="max-standard-number"
              label="Maximum"
              value={yaxis["fixed.max"] || ""}
              disabled={yaxis.tagprop}
              onChange={(event) => {
                const val = event.target.value;
                this.props.setOptions({
                  yaxis: { ...yaxis, "fixed.max": val },
                });
                this.validations(null, event.target.value, "fixed");
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              styles={{ margin: 6 }}
            />
            <FormControlLabel
              name="defaults"
              label="Use Tag Settings"
              control={
                <Checkbox
                  checked={yaxis.tagprop}
                  onChange={(event) => {
                    this.props.noErrorDetected("axisFieldCompletion");
                    this.props.noErrorDetected("axisMinMax");
                    const value = event.target.checked;
                    this.props.setOptions({
                      yaxis: { ...yaxis, tagprop: value },
                    });
                    //this.validations(null, null, event.target.value);
                  }}
                  name="tagDefault"
                />
              }
            />
          </FormControl>
        )}
        {yaxis.type === "fixed" && this.errorMessage()}
      </FormControl>
    );
  };
}
export default withStyles(styles)(YAxisOption);
