import React from "react";
import { OeeWidget as OeeWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";

/*const OeeWidget = (props) => {
  return (
    <BaseTimeSeriesWidget {...props}>
      {(baseProps) => {
        return <OeeWidgetComponent {...baseProps} data={baseProps.streams} />;
      }}
    </BaseTimeSeriesWidget>
  );
};

export default OeeWidget;*/

class OeeWidget extends React.Component {
  requestPending = false;

  render = () => (
    <OeeWidgetComponent {...this.props} setProps={this.setProps} />
  );

  setProps = (props) => {
    console.log("sending props", props);
    QueryService.sendProcessMessage(props).then((result) => {
      console.log("result after making query is", result);
      return result;
    });
  };
}

export default connectWidget(OeeWidget);
