import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { RangeQueriesService } from "services/RangeQueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import ISXUtils from "services/Utils";
import { TimeframeService } from "services/TimeframeService";
import TagsSubscriptionsContext from "services/TagsSubscriptionsService";
import TagEventIntervalsContext from "services/TagEventIntervalsService";

import { default as _last } from "lodash/last";
import { default as _isEqual } from "lodash/isEqual";
import { default as _pick } from "lodash/pick";

const DEFAULT_TIMEFRAME_INTERVAL = 10; // "auto" no longer supported, use this instead

const BaseTimeSeriesWidget = (props) => {
  const rawStreams = useSelector((state) => state.isx.streams);
  const tagEventIntervals = useSelector((state) => state.isx.tagEventIntervals);
  const stacks2orgs = useSelector((state) => state.isx.stacks2orgs);

  const [timeframe, setTimeframe] = useState(
    props.widget?.options?.timeframe ?? {}
  );
  const [loading, setLoading] = useState(props.widget?.tags?.length > 0);
  const [streams, setStreams] = useState({});

  const subscriptionsContext = useContext(TagsSubscriptionsContext);
  const eventIntervalsContext = useContext(TagEventIntervalsContext);

  const subscriptions = useRef({});
  const lastScheduleRunTime = useRef(0);

  const refreshPeriod = useRef(); // in ms

  const startTime = useRef(null);
  const endTime = useRef(null);
  const lastDurationInSeconds = useRef(null);

  const maxWindowInSeconds = useRef(TimeframeService.MAX_EVENTS_WINDOW);

  const lastTimeframe = useRef({});
  const lastLinearfn = useRef(null);
  const lastLinearfnParams = useRef({});

  const lastTagEventIntervals = useRef({});

  const dispatch = useDispatch();

  const _getSubscriptionStartTime = (timeseries, now) => {
    if (timeseries?.length > 0) {
      const lastTimestamp = _last(timeseries).x + 1;
      const startTime = moment.utc(lastTimestamp);
      if (now.diff(startTime, "seconds") < lastDurationInSeconds.current) {
        return lastTimestamp;
      }
    }
    return now
      .clone()
      .subtract(lastDurationInSeconds.current, "seconds")
      .add(refreshPeriod.current, "milliseconds")
      .valueOf();
  };

  const resolveRangeQueries = useCallback(
    async (promises, startTimeValue, refreshAll = false) => {
      const allResults = await Promise.all(promises);

      // map incoming data streams by key to merge with existing data
      let ustreams = {};
      const now = moment.utc();

      allResults.forEach((result) => {
        (result ?? []).forEach((entry) => {
          const {
            guuid,
            attr,
            data: timeseriesRaw,
            is_finalized: finalized,
          } = entry;
          const key = ISXUtils.createTagKeyFromStackAndAttr(guuid, attr);
          const timeseries = timeseriesRaw?.map(([x, y]) => ({ x, y })) ?? [];
          if (lastTimeframe.current.type === "moving" || !finalized) {
            const subId = subscriptionsContext.subscribe(
              key,
              _getSubscriptionStartTime(timeseries, now),
              refreshPeriod.current / 1000.0,
              {
                maxWindowInSeconds: maxWindowInSeconds.current,
                eventInterval: lastTagEventIntervals.current[key],
              }
            );
            subscriptions.current[key] = subId;
          }

          const linearFnValues = lastLinearfnParams.current[key] || {};
          if (timeseries) {
            const data = timeseries.map((d) => ({
              x: d.x,
              y: linearFnValues
                ? ISXUtils.linearFunction(
                    d.y,
                    linearFnValues.slope,
                    linearFnValues.intercept
                  )
                : d.y,
            }));
            data.sort((a, b) => a.x - b.x);
            ustreams[key] = data;
          } else {
            ustreams[key] = [];
          }
        });
      });

      setStreams(() => {
        let effectiveStartTimeValue = null;
        if (lastTimeframe.current.type === "moving") {
          const effectiveEndTimeValue = Math.max(
            ...Object.values(ustreams)
              .map((data) => _last(data)?.x)
              .filter(Boolean)
          );
          if (effectiveEndTimeValue) {
            endTime.current = moment.utc(effectiveEndTimeValue);
            startTime.current = moment
              .utc(endTime.current)
              .subtract(lastDurationInSeconds.current, "seconds");
            effectiveStartTimeValue = startTime.current.valueOf();
          }
        } else {
          effectiveStartTimeValue = startTimeValue;
        }
        if (effectiveStartTimeValue) {
          Object.entries(ustreams).forEach(([key, data]) => {
            let i = 0;
            for (; i < data.length; i++) {
              if (data[i].x >= effectiveStartTimeValue) {
                break;
              }
            }
            ustreams[key] = data.slice(i);
          });
        }
        return ustreams;
      });
      setLoading(false);
      return allResults;
    },
    [subscriptionsContext]
  );

  const lastTags = useRef([]);
  const lastStacks = useRef({});

  const { stacks, widget = {}, updateWidget } = props;

  useEffect(() => {
    // called on destruction to clear all subscriptions
    return () => {
      Object.values(subscriptions.current).forEach((subId) =>
        subscriptionsContext.unsubscribe(subId)
      );
      subscriptions.current = {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!startTime.current) {
      return;
    }

    const refreshPeriodInSeconds = refreshPeriod.current / 1000;

    const runtime = subscriptionsContext.getScheduleRuntime(
      refreshPeriodInSeconds
    );

    if (!runtime || runtime <= lastScheduleRunTime.current) {
      return;
    }
    lastScheduleRunTime.current = runtime;

    const startTimeValue = startTime.current.valueOf();
    const endTimeValue = endTime.current.valueOf();

    let ustreams = {};

    // get keys for all tags of interest to us
    // TODO: cache these?
    const stackAttrs = ISXUtils.unpackTags(
      lastTags.current,
      lastStacks.current
    );
    const keys = stackAttrs
      .map((entry) => {
        const st = entry.stack.guuid;
        const attrs = entry.attributes;
        return attrs.map((a) => ISXUtils.createTagKeyFromStackAndAttr(st, a));
      })
      .flat();
    keys.forEach((key) => {
      let timeseries = rawStreams[key];
      // should we update subscriptions here?
      const subId = subscriptions.current[key];
      if (!subId) {
        return;
      }

      const now = moment.utc();
      const earliestStartTime = _getSubscriptionStartTime(timeseries, now);
      // console.log("updating subscription", subId, key, earliestStartTime);
      subscriptionsContext.updateSubscription(subId, earliestStartTime);

      if (timeseries != null) {
        const linearFnValues = lastLinearfnParams.current[key] || {};
        if (timeseries.length > 0) {
          if (
            lastTimeframe.current.type === "fixed" &&
            _last(timeseries).x >= endTimeValue
          ) {
            timeseries = timeseries.filter((d) => d.x <= endTimeValue);
            console.log("unsubscribe", key);
            subscriptionsContext.unsubscribe(subId);
            delete subscriptions.current[key];
          }
          const data = timeseries.map((d) => ({
            x: d.x,
            y: linearFnValues
              ? ISXUtils.linearFunction(
                  d.y,
                  linearFnValues.slope,
                  linearFnValues.intercept
                )
              : d.y,
          }));
          data.sort((a, b) => a.x - b.x);
          ustreams[key] = data;
        } else {
          ustreams[key] = [];
        }
      }
    });

    if (Object.keys(ustreams).length === 0) {
      return;
    }

    setStreams((streams) => {
      // merge with existing data
      Object.entries(ustreams).forEach(([key, udata]) => {
        let data = streams[key] ?? [];
        let last = _last(data)?.x ?? 0;
        udata.forEach((d) => {
          if (d.x > last && d.x >= startTimeValue) {
            const { x, y } = d;
            data.push({ x, y });
            last = x;
          }
        });
        ustreams[key] = data;
      });
      let effectiveStartTimeValue = null;
      if (lastTimeframe.current.type === "moving") {
        const effectiveEndTimeValue = Math.max(
          ...Object.values(ustreams)
            .map((data) => _last(data)?.x)
            .filter(Boolean)
        );
        if (effectiveEndTimeValue) {
          endTime.current = moment.utc(effectiveEndTimeValue);
          startTime.current = moment
            .utc(endTime.current)
            .subtract(lastDurationInSeconds.current, "seconds");
          effectiveStartTimeValue = startTime.current.valueOf();
        }
      } else {
        effectiveStartTimeValue = startTime.current.valueOf();
      }
      if (effectiveStartTimeValue) {
        Object.entries(ustreams).forEach(([key, data]) => {
          let i = 0;
          for (; i < data.length; i++) {
            if (data[i].x >= effectiveStartTimeValue) {
              break;
            }
          }
          ustreams[key] = data.slice(i);
        });
      }
      return { ...streams, ...ustreams };
    });
  }, [rawStreams, subscriptionsContext]);

  useEffect(() => {
    // if widget does not specify timeframe, see if there is a default supplied
    // as a property
    const newTimeframe =
      widget.options?.timeframe ?? props.defaultTimeframe ?? {};
    const timeframeChanged = TimeframeService.timeframeChanged(
      newTimeframe,
      lastTimeframe.current ?? {}
    );
    if (timeframeChanged) {
      setTimeframe(newTimeframe);
    }
  }, [widget.options?.timeframe, props.defaultTimeframe]);

  useEffect(() => {
    const rangeQueriesLatestOverPeriod = async (tags, period, orgids) => {
      return RangeQueriesService.queryLatestOverPeriod(
        tags,
        period,
        orgids
      ).catch(() => {
        console.error("CAUGHT EXCEPTION");
      });
    };

    const rangeQueries = async (tags, startTime, endTime, orgids) => {
      return RangeQueriesService.query(
        tags,
        startTime,
        endTime,
        lastDurationInSeconds.current,
        orgids
      ).catch((err) => {
        console.error("CAUGHT EXCEPTION", err);
      });
    };

    const _batchKeys = (keys, window) => {
      const groups = [];
      let lastGroup = null;
      keys.forEach((tagKey) => {
        const eventInterval = lastTagEventIntervals.current[tagKey];
        const numEvents = window / eventInterval;
        if (
          lastGroup &&
          lastGroup.expectedEvents + numEvents <=
            TimeframeService.MAX_EVENTS_WINDOW
        ) {
          lastGroup.tagKeys.push(tagKey);
          lastGroup.expectedEvents += numEvents;
        } else {
          lastGroup = {
            tagKeys: [tagKey],
            expectedEvents: numEvents,
          };
          groups.push(lastGroup);
        }
      });
      return groups;
    };

    lastStacks.current = stacks;
    if (Object.keys(lastStacks.current).length === 0) {
      return;
    }

    if (Object.keys(stacks2orgs).length === 0) {
      return;
    }

    const newTags = widget.tags ?? [];
    const tagsChanged = !_isEqual(newTags, lastTags.current);
    if (tagsChanged) {
      lastTags.current = newTags;
    }

    const stackAttrs = ISXUtils.unpackTags(
      lastTags.current,
      lastStacks.current
    );

    // if any of the linearfn props have changed or if the tags changed, prompt
    // a refresh
    const curLinearfn = widget.options?.linearfn;
    const linearFnParamsChanged =
      curLinearfn?.type !== lastLinearfn.current?.type ||
      curLinearfn?.tagprop !== lastLinearfn.current?.tagprop ||
      curLinearfn?.slope !== lastLinearfn.current?.slope ||
      curLinearfn?.intercept !== lastLinearfn.current?.intercept;
    if (linearFnParamsChanged) {
      lastLinearfn.current = curLinearfn;
      lastLinearfnParams.current = ISXUtils.updateLinearFunctionParams(
        widget.options ?? {},
        stackAttrs
      );
    }

    const keys = stackAttrs
      .map((entry) => {
        const st = entry.stack.guuid;
        const attrs = entry.attributes;
        return attrs.map((a) => ISXUtils.createTagKeyFromStackAndAttr(st, a));
      })
      .flat();

    // check if event intervals have changed, or we need to fetch them; should
    // only expect a change when new intervals are fetched and added
    // find the intervals for tags we care about
    let eventIntervalsChanged = false;
    const relevantTagEventIntervals = _pick(tagEventIntervals, keys);
    // change if the intervals we previously had have changed, or we've
    // introduced new ones (or removed)
    eventIntervalsChanged =
      !_isEqual(relevantTagEventIntervals, lastTagEventIntervals.current) ||
      keys.length !== Object.keys(relevantTagEventIntervals).length;
    if (eventIntervalsChanged) {
      lastTagEventIntervals.current = relevantTagEventIntervals;
      // what is missing - need to register interest to cause fetch
      const missingIntervals = keys.filter((key) => !tagEventIntervals[key]);
      if (missingIntervals.length > 0) {
        eventIntervalsContext.registerInterest(missingIntervals);
        // short-circuit and wait for interval fetches to complete
        return;
      }
      if (!props.fixedWindow) {
        // need to determine max window across all tags; get lowest interval, as
        // that will control the rest
        const allIntervals = [...Object.values(lastTagEventIntervals.current)];
        const cinterval =
          allIntervals.length > 0 ? Math.min(...allIntervals) : 1;
        maxWindowInSeconds.current =
          TimeframeService.MAX_EVENTS_WINDOW * cinterval;
      } else {
        // fixed window, so window can be as large as needed
        maxWindowInSeconds.current = Number.MAX_VALUE;
      }
    }

    const timeframeChanged = TimeframeService.timeframeChanged(
      timeframe,
      lastTimeframe.current ?? {}
    );

    if (timeframeChanged || eventIntervalsChanged) {
      lastTimeframe.current = timeframe;
      lastDurationInSeconds.current = Math.min(
        TimeframeService.getDurationInSeconds(
          timeframe["length.value"],
          timeframe["length.units"]
        ),
        maxWindowInSeconds.current
      );
      if (lastTimeframe.current.type === "fixed") {
        startTime.current = moment.utc(timeframe.start);
        endTime.current = moment
          .utc(startTime.current)
          .add(lastDurationInSeconds.current, "seconds");
      }
      refreshPeriod.current =
        (lastTimeframe.current["interval.type"] !== "auto"
          ? TimeframeService.getDurationInSeconds(
              lastTimeframe.current["interval.value"],
              lastTimeframe.current["interval.units"]
            )
          : DEFAULT_TIMEFRAME_INTERVAL) * 1000;
    }

    // if significant change in props, reset all; could optimize, but this
    // will not likely occur that often
    if (
      timeframeChanged ||
      tagsChanged ||
      eventIntervalsChanged ||
      linearFnParamsChanged
    ) {
      // make sure we have valid timeframe first
      if (lastTimeframe.current.type) {
        // clear all subscriptions, as these will be rebuilt
        Object.values(subscriptions.current).forEach((subId) =>
          subscriptionsContext.unsubscribe(subId)
        );
        lastScheduleRunTime.current = Date.now();
        subscriptions.current = {};

        setLoading(true);
        let promises;
        if (lastTimeframe.current.type === "fixed") {
          const startTimeValue = startTime.current.valueOf();
          const endTimeValue =
            endTime.current?.valueOf() ?? moment.utc().valueOf();
          const window = (endTimeValue - startTimeValue) / 1000;
          const groups = _batchKeys(keys, window);
          promises = groups.map(({ tagKeys }) => {
            const tags = ISXUtils.tagKeysToTags(tagKeys);
            const orgids = ISXUtils.getOrgsForTags(tags, stacks2orgs);
            return rangeQueries(tags, startTimeValue, endTimeValue, orgids);
          });
        } else {
          // moving time period
          const groups = _batchKeys(keys, lastDurationInSeconds.current);
          promises = groups.map(({ tagKeys }) => {
            const tags = ISXUtils.tagKeysToTags(tagKeys);
            const orgids = ISXUtils.getOrgsForTags(tags, stacks2orgs);
            return rangeQueriesLatestOverPeriod(
              tags,
              lastDurationInSeconds.current * 1000,
              orgids
            );
          });
        }
        if (promises.length > 0) {
          (async () => {
            // on resolution will subscribe to tags as needed
            await resolveRangeQueries(promises, null, true);
          })();
        } else {
          setStreams({});
        }
      }
    }
  }, [
    stacks,
    widget,
    resolveRangeQueries,
    timeframe,
    subscriptionsContext,
    dispatch,
    tagEventIntervals,
    eventIntervalsContext,
    props.defaultTimeframeInterval,
    props.fixedWindow,
    stacks2orgs,
  ]);

  return (
    <>
      {props.children({
        widget,
        stacks,
        startTime: startTime.current,
        endTime: endTime.current,
        streams,
        updateWidget,
        setTimeframe,
        timeframe,
        maxWindowInSeconds: maxWindowInSeconds.current,
        loading,
        removeWidget: props.removeWidget,
        readOnly: props.readOnly,
      })}
    </>
  );
};

export default connectWidget(BaseTimeSeriesWidget);
