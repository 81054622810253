import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "50%",
  },
}));

const errorMessages = {
  rangeMinMax: "Please enter a minimum that is lower than the maximum",
  rangeFieldCompletion: "Please enter both a minimum and a maximum",
};

const RangeOption = (props) => {
  const { options, setOptions, errorDetected, noErrorDetected } = props;
  const classes = useStyles();

  const range = options.range;
  if (!range.type) {
    if (range.min && range.max) {
      range.type = "custom";
    } else {
      range.type = "tag";
    }
  }

  const errorMessage = () => {
    let errorFound = false;
    for (let error in props.errors) {
      if (error.includes("range") && !errorFound) {
        errorFound = true;
        return (
          <p style={{ color: "red", fontSize: "10px" }}>
            {errorMessages[error]}
          </p>
        );
      }
    }
  };

  const validations = useCallback(
    (min, max) => {
      let currentMin = min != null ? Number(min) : Number(range.min);
      let currentMax = max != null ? Number(max) : Number(range.max);
      if (min === "") currentMin = null;
      if (max === "") currentMax = null;

      if (
        currentMin != null &&
        currentMax != null &&
        currentMin >= currentMax
      ) {
        errorDetected("rangeMinMax");
      } else {
        noErrorDetected("rangeMinMax");
      }
      if (currentMax == null) {
        errorDetected("rangeFieldCompletion");
      } else if (currentMin == null) {
        errorDetected("rangeFieldCompletion");
      } else {
        noErrorDetected("rangeFieldCompletion");
      }
    },
    [range.min, range.max, errorDetected, noErrorDetected]
  );

  useEffect(() => {
    if (range.type === "custom") {
      validations(range.min, range.max);
    }
  }, [validations, range.min, range.max, range.type]);

  return (
    <FormControl
      fullWidth={true}
      style={{
        flexDirection: "row",
        margin: 24,
        width: "calc(100% - 48px)",
      }}
    >
      <FormControl style={{ margin: 6, flexDirection: "column" }}>
        <FormLabel
          style={{ fontSize: 14, fontStyle: "italic", margin: 6 }}
          component="legend"
        >
          Range
        </FormLabel>
        <RadioGroup
          aria-label="rangetype"
          name="range"
          value={range.type}
          onChange={(event) => {
            const value = event.target.value;
            noErrorDetected("rangeFieldCompletion");
            noErrorDetected("rangeMinMax");
            setOptions({ range: { ...range, type: value } });
            //this.validations(null, null, event.target.value);
          }}
        >
          <FormControlLabel
            value="tag"
            control={<Radio />}
            label="Use Tag Settings"
          />
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        </RadioGroup>
      </FormControl>
      {range.type === "custom" && (
        <FormControl style={{ margin: 6, flexDirection: "row" }}>
          <TextField
            className={classes.textField}
            id="min-standard-number"
            label="Minimum"
            value={range.min || ""}
            onChange={(event) => {
              const val = event.target.value;
              setOptions({
                range: { ...range, min: val },
              });
            }}
            type="number"
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />

          <TextField
            className={classes.textField}
            id="max-standard-number"
            label="Maximum"
            value={range.max || ""}
            onChange={(event) => {
              const val = event.target.value;
              setOptions({
                range: { ...range, max: val },
              });
              validations(null, event.target.value);
            }}
            type="number"
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
        </FormControl>
      )}
      {range.type === "custom" && errorMessage()}
    </FormControl>
  );
};

export default RangeOption;
