import React from "react";
import { LubeCartridgeWidget as LubeCartridgeWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";

class LubeCartridgeWidget extends React.Component {
  requestPending = false;

  render = () => (
    <LubeCartridgeWidgetComponent
      {...this.props}
      updateVolume={this.updateVolume}
    />
  );
  updateVolume = (stackid, tag, tcount) => {
    const pmsg = [
      {
        name: "update_lube_count",
        tag: tag,
        value: tcount,
        absolute: true,
      },
    ];
    const msgtosend = {
      access_key: this.props.stacks?.[stackid]?.access_key,
      guuid: stackid,
      op: "update",
      process: pmsg,
    };
    const results = QueryService.sendProcessMessage(msgtosend);
    return results;
  };
}

export default connectWidget(LubeCartridgeWidget);
