//red green yellow black gray blue orange purple
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import orange from "@material-ui/core/colors/orange";
import purple from "@material-ui/core/colors/purple";

export const WIDGET_COLORS = {
  blue: blue[500],
  red: red[500],
  yellow: yellow[600],
  green: green[500],
  orange: orange[600],
  magenta: red.A400,
  purple: purple[500],
  grey: grey[500],
  darkgrey: grey[800],
  black: grey[900],
  white: "#ffffff",
};

export const WIDGET_COLORS_DARKGREY = grey[800];

export const WIDGET_COLORS_ARRAY = Object.values(WIDGET_COLORS);

export const WIDGET_OFF_ON_COLORS_ARRAY = [red[500], green[500]];

export const STATUS_COLORS = {
  good: green[500],
  warning: yellow[600],
  bad: red.A400,
};
