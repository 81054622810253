import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/SaveAlt";

import TagRoleProperties from "components/TagConfig/TagRoleProperties";
import { updateItem as updateItemOp } from "store/operations";
import { ErrorProvider, ErrorContext } from "services/Utils";
import ISXUtils from "services/Utils";

import { default as _head } from "lodash/head";
import { default as _set } from "lodash/set";
import { default as _isEmpty } from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    borderRadius: 8,
    borderColor: "lightgray",
    borderWidth: 3,
    borderStyle: "solid",
  },
  dialogPaper: {
    width: "100%",
    maxWidth: 960,
  },
  dialogTitleHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dialogContents: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    paddingLeft: 36,
    paddingRight: 36,
  },
  detailStatusCell: {
    height: "1rem",
    width: "4rem",
  },
  alertsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  alertsActionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  alertsAction: {
    marginLeft: 3,
    marginRight: 3,
  },
  alertItemsContainer: {
    height: "100%",
    paddingLeft: 4,
    paddingRight: 4,
    overflow: "auto",
    borderTop: "1px dotted lightgray",
  },
  statusCell: {
    height: "1em",
    width: "3em",
  },
  propertyNameCell: {
    width: "30%",
    fontWeight: "bold",
  },
  propertyValueCell: {
    width: "70%",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const SaveButton = (props) => {
  const classes = useStyles();

  const errorContext = useContext(ErrorContext);

  return (
    <Button
      className={classes.alertsAction}
      color="primary"
      onClick={props.save}
      startIcon={<SaveIcon />}
      disabled={Object.values(errorContext.errors).some(Boolean)}
    >
      Save
    </Button>
  );
};

const TagRolePropertiesDialog = (props) => {
  const [tagProperties, setTagProperties] = useState({});
  const [dirty, setDirty] = useState({});
  const [warningToastOpen, setWarningToastOpen] = useState(false);
  const [confirmToastOpen, setConfirmToastOpen] = useState(false);
  const stacks = useSelector((state) => state.isx.stacks);
  const lastTagId = useRef(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  const { open, setOpen, tag, role } = props;

  const stack = stacks?.[tag?.stack];

  useEffect(() => {
    if (tag && tag.guuid !== lastTagId.current) {
      setTagProperties(stack.data_config?.[tag.attribute] ?? {});
      lastTagId.current = tag.guuid;
    }
  }, [stack.data_config, tag]);

  const handleClose = () => {
    if (Object.keys(dirty).length === 0) {
      setOpen(false);
    } else {
      setWarningToastOpen(true);
    }
  };

  const handleTagPropChange = (tagId, prop, value) => {
    const propPath = prop.split(".");
    const headProp = _head(propPath);
    setTagProperties((tagProperties) => {
      return _set({ ...tagProperties }, propPath, value);
    });
    setDirty((dirty) => {
      return { ...dirty, [headProp]: true };
    });
  };

  const handleTagPropsChange = (tagId, tagProps) => {
    setTagProperties((tagProperties) => {
      return {
        ...tagProperties,
        ...tagProps,
      };
    });
    setDirty((dirty) => {
      const dirtyProps = Object.keys(tagProps).reduce((acc, p) => {
        acc[p] = true;
        return acc;
      }, {});
      return { ...dirty, ...dirtyProps };
    });
  };

  const updateItem = (guuid, contents) => {
    dispatch(updateItemOp(guuid, contents));
  };

  async function saveTagPropertiesToDB() {
    const tagAttributeEscaped = ISXUtils.escapeTagPeriods(tag.attribute);
    const update = Object.entries(dirty).reduce((acc, [prop, flag]) => {
      if (flag) {
        const val = (tagProperties ?? {})[prop];
        const key = `data_config.${tagAttributeEscaped}.${prop}`;
        acc[key] = val;
      }
      return acc;
    }, {});

    if (!_isEmpty(update)) {
      //   console.log("UPDATE", stack.guuid, update);
      await updateItem(stack.guuid, update);
    }
    setDirty({});
    setConfirmToastOpen(true);
  }

  const reset = () => {
    setTagProperties(stack.data_config?.[tag.attribute] ?? {});
    setDirty({});
  };

  if (tag) {
    const label =
      (tag &&
        stack &&
        (stack?.name || JSON.parse(stack.dev_session ?? "{}")["stack.label"]) +
          ":" +
          ISXUtils.getTagDisplayText(tag.attribute, stack.data_config)) ||
      "";

    return (
      <ErrorProvider>
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle>
            <div className={classes.dialogTitleHeader}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {label}
                <Typography variant="overline">{role.name}</Typography>
              </div>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContents}>
            <TagRoleProperties
              tagId={tag.attribute}
              tagProperties={tagProperties}
              role={role}
              handleTagPropChange={handleTagPropChange}
              handleTagPropsChange={handleTagPropsChange}
              readOnly={false}
            />
          </DialogContent>
          <DialogActions>
            {Object.values(dirty).some(Boolean) ? (
              <>
                <SaveButton save={saveTagPropertiesToDB} />
                <Tooltip
                  title="Current changes will be discarded"
                  placement="top"
                >
                  <Button
                    className={classes.alertsAction}
                    style={{ color: "red" }}
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Button
                className={classes.alertsAction}
                style={{ color: "red" }}
                onClick={handleClose}
              >
                Close
              </Button>
            )}
          </DialogActions>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={warningToastOpen}
            autoHideDuration={3000}
            onClose={() => setWarningToastOpen(false)}
            classes={{
              root: classes.error,
            }}
            message="Updates not saved. Please save or cancel."
          />
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={confirmToastOpen}
            autoHideDuration={3000}
            onClose={() => setConfirmToastOpen(false)}
            message="Updated tag properties saved"
          />
        </Dialog>
      </ErrorProvider>
    );
  } else {
    return null;
  }
};

export default TagRolePropertiesDialog;
