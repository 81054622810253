import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ResetPassword as ResetPasswordComponent } from "../../components";
import auth from "services/Auth";

const ResetPassword = (props) => {
  const [username, setUsername] = useState("");
  const [newpassword, setNewassword] = useState("");
  const [newpasswordAgain, setNewasswordAgain] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  // const [mfa, setMfa] = useState(false);
  const [mfactor, setMfactor] = useState("");
  const [successful, setSuccessful] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const username = params.get("username");
    const mfactor = params.get("change");
    setUsername(username);
    setMfactor(mfactor);
  }, [location]);

  useEffect(() => {
    const error =
      newpassword && newpasswordAgain && newpassword !== newpasswordAgain
        ? "Passwords must match"
        : "";
    setError(error);
  }, [newpassword, newpasswordAgain]);

  const handleNewPassword = (e) => {
    const val = e.target.value;
    setNewassword(val);
  };

  const handleNewPasswordAgain = (e) => {
    const val = e.target.value;
    setNewasswordAgain(val);
  };

  const attemptChangePassword = () => {
    if (disabled) {
      return;
    }

    if (
      username &&
      mfactor &&
      newpassword &&
      newpassword === newpasswordAgain
    ) {
      setError("");
      setDisabled(true);
      auth
        .changepass(username, "", mfactor, newpassword)
        .then((rv) => {
          if (!rv) {
            setError("Change password attempt failed.");
            setDisabled(false);
          } else {
            // success, back to login page
            setSuccessful(true);
            setTimeout(() => {
              window.location.hash = "#/login";
            }, 3000);
          }
        })
        .catch((err) => {
          setError(
            err.Message
              ? `Change password failed; ${err.Message}`
              : `Error on change password; please contact an administrator`
          );
          setDisabled(false);
        });
    } else if (!error) {
      setError("A valid new password is required.");
    }
  };

  return (
    <ResetPasswordComponent
      attemptChangePassword={attemptChangePassword}
      username={username}
      mfactor={mfactor}
      handleNewPassword={handleNewPassword}
      handleNewPasswordAgain={handleNewPasswordAgain}
      error={error}
      disabled={disabled}
      successful={successful}
    />
  );
};

export default ResetPassword;
