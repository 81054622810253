import React, { useState,useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { blue } from "@material-ui/core/colors";

import TagRolePropertiesDialog from "components/TagRolePropertiesDialog";
import { STATES_ROLE } from "services/RolesService";
import ISXUtils from "services/Utils";

import { default as _pick } from "lodash/pick";

const useStyles = makeStyles({
  openIcon: {
    color: blue[500],
    cursor: "pointer",
  },
});

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

const PeriodsTrackingTagEntry = ({
  tag,
  trackingOptions,
  setTagTrackingOption,
  stack,
}) => {
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);

  const classes = useStyles();

  //const stack = stacks[tag.stack];
  const trackingType = trackingOptions?.trackingType ?? "states";
  const summaryUnit = trackingOptions?.summaryUnit ?? "percent";
  const labels = Array.from(new Set(
    (stack?.data_config?.[tag.attribute]?.states || []).map((s) => s.label)
  ));
  const allLabels = labels.concat(['No Data']);
  
  const [displayOptions,setDisplayOptions] = useState(trackingOptions?.display ?? {"intervals":{enabled:true,labels:[...labels]},"transitions":{enabled:true,labels:[...labels]}});

  useEffect(() => {
    if(((tag?.stack ?? "")+(tag?.attribute ?? "")) !== (trackingOptions?.key ?? "")) {
      
      const newDisplayOptions = {"intervals":{enabled:true,labels:[...labels]},"transitions":{enabled:true,labels:[...labels]}};
      setDisplayOptions(newDisplayOptions);
      setTagTrackingOption([{target:{name:"display",value:newDisplayOptions}},{target:{name:"key",value:(tag.stack+tag.attribute)}}]);
    }
  }, [tag]);

  const setEnable = (event) => {
    const { name, checked } = event.target;
    let newDisplayOptions = { ...displayOptions };
    newDisplayOptions[name].enabled = checked;
    setDisplayOptions(newDisplayOptions);
    setTagTrackingOption([{target:{name:"display",value:newDisplayOptions}}]);
  }

  const setLabel = (bucket,event) => {
    const { name, checked } = event.target;
    let newDisplayOptions = { ...displayOptions };
    const idx = (newDisplayOptions[bucket]?.labels ?? []).indexOf(name)
    if(checked) {
      if (idx === -1) {
        newDisplayOptions[bucket]?.labels.push(name);
      }
    }
    else {
      if (idx !== -1) {
        newDisplayOptions[bucket]?.labels.splice(idx,1);
      }
    }
    setDisplayOptions(newDisplayOptions);
    setTagTrackingOption([{target:{name:"display",value:newDisplayOptions}}]);
  }

  return (
    <Grid item container spacing={1} xs={12}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }}>
          {(stack?.name || JSON.parse(stack?.dev_session ?? "{}")["stack.label"]) +
            ":" +
            ISXUtils.getTagDisplayText(tag.attribute, stack.data_config)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="select-periods-tracking"
          name="trackingType"
          select
          label="Reporting"
          value={trackingType}
          onChange={(event) => setTagTrackingOption([event])}
        >
          <MenuItem key="counts" value="counts">
            Counts
          </MenuItem>
          <MenuItem key="states" value="states">
            States
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={8}>
        {trackingType === "states" && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="caption"
                style={{ fontSize: "0.66rem", fontStyle: "italic" }}
              >
                The state levels are configured in the Device Tags properties.
              </Typography>
              <InfoTooltip
                title="Click to view/modify states properties"
                onClick={(e) => {
                  setRoleDialogOpen(true);
                }}
              >
                <OpenInNewIcon fontSize="small" className={classes.openIcon} />
              </InfoTooltip>
            </div>
            <div> With Intervals
            <Switch
            name = "intervals"
      checked={displayOptions?.intervals?.enabled ?? false}
      onChange={(event) => setEnable(event)}
    /> As
            <RadioGroup
              aria-label="summaryUnit"
              name="summaryUnit"
              value={summaryUnit}
              onChange={(event) => setTagTrackingOption([event])}
              row
            >
              <FormControlLabel
                value="percent"
                control={<Radio />}
                label="Percentage of time"
              />
              <FormControlLabel
                value="time"
                control={<Radio />}
                label="Total time"
              />
            </RadioGroup>
            </div>
            <FormControl component="fieldset">
      { displayOptions?.intervals?.enabled && labels.length > 0 && (
        <>
      <FormLabel component="legend">Display</FormLabel>
      
      <FormGroup row>
        { allLabels.map((label) => (
        <FormControlLabel
          control={<Checkbox
            name = {label}
      checked={(displayOptions?.intervals?.labels ?? []).includes(label)}
      onChange={(event) => setLabel("intervals",event)} />}
          label={label}
        />))}
      </FormGroup>
      </>
    )}
    </FormControl>
    
    <div> With Transitions
            <Switch
            name = "transitions"
      checked={displayOptions?.transitions?.enabled ?? false}
      onChange={(event) => setEnable(event)}
    />
    </div>
    <FormControl component="fieldset">
    { displayOptions?.transitions?.enabled && labels.length > 0 && (
        <>
      <FormLabel component="legend">Display</FormLabel>
      
      <FormGroup row>
        { allLabels.map((label) => (
        <FormControlLabel
          //value="end"
          control={<Checkbox
            name = {label}
      checked={(displayOptions?.transitions?.labels ?? []).includes(label)}
      onChange={(event) => setLabel("transitions",event)} />}
          label={label}
        />))}
      </FormGroup>
      </>
    )}
    
    </FormControl>
    
       
            {roleDialogOpen && (
              <TagRolePropertiesDialog
                open={roleDialogOpen}
                setOpen={setRoleDialogOpen}
                tag={tag}
                role={STATES_ROLE}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const ReportingDisplayOption = (props) => {
  const {
    options = {},
    setOptions,
    // widget = {},
    tagsSelected,
    stacks = {},
  } = props;
  const { "periods.tracking": tracking = {} } = options;
  const tags = Object.values(tagsSelected ?? {});
  // const { tags = [] } = widget;
  const setTagTrackingOption = (guuid) => (events) => {
    let trackingEntry = tracking[guuid];
    events.forEach((e) => { 
    const { name, value } = e.target;
    trackingEntry = trackingEntry ? { ...trackingEntry } : {};
    trackingEntry[name] = value;
    })
    // filter out stale tags
    const newTracking = _pick(
      tracking,
      tags.map((t) => t.guuid)
    );
    newTracking[guuid] = trackingEntry;
    setOptions({ "periods.tracking": newTracking });
  };

  return (
    <FormControl
      margin="dense"
      style={{ width: "calc(100% - 48px)", margin: 24 }}
    >
      <FormLabel component="legend">Display Options</FormLabel>
      <div style={{ marginTop: 16 }}>
        <Grid container spacing={3}>
          {tags.map((t) => (
            <PeriodsTrackingTagEntry
              key={t.guuid}
              tag={t}
              stack={stacks[t.stack]}
              trackingOptions={tracking[t.guuid]}
              setTagTrackingOption={setTagTrackingOption(t.guuid)}
            />
          ))}
        </Grid>
      </div>
    </FormControl>
  );
};

export default ReportingDisplayOption;
