import React, { useState, useCallback, useEffect } from "react";
import { OrganizationManagementWidget as OrganizationManagementWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { getOrgs } from "store/operations";
import { useDispatch, useSelector } from "react-redux";
import { connectWidget } from "../../widget-connector/WidgetConnector";
const ISX_GENERIC_EVENTS_TAG = "isxreserved|events";
const OrganizationManagementWidget = (props) => {
  const [organizationTree, setOrganizationTree] = useState([]);
  const orgs = useSelector((state) => state.isx.orgs);

  const dispatch = useDispatch();

  const createOrUpdateUsers = (orgId, users) => {
    const { username, password, user } = props;
    if ("orgs" in user && user.orgs.length > 0 && "orgid" in user.orgs[0]) {
      return QueryService.createOrUpdateUsers(username, password, orgId, users);
    }
  };

  const logUserEvent = (guuid, access_key, msg) => {
    const msgtosend = {
      guuid: guuid,
      access_key: access_key,
      op: "update",
      st: {
        [ISX_GENERIC_EVENTS_TAG]: [[Date.now(), msg]],
      },
    };
    return QueryService.sendProcessMessage(msgtosend);
  };

  const inviteUsers = (orgId, invites) => {
    const { username, password, user } = props;
    if ("orgs" in user && user.orgs.length > 0 && "orgid" in user.orgs[0]) {
      return QueryService.inviteUsers(username, password, orgId, invites);
    }
  };

  const getInvites = (orgid, filterstatus = "") => {
    const { username, password } = props;
    return QueryService.getInvites(username, password, orgid, filterstatus);
  };

  const removeUsers = (orgid, users) => {
    const { username, password } = props;
    return QueryService.removeOrgUsers(username, password, orgid, users);
  };

  const createOrg = (orgitem) => {
    const { username, password } = props;
    return QueryService.createOrg(username, password, orgitem);
  };

  const areOrganizationsDifferent = useCallback(
    (oldOrganization, newOrganization) => {
      const oldPendingList = oldOrganization.pendingusers || [];
      const newPendingList = newOrganization.pendingusers || [];
      return (
        oldOrganization.guuid !== newOrganization.guuid ||
        oldOrganization.name !== newOrganization.name ||
        oldOrganization.users.length !== newOrganization.users.length ||
        oldOrganization.users.some(
          (user, userIndex) =>
            user.userid !== newOrganization.users[userIndex].userid
        ) ||
        oldOrganization.devices.length !== newOrganization.devices.length ||
        oldOrganization.devices.some(
          (device, devIndex) =>
            device.guuid !== newOrganization.devices[devIndex].guuid ||
            device.st_time !== newOrganization.devices[devIndex].st_time ||
            device.lastbpuptime !==
              newOrganization.devices[devIndex].lastbpuptime
        ) ||
        oldOrganization.orgs.length !== newOrganization.orgs.length ||
        oldOrganization.orgs.some((org, orgIndex) =>
          areOrganizationsDifferent(org, newOrganization.orgs[orgIndex])
        ) ||
        oldPendingList.length !== newPendingList.length ||
        oldPendingList.some(
          (invite, pendIndex) =>
            invite.guuid !== newPendingList[pendIndex].guuid
        )
      );
    },
    []
  );

  useEffect(() => {
    if (orgs && props.stacks) {
      const organizationDetails = Object.values(orgs).reduce(
        (organizationInfo, organization) => {
          organizationInfo.set(organization.guuid, organization);
          return organizationInfo;
        },
        new Map()
      );

      const updatedDataOrganizations = Object.values(orgs).map((org) => {
        return {
          ...org,
          orgs:
            org?.orgs
              ?.map((id) => organizationDetails.get(id))
              .filter(Boolean) ?? [],
          devices: org.devices
            .map((id) => props.stacks[id])
            .filter((dev) => !!dev)
            .map((dev) => {
              return {
                guuid: dev.guuid,
                dev_session: dev.dev_session,
                data_config: dev.data_config,
                lctime: dev.lctime,
                lastbpuptime: dev.lastbpuptime,
                st_errors: dev.st_errors,
              };
            }),
        };
      });

      const topLevel = updatedDataOrganizations.filter(
        (org) => !org.parent || !organizationDetails.has(org.parent)
      );

      setOrganizationTree((organizationTree) => {
        if (
          organizationTree.length !== topLevel.length ||
          organizationTree.some((org, index) =>
            areOrganizationsDifferent(org, topLevel[index])
          )
        ) {
          return topLevel;
        } else {
          return organizationTree;
        }
      });
    }
  }, [areOrganizationsDifferent, orgs, props.stacks]);

  const populateOrganizationTree = () => {
    const orgids = props.user?.orgs?.map((org) => org.orgid);
    if (orgids) {
      dispatch(getOrgs(orgids)).catch((e) => {
        console.warn("getOrgs exception", e);
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <OrganizationManagementWidgetComponent
        {...props}
        configurable={false}
        userid={props.user.userid}
        organizationTree={organizationTree}
        inviteUsers={inviteUsers}
        createOrUpdateUsers={createOrUpdateUsers}
        logUserEvent={logUserEvent}
        getInvites={getInvites}
        removeUsers={removeUsers}
        createOrg={createOrg}
        populateOrganizationTree={populateOrganizationTree}
      />
    </div>
  );
};

export default connectWidget(OrganizationManagementWidget);
