import React from "react";
import { MessagesDialog as MessagesDialogComponent } from "components";
import ISXUtils from "services/Utils";
import { createDashboard, removeUserMessage } from "store/operations";
import { useSelector, useDispatch } from "react-redux";

export default (props) => {
  const messages = useSelector((state) => state.isx.messages);

  const dispatch = useDispatch();

  async function copyDashboardTemplate(template, sender) {
    const dashboard = ISXUtils.copyDashboardAsTemplate(template, sender);
    const rv = await dispatch(createDashboard(dashboard));
    return rv.guuid;
  }

  const removeMessage = (guuid) => {
    dispatch(removeUserMessage(guuid));
  };

  return (
    <MessagesDialogComponent
      {...props}
      messages={messages}
      copyDashboardTemplate={copyDashboardTemplate}
      removeMessage={removeMessage}
    />
  );
};
