import { utils, writeFile } from "xlsx";
import moment from "moment";

const CSV_TIMESTAMP_HEADERS = ["Timestamp ISO 8601", "Timestamp"];
const DATE_FORMAT = "YYYY-MM-DD-HHmmss";
// const DATE_FORMAT = "DD MMM YYYY HH-mm-ss";

const CSVExporter = {
  formatFilename: (title, start, end) => {
    return `${title.replace(/\s+/g, "").replace(/,/g, "-")}--${moment(
      start
    ).format(DATE_FORMAT)}--${moment(end).format(DATE_FORMAT)}.csv`;
  },

  timestampColumns: (timestampUnix) => {
    const timestamp = moment(timestampUnix);
    return [timestamp.format(), timestamp.format("YYYY/MM/DD HH:mm:ss")];
  },

  export: (filename = "export.csv", data = []) => {
    const wb = utils.book_new();
    const sheet = utils.aoa_to_sheet(data);
    utils.book_append_sheet(wb, sheet, "sheet");
    writeFile(wb, filename);
  },
};

// const csvExporter = (filename = "export.csv", data = []) => {
//   const wb = XLSX.utils.book_new();
//   // const sheet = XLSX.utils.json_to_sheet(data);
//   const sheet = XLSX.utils.aoa_to_sheet(data);
//   XLSX.utils.book_append_sheet(wb, sheet, "sheet");
//   XLSX.writeFile(wb, filename);
//   // const columnDelimiter = ",";
//   // const lineDelimiter = "\n";
//   // let lines = [headers.join(columnDelimiter)];

//   // data.forEach(cur => {
//   //   const row = headers.map(
//   //     hid => '"' + (cur[hid] || "").replace(/"/g, '""') + '"'
//   //   );
//   //   lines.push(row.join(columnDelimiter));
//   // });

//   // lines = lines.join(lineDelimiter);

//   // if (navigator.msSaveBlob) {
//   //   // for IE...
//   //   var blob = new Blob([csv], { type: "data:text/csv;charset=utf8;" });
//   //   navigator.msSaveBlob(blob, filename);
//   // } else {
//   //   if (!csv.match(/^data:text\/csv/i))
//   //     csv = "data:text/csv;charset=utf-8," + csv;
//   //   const data = encodeURI(csv);
//   //   let link = document.createElement("a");
//   //   link.setAttribute("href", data);
//   //   link.setAttribute("download", filename);
//   //   document.body.appendChild(link);
//   //   link.click();
//   //   link.remove();
//   // }
// };

export default CSVExporter;
export { CSV_TIMESTAMP_HEADERS };
