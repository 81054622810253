import React, { useEffect, useState } from "react";
import QueryService from "services/QueryService";
import { ShareDashboardDialog as ShareDashboardDialogComponent } from "components";
import { getDashboard } from "store/operations";
import { useDispatch, useSelector } from "react-redux";

const ShareDashboardDialog = (props) => {
  const orgs = useSelector((state) => state.isx.orgs);
  const [orgData, setOrgData] = useState([]);

  const dispatch = useDispatch();

  const { open, dashboard } = props;

  useEffect(() => {
    if (open) {
      setOrgData(
        Object.values(orgs)?.sort((a, b) => (a.name < b.name ? -1 : 1)) ?? []
      );
    }
  }, [orgs, open]);

  async function share(orgids) {
    const results = await QueryService.shareDashboard(dashboard.guuid, orgids);
    const { status, error } = results;
    // force fetch dashboard again to get updated dashboard state
    dispatch(getDashboard(dashboard.guuid, true)).catch((e) => {
      console.error(e);
    });
    return { status, error };
  }

  return (
    <ShareDashboardDialogComponent {...props} share={share} groups={orgData} />
  );
};

export default ShareDashboardDialog;
