import React, { useState, useRef, useEffect, useCallback } from "react";
import Widget from "../../widget/Widget";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";

import OrganizationManagementOrganization from "./OrganizationManagementOrganization";
import OrganizationManagementRemoveUserDialog from "./OrganizationManagementRemoveUserDialog";
import OrganizationManagementCreateOrganizationDialog from "./OrganizationManagementCreateOrganizationDialog";
import OrganizationManagementInviteUsersDialog from "./OrganizationManagementInviteUsersDialog";
import OrganizationManagementCreateUsersDialog from "./OrganizationManagementCreateUsersDialog";

const OrganizationManagementWidget = (props) => {
  const [expandedOrgs, setExpandedOrgs] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openRemoveUsersConfirmDialog, setOpenRemoveUsersConfirmDialog] =
    useState(false);
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] =
    useState(false);
  const [
    createOrganizationDialogOrganizationId,
    setCreateOrganizationDialogOrganizationId,
  ] = useState(null);
  const [openInviteUsersConfirmDialog, setOpenInviteUsersConfirmDialog] =
    useState(false);
  const [inviteUserDialogOrganizationId, setInviteUserDialogOrganizationId] =
    useState(null);
  const [openCreateUsersConfirmDialog, setOpenCreateUsersConfirmDialog] =
    useState(false);
  const [createUserDialogOrganizationId, setCreateUserDialogOrganizationId] =
    useState(null);
  const [
    createUserDialogOrganizationName,
    setCreateUserDialogOrganizationName,
  ] = useState(null);

  const createOrganizationDialogRef = useRef();
  const inviteUsersDialogRef = useRef();
  const createUsersDialogRef = useRef();

  const lastOrganizationTree = useRef();
  const lastWidgetOptions = useRef();
  const filters = useRef();

  const collapseAll = useCallback(() => {
    if (!!props.organizationTree && props.organizationTree.length > 0) {
      setExpandedOrgs([props.organizationTree[0].guuid]);
    }
  }, [props.organizationTree]);

  useEffect(() => {
    const oldTree = lastOrganizationTree.current;
    const newTree = props.organizationTree;
    if ((!oldTree || oldTree.length === 0) && !!newTree && newTree.length > 0) {
      collapseAll();
    }
    lastOrganizationTree.current = newTree;
  }, [collapseAll, props.organizationTree]);

  const expandAll = () => {
    const allItems = props.organizationTree.flatMap((organization) =>
      getAllChildren(organization)
    );
    setExpandedOrgs(allItems);
  };

  const getAllChildren = (organization) => {
    return [
      organization.guuid,
      organization.guuid + "-organizations",
      organization.guuid + "-devices",
      organization.guuid + "-users",
    ].concat(organization.orgs.flatMap((org) => getAllChildren(org)));
  };

  const onOpenCreateOrganizationDialog = (organizationId) => {
    setCreateOrganizationDialogOrganizationId(organizationId);
    setOpenCreateOrganizationDialog(true);
  };

  const closeCreateOrganizationDialog = () => {
    setOpenCreateOrganizationDialog(false);
    setCreateOrganizationDialogOrganizationId(null);
  };

  const openInviteUserDialog = (organizationId) => {
    setInviteUserDialogOrganizationId(organizationId);
    setOpenInviteUsersConfirmDialog(true);
  };

  const closeInviteUserDialog = () => {
    setOpenInviteUsersConfirmDialog(false);
    setInviteUserDialogOrganizationId(null);
  };

  const openCreateUserDialog = (organizationId, organizationName) => {
    setCreateUserDialogOrganizationId(organizationId);
    setCreateUserDialogOrganizationName(organizationName);
    setOpenCreateUsersConfirmDialog(true);
  };

  const closeCreateUserDialog = () => {
    setOpenCreateUsersConfirmDialog(false);
    setCreateUserDialogOrganizationId(null);
    setCreateUserDialogOrganizationName(null);
  };

  const widget = props.widget ?? {};
  const widgetOptions = widget.options ?? {};
  if (lastWidgetOptions.current !== widgetOptions) {
    lastWidgetOptions.current = widgetOptions;
    const filtersOption = widgetOptions.filters ?? {};
    filters.current = [
      filtersOption.selected ? ["selected"] : [],
      filtersOption.email ?? [],
      filtersOption.org ?? [],
      filtersOption.permissions ?? [],
      filtersOption.last_login ?? [],
      filtersOption.status ?? [],
    ];
  }

  const handleExpansion = (event, nodes) => {
    //Need to handle button clicks, so they don't collapse the TreeItem
    if (!(!!event.target && event.target.matches(".MuiButton-label"))) {
      setExpandedOrgs(nodes);
    }
  };

  const toggleRowSelect = (event) => {
    if (event.target.checked) {
      setSelectedUsers((selectedUsers) =>
        selectedUsers.concat(event.target.id)
      );
    } else {
      setSelectedUsers((selectedUsers) =>
        selectedUsers.filter((item) => item !== event.target.id)
      );
    }
  };

  return (
    <Widget {...props} dataType="tags">
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={(ev) => {
          // stop propagation so this stack remains selected
          ev.stopPropagation();
        }}
      >
        <Card style={{ height: "100%", overflow: "auto" }}>
          <CardContent style={{ height: "calc(100% - 40px`)", padding: 0 }}>
            <div style={{ marginTop: 0, height: 40 }}>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "0.875rem",
                }}
                onClick={() => {
                  expandAll();
                }}
              >
                Expand All
              </Button>

              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "0.875rem",
                }}
                onClick={() => {
                  collapseAll();
                }}
              >
                Collapse All
              </Button>

              <Button
                disabled={selectedUsers.length === 0}
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "0.875rem",
                }}
                onClick={() => {
                  setOpenRemoveUsersConfirmDialog(true);
                }}
              >
                Remove Users
              </Button>
            </div>
            <div style={{ marginTop: 0, height: "calc(100% - 40px`)" }}>
              {!!props.organizationTree &&
                props.organizationTree.length > 0 && (
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    /*
                      defaultCollapseIcon={<RemoveOutlined />}
                      defaultExpandIcon={<AddOutlined />}
                      */
                    expanded={expandedOrgs}
                    onNodeToggle={handleExpansion}
                  >
                    {props.organizationTree.map((org) => {
                      return (
                        <OrganizationManagementOrganization
                          key={"spo-" + org.guuid}
                          currentUserId={props.userid}
                          organization={org}
                          classes={props.classes}
                          filters={filters.current}
                          expandedOrgs={expandedOrgs}
                          removeUsers={props.removeUsers}
                          getInvites={props.getInvites}
                          openCreateOrganizationDialog={
                            onOpenCreateOrganizationDialog
                          }
                          openInviteUserDialog={openInviteUserDialog}
                          openCreateUserDialog={openCreateUserDialog}
                          // openAddDeviceDialog={openAddDeviceDialog}
                          toggleRowSelect={toggleRowSelect}
                          selectedUsers={selectedUsers}
                        />
                      );
                    })}
                  </TreeView>
                )}
            </div>
          </CardContent>
        </Card>
      </div>
      <OrganizationManagementRemoveUserDialog
        currentUserId={props.currentUserId}
        users={selectedUsers}
        isOpen={openRemoveUsersConfirmDialog}
        removeUsers={props.removeUsers}
        handleClose={(update = false) =>
          () => {
            setOpenRemoveUsersConfirmDialog(false);
            if (update) {
              props.populateOrganizationTree();
            }
          }}
      />
      <OrganizationManagementCreateOrganizationDialog
        textFieldRef={createOrganizationDialogRef}
        currentUserId={props.currentUserId}
        organizationId={createOrganizationDialogOrganizationId}
        isOpen={openCreateOrganizationDialog}
        handleClose={(update = false) =>
          () => {
            closeCreateOrganizationDialog();
            if (update) {
              props.populateOrganizationTree();
            }
          }}
        createOrganization={props.createOrg}
      />
      <OrganizationManagementInviteUsersDialog
        textFieldRef={inviteUsersDialogRef}
        currentUserId={props.currentUserId}
        organizationId={inviteUserDialogOrganizationId}
        isOpen={openInviteUsersConfirmDialog}
        handleClose={(update = false) =>
          () => {
            closeInviteUserDialog();
            if (update) {
              props.populateOrganizationTree();
            }
          }}
        onRenderCallback={() => {
          inviteUsersDialogRef.current.focus();
        }}
        inviteUsers={props.inviteUsers}
      />
      <OrganizationManagementCreateUsersDialog
        textFieldRef={createUsersDialogRef}
        currentUserId={props.currentUserId}
        user={props.user}
        organizationId={createUserDialogOrganizationId}
        organizationName={createUserDialogOrganizationName}
        isOpen={openCreateUsersConfirmDialog}
        handleClose={(update = false) =>
          () => {
            closeCreateUserDialog();
            if (update) {
              props.populateOrganizationTree();
            }
          }}
        onRenderCallback={() => {
          createUsersDialogRef.current.focus();
        }}
        createOrUpdateUsers={props.createOrUpdateUsers}
        logUserEvent={props.logUserEvent}
      />
    </Widget>
  );
};

export default OrganizationManagementWidget;
