import React from "react";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";
import { LineChartWidget as LineChartWidgetComponent } from "../../../components/widgets";

const LineChartWidget = (props) => {
  return (
    <BaseTimeSeriesWidget {...props}>
      {(baseProps) => {
        return (
          <LineChartWidgetComponent {...baseProps} data={baseProps.streams} />
        );
      }}
    </BaseTimeSeriesWidget>
  );
};

export default LineChartWidget;
