import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { createTheme } from "@material-ui/core/styles";

//defailt policies does not include Owner. Have to decide whether to include/ when to include
export const OWNER_POLICY = {
  policyid: "~0108364172f5c611e996ecf208b940ce30",
  name: "Owner",
};

export const ADMIN_POLICY = {
  policyid: "~01f0572050ec4f11e98f3295659e03fb04",
  name: "Admin",
};

export const READ_WRITE_POLICY = {
  policyid: "~0100282a02ec5111e98f329393ccd6e13a",
  name: "Read Write",
};

export const READ_ONLY_POLICY = {
  policyid: "~01c51b3fbaec4d11e98f32f00895e013fb",
  name: "Read",
};

export const DEFAULT_POLICIES = [
  ADMIN_POLICY,
  READ_WRITE_POLICY,
  READ_ONLY_POLICY,
];

export const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

export const MUI_TABLE_THEME = (theme) =>
  createTheme({
    // ...theme,
    overrides: {
      // ...theme.overrides,
      MUIDataTable: {
        paper: {
          //border: "2px solid grey"
        },
      },
      MUIDataTableToolbar: {
        root: {
          minHeight: 24,
        },
        icon: {
          padding: 2,
        },
        iconActive: {
          padding: 2,
        },
      },
      MUIDataTableSearch: {
        clearIcon: {
          padding: 2,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 16,
          fontWeight: 800,
          padding: 2,
        },
        fixedHeader: {
          fontSize: 16,
          fontWeight: 800,
          padding: 2,
        },
      },
      MUIDataTableBodyCell: {
        root: {
          padding: 1,
        },
      },
      MUIDataTableSelectCell: {
        root: {
          padding: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  });

export const CREATE_MUI_DATATABLE_BASE_OPTIONS = (
  parent,
  renderExpandableRowFunction
) => {
  return {
    filter: false,
    sort: false,
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: parent.state.expanded,
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    rowHover: false,
    searchText: parent.state.searchText,
    onSearchChange: (searchText) => {
      parent.setState({ searchText });
    },
    onSearchClose: () => {
      parent.setState({ searchText: "" });
    },
    onRowExpansionChange: (curExpanded, allExpanded) => {
      parent.setState({ expanded: allExpanded.map((row) => row.index) });
    },
    renderExpandableRow: (rowData, rowMeta) =>
      renderExpandableRowFunction(rowData, rowMeta),
  };
};

export const CREATE_MUI_DATATABLE_BASE_COLUMNS = (
  parentId,
  checkSelectDisabled,
  toggleRowSelectCallback
) => {
  return [
    {
      name: "selected",
      id: "selected",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              disabled={checkSelectDisabled(value, tableMeta, updateValue)}
              checked={value}
              onChange={toggleRowSelectCallback}
              id={parentId + "-" + tableMeta.rowData[1]}
            />
          );
        },
        customHeadRender: ({ index, ...column }) => <TableCell key={index} />,
      },
    },
  ];
};
