import React, { useState, useContext, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";

import PasswordTextField from "components/PasswordTextField";
import { ISXContext } from "services/Utils";

const useStyles = makeStyles({
  buttonText: {
    textTransform: "none",
  },
});

const ForgotPasswordDialog = (props) => {
  const [username, setUsername] = useState("");

  const handleResetpass = () => {
    props.handleResetpass(username);
    setUsername("");
    props.handleClose();
  };

  const handleClose = () => {
    setUsername("");
    props.handleClose();
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Forgot Password?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your user name; you will be sent a link that will allow
          you to change your password.
        </DialogContentText>
        <TextField
          fullWidth
          label="Username"
          type="email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleResetpass()}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleResetpass}>
          Request Link
        </Button>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Login = (props) => {
  const [mfaRequested, setMfaRequested] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [passwordResetRequestSent, setPasswordResetRequestSent] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isxContext = useContext(ISXContext);

  useEffect(() => {
    document.title = `Login | ${isxContext.labels.organization}`;
  }, [isxContext.labels.organization]);

  const classes = useStyles();

  const requestResendMfa = () => {
    props.attemptLogin(true);
    setMfaRequested(true);
    props.clearMFA();
  };

  const handleResetpass = (username) => {
    props.handleResetpass(username);
    setPasswordResetRequestSent(true);
  };

  // if (passwordResetRequestSent) {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       style={{ width: "100%", padding: 20, paddingTop: 56, flex: 1 }}
  //     >
  //       <Grid item sm={12} md={6} lg={4} style={{ width: "100%" }}>
  //         <Grid container spacing={3} align="left">
  //           <Grid item xs={12}>
  //             <Typography variant="h6">{`${isxContext.labels.deviceCapitalized} Dashboard Login`}</Typography>
  //           </Grid>

  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  const isInitialLogin = !passwordResetRequestSent && !props.mfa;

  return (
    <Grid
      container
      justifyContent="center"
      style={{ width: "100%", padding: 20, paddingTop: 56, flex: 1 }}
    >
      <Grid item sm={12} md={6} lg={4} style={{ width: "100%" }}>
        <Grid container spacing={3} align="left">
          <Grid item xs={12}>
            <Typography variant="h6">{`${isxContext.labels.deviceCapitalized} Dashboard Login`}</Typography>
          </Grid>

          {isInitialLogin && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  type="email"
                  onChange={props.handleUsername}
                  onKeyPress={(e) => e.key === "Enter" && props.attemptLogin()}
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                  disabled={props.disabled}
                />
              </Grid>
              { props.methods && 
                <Grid item xs={12}>
                <InputLabel>Authentication Method</InputLabel>
                <Select
                  value={props.method}
                  onChange={props.handleMethod}
                >
                {props.methods && props.methods.map((t)=> {
                    return (<MenuItem value={t} key={t}> {t} </MenuItem>)
                })}
                </Select>
                </Grid>
              }
              { !props.hidePassword &&
              <Grid item xs={12}>
                <PasswordTextField
                  fullWidth
                  label="Password"
                  onChange={props.handlePassword}
                  onKeyPress={(e) => e.key === "Enter" && props.attemptLogin()}
                  disabled={props.disabled}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </Grid>
              }
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
              { !props.hidePassword && 
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    classes={{ text: classes.buttonText }}
                    onClick={() => {
                      setResetPasswordDialogOpen(true);
                    }}
                  >
                    forgot password?
                  </Button>
              }
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.attemptLogin}
                    disabled={props.disabled || (props.mfa && !props.mfactor)}
                  >
                    Login
                  </Button>
                </div>
              </Grid>
            </>
          )}

          {props.mfa && (
            <>
              <Grid item xs={12}>
                <Typography>
                  A security code has been sent to the email or verified SMS number registered
                  with your account.
                </Typography>
                <TextField
                  fullWidth
                  label="Please enter your security code"
                  value={props.mfactor}
                  onChange={props.handleMFA}
                  onKeyPress={(e) => e.key === "Enter" && props.attemptLogin()}
                  disabled={props.disabled}
                />
                <div>
                  <Checkbox
                    checked={props.msecure}
                    onChange={props.handleSecure}
                  />
                  Remember me on this device; only check the box if the device
                  is secure
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "1.5rem" }}
                    onClick={requestResendMfa}
                    disabled={props.disabled}
                  >
                    Resend Code
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.attemptLogin}
                    disabled={props.disabled || (props.mfa && !props.mfactor)}
                  >
                    Login
                  </Button>
                </div>
              </Grid>
            </>
          )}

          {passwordResetRequestSent && (
            <Grid item xs={12}>
              <Typography>
                An email with a link to change your pasword has been sent to the
                email or verified SMS registered with your account.
              </Typography>
            </Grid>
          )}

          {props.error && (
            <Grid item xs={12}>
              <Typography color="error" align="right">
                {props.error}
              </Typography>
            </Grid>
          )}
          {mfaRequested && (
            <Grid item xs={12}>
              <Typography color="primary" align="right">
                Security code resent
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ForgotPasswordDialog
        open={resetPasswordDialogOpen}
        handleClose={() => {
          setResetPasswordDialogOpen(false);
        }}
        handleUsername={props.handleUsername}
        handleResetpass={handleResetpass}
      />
    </Grid>
  );
};

export default Login;
