import ISXUtils from "services/Utils";
function getIndicators(widget, stacks) {
  return (widget.tags || []).flatMap((t) => {
    const stack = stacks[t.stack];
    let label = "";
    try {
      label =
        stack?.name || JSON.parse(stack.dev_session)["stack.label"] || "?";
    } catch (err) {
      label = "?";
    }

    label += " - " + t.attribute;
    let tindicators = [];
    if (t.indicator_source === "tag") {
      if (stack && t.attribute) {
        tindicators = ISXUtils.getTagIndicators(
          stack?.data_config?.[t.attribute.split("|")[0]] || {}
        );
      }
    } else {
      tindicators = (t && t.indicators) || [];
    }
    return (tindicators || []).map((i) => {
      return {
        guuid: i.guuid,
        value: i.value,
        color: (i.options || {}).color || "black",
        label: label,
      };
    });
  });
}

function getAnnotations(indicators, chartToRefresh) {
  if (!indicators || indicators.length === 0) {
    return [];
  }

  return indicators.map((indicator) => {
    return {
      id: indicator.guuid,
      scaleID: "y-axis-0",
      type: "line",
      mode: "horizontal",
      borderColor: indicator.color,
      borderWidth: 3,
      borderDash: [6, 3],
      value: indicator.value,
      label: {
        content: indicator.label,
        enabled: false,
        position: "center",
      },
      onClick: ((self) => {
        return function (e) {
          this.options.label.enabled = !this.options.label.enabled;
          const chart = chartToRefresh.chartInstance;
          chart.update();
        };
      })(this),
    };
  });
}

function initializeYAxes(widget, options, stacks, prevProps) {
  if (!!options) {
    const yaxis = (widget.options || {}).yaxis || { type: "auto" };

    //For some reason, this comparison with prevProps is always returning False! not sure why
    /*const { widget: prevWidget = {} } = prevProps;
    const prevYaxis = (prevWidget.options || {}).yaxis || { type: "auto" };
    const refresh =
      yaxis.type !== prevYaxis.type ||
      (yaxis.type === "fixed" &&
        (yaxis["fixed.min"] !== prevYaxis["fixed.min"] ||
          yaxis["fixed.max"] !== prevYaxis["fixed.max"] ||
          yaxis["tagprop"] !== prevYaxis["tagprop"]));
    //const yaxis = (widget.options || {}).yaxis || { type: "auto" };
    */
    if (true) {
      if (yaxis.type === "fixed" && stacks) {
        if (yaxis.tagprop) {
          let tmin = null;
          let tmax = null;
          (widget.tags || []).flatMap((t) => {
            const stack = stacks[t.stack];
            const res = ISXUtils.getTagRange(
              stack?.data_config?.[t.attribute.split("|")[0]] || {}
            );
            tmin = tmin === null ? res.min : Math.min(tmin, res.min);
            tmax = tmax === null ? res.max : Math.max(tmax, res.max);
          });
          options.scales.yAxes[0].ticks = { min: tmin, max: tmax };
        } else {
          let ticks = (options.scales.yAxes[0].ticks = {});
          if (yaxis["fixed.min"] != null) {
            ticks.min = Number(yaxis["fixed.min"]);
          }
          if (yaxis["fixed.max"] != null) {
            ticks.max = Number(yaxis["fixed.max"]);
          }
        }
      } else {
        delete options.scales.yAxes[0].ticks;
      }
    }
    return options.scales.yAxes[0];
  }
  return null;
}

function getYAxesLabels(uniqueUnits, units) {
  return uniqueUnits.size === 1
    ? uniqueUnits.values().next().value
    : units.join(",");
}

function refreshChart(
  widget,
  stacks,
  options,
  chartToRefresh,
  prevProps,
  force = false
) {
  if (!chartToRefresh || !chartToRefresh.chartInstance) {
    return;
  }
  const chart = chartToRefresh.chartInstance;

  const { widget: prevWidget = {} } = prevProps;
  const yaxis = (widget.options || {}).yaxis || { type: "auto" };
  const prevYaxis = (prevWidget.options || {}).yaxis || { type: "auto" };
  const refresh =
    force ||
    yaxis.type !== prevYaxis.type ||
    (yaxis.type === "fixed" &&
      (yaxis["fixed.min"] !== prevYaxis["fixed.min"] ||
        yaxis["fixed.max"] !== prevYaxis["fixed.max"] ||
        yaxis["tagprop"] !== prevYaxis["tagprop"]));
  if (refresh) {
    if (yaxis.type === "fixed") {
      if (yaxis.tagprop) {
        let tmin = null;
        let tmax = null;
        (widget.tags || []).flatMap((t) => {
          const stack = stacks[t.stack];
          const res = ISXUtils.getTagRange(
            stack?.data_config?.[t.attribute.split("|")[0]] || {}
          );
          tmin = tmin === null ? res.min : Math.min(tmin, res.min);
          tmax = tmax === null ? res.max : Math.max(tmax, res.max);
        });
        options.scales.yAxes[0].ticks = {
          min: tmin,
          max: tmax,
        };
        chart.options.scales.yAxes[0].ticks = {
          min: tmin,
          max: tmax,
        };
      } else {
        let ticks = (chart.options.scales.yAxes[0].ticks = {});
        if (yaxis["fixed.min"] != null) {
          ticks.min = Number(yaxis["fixed.min"]);
        }
        if (yaxis["fixed.max"] != null) {
          ticks.max = Number(yaxis["fixed.max"]);
        }
        options.scales.yAxes[0].ticks = ticks;
      }
    } else {
      delete chart.options.scales.yAxes[0].ticks;
      delete options.scales.yAxes[0].ticks;
    }
    chart.update();
  }

  if (widget.tags !== prevProps.widget.tags) {
    let indicators = getIndicators(widget, stacks);

    if (!!chart) {
      // seems we have to delete, update and recreate
      delete chart.options.annotation.annotations;
      chart.update();

      chart.options.annotation.annotations = options.annotation.annotations =
        getAnnotations(indicators, chartToRefresh);
      chart.update();
    }
  }

  if (JSON.stringify(options) !== JSON.stringify(chart.options)) {
    chart.options = options;
    chart.update();
  }
}

export const ChartService = {
  getIndicators,
  getAnnotations,
  initializeYAxes,
  getYAxesLabels,
  refreshChart,
};
