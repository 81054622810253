import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { isEmpty, isNull, isUndefined } from "lodash";

const useStyles = makeStyles((theme) => ({
  inputs: {
    fontSize: 12,
  },
  inputLabels: {
    fontSize: 12,
  },
}));

const lubricationDevices = {
  jack: {
    value: "jack",
    name: "Jack",
    capacity: ["125", "250"],
    unitvolume: ["0.5"],
    cycletime: "60",
  },
  ultimate: {
    value: "ultimate",
    name: "Ultimate",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.5"],
    cycletime: "60",
  },
  "m-ue": {
    value: "m-ue",
    name: "M-UE",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.333", "0.666", "1.0", "1.333", "1.666", "2.0"],
    cycletime: "300",
  },
  "bt-ue": {
    value: "bt-ue",
    name: "BT-UE",
    capacity: ["60", "125", "250", "500"],
    unitvolume: ["0.333", "0.666", "1.0", "1.333", "1.666", "2.0"],
    cycletime: "300",
  },
};

const LubeTagProperties = (props) => {
  const { tagId, tagProperties, handleTagPropsChange, readOnly } = props;
  console.log("tagproperties are",tagProperties);
  const [lubeProps, setLubeProps] = useState({ ...tagProperties });
  const [errors, setErrors] = useState({});
  const [changed, setChanged] = useState({});
  //console.log("lubeprops are",lubeProps);
  useEffect(() => {
    let update = {};
    if (Object.keys(errors).length === 0 && Object.keys(changed).length) {
      Object.keys(changed).forEach((c) => {
        update[c] = lubeProps[c];
      });
      handleTagPropsChange(tagId, update);
      //console.log("to update",Object.keys(errors).length, Object.keys(changed).length, update);
      setChanged({});
    }
  }, [errors, changed, lubeProps, handleTagPropsChange, tagId]);

  const handleDevicePropChange = (event, tagId, prop) => {
    const newLubeProps = { ...lubeProps };
    const newErrors = { ...errors };
    newLubeProps.devicetype = event.target.value;
    newLubeProps.capacity = null;
    newErrors.capacity = "Select Capacity";
    newLubeProps.cycletime =
      lubricationDevices[newLubeProps.devicetype]["cycletime"];
    if (
      newLubeProps.devicetype === "jack" ||
      newLubeProps.devicetype === "ultimate"
    ) {
      newLubeProps.unitvolume =
        lubricationDevices[newLubeProps.devicetype]["unitvolume"][0];
      if ("unitvolume" in newErrors) {
        delete newErrors.unitvolume;
      }
    } else {
      newLubeProps.unitvolume = null;
      newErrors.unitvolume = "Select Dispense Amount";
    }
    setErrors(newErrors);
    setLubeProps(newLubeProps);
    const newChanged = { ...changed };

    newChanged["devicetype"] = true;
    newChanged["capacity"] = true;
    newChanged["unitvolume"] = true;
    newChanged["cycletime"] = true;
    setChanged(newChanged);
    //console.log("newprops and errors",newLubeProps,newErrors,newChanged);
    //updateChanges(newErrors,newChanged);
  };

  const handleCapacityPropChange = (event, tagId, prop) => {
    const newErrors = { ...errors };
    if ("capacity" in errors) {
      delete newErrors.capacity;
      setErrors(newErrors);
      //console.log("errors after capacity update",newErrors);
    }
    const newLubeProps = { ...lubeProps };
    newLubeProps.capacity = event.target.value;
    setLubeProps(newLubeProps);
    const newChanged = { ...changed };
    newChanged["capacity"] = true;
    setChanged(newChanged);
    //console.log("newprops after capacity update",newLubeProps,newErrors,newChanged);
    //updateChanges(newErrors,newChanged);
  };

    const handleAutoLubePropChange = (event, tagId, prop) => {
    const newErrors = { ...errors };
    if ("autolube" in errors) {
      delete newErrors.autolube;
      setErrors(newErrors);
      //console.log("errors after capacity update",newErrors);
    }
    const newLubeProps = { ...lubeProps };
    newLubeProps.autolube = event.target.value;
    setLubeProps(newLubeProps);
    const newChanged = { ...changed };
    newChanged["autolube"] = true;
    setChanged(newChanged);
    //console.log("newprops after capacity update",newLubeProps,newErrors,newChanged);
    //updateChanges(newErrors,newChanged);
  };

  const handleUnitVolumePropChange = (event, tagId, prop) => {
    const newErrors = { ...errors };
    if ("unitvolume" in errors) {
      delete newErrors.unitvolume;
      setErrors(newErrors);
      //console.log("errors after unitvolume update",newErrors);
    }
    const newLubeProps = { ...lubeProps };
    newLubeProps.unitvolume = event.target.value;
    setLubeProps(newLubeProps);
    const newChanged = { ...changed };
    newChanged["unitvolume"] = true;
    setChanged(newChanged);
    //console.log("newprops after unitvolume update",newLubeProps,newErrors,newChanged);
    //updateChanges(newErrors,newChanged);
  };

  const validateLubeProperties = (event, tagId, prop) => {
    let valid = true;
    let errorMsg = "Provide valid input";
    //console.log("in validate lube props",event.target.value,tagId,prop,isNaN(event.target.value));
    if (isUndefined(event.target.value) || isNull(event.target.value)) {
      valid = false;
    }
    if (
      prop !== "grease" &&
      (isNaN(event.target.value) || isEmpty(event.target.value))
    ) {
      valid = false;
      errorMsg = "Value must be a number";
    }
    const newErrors = { ...errors };
    if (valid) {
      if (prop in errors) {
        delete newErrors[prop];
        setErrors(newErrors);
        //console.log("errors after prop update",prop,valid,newErrors);
      }
    } else {
      newErrors[prop] = errorMsg;
      setErrors(newErrors);
      //console.log("errors after prop update",prop,valid,newErrors);
    }

    if (true) {
      const newLubeProps = { ...lubeProps };
      newLubeProps[prop] = event.target.value;
      setLubeProps(newLubeProps);
      const newChanged = { ...changed };
      newChanged[prop] = true;
      setChanged(newChanged);
      //console.log("newprops after prop update",prop,newLubeProps,newErrors,newChanged);
    }
  };

  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                select
                fullWidth
                required
                label="Cartridge Type"
                type="string"
                //SelectProps={{inputProps:{ref:deviceRef}}}
                value={lubeProps["devicetype"] || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  handleDevicePropChange(event, tagId, "devicetype")
                }
                disabled={readOnly}
              >
                {Object.keys(lubricationDevices).map((attr) => {
                  return (
                    <MenuItem
                      value={lubricationDevices[attr]["value"]}
                      key={lubricationDevices[attr]["name"]}
                    >
                      {lubricationDevices[attr]["name"]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                select
                required
                fullWidth
                error={"capacity" in errors}
                helperText={errors.capacity || ""}
                label="Cartridge Pouch Capacity (cc)"
                value={lubeProps["capacity"] || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  handleCapacityPropChange(event, tagId, "capacity")
                }
                disabled={readOnly}
              >
                {(lubeProps["devicetype"] in lubricationDevices
                  ? lubricationDevices[lubeProps["devicetype"]]["capacity"]
                  : []
                ).map((attr) => {
                  return (
                    <MenuItem value={attr} key={attr}>
                      {attr}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                select
                fullWidth
                required
                error={"unitvolume" in errors}
                helperText={errors.unitvolume || ""}
                label="Dispense Amount / Interval (cc)"
                value={lubeProps.unitvolume || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  handleUnitVolumePropChange(event, tagId, "unitvolume")
                }
                disabled={readOnly}
              >
                {(lubeProps["devicetype"] in lubricationDevices
                  ? lubricationDevices[lubeProps["devicetype"]]["unitvolume"]
                  : []
                ).map((attr) => {
                  return (
                    <MenuItem value={attr} key={attr}>
                      {attr}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                required
                type="number"
                min="1"
                step="1"
                error={"maxlubeamount" in errors}
                helperText={errors.maxlubeamount || ""}
                label="Max Bearing Capacity (cc)"
                value={lubeProps.maxlubeamount || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  validateLubeProperties(event, tagId, "maxlubeamount")
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                error={"calcgreaseinterval" in errors}
                helperText={errors.calcgreaseinterval || ""}
                type="number"
                min="1"
                step="1"
                label="Calc Grease Interval (days)"
                value={lubeProps.calcgreaseinterval || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  validateLubeProperties(event, tagId, "calcgreaseinterval")
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                type="number"
                min="1"
                step="1"
                error={"calcgreaseamount" in errors}
                helperText={errors.calcgreaseamount || ""}
                label="Calc Grease Amount (cc)"
                value={lubeProps.calcgreaseamount || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  validateLubeProperties(event, tagId, "calcgreaseamount")
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                required
                error={"baseline" in errors}
                helperText={errors.baseline || ""}
                type="number"
                min="0"
                max="50"
                step="1"
                label="Baseline Friction Level (dB)"
                value={lubeProps.baseline || ""}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  validateLubeProperties(event, tagId, "baseline")
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                fullWidth
                // defaultValue="NLGI#2"
                label="Lubricant Type"
                value={lubeProps.grease || "NLGI#2"}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  validateLubeProperties(event, tagId, "grease")
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth={true}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexGrow: 1 }}>
              <TextField
                select
                required
                fullWidth
                error={"autolube" in errors}
                helperText={errors.autolube || ""}
                label="Auto Lubrication"
                value={lubeProps["autolube"] || false}
                InputLabelProps={{
                  className: classes.inputLabels,
                }}
                InputProps={{ className: classes.inputs }}
                onChange={(event) =>
                  handleAutoLubePropChange(event, tagId, "autolube")
                }
                disabled={readOnly}
              >
                <MenuItem value={true} key={"true"}>
                      Enabled
                    </MenuItem>
                <MenuItem value={false} key={"false"}>
                      Disabled
                    </MenuItem>
              </TextField>
            </div>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LubeTagProperties;
