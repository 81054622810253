import React, { useRef } from "react";
import { StacksManagementWidget as StacksManagementWidgetComponent } from "components/widgets";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import { useDispatch } from "react-redux";
import { updateItem as updateItemOp } from "store/operations";

const StacksManagementWidget = (props) => {
  const widgetOptions = useRef({});

  const dispatch = useDispatch();

  widgetOptions.current = (props.widget || {}).options || {};

  const updateWidgetOptions = (options) => {
    widgetOptions.current = { ...widgetOptions.current, ...options };
    props.updateWidget({
      options: widgetOptions.current,
    });
  };

  const updateItem = (guuid, contents) => {
    dispatch(updateItemOp(guuid, contents));
  };

  return (
    <StacksManagementWidgetComponent
      {...props}
      updateWidgetOptions={updateWidgetOptions}
      updateItem={updateItem}
    />
  );
};

export default connectWidget(StacksManagementWidget);
