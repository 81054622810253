import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import {
  READ_WRITE_POLICY,
  DEFAULT_POLICIES,
} from "./OrganizationManagementWidgetConstants";

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

const newInvite = () => {
  return {
    invitee: "",
    policies: [READ_WRITE_POLICY.policyid],
  };
};

const OrganizationManagementInviteUsersDialog = (props) => {
  const [invites, setInvites] = useState([newInvite()]);
  const [message, setMessage] = useState({});
  const [inviteTimeout, setInviteTimeout] = useState({});

  const inviteUsers = () => {
    if (Object.keys(invites).length > 0) {
      props.inviteUsers(props.organizationId, invites).then((rv) => {
        clearTimeout(inviteTimeout);
        if (rv.status === 0) {
          setInvites([newInvite()]);
          setMessage({ content: rv.info + " successfully" });
          setInviteTimeout(
            setTimeout(() => {
              setInvites([newInvite()]);
              setMessage({});
              props.handleClose(true)();
            }, 1000)
          );
        } else {
          // error
          setMessage({
            content: `ERROR: ${
              rv.error || "Something went wrong with your request"
            }; please try again or contact support`,
            severity: "error",
          });
          setInviteTimeout(
            setTimeout(() => {
              setInvites([newInvite()]);
              setMessage({});
              props.handleClose(true)();
            }, 5000) //close after 5 seconds of showing the error
          );
        }
      });
    }
  };

  const addInvite = () => {
    setInvites(invites.concat([newInvite()]));
  };

  const deleteInvite = (i) => {
    const newInvites = invites.filter((invite, index) => index !== i);
    setInvites(newInvites.length === 0 ? [newInvite()] : newInvites);
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invite Users</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ height: "100%", padding: 12 }}
        >
          <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
            <Table>
              <TableBody>
                {invites.map((a, i) => {
                  return (
                    <TableRow key={"invite-row-" + i}>
                      <AttributeTableCell>
                        <TextField
                          id={"select-email-" + a.inviteid}
                          inputRef={props.textFieldRef}
                          label="Email"
                          margin="normal"
                          style={{ margin: 3, width: "100%" }}
                          value={a.invitee}
                          onChange={(event) => {
                            const tempInvites = invites.slice();
                            tempInvites[i].invitee = event.target.value;
                            setInvites(tempInvites);
                          }}
                        />
                      </AttributeTableCell>
                      <AttributeTableCell>
                        <Select
                          label="Policy"
                          value={a.policies[0]}
                          onChange={(event) => {
                            const tempInvites = invites.slice();
                            tempInvites[i].policies[0] = event.target.value;
                            setInvites(tempInvites);
                          }}
                          style={{
                            fontSize: "inherit",
                            margin: 3,
                          }}
                        >
                          {DEFAULT_POLICIES.map((attr) => {
                            return (
                              <MenuItem
                                value={attr.policyid}
                                key={attr.policyid}
                              >
                                {attr.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </AttributeTableCell>
                      <AttributeTableCell>
                        <IconButton onClick={() => deleteInvite(i)}>
                          <CancelIcon />
                        </IconButton>
                        {i === invites.length - 1 && (
                          <IconButton
                            aria-owns="attr-type-menu"
                            onClick={addInvite}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </AttributeTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap">
              <Grid item xs={9}>
                <Typography variant="body2" color={message.severity}>
                  {message.content}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={inviteUsers} color="primary">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationManagementInviteUsersDialog;
