import React, { useState, useEffect, useContext, useRef } from "react";
import Widget from "../../widget/Widget";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Popover from "@material-ui/core/Popover";
import RootRef from "@material-ui/core/RootRef";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import MUIDataTable, { TableToolbar, TableFilterList } from "mui-datatables";
import moment from "moment";

import InfoIcon from "@material-ui/icons/Info";
import { blue } from "@material-ui/core/colors";

import ISXUtils, { ISXContext } from "services/Utils";
const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";
const UTC_DATE_FORMAT = "dddd DD MMMM, YYYY HH:mm:ss UTC";

const tableMuiTheme = (theme) =>
  createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: {
          display: "flex",
          flexFlow: "column",
          height: "100%",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(even)": {
            backgroundColor: "rgb(250, 250, 250)",
          },
          cursor: "pointer",
        },
      },
      MuiTableCell: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      MuiIconButton: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "1em",
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
        },
      },
    },
  });

const conditions = [
  "", //the conditions are 1-indexed in backend, adding the zeroth index as empty string to match the db
  "Equals",
  "Greater than",
  "Greater than or equal to",
  "Less than",
  "Less than or equal to",
  "Between",
  "Outside",
  "Not Equals",
  "Any",
];
const showEmailMessage = false;

const evtEmailFromTemplate = (evt) => {
  const location = evt.location ? `in ${evt.location}` : "";
  const condition = evt.cdn || [];
  const conditionText = (conditions[evt.cdn[0] % 100] || "?").toLowerCase();
  return {
    body: `You are receiving this email because your Interstacks Device ${
      evt.label
    } ${location} has entered the ALARM state, because sensor tag ${
      evt.name || evt.tagName
    } was ${conditionText} threshold value ${
      condition[1] == null ? "?" : condition[1]
    } at ${moment.utc(evt.timestamp).format(UTC_DATE_FORMAT)}.`,
    subject: "ALARM: Interstacks notification",
  };
};
const getMeasurementName = (dname) => {
  let retval = "sample";
  if (dname == null) {
    return retval;
  }
  if (dname.startsWith("ma")) {
    retval = "Moving Average";
    let snames = dname.split("-");
    if (snames[1].endsWith("sm")) {
      retval += " (" + snames[1].slice(0, -2) + " samples)";
    }
  }
  return retval;
};
const EventCard = ({ evt }) => {
  const isxContext = useContext(ISXContext);
  return (
    <Card variant="outlined" style={{ width: 600 }}>
      <CardContent>
        <Typography variant="h6">Event Details</Typography>
        <Typography>{`${isxContext.labels.deviceCapitalized}: ${evt.label}`}</Typography>
        <Typography>{`Tag Id: ${evt.eventData.tag}`}</Typography>
        {evt.name && <Typography>{`Tag Name: ${evt.name}`}</Typography>}
        <Typography>
          {`\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014`}
        </Typography>
        <Typography>{`Timestamp: ${
          evt.timestamp ? moment(evt.timestamp).format(DATE_FORMAT) : "---"
        }`}</Typography>
        <Typography>{`Event Category: ${evt.eventCategory}`}</Typography>
        <Typography>{`Event Type: ${evt.eventType}`}</Typography>
        <Typography>{`Event Source: ${evt.eventSource}`}</Typography>
        <Typography>
          {`\u2014\u2014\u2014\u2014\u2014\u2014\u2014\u2014`}
        </Typography>
        <pre>
          <Typography>{`Event Data: ${JSON.stringify(
            evt.eventData,
            null,
            3
          )}`}</Typography>
        </pre>
      </CardContent>
    </Card>
  );
};

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    borderWidth: 2,
    borderStyle: "solid",
    border: theme.palette.divider,
  },
}))(Tooltip);

// for better performance, make component "pure" (rerender only on shallow prop changes)
const MemoizedTable = React.memo(MUIDataTable);

const ReadonlyToolbar = (props) => {
  return (
    <Tooltip
      disableFocusListener
      title="You will need to unlock the dashboard to sort, filter or search"
    >
      <div style={{ pointerEvents: "auto", opacity: 0.5 }}>
        <div style={{ pointerEvents: "none" }}>
          <TableToolbar {...props} />
          <div style={{ height: 0, width: 0, overflow: "hidden" }}>
            <input autoFocus></input>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const ReadonlyChip = (props) => {
  const { label } = props;
  return (
    <Tooltip title="You will need to unlock the dashboard to change filtering">
      <Chip label={label} onDelete={undefined} />
    </Tooltip>
  );
};

const ReadonlyTableFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={ReadonlyChip} />;
};

const EventsWidget = (props) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  const { readOnly } = props;

  const isxContext = useContext(ISXContext);

  const selectedRow = useRef(null);

  const tableRef = useRef();
  const data = useRef([]);
  const evt = useRef({});
  const readOnlyRef = useRef(false);

  const lastWidgetOptions = useRef();
  const searchTextTimeout = useRef();

  const columns = useRef([
    {
      name: "",
      label: "",
      options: {
        empty: true,
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <InfoTooltip title="Click to see evt details">
            <InfoIcon fontSize="small" style={{ color: blue[500] }} />
          </InfoTooltip>
        ),
      },
    },
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        // sortDirection: "desc",
        filterList: [],
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? moment(value).format(DATE_FORMAT) : "---",
      },
    },
    {
      name: "eventCategory",
      label: "Category",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "eventType",
      label: "Type",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "eventSource",
      label: "Source",
      options: {
        // sortDirection: "none",
        filterList: [],
        // hint: "click on a row to see evt details",
      },
    },
    {
      name: "label",
      label: `${isxContext.labels.deviceCapitalized} Label`,
      options: {
        // sortDirection: "none",
        filterList: [],
      },
    },
    {
      name: "eventData",
      label: "Tag",
      options: {
        // sortDirection: "none",
        filterList: [],
        customBodyRender: (value, tableMeta, updateValue) =>
          value?.tag ? value?.tag : null,
      },
    },
  ]);

  const options = useRef({
    elevation: 0,
    responsive: "standard",
    filter: true,
    search: true,
    filterType: "dropdown",
    selectableRows: "none",
    expandableRows: false,
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    rowHover: false,
    searchText: "",
    sortOrder: {
      // name: "timestamp",
      // direction: "desc",
    },
    textLabels: {
      body: {
        toolTip: "Sort",
      },
    },
    setRowProps: (row, dataIndex) => {
      if (
        selectedRow.current &&
        row.slice(1).every((c, i) => c === selectedRow.current[i])
      ) {
        return {
          style: { backgroundColor: "darkgray" },
        };
      }
      return null;
    },
    onRowClick: (rowData, rowMeta) => {
      evt.current = data.current[rowMeta.dataIndex] || {};
      selectedRow.current = rowData.slice(1);
      setDetailsOpen(true);
    },
    // onCellClick: (colData, colMeta) => {
    //   evt.current = data.current[colMeta.dataIndex] || {};
    //   setDetailsOpen(true);
    //   selectedRow.current = [...rowData];
    // },
    onColumnSortChange: (colname, sortdir) => {
      // options.current.sortOrder = {
      //   name: colname,
      //   direction: sortdir,
      // }
      // columns.current.forEach(
      //   (col) =>
      //     (col.options.sortDirection = col.name === colname ? sortdir : "none")
      // );
      if (readOnlyRef.current) {
        const [colname, sortdir] = lastWidgetOptions.current.sort || [
          "timestamp",
          "desc",
        ];
        // options.current.sortOrder = {
        //   name: colname,
        //   direction: sortdir,
        // }
        options.current = {
          ...options.current,
          sortOrder: {
            name: colname,
            direction: sortdir,
          },
        };
        setForceRender((f) => f + 1);
      } else {
        props.updateWidgetOptions({
          sort: [colname, sortdir],
        });
      }
      // props.updateWidgetOptions({
      //   sort: [colname, sortdir],
      // });
    },
    onFilterChange: (col, filterList) => {
      const colIdx = columns.current.findIndex(
        (element) => element.name === col
      );
      if (colIdx !== -1) {
        columns.current[colIdx].options.filterList = filterList[colIdx];
      }
      const filters = columns.current.reduce((acc, col) => {
        if (col.options.filter !== false) {
          acc[col.name] = col.options.filterList;
        }
        return acc;
      }, {});
      props.updateWidgetOptions({ filters });
    },
    // setFilterChipProps: (colIndex, colName, data) => {
    //   return {
    //     disabled: readOnlyRef.current,
    //   };
    // },
    onSearchChange: (searchText) => {
      options.current.searchText = searchText;
      clearTimeout(searchTextTimeout.current);
      searchTextTimeout.current = setTimeout(() => {
        props.updateWidgetOptions({ search: searchText || null });
      }, 1000);
    },
  });

  useEffect(() => {
    // options.current.textLabels.body.toolTip = readOnly ? "You will need to unlock dashboard to sort" : "Sort";
    // options.current.setFilterChipProps = (colIndex, colName, data) => {
    //   return {
    //     disabled: readOnly,
    //   };
    // }
    readOnlyRef.current = readOnly;
    setForceRender((f) => f + 1);
  }, [readOnly]);

  data.current = props.data;
  const widget = props.widget || {};
  const widgetOptions = widget.options;
  if (widgetOptions !== lastWidgetOptions.current) {
    lastWidgetOptions.current = widgetOptions;
    const newWidgetOptions = widgetOptions || {};
    const widgetFilters = newWidgetOptions.filters || {};
    columns.current.forEach((col) => {
      if (col.options.filter !== false) {
        col.options.filterList = widgetFilters[col.name] || [];
      }
    });
    const [colname, sortdir] = newWidgetOptions.sort || ["timestamp", "desc"];
    // columns.current.forEach(
    //   (col) =>
    //     (col.options.sortDirection = col.name === colname ? sortdir : "none")
    // );
    options.current.sortOrder = {
      name: colname,
      direction: sortdir,
    };
    options.current.searchText = newWidgetOptions.search || "";
  }

  return (
    <RootRef rootRef={tableRef}>
      <>
        <Widget {...props} dataType="stacks" widgetTitle="evts">
          <MuiThemeProvider theme={(theme) => tableMuiTheme(theme)}>
            <MemoizedTable
              data={data.current}
              columns={columns.current}
              options={options.current}
              detailsOpen={detailsOpen} // this is to force row to highlight
              forceRender={forceRender}
              components={{
                TableToolbar: readOnly ? ReadonlyToolbar : TableToolbar,
                TableFilterList: readOnly
                  ? ReadonlyTableFilterList
                  : TableFilterList,
              }}
            />
          </MuiThemeProvider>
        </Widget>

        <Popover
          open={detailsOpen}
          onClose={() => {
            setDetailsOpen(false);
            selectedRow.current = null;
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          // anchorRefence="anchorPosition"
          // anchorPosition={{ top: 0, left: 0 }}
          anchorEl={tableRef.current}
        >
          <EventCard evt={evt.current} />
        </Popover>
      </>
    </RootRef>
  );
};

export default EventsWidget;
