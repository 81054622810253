import React from "react";
import BaseTimeSeriesWidget from "../base-widgets/BaseTimeSeriesWidget";
import { StateHistoryWidget as StateHistoryWidgetComponent } from "../../../components/widgets";

const StateHistoryWidget = (props) => {
  return (
    <BaseTimeSeriesWidget {...props}>
      {(baseProps) => {
        return (
          <StateHistoryWidgetComponent
            {...baseProps}
            data={baseProps.streams}
          />
        );
      }}
    </BaseTimeSeriesWidget>
  );
};

export default StateHistoryWidget;
