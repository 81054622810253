import React, { useRef } from "react";
import BaseSinglePointWidget from "../base-widgets/BaseSinglePointWidget";
import { GaugeWidget as GaugeWidgetComponent } from "../../../components/widgets";
import ISXUtils from "services/Utils";
import { default as uniq } from "lodash/uniq";

const GaugeWidget = (props) => {
  const lastTag = useRef();
  const lastRange = useRef();
  const config = useRef();
  const lastStack = useRef();

  const buildRegions = (indicators, range) => {
    const min = range.min ? parseFloat(range.min) : 0;
    const max = range.max ? parseFloat(range.max) : 1000;

    const sortedIndicators = ISXUtils.sortedIndicators(indicators);

    const points = [
      min,
      ...uniq(sortedIndicators.map((x) => x.value)).sort((a, b) => a - b),
      max,
    ];
    const midpoints = points
      .slice(0, -1)
      .map((x, i) => (x + points[i + 1]) / 2);
    const colors = midpoints.map((val) => {
      let color = "lightgray";
      sortedIndicators.forEach((indicator) => {
        const op = ISXUtils.indicatorToOp(indicator);
        color = op(val).color || color;
      });
      return color;
    });
    const rv = { points, colors, range };
    return rv;
  };

  return (
    <BaseSinglePointWidget {...props}>
      {(baseProps) => {
        const { widget = {}, tag, color, stacks } = baseProps;

        // need to rebuild widget regions if tag or range has changed
        const range = (widget.options || {}).range;
        if (
          lastTag.current !== tag ||
          lastRange.current !== range ||
          lastStack.current !== tag.stack
        ) {
          lastTag.current = tag;
          lastRange.current = range;
          if (stacks?.[tag.stack]) {
            lastStack.current = tag.stack;
          }
          const newrange =
            range?.type === "tag"
              ? ISXUtils.getTagRange(
                  //stacks?.[tag.stack]?.["data_config"]?.[tag.attribute] || {}
                  stacks?.[tag.stack]?.["data_config"]?.[
                    tag.attribute.split("|")[0]
                  ] || {}
                )
              : range;
          //const tagIndicators = (tag && tag.indicators) || [];
          let tagIndicators = [];
          if (tag.indicator_source === "tag") {
            if (tag.stack && tag.stack in stacks) {
              //if (stack && attr.current) {
              tagIndicators = ISXUtils.getTagIndicators(
                //stacks[tag.stack]["data_config"][tag.attribute] || {}
                stacks?.[tag.stack]?.["data_config"]?.[
                  tag.attribute.split("|")[0]
                ] || {}
              );
            }
          } else {
            tagIndicators = (tag && tag.indicators) || [];
          }
          config.current = buildRegions(tagIndicators, newrange || {});
        }

        return (
          <GaugeWidgetComponent
            {...baseProps}
            // value={value || ""}
            color={color || "black"}
            config={config.current}
          />
        );
      }}
    </BaseSinglePointWidget>
  );
};

export default GaugeWidget;
