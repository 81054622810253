import React, { useState, useRef, useContext } from "react";

// React grid styles
import "react-grid-layout/css/styles.css";

// Material UI
import { withStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import {
//   WidgetIndicatorDialog,
//   WidgetConfigureDialog
// } from "../../containers/widget";
import WidgetIndicatorDialog from "./widget-indicator-dialog/WidgetIndicatorDialog";
import WidgetOptionsMenu from "./widget-options-menu/WidgetOptionsMenu";
import WidgetTagsConfigureDialog from "./widget-configure-dialogs/widget-tags-configure-dialog/WidgetTagsConfigureDialog";
import WidgetStacksConfigureDialog from "./widget-configure-dialogs/widget-stacks-configure-dialog/WidgetStacksConfigureDialog";
// import {WidgetTagsConfigureDialog}
// import {
//   WidgetOptionsMenu,
//   WidgetTagsConfigureDialog,
//   WidgetStacksConfigureDialog,
// } from ".";

import { ISXContext } from "services/Utils";

const styles = (theme) => ({
  header: {
    display: "flex",
    height: 20,
    padding: "0px 8px 0px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.widget.text.primary,
    backgroundColor: theme.palette.primary.main,
    cursor: "move",
  },
  headerButtons: {
    width: 24,
    height: 24,
    padding: 0,
    color: theme.widget.text.secondary,
  },
  headerButtonsIcon: {
    width: 20,
    height: 20,
  },
  headerTitleInput: {
    color: theme.widget.text.primary,
  },
  headerTitleEmpty: {
    color: theme.widget.text.secondary,
  },
  content: {
    flex: "1 0 auto",
    height: "calc(100% - 20px)",
  },
  childContainer: {
    width: "100%",
    height: "100%",
  },
  textInput: {
    color: theme.widget.text.primary,
    textOverflow: "ellipsis",
  },
  grid: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 20,
    paddingLeft: 0,
    paddingBottom: 5,
    paddingRight: 25,
  },
  addButton: {
    position: "fixed",
    right: 25,
    bottom: 25,
  },
});

const WidgetConfigureDialog = (props) => {
  if (props.dataType === "tags") {
    return (
      <WidgetTagsConfigureDialog
        widget={props.widget}
        widgetTitle={props.widgetTitle}
        maxTags={props.maxTags}
        showDerivedTags={props.showDerivedTags}
        createNewTag={props.createNewTag}
        attributeColumnHeader={props.attributeColumnHeader}
        filterTagsOptions={props.filterTagsOptions}
        options={props.options || []}
        open={props.openConfigureDialog}
        removeDialog={props.removeConfigureDialog}
        stacks={props.stacks}
        updateWidget={props.updateWidget}
      />
    );
  } else if (props.dataType === "stacks") {
    return (
      <WidgetStacksConfigureDialog
        widget={props.widget}
        maxStacks={props.maxStacks}
        widgetTitle={props.widgetTitle}
        options={props.options || []}
        open={props.openConfigureDialog}
        removeDialog={props.removeConfigureDialog}
        stacks={props.stacks}
        updateWidget={props.updateWidget}
      />
    );
  } else {
    return null;
  }
};

const Widget = (props) => {
  const widget = props.widget || {};
  const widgetTitle = widget.title;
  const [title, setTitle] = useState(widgetTitle || "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfigureDialog, setOpenConfigureDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlertsDialog, setOpenAlertsDialog] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [showIcons, setShowIcons] = useState(!!isMobile);

  const titleTimeout = useRef(null);
  const titleInput = useRef();
  const lastWidgetTitle = useRef(widgetTitle);

  // eventually all widgets that need configuration will provide this
  const ConfigureDialog = props.configureDialog;

  const isxContext = useContext(ISXContext);

  if (widget.title !== lastWidgetTitle.current && !titleTimeout.current) {
    const newTitle = widget.title || "";
    if (newTitle !== title) {
      setTitle(newTitle);
    }
  }
  lastWidgetTitle.current = widget.title;

  const editWidgetTitle = () => {
    setAnchorEl(null);
    setEditTitle(true);
    setTimeout(() => titleInput.current.focus(), 500);
  };

  const setWidgetTitle = (newTitle) => {
    setTitle(newTitle);
    clearTimeout(titleTimeout.current);
    titleTimeout.current = setTimeout(() => writebackTitle(), 3000);
  };

  const writebackTitle = () => {
    clearTimeout(titleTimeout.current);
    if ((props.widget || {}).title !== title) {
      props.updateWidget({
        title: title || null,
      });
    }
  };

  const handleOptionsMenu = (event) => setAnchorEl(event.currentTarget);

  const handleConfigureMenu = () => setOpenConfigureDialog(true);

  const handleOptionsMenuClose = () => setAnchorEl(null);

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(true);
    setAnchorEl(null);
  };

  const removeConfigureDialog = () => setOpenConfigureDialog(false);

  const handleAlertsMenu = () => setOpenAlertsDialog(true);

  const removeAlertsDialog = () => setOpenAlertsDialog(false);

  const removeConfirmDialog = () => setOpenConfirmDialog(false);

  const removeWidget = () => props.removeWidget(props.widget.guuid);

  // const renameWidget = () => titleInput.current.focus();

  const handleShowIcons = () => {
    if (!showIcons) {
      setShowIcons(true);
    }
  };

  const handleHideIcons = () => setShowIcons(false);

  const { classes = {}, configurable = true, readOnly = false } = props;
  const w = props.widget || {};
  const widgetType = w.type;
  const widgetContents =
    isxContext.widgets[widgetType] || isxContext.widgets.Unsupported;
  const widgetProps = widgetContents.properties || {};
  const configurationOptions = widgetProps.configurationOptions || [];
  const alerts = widgetProps.alerts != null ? widgetProps.alerts : true;

  return (
    <React.Fragment>
      <span
        onMouseOver={isMobile || readOnly ? null : handleShowIcons}
        onMouseLeave={isMobile || readOnly ? null : handleHideIcons}
      >
        <header
          className={[classes.header, "draghandle"].join(" ")}
          style={readOnly ? { cursor: "unset" } : {}}
        >
          {!readOnly && editTitle ? (
            <Input
              inputRef={titleInput}
              classes={{ input: classes.textInput }}
              placeholder={w.default_title || "Unnamed Widget"}
              disableUnderline={true}
              variant="h6"
              value={title}
              onChange={(e) => setWidgetTitle(e.target.value)}
              onBlur={() => {
                writebackTitle();
                setEditTitle(false);
              }}
              style={{
                width: showIcons ? "calc(100% - 120px)" : "100%",
              }}
            />
          ) : (
            <Typography
              noWrap
              className={(!title && classes.headerTitleEmpty) || null}
              style={{
                fontSize: "1em",
                width: showIcons ? "calc(100% - 120px)" : "100%",
                ...(!title && {
                  color: classes.headerTitleEmpty,
                }),
              }}
            >
              {title || w.default_title || "Unnamed Widget"}
            </Typography>
          )}
          {!readOnly && !editTitle && (
            <div>
              {alerts && showIcons && (
                <IconButton
                  className={classes.headerButtons}
                  onClick={handleAlertsMenu}
                >
                  <NotificationsIcon className={classes.headerButtonsIcon} />
                </IconButton>
              )}
              {configurable && showIcons && (
                <IconButton
                  className={classes.headerButtons}
                  onClick={handleConfigureMenu}
                >
                  <SettingsIcon className={classes.headerButtonsIcon} />
                </IconButton>
              )}
              {(showIcons || anchorEl) && (
                <IconButton
                  className={classes.headerButtons}
                  aria-owns={anchorEl ? "widget-options-menu" : null}
                  onClick={handleOptionsMenu}
                >
                  <MoreVertIcon className={classes.headerButtonsIcon} />
                </IconButton>
              )}
            </div>
          )}
        </header>
        <div style={{ width: "100%", height: "calc(100% - 20px)" }}>
          {props.children}
        </div>
      </span>
      {openConfigureDialog &&
        (ConfigureDialog ? (
          <ConfigureDialog
            stacks={props.stacks}
            updateWidget={props.updateWidget}
            widget={props.widget}
            widgetTitle={props.widgetTitle}
            options={configurationOptions}
            open={openConfigureDialog}
            openConfigureDialog={openConfigureDialog}
            removeDialog={removeConfigureDialog}
          />
        ) : (
          <WidgetConfigureDialog
            stacks={props.stacks}
            updateWidget={props.updateWidget}
            dataType={props.dataType}
            widget={props.widget}
            widgetTitle={props.widgetTitle}
            maxTags={props.maxTags}
            maxStacks={props.maxStacks}
            showDerivedTags={props.showDerivedTags}
            filterTagsOptions={props.filterTagsOptions}
            options={configurationOptions}
            open={openConfigureDialog}
            openConfigureDialog={openConfigureDialog}
            removeConfigureDialog={removeConfigureDialog}
          />
        ))}
      <WidgetOptionsMenu
        anchorEl={anchorEl}
        handleOptionsMenuClose={handleOptionsMenuClose}
        handleWidgetDeleteConfirmDialog={handleConfirmDialog}
        editWidgetTitle={editWidgetTitle}
        handleCSVExport={props.exportToCSV}
      />
      <Dialog
        open={openConfirmDialog}
        onClose={removeConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Are you sure that you want to remove this widget?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Press the confirm button to finalize the removal of this widget.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={removeWidget} color="primary" autoFocus>
            Confirm
          </Button>
          <Button onClick={removeConfirmDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openAlertsDialog && (
        <WidgetIndicatorDialog
          stacks={props.stacks}
          updateWidget={props.updateWidget}
          widget={w}
          open={openAlertsDialog}
          removeDialog={removeAlertsDialog}
        />
      )}
    </React.Fragment>
  );
};

Widget.propTypes = {};

export default withStyles(styles)(Widget);
