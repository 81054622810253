import React from "react";
import QueryService from "services/QueryService";
import { ShareTemplateDialog as ShareTemplateDialogComponent } from "components";
import { useSelector } from "react-redux";

const ShareTemplateDialog = (props) => {
  const allWidgets = useSelector((state) => state.isx.widgets);

  async function send(recipients) {
    // construct dashboard content as template invite message payload
    const {
      title,
      widgets: widgetsList = [],
      widgets_layout,
    } = props.dashboard;
    const widgets = widgetsList
      .map((wguuid) => {
        return allWidgets[wguuid];
      })
      .filter(Boolean);
    const template = { title, widgets, widgets_layout };
    const results = await QueryService.sendMessages(
      "share_template",
      { template: JSON.stringify(template) },
      recipients
    );
    const { error, failed } = results;
    return { error, failed };
  }

  return <ShareTemplateDialogComponent {...props} send={send} />;
};

export default ShareTemplateDialog;
