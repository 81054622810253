import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Grid from "@material-ui/core/Grid";
import Widget from "../../widget/Widget";
import { ISXContext } from "services/Utils";
import { TimeframeService } from "services/TimeframeService";
import CSVExporter from "services/CSVExporter";

import moment from "moment";
import { default as _isEqual } from "lodash/isEqual";
import { default as _last } from "lodash/last";

const LubeHistoryWidget = (props) => {
  // console.log("in QM History", props);
  const [currentStreamValues, setCurrentStreamValues] = useState([]);
  const [index, setIndex] = useState(-1);
  const [pending, setPending] = useState(true);

  const isxContext = useContext(ISXContext);

  const lastStreamValues = useRef([]);
  const direction = useRef();

  const {
    widget = {},
    stacks,
    streams,
    timeframe,
    setTimeframe,
    startTime,
    defaultTimeframe,
    maxWindowInSeconds,
  } = props;

  const initialTimeframe = useMemo(() => {
    return widget.options?.timeframe || defaultTimeframe;
  }, [defaultTimeframe, widget.options?.timeframe]);

  const tag = useMemo(() => widget?.tags?.[0], [widget?.tags]);

  const unitVolume = useMemo(() => {
    const tagdc =
      tag && stacks && stacks[tag.stack]?.data_config?.[tag.attribute];
    return tagdc?.unitvolume ? parseFloat(tagdc.unitvolume) : 0.5; // default value is 0.5cc
  }, [stacks, tag]);

  const streamValues = useMemo(() => {
    const tagKey = tag && `${tag.stack}|${tag.attribute}`;
    return (tagKey && streams[tagKey]) ?? [];
  }, [streams, tag]);

  useEffect(() => {
    if (streamValues !== lastStreamValues.current) {
      setPending(false);
      if (timeframe.type === "moving") {
        if (direction.current) {
          // ignore
        } else {
          setCurrentStreamValues(streamValues);
          setIndex(streamValues.length - 1);
        }
      } else {
        setCurrentStreamValues(streamValues);
        if (direction.current === "previous") {
          setIndex(
            streamValues.length -
              (streamValues.length > 0 &&
              lastStreamValues.current.length > 0 &&
              _isEqual(lastStreamValues.current[0], _last(streamValues))
                ? 2
                : 1)
          );
        } else if (direction.current === "next") {
          setIndex(
            streamValues.length > 0 &&
              lastStreamValues.current.length > 0 &&
              _isEqual(streamValues[0], _last(lastStreamValues.current))
              ? 1
              : 0
          );
        } else {
          setCurrentStreamValues(streamValues);
          setIndex(streamValues.length - 1);
        }
      }
      lastStreamValues.current = streamValues;
    }
  }, [streamValues, timeframe.type]);

  const showPrevious = () => {
    direction.current = "previous";
    if (index <= 0) {
      setPending(true);
      setTimeframe(
        TimeframeService.getPreviousTimeframe(
          timeframe,
          startTime,
          maxWindowInSeconds
        )
      );
    } else {
      setIndex((index) => index - 1);
    }
  };

  const showNext = () => {
    direction.current = "next";
    if (index >= currentStreamValues.length - 1) {
      setPending(true);
      setTimeframe(
        TimeframeService.getNextTimeframe(
          timeframe,
          startTime,
          maxWindowInSeconds
        )
      );
    } else {
      setIndex((index) => index + 1);
    }
  };

  const showRecent = () => {
    direction.current = undefined;
    if (_isEqual(timeframe, initialTimeframe)) {
      setIndex(currentStreamValues.length - 1);
    } else {
      setPending(true);
      setTimeframe(initialTimeframe);
    }
  };

  const value = useMemo(
    () => currentStreamValues[index]?.y,
    [index, currentStreamValues]
  );

  const exportToCSV = () => {
    const LUBE_HISTORY_HEADERS_CSV = [
      "Begin Lubrication (ISO 8601)",
      "End Lubrication (ISO 8601)",
      "Begin Lubrication",
      "End Lubrication",
      "Volume of Lubricant Used (cc)",
      "Baseline Friction Level (dB)",
      "Starting Friction Level (dB)",
      "Ending Friction Level (dB)",
      "Mode Used",
      "Reason",
      "User",
    ];

    if (streamValues.length > 0) {
      const rows = [
        LUBE_HISTORY_HEADERS_CSV,
        ...streamValues
          .map((entry) => {
            const value = entry.y;
            const btime = moment(value.begintime);
            const etime = moment(value.endtime);
            return [
              btime.format(),
              etime.format(),
              btime.format("YYYY/MM/DD HH:mm:ss"),
              etime.format("YYYY/MM/DD HH:mm:ss"),
              Number.parseFloat(value.count * unitVolume).toFixed(2),
              value.baseline,
              value.beginvalue,
              value.endvalue,
              value.mode &&
                value.mode.charAt(0).toUpperCase() + value.mode.slice(1),
              value.reason,
              value.user,
            ];
          })
          .reverse(),
      ];

      const filename = CSVExporter.formatFilename(
        props.widgetTitle || "Lube History",
        streamValues[0].x,
        _last(streamValues).x
      );

      CSVExporter.export(filename, rows);
    }
  };

  return (
    <Widget
      {...props}
      dataType="tags"
      maxStacks={1}
      showDerivedTags={false}
      filterTagsOptions={{ matchPrefixes: ["lube_sensor"] }}
      maxTags={1}
      widgetTitle={`${isxContext.labels.deviceCapitalized} Last Lubrication Cycle`}
      exportToCSV={exportToCSV}
    >
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        wrap="nowrap"
        style={{ overflowY: "auto", height: "100%" }}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          style={{ paddingLeft: 16, paddingTop: 5 }}
        >
          <Grid container direction="column">
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">
                  Volume of Lubricant Used:&nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value
                    ? `${Number.parseFloat(value.count * unitVolume).toFixed(
                        2
                      )} cc`
                    : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">
                  Last Lubricated:&nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value?.endtime
                    ? moment(value.endtime).format("DD MMM YYYY HH:mm:ss")
                    : "Not available"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">Mode Used:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value?.mode
                    ? value.mode.charAt(0).toUpperCase() + value.mode.slice(1)
                    : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">
                  Baseline Friction Level:&nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value?.baseline ? value.baseline : 0} dB
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">
                  Starting Friction Level:&nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value?.beginvalue ? value.beginvalue : 0} dB
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item>
                <Typography variant="subtitle2">
                  Ending Friction Level:&nbsp;
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {value?.endvalue ? value.endvalue : 0} dB
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={showPrevious} disabled={pending}>
              <NavigateBeforeIcon />
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={showRecent}
              disabled={
                pending ||
                (TimeframeService.disableReset(timeframe, initialTimeframe) &&
                  index === currentStreamValues.length - 1)
              }
            >
              {initialTimeframe.type === "moving" ? "Recent" : "Reset"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={showNext}
              disabled={
                pending ||
                (timeframe.type === "moving" &&
                  index === currentStreamValues.length - 1)
              }
            >
              Next <NavigateNextIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Widget>
  );
};

export default LubeHistoryWidget;
