import React, { useRef, useContext } from "react";
import { SizeMe } from "react-sizeme";
import ReactSpeedometer from "react-d3-speedometer";
import Widget from "../../widget/Widget";
import ISXUtils, { ISXContext } from "services/Utils";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    bottom: 10,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
})(Backdrop);

const enableViewPrevious = false; //flag that allows the overlay backdrop to be cleared by clicking on it

const GaugeWidget = (props) => {
  const lastSize = useRef(null);
  const lastConfig = useRef(null);
  const lastWidget = useRef(null);
  const lastNeedleColor = useRef(null);
  const [showBackdrop, setShowBackdrop] = React.useState(true);
  if ("status" in props && !showBackdrop && props.status) {
    setShowBackdrop(true);
  }

  const viewPrevious = () => {
    if (enableViewPrevious) {
      setShowBackdrop(false);
    }
  };
  const { widget } = props;

  const {
    points: customSegmentStops,
    colors: segmentColors,
    range,
  } = props.config || {};

  let value = props.value;

  //const range = (widget.options || {}).range || {};
  const min = range.min ? parseFloat(range.min) : 0;
  const max = range.max ? parseFloat(range.max) : 1000;

  const isxContext = useContext(ISXContext);

  // expect a number
  if (typeof value === "string") {
    value = Number(value);
  }

  let needleColor;

  // value actually plotted, may not be actual value if too large or too small
  let plottedValue;
  if (value < min) {
    plottedValue = min - 1;
    needleColor = "red";
  } else if (value > max) {
    plottedValue = max + 1;
    needleColor = "red";
  } else {
    plottedValue = value;
    needleColor = "black";
  }

  let forceRender = false;

  if (lastNeedleColor.current !== needleColor) {
    lastNeedleColor.current = needleColor;
    forceRender = true;
  }

  // need to make value string, since we will override speedometer component display
  value =
    value == null ? "---" : "" + ISXUtils.formattedValue(value, isxContext);

  if (lastWidget.current !== widget) {
    lastWidget.current = widget;
    forceRender = true;
  }

  // this comparison may be redundant if widget has changed
  if (lastConfig.current !== props.config) {
    lastConfig.current = props.config;
    forceRender = true;
  }

  return (
    <Widget
      {...props}
      widget={widget}
      dataType="tags"
      maxTags={1}
      widgetTitle="Gauge"
    >
      <SizeMe monitorHeight monitorWidth>
        {({ size }) => {
          if (size !== lastSize.current) {
            lastSize.current = size;
            forceRender = true;
          }
          const width =
            Math.max(Math.min(size.height * 1.7, size.width) - 20, 1) || 1;
          const ringWidth =
            Math.max(Math.min(size.height / 2, size.width / 4), 1) || 1;
          const height = size.height || 1;
          const currentValueText = value;
          const needleHeightRatio = ringWidth < 50 ? 0.7 : 0.9;
          const valueFontSize = Math.max(Math.min((32 * width) / 600, 24), 12);
          const labelFontSize = Math.max(Math.min((16 * width) / 600, 14), 9);

          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LimitedBackdrop
                open={props.status ? false : showBackdrop}
                onClick={(e) => viewPrevious()}
              >
                <Typography variant="button">Not Available</Typography>
              </LimitedBackdrop>
              <ReactSpeedometer
                style={{ overflow: "visible" }}
                minValue={min}
                maxValue={max}
                width={width}
                height={height}
                forceRender={forceRender}
                value={!plottedValue == null ? 0 : plottedValue}
                currentValueText={currentValueText}
                needleTransitionDuration={forceRender ? 0 : 500}
                needleHeightRatio={needleHeightRatio}
                needleColor={needleColor}
                customSegmentStops={customSegmentStops}
                segmentColors={segmentColors}
                ringWidth={ringWidth}
                valueTextFontSize={`${valueFontSize}px`}
                labelFontSize={`${labelFontSize}px`}
              ></ReactSpeedometer>
            </div>
          );
        }}
      </SizeMe>
    </Widget>
  );
};

export default GaugeWidget;
