import * as types from "./types";
import moment from "moment";
import { default as _isEqual } from "lodash/isEqual";
import { default as _last } from "lodash/last";
import { default as _omit } from "lodash/omit";
import { default as _defaults } from "lodash/defaults";

var stackUpdateIntervals = {}; // history of update intervals

const INITIAL_STATE = {
  isAuthenticated: false,
  username: "",
  password: "",
  user: {},
  dashboards: {},
  widgets: {},
  widgetsLayouts: {},
  refreshIntervals: null, // per stack update intervals (in ms)
  stacks: {}, // all stack dataset updates, etc.
  streams: {}, // data streams from widget subscritions
  orgs: {},
  stacks2orgs: {}, // map stacks to orgs they belong to
  messages: {}, // messages for this user as recipient
  tagEventIntervals: {}, // event intervals, keyed by <stackid>|<tag>
};

const isxReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REFRESH_RESPONSE: {
      return {
        ...state,
        isAuthenticated: action.response.isAuthenticated,
        user: action.response.user,
      };
    }
    case types.LOGIN_RESPONSE: {
      return {
        ...state,
        isAuthenticated: action.response.isAuthenticated,
        username: action.response.username,
        password: action.response.password,
        user: action.response.user,
      };
    }
    case types.LOGOUT_RESPONSE: {
      if (action.success) {
        stackUpdateIntervals = {};
        return INITIAL_STATE;
      } else {
        return state;
      }
    }
    case types.GET_ALL_ITEMS_RESPONSE: {
      const now = moment();
      const items = (action.items || []).reduce((acc, item) => {
        acc[item.guuid] = { ...item, lastFetched: now };
        return acc;
      }, {});
      if (action.items) {
        let newStackUpdateIntervals = {};
        action.items.forEach((item) => {
          const rinterval = parseInt(item.upd_int || 10);
          let history = stackUpdateIntervals[item.guuid] || [];
          history = history.slice(0, 9);
          history.unshift(rinterval);
          newStackUpdateIntervals[item.guuid] = history;
        });
        stackUpdateIntervals = newStackUpdateIntervals;
      }
      let refreshIntervals = {};
      Object.entries(stackUpdateIntervals).forEach((entry) => {
        const [guuid, h] = entry;
        const minv = Math.min(...h);
        const maxv = Math.max(...h);
        if (minv === maxv) {
          // short circuit if all intervals are the same
          refreshIntervals[guuid] = minv;
        } else {
          // lower values get more weight than higher values
          // excess over min value is reduced
          // const weightedh = h.map(v => minv + (v - minv) / 2);
          const weightedh = h.map((v) => minv + Math.log(v - minv + 1));
          refreshIntervals[guuid] =
            weightedh.reduce((acc, val) => acc + val) / weightedh.length;
        }
      });
      return {
        ...state,
        stacks: items,
        refreshIntervals,
      };
    }
    case types.GET_DASHBOARD_RESPONSE: {
      const { guuid, dashboard } = action;
      const widgets = (dashboard || {}).widgets || [];
      const widgetIds = widgets.map((w) => w.guuid);
      const widgetsLayout = (dashboard || {}).widgets_layout || {};
      let newWidgets = {};
      widgets.forEach((w) => {
        w.dashboard = guuid;
        // compare old vs new and update only if necessary,
        // to avoid unnecessary re-renders of widgets
        if (!_isEqual(state.widgets[w.guuid], w)) {
          newWidgets[w.guuid] = w;
        }
      });
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [guuid]: {
            ...dashboard,
            widgets: widgetIds,
          },
        },
        widgets: {
          ...state.widgets,
          ...newWidgets,
        },
        widgetsLayouts: {
          ...state.widgetsLayouts,
          [guuid]: widgetsLayout,
        },
      };
    }
    case types.GET_DASHBOARDS_RESPONSE: {
      const { dashboards } = action;
      let allDashboards = {};
      let allWidgets = {};
      let allWidgetsLayouts = {};
      dashboards.forEach((dashboard) => {
        if (!dashboard) {
          return;
        }
        const widgets = (dashboard || {}).widgets || [];
        const widgetIds = widgets.map((w) => w.guuid);
        allDashboards[dashboard.guuid] = {
          ...dashboard,
          widgets: widgetIds,
        };
        widgets.forEach((w) => {
          w.dashboard = dashboard.guuid;
          allWidgets[w.guuid] = w;
        });
        const widgetsLayout = (dashboard || {}).widgets_layout || {};
        allWidgetsLayouts[dashboard.guuid] = widgetsLayout;
      });
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          ...allDashboards,
        },
        widgets: {
          ...state.widgets,
          ...allWidgets,
        },
        widgetsLayouts: {
          ...state.widgetsLayouts,
          ...allWidgetsLayouts,
        },
      };
    }
    case types.CREATE_DASHBOARD_RESPONSE: {
      const { dashboard } = action;
      const guuid = dashboard["guuid"];

      let userDashboards = state.user.dashboards || [];
      if (userDashboards.indexOf(guuid) === -1) {
        userDashboards = [...userDashboards];
        userDashboards.push(guuid);
      }

      let newState = {
        ...state,
        user: {
          ...state.user,
          dashboards: userDashboards,
        },
        dashboards: {
          ...state.dashboards,
          [guuid]: {
            ...(state.dashboards[guuid] || {}),
            ...dashboard,
          },
        },
      };
      return newState;
    }
    case types.UPDATE_DASHBOARD_RESPONSE: {
      const { guuid, properties } = action;
      let newState = {
        ...state,
        dashboards: {
          ...state.dashboards,
          [guuid]: {
            ...(state.dashboards[guuid] || {}),
            ...properties,
          },
        },
      };
      if ("widgets" in properties) {
        const widgetIds = properties.widgets.map((w) => w.guuid);
        let updatedWidgets = {};
        properties.widgets.forEach((w) => {
          updatedWidgets[w.guuid] = w;
        });
        newState.dashboards[guuid].widgets = widgetIds;
        newState.widgets = {
          ...(state.widgets || {}),
          ...updatedWidgets,
        };
        if ("widgets_layout" in properties) {
          newState.widgetsLayouts = {
            ...(state.widgetsLayouts || {}),
            [guuid]: {
              ...properties.widgets_layout,
            },
          };
        }
      }
      return newState;
    }
    case types.REMOVE_DASHBOARD_RESPONSE: {
      const { guuid } = action;
      let userDashboards = state.user.dashboards || [];
      const userDashboardIndex = userDashboards.indexOf(guuid);
      if (userDashboardIndex >= 0) {
        userDashboards = [...userDashboards];
        userDashboards.splice(userDashboardIndex, 1);
      }

      const dashboards = { ...state.dashboards };
      delete dashboards[guuid];

      const widgetsLayouts = { ...state.widgetsLayouts };
      delete widgetsLayouts[guuid];
      return {
        ...state,
        user: {
          ...state.user,
          dashboards: userDashboards,
        },
        dashboards: dashboards,
        widgetsLayouts: widgetsLayouts,
      };
    }
    case types.UPDATE_DASHBOARDS_RESPONSE: {
      const { operations } = action;
      const newState = {
        ...state,
        dashboards: { ...state.dashboards },
        widgets: { ...state.widgets },
        widgetsLayouts: { ...state.widgetsLayouts },
      };
      operations.creations.forEach((dashboard) => {
        dashboard.widgets &&
          dashboard.widgets.forEach((widget) => {
            newState.widgets[widget.guuid] = widget;
          });
        const widgetIds = dashboard.widgets?.map((w) => w.guuid) ?? [];
        newState.dashboards[dashboard.guuid] = {
          ...dashboard,
          widgets: widgetIds,
        };
        if (dashboard.widgets_layout) {
          newState.widgetsLayouts[dashboard.guuid] = dashboard.widgetsLayout;
        }
      });
      operations.updates.forEach((dashboard) => {
        dashboard.widgets &&
          dashboard.widgets.forEach((widget) => {
            newState.widgets[widget.guuid] = widget;
          });
        const widgetIds = dashboard.widgets?.map((w) => w.guuid) ?? [];
        newState.dashboards[dashboard.guuid] = {
          ...dashboard,
          widgets: widgetIds,
        };
        if (dashboard.widgets_layout) {
          newState.widgetsLayouts[dashboard.guuid] = dashboard.widgetsLayout;
        }
      });
      operations.deletions.forEach((guuid) => {
        delete newState.dashboards[guuid];
        delete newState.widgetsLayouts[guuid];
      });
      return newState;
    }
    case types.UPDATE_WIDGETS_LAYOUT_RESPONSE: {
      const { guuid, layout, version } = action;
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [guuid]: {
            ...(state.dashboards[guuid] || {}),
            _version: version,
          },
        },
        widgetsLayouts: {
          ...(state.widgetsLayouts || {}),
          [guuid]: { ...layout },
        },
      };
    }
    case types.ADD_WIDGET_RESPONSE: {
      const { guuid, widget } = action;
      let widgetIds = state.dashboards[guuid].widgets || [];
      widgetIds = widgetIds.slice();
      widgetIds.push(widget.guuid);
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [guuid]: {
            ...(state.dashboards[guuid] || {}),
            widgets: widgetIds,
          },
        },
        widgets: {
          ...(state.widgets || {}),
          [widget.guuid]: { ...widget, dashboard: guuid },
        },
      };
    }
    case types.UPDATE_WIDGET_RESPONSE: {
      const { guuid, properties } = action;
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [guuid]: {
            ...(state.widgets[guuid] || {}),
            ...properties,
          },
        },
      };
    }
    case types.REMOVE_WIDGET_RESPONSE: {
      const { dashboardGuuid, widgetGuuid } = action;
      let widgetIds = state.dashboards[dashboardGuuid].widgets || [];
      widgetIds = widgetIds.filter((wid) => wid !== widgetGuuid);
      let widgets = { ...state.widgets };
      delete widgets[widgetGuuid];
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [dashboardGuuid]: {
            ...state.dashboards[dashboardGuuid],
            widgets: widgetIds,
          },
        },
        widgets: widgets,
      };
    }
    /*case types.GET_ITEMS_RESPONSE: {
      const now = moment();
      let items = (action.items || []).reduce((acc, item) => {
        acc[item.guuid] = {
          ...(state.items[item.guuid] || {}),
          ...item,
          lastFetched: now,
        };
        return acc;
      }, {});
      return {
        ...state,
        items: {
          ...state.items,
          ...items,
        },
      };
    }*/
    case types.GET_ITEMS_RESPONSE: {
      const now = moment();
      let items = (action.items || []).reduce((acc, item) => {
        acc[item.guuid] = {
          ...(state.stacks[item.guuid] || {}),
          ...item,
          lastFetched: now,
        };
        return acc;
      }, {});
      return {
        ...state,
        stacks: {
          ...state.stacks,
          ...items,
        },
      };
    }
    case types.GET_ITEM_RESPONSE: {
      const { item } = action;
      return {
        ...state,
        stacks: {
          ...state.stacks,
          [item.guuid]: {
            ...(state.stacks[item.guuid] || {}),
            ...item,
            lastFetched: moment(),
          },
        },
      };
    }
    case types.GET_ORGS_RESPONSE: {
      let orgs = (action.orgs ?? []).reduce((acc, org) => {
        acc[org.guuid] = {
          ...(state.orgs[org.guuid] ?? {}),
          ...org,
        };
        return acc;
      }, {});
      if (!_isEqual(orgs, state.orgs)) {
        // rebuild mapping of stacks to orgs they belong to
        const updatedOrgs = {
          ...state.orgs,
          ...orgs,
        };
        const stacks2orgs = Object.values(updatedOrgs).reduce((oacc, org) => {
          return org.devices?.reduce((iacc, stack) => {
            const entry = _defaults(iacc, { [stack]: new Set() })[stack];
            entry.add(org.guuid);
            return iacc;
          }, oacc);
        }, {});
        return {
          ...state,
          orgs: updatedOrgs,
          stacks2orgs,
        };
      } else {
        return state;
      }
    }
    case types.SET_STATE_TABLE: {
      const { guuid, statetable } = action;
      return {
        ...state,
        statetables: {
          ...state.statetables,
          [guuid]: statetable,
        },
      };
    }
    case types.SET_HOURLY_AGGREGATES: {
      const { guuid, aggregates } = action;
      return {
        ...state,
        hourlyAggregates: {
          ...state.hourlyAggregates,
          [guuid]: aggregates,
        },
      };
    }
    case types.SET_DATASET_CONTENT: {
      const { guuid, dataset, content } = action;
      return {
        ...state,
        datasetContent: {
          ...state.datasetContent,
          [guuid]: {
            ...(state.datasetContent[guuid] || {}),
            [dataset]: content,
          },
        },
      };
    }
    case types.UPDATE_CALIBRATION_RESPONSE: {
      const { guuid, params } = action;
      // update params in place
      return {
        ...state,
        items: {
          ...state.items,
          [guuid]: {
            ...state.items[guuid],
            ...params,
          },
        },
      };
    }

    case types.INITIALIZE_STREAM: {
      const { key } = action;
      return {
        ...state,
        datasets: {
          ...state.datasets,
          [key]: Array(86400).fill(null),
        },
      };
    }

    case types.UPDATE_USER: {
      const { props } = action;
      return {
        ...state,
        user: {
          ...state.user,
          ...props,
        },
      };
    }

    case types.UPDATE_ITEM: {
      const { guuid, props } = action;
      return {
        ...state,
        stacks: {
          ...state.stacks,
          [guuid]: {
            ...state.stacks[guuid],
            ...props,
            lastFetched: moment(),
          },
        },
      };
    }

    case types.GET_USER_MESSAGES_RESPONSE: {
      const { messages } = action;
      const allMessages = messages.reduce((acc, m) => {
        acc[m.guuid] = m;
        return acc;
      }, {});
      return {
        ...state,
        messages: allMessages,
      };
    }

    case types.CREATE_USER_MESSAGE_RESPONSE: {
      const { message } = action;
      const guuid = message.guuid;

      return {
        ...state,
        messages: {
          ...(state.messages || {}),
          [guuid]: { ...message },
        },
      };
    }

    case types.UPDATE_USER_MESSAGE_RESPONSE: {
      const { guuid, message } = action;
      return {
        ...state,
        messages: {
          ...(state.messages || {}),
          [guuid]: { ...message },
        },
      };
    }

    case types.REMOVE_USER_MESSAGE_RESPONSE: {
      const { guuid } = action;
      const messages = { ...state.messages };
      delete messages[guuid];
      return {
        ...state,
        messages,
      };
    }

    case types.UPDATE_SUBSCRIPTION_RESPONSE: {
      const { subscriptionId, data } = action;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [subscriptionId]: {
            ...state.subscriptions[subscriptionId],
            ...data,
          },
        },
      };
    }

    case types.UPDATE_STREAMS: {
      const { streamData } = action;
      const updatedStreams = { ...state.streams };
      Object.entries(streamData).forEach((entry) => {
        const [tagKey, { data, earliestNeededTime }] = entry;
        let updatedStreamData = updatedStreams[tagKey] ?? [];

        // expunge data prior to earliest start time
        let sidx;
        for (sidx = 0; sidx < updatedStreamData.length; sidx++) {
          if (updatedStreamData[sidx].x >= earliestNeededTime) {
            break;
          }
        }
        if (sidx > 0) {
          updatedStreamData = updatedStreamData.slice(sidx);
        }
        // add new data to end
        let eidx = 0;
        if (data?.length > 0 && updatedStreamData.length > 0) {
          const lastEntry = _last(updatedStreamData);
          for (; eidx < data.length; eidx++) {
            if (lastEntry.x < data[eidx].x) {
              break;
            }
          }
        }
        updatedStreamData = [
          ...updatedStreamData,
          ...(eidx > 0 ? data.slice(eidx) : data),
        ];
        updatedStreams[tagKey] = updatedStreamData;
      });
      return {
        ...state,
        streams: updatedStreams,
      };
    }

    case types.REMOVE_STREAMS: {
      const { tags } = action;
      return {
        ...state,
        streams: _omit(state.streams, tags),
      };
    }

    case types.CLEAR_STREAMS: {
      return {
        ...state,
        streams: {},
      };
    }

    case types.UPDATE_TAG_EVENT_INTERVALS: {
      const { eventIntervals } = action;
      return {
        ...state,
        tagEventIntervals: {
          ...state.tagEventIntervals,
          ...eventIntervals,
        },
      };
    }

    case types.CLEAR_TAG_EVENT_INTERVALS: {
      return {
        ...state,
        tagEventIntervals: {},
      };
    }

    default:
      return state;
  }
};

export default isxReducer;
