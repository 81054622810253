import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Column from "react-virtualized/dist/commonjs/Table/Column";
import Table from "react-virtualized/dist/commonjs/Table";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import moment from "moment";
import WebWorker from "../../workers/workerSetup";
import stateTableProcessingWorker from "../../workers/stateTableProcessingWorker";
import stateHistoryProcessingWorker from "../../workers/stateHistoryProcessingWorker";
import { ChartService } from "../../../services/ChartService";
import { TimeSeriesService } from "../../../services/TimeSeriesService";
import { TimeframeService } from "../../../services/TimeframeService";
import { WIDGET_COLORS, WIDGET_COLORS_ARRAY } from "../../color/colors";
import CSVExporter from "../../../services/CSVExporter";
import Widget from "../../widget/Widget";

import { default as _isEqual } from "lodash/isEqual";
import { default as _pick } from "lodash/pick";
import { default as _defaults } from "lodash/defaults";

var Chart = require("chart.js");

const _rowStyle = ({ index }) => {
  let style = { borderBottom: "1px solid #e0e0e0" };
  if (index < 0) {
    style.textTransform = "none";
  } else if (index % 2 === 0) {
    // won't see border without this
    style.zIndex = 1;
  } else {
    style.backgroundColor = "#fafafa";
  }
  style.fontSize = "0.8125rem";
  return style;
};

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const convertMillisecondsToLabel = (millis, SEGMENT_BY_HOURS) => {
  if (millis === (SEGMENT_BY_HOURS ? 3600000 : 86400000)) {
    return SEGMENT_BY_HOURS ? "1:00:00" : "24:00:00";
  }
  return new Date(millis).toISOString().slice(SEGMENT_BY_HOURS ? 11 : 14, 19);
};

class StateHistoryWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      loading: false,
      dataStart: null,
      dataEnd: null,
      dataCount: null,
      stateHistoryData: [],
      chartOptions: {
        type: "bar",
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        bounds: "ticks",
        elements: { line: { tension: 0 } },
        title: { display: false, text: "" },
        scales: {
          xAxes: [
            {
              display: true,
              type: "time",
              distribution: "series",
              time: {
                unit: "hour",
                displayFormats: {
                  hour: "MM/DD HH:00",
                  day: "MM/DD",
                },
              },
              ticks: {
                autoSkip: true,
                beginAtZero: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
              stacked: true,
              offset: true,
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: { display: true, labelString: "%" },
              stacked: true,
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
              },
            },
          ],
        },
        annotation: {
          events: ["click"],
          annotations: [],
        },
      },
    };

    this.chartRef = React.createRef();
    this.tableRef = React.createRef();

    this.stateHistoryWorker = new WebWorker(stateHistoryProcessingWorker);
    this.stateHistoryWorker.addEventListener("message", (event) => {
      this.handleCallback(event.data);

      this.SEGMENT_BY_HOURS =
        this.props.endTime &&
        this.props.startTime &&
        this.props.endTime.unix() - this.props.startTime.unix() < 25 * 60 * 60;
    });

    this.stateTableWorker = new WebWorker(stateTableProcessingWorker);
    this.stateTableWorker.addEventListener("message", (event) => {
      this.stateHistoryWorker.postMessage({
        dataset: event.data.dataset,
        history: event.data.result.history,
        segmentByHours: this.SEGMENT_BY_HOURS,
        stateCounts: event.data.stateCounts,
      });
    });
  }

  _getTagStateProps = (tag, props) => {
    const attr = tag?.attribute?.split("|")?.[0];
    const stateProps = _pick(
      props.stacks?.[tag?.stack]?.data_config?.[attr] ?? {},
      [
        "states",
        "statesNoDataColor",
        "statesNoDataMinDuration",
        "statesSeriesType",
      ]
    );
    return stateProps;
  };

  processDatasetsForChart = ({ dataset, result }) => {
    const stateProps = this._getTagStateProps(dataset.tag, this.props);

    const EDGES_ONLY = (stateProps.statesSeriesType || "full") === "edges";

    const STATES_FOR_MACHINE = stateProps.states ?? [];

    const FULL_COLORS = EDGES_ONLY
      ? [WIDGET_COLORS.red, WIDGET_COLORS.green]
      : WIDGET_COLORS_ARRAY;

    const NO_DATA_COLOR = stateProps.statesNoDataColor;

    const OUTPUT_UNIT =
      (this.props.widget.options.statereporting || {}).outputUnit || "percent";

    //We need to ensure all datasets have entries for all times to fix a bug in Chart.js
    const buckets = Array.from(
      Array.from(result.entries())
        .flatMap(([label, data]) => data)
        .reduce((buckets, entry) => {
          buckets.add(entry.x);
          return buckets;
        }, new Set())
        .values()
    ).sort((l, r) => l - r);

    const newDatasets = Array.from(result.entries()).map(
      ([label, data], index) => {
        const stateForDataset = STATES_FOR_MACHINE.find(
          (state) => state.label === label
        );

        const datasetClone = { ...dataset };
        datasetClone.type = "bar";
        dataset.backgroundColor = dataset.coreColor;
        dataset.borderColor = dataset.coreColor;
        datasetClone.rawData = []; //Clear it out
        datasetClone.label = label;
        datasetClone.stack = "all-data";
        datasetClone.sortIndex = !!stateForDataset ? stateForDataset.id : 1000;

        if (
          (!!stateForDataset && stateForDataset.display === "off") ||
          datasetClone.label === "No Data"
        ) {
          datasetClone.hidden = true;
        }

        const color = FULL_COLORS[index % FULL_COLORS.length];
        const alphaColor =
          (!!stateForDataset && stateForDataset.color) ||
          (datasetClone.label === "No Data" && NO_DATA_COLOR) ||
          Chart.helpers.color(color).rgbString();

        datasetClone.backgroundColor = alphaColor;
        datasetClone.borderColor = alphaColor;
        datasetClone.pointBackgroundColor = color;
        datasetClone.pointHoverBackgroundColor = color;

        const fullsets = buckets.map((x) => {
          const value = data.find((datum) => datum.x === x);
          return !!value ? value : { x, y: 0, raw: 0 };
        });

        if (OUTPUT_UNIT === "percent") {
          datasetClone.data = fullsets.map((datum) => {
            return {
              x: datum.x,
              raw: datum.y,
            };
          });
        } else if (OUTPUT_UNIT === "time") {
          datasetClone.data = fullsets.map((datum) => ({
            x: datum.x,
            y: datum.y / (this.SEGMENT_BY_HOURS ? 60000 : 3600000),
            raw: datum.y,
          }));
        } else if (OUTPUT_UNIT === "count") {
          datasetClone.data = fullsets.map((datum) => ({
            ...datum,
            count: datum.y,
          }));
        }

        return datasetClone;
      }
    );

    if (OUTPUT_UNIT === "percent") {
      // compute total time spent over all states for each period time window
      const totalPeriodTimes = newDatasets.reduce((acc, ds) => {
        ds.data.forEach((datum) => {
          _defaults(acc, { [datum.x]: 0 })[datum.x] += datum.raw ?? 0;
        });
        return acc;
      }, {});
      newDatasets.forEach((ds) => {
        ds.data.forEach((datum) => {
          // compute pct as time in state over total time of period
          const pct = 100 * (datum.raw / totalPeriodTimes[datum.x]);
          datum.y = pct;
          datum.percent = pct;
        });
      });
    }
    newDatasets.sort((left, right) => left.sortIndex - right.sortIndex);
    return newDatasets.every((set) => set.hidden)
      ? newDatasets.map((set) => {
          set.hidden = false;
          return set;
        })
      : newDatasets;
  };

  processOptionsForChart = (newDatasets) => {
    const OUTPUT_UNIT =
      (this.props.widget.options.statereporting || {}).outputUnit || "percent";

    const tags = (this.props.widget || {}).tags || [];
    const stacks = this.props.stacks || {};
    const newChartOptions = { ...this.state.chartOptions };
    if (newChartOptions && newChartOptions.scales) {
      const units = tags.reduce(
        (units, tag) => {
          const st = stacks[tag.stack] || {};
          const dconfig = st.data_config || {};
          if (st) {
            const a = tag.attribute;
            const aunits = (dconfig[a] || {}).unit || "-";
            units.unique.add(aunits);
            units.all.push(aunits);
          }
          return units;
        },
        { unique: new Set(), all: [] }
      );

      newChartOptions.scales.yAxes[0].scaleLabel.labelString =
        ChartService.getYAxesLabels(units.unique, units.all);
    }

    if (newChartOptions && newChartOptions.title) {
      newChartOptions.title.text =
        tags
          .map((t) => t.attribute[0].toUpperCase() + t.attribute.substr(1))
          .join(", ") || "untitled";
    }

    newChartOptions.scales.xAxes[0].time.unit = this.SEGMENT_BY_HOURS
      ? "hour"
      : "day";
    newChartOptions.scales.xAxes[0].scaleLabel.labelString = this
      .SEGMENT_BY_HOURS
      ? "Hour"
      : "Day";

    if (
      !!newDatasets &&
      !!newDatasets[0] &&
      !!newDatasets[0].data &&
      newDatasets[0].data.length > 0
    ) {
      newChartOptions.scales.xAxes[0].time.min =
        newDatasets[0].data[0].x - (this.SEGMENT_BY_HOURS ? 3600000 : 86400000);
      newChartOptions.scales.xAxes[0].time.max =
        newDatasets[0].data[newDatasets[0].data.length - 1].x +
        (this.SEGMENT_BY_HOURS ? 3600000 : 86400000);
    }

    if (OUTPUT_UNIT === "percent") {
      newChartOptions.scales.yAxes[0].ticks.max = 100;
      newChartOptions.scales.yAxes[0].scaleLabel.labelString =
        "Percent of Time";
      newChartOptions.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += " - ";
            }

            label = label + tooltipItem.yLabel.toFixed(2) + "%";
            return label;
          },
        },
      };
    } else if (OUTPUT_UNIT === "time") {
      newChartOptions.scales.yAxes[0].ticks.max = this.SEGMENT_BY_HOURS
        ? 60
        : 24;
      newChartOptions.scales.yAxes[0].scaleLabel.labelString = "Total Time";
      newChartOptions.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += " - ";
            }

            return (
              label +
              convertMillisecondsToLabel(
                (this.SEGMENT_BY_HOURS ? 60000 : 3600000) * tooltipItem.yLabel,
                this.SEGMENT_BY_HOURS
              )
            );
          },
        },
      };
    } else if (OUTPUT_UNIT === "count") {
      newChartOptions.scales.yAxes[0].ticks.max = newDatasets
        .map((set) => set.data)
        .reduce((totals, series) =>
          totals.map((total, index) => total + series[index])
        )
        .reduce((max, datum) => (max > datum.y ? max : datum.y));
      newChartOptions.scales.yAxes[0].scaleLabel.labelString = "# of Cycles";

      newChartOptions.tooltips = {
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += " - ";
            }

            return label + tooltipItem.yLabel;
          },
        },
      };
    }

    return newChartOptions;
  };

  handleCallback = ({ dataset, result }) => {
    const newDatasets = this.processDatasetsForChart({ dataset, result });
    const newChartOptions = this.processOptionsForChart(newDatasets);

    const updateChart =
      (!this.state.stateHistoryData && !!newDatasets) ||
      (!!this.state.stateHistoryData && !newDatasets) ||
      this.state.stateHistoryData.length !== newDatasets.length ||
      this.state.stateHistoryData.some((dataset, index) => {
        const newDataset = newDatasets[index];
        return (
          dataset.label !== newDataset.label ||
          dataset.backgroundColor !== newDataset.backgroundColor ||
          dataset.borderColor !== newDataset.borderColor ||
          dataset.data.length !== newDataset.data.length ||
          dataset.data.some((datapoint, pointIndex) => {
            const newDatapoint = newDataset.data[pointIndex];
            return (
              datapoint.x !== newDatapoint.x || datapoint.y !== newDatapoint.y
            );
          })
        );
      });

    const updateOptions =
      JSON.stringify(this.state.chartOptions) !==
      JSON.stringify(newChartOptions);

    if (updateOptions || updateChart) {
      this.setState({
        stateHistoryData: newDatasets,
        chartOptions: newChartOptions,
        pending: false,
        loading: false,
      });
    } else {
      this.setState({ pending: false, loading: false });
    }
  };

  componentWillUnmount = () => {
    // this is a HACK needed due to bug in react-chartjs-2
    this.state.stateHistoryData.forEach((ds) => {
      Object.keys(ds._meta || {}).forEach((id) => {
        let meta = ds._meta[id];
        if (meta.controller == null) {
          delete ds._meta[id];
        }
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const oldTags = (prevProps.widget || {}).tags || [];
    const newTags = (this.props.widget || {}).tags || [];

    this.SEGMENT_BY_HOURS =
      this.props.endTime &&
      this.props.startTime &&
      this.props.endTime.unix() - this.props.startTime.unix() < 25 * 60 * 60;

    const settingsChanged =
      prevProps.widget?.options?.statereporting?.outputUnit !==
        this.props.widget?.options?.statereporting?.outputUnit ||
      oldTags.length !== newTags.length ||
      oldTags.some(
        (tag, index) =>
          tag.stack !== newTags[index].stack ||
          tag.attribute !== newTags[index].attribute ||
          !_isEqual(
            this._getTagStateProps(tag, prevProps),
            this._getTagStateProps(tag, this.props)
          )
      ) ||
      this.props.startTime !== prevProps.startTime ||
      this.props.endTime !== prevProps.endTime;

    this.populateData(settingsChanged);

    if (!!this.tableRef.current) {
      this.tableRef.current.forceUpdateGrid();
    }

    ChartService.refreshChart(
      this.props.widget,
      this.props.stacks,
      this.state.chartOptions,
      this.chartRef,
      prevProps
    );
  };

  populateData(settingsChanged) {
    const newDatasets = TimeSeriesService.populateDataSets(this.props);

    const OUTPUT_UNIT =
      (this.props.widget.options.statereporting || {}).outputUnit || "percent";

    if (!!newDatasets && newDatasets.length > 0) {
      newDatasets.forEach((dataset) => {
        const stateProps = this._getTagStateProps(dataset.tag, this.props);
        const STATES_FOR_MACHINE = stateProps.states || [];
        const EDGES_ONLY = (stateProps.statesSeriesType ?? "full") === "edges";

        const datachanged =
          !!dataset.rawData &&
          dataset.rawData.length > 0 &&
          (!this.state.dataStart ||
            this.state.dataStart !== dataset.rawData[0].x ||
            !this.state.dataEnd ||
            this.state.dataEnd !==
              dataset.rawData[dataset.rawData.length - 1].x ||
            !this.state.dataCount ||
            this.state.dataCount !== dataset.rawData.length);

        const newStart =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData[0].x) ||
          0;
        const newEnd =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData[dataset.rawData.length - 1].x) ||
          0;
        const newCount =
          (!!dataset.rawData &&
            dataset.rawData.length > 0 &&
            dataset.rawData.length) ||
          0;

        if (datachanged || settingsChanged) {
          this.setState(
            {
              pending: true,
              dataStart: newStart,
              dataEnd: newEnd,
              dataCount: newCount,
            },
            () => {
              setTimeout(() => {
                if (this.state.pending) {
                  this.setState({
                    loading: true,
                  });
                }
              }, 250);
            }
          );
          dataset.missingDataCutoff =
            stateProps.statesNoDataMinDuration?.type === "set"
              ? (stateProps.statesNoDataMinDuration?.value ?? 0) * 1000
              : null;

          this.stateTableWorker.postMessage({
            dataset,
            startTime:
              (!!this.props.startTime && this.props.startTime.valueOf()) ||
              newStart,
            endTime:
              (!!this.props.endTime && this.props.endTime.valueOf()) || newEnd,
            states: STATES_FOR_MACHINE,
            edgesOnly: EDGES_ONLY,
            stateCounts: OUTPUT_UNIT === "count",
          });
        }
      });
    } else if (this.state.pending) {
      this.setState({
        pending: false,
        loading: false,
      });
    }
  }

  showPrevious = () => {
    this.props.setTimeframe(
      TimeframeService.getPreviousTimeframe(
        this.props.timeframe,
        this.props.startTime,
        this.props.maxWindowInSeconds
      )
    );
  };

  showNext = () => {
    this.props.setTimeframe(
      TimeframeService.getNextTimeframe(
        this.props.timeframe,
        this.props.startTime,
        this.props.maxWindowInSeconds
      )
    );
  };

  reset = () => {
    if (!!this.props.timeframe) {
      const newTimeframe = ((this.props.widget || {}).options || {}).timeframe;
      this.props.setTimeframe(newTimeframe);
    }
  };

  exportToCSV = () => {
    const OUTPUT_UNIT =
      (this.props.widget.options.statereporting || {}).outputUnit || "percent";

    const headers = ["Period Start"].concat(
      (this.state.stateHistoryData || []).map((dataset) => dataset.label)
    );

    let data = [];
    if (
      !!this.state.stateHistoryData &&
      this.state.stateHistoryData.length > 0
    ) {
      data = this.state.stateHistoryData[0].data.map((bucket, index) => {
        return [moment(bucket.x).format(DATE_TIME_FORMAT)].concat(
          this.state.stateHistoryData.map((series) => {
            if (OUTPUT_UNIT === "percent") {
              return series.data[index].percent.toFixed(2) + "%";
            } else if (OUTPUT_UNIT === "time") {
              return convertMillisecondsToLabel(
                series.data[index].raw,
                this.SEGMENT_BY_HOURS
              );
            } else if (OUTPUT_UNIT === "count") {
              return series.data[index].count;
            }
            return "NO OUTPUT TYPE DEFINED";
          })
        );
      });
    }

    const filename = CSVExporter.formatFilename(
      this.props.widgetTitle || "State History Data",
      this.props.startTime,
      this.props.endTime
    );

    CSVExporter.export(filename, [headers].concat(data));
  };

  render() {
    const OUTPUT_UNIT =
      (this.props.widget.options.statereporting || {}).outputUnit || "percent";
    return (
      <Widget
        {...this.props}
        dataType="tags"
        maxTags={1}
        widgetTitle="State History Chart"
        exportToCSV={this.exportToCSV}
      >
        <Card style={{ height: "calc(100%)", marginTop: 0, marginBottom: 0 }}>
          <CardContent
            style={{ height: "calc(100% - 32px)", paddingBottom: 16 }}
          >
            <Backdrop
              style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
              open={this.state.loading || this.props.loading}
            >
              <Paper style={{ backgroundColor: "white", padding: 10 }}>
                Calculating...
              </Paper>
            </Backdrop>
            <div style={{ height: 19 }}>
              State History
              {!!this.props.startTime
                ? " from " +
                  moment(this.props.startTime)
                    .local()
                    .format(DATE_TIME_FORMAT) +
                  " - " +
                  moment(this.props.endTime).local().format(DATE_TIME_FORMAT)
                : ""}
            </div>
            <Grid
              container
              style={{ height: 36 }}
              justifyContent="space-between"
            >
              <Grid item>
                <Button onClick={this.showPrevious}>
                  <NavigateBeforeIcon />
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.reset}
                  disabled={TimeframeService.disableReset(
                    this.props.timeframe,
                    ((this.props.widget || {}).options || {}).timeframe
                  )}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.showNext}
                  disabled={(this.props.timeframe || {}).type === "moving"}
                >
                  Next <NavigateNextIcon />
                </Button>
              </Grid>
            </Grid>
            <div style={{ height: 300 }}>
              <Bar
                ref={this.chartRef}
                data={{ labels: [], datasets: this.state.stateHistoryData }}
                options={this.state.chartOptions}
              />
            </div>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  ref={this.tableRef}
                  width={width}
                  height={height - 355}
                  headerHeight={20}
                  rowHeight={40}
                  rowCount={
                    !!this.state.stateHistoryData &&
                    !!this.state.stateHistoryData[0] &&
                    !!this.state.stateHistoryData[0].data
                      ? this.state.stateHistoryData[0].data.length
                      : 0
                  }
                  rowGetter={({ index }) => {
                    const reverseIndex =
                      this.state.stateHistoryData[0].data.length - 1 - index;
                    return [
                      this.state.stateHistoryData[0].data[reverseIndex].x,
                    ].concat(
                      this.state.stateHistoryData.map((series) => ({
                        ...series.data[reverseIndex],
                        time: series.data[reverseIndex].raw,
                      }))
                    );
                  }}
                  rowStyle={_rowStyle}
                >
                  <Column
                    key="period"
                    label="Period"
                    dataKey="0"
                    width={150}
                    flexGrow={1}
                    cellRenderer={({ cellData }) =>
                      moment(cellData).format(DATE_TIME_FORMAT)
                    }
                  />
                  {this.state.stateHistoryData.map((series, index) => (
                    <Column
                      key={series.label}
                      label={series.label}
                      dataKey={index + 1}
                      width={75}
                      flexGrow={1}
                      cellRenderer={({ cellData }) => {
                        if (OUTPUT_UNIT === "percent") {
                          return `${(cellData.percent ?? 0).toFixed(2)}%`;
                        } else if (OUTPUT_UNIT === "time") {
                          return convertMillisecondsToLabel(
                            cellData.time,
                            this.SEGMENT_BY_HOURS
                          );
                        } else if (OUTPUT_UNIT === "count") {
                          return cellData.count;
                        }
                      }}
                    />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </CardContent>
        </Card>
      </Widget>
    );
  }
}

export default StateHistoryWidget;
