import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimezoneSelect from 'react-timezone-select'

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "column",
    margin: theme.spacing(3),
    width: "calc(100% - 48px)",
  },
  timezoneContainer: {
    marginTop: theme.spacing(1),
    zIndex: 10,
  },
}));

const TimezoneOption = (props) => {
  const { options, setOptions } = props;

  const classes = useStyles();

  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <FormLabel component="legend">Timezone</FormLabel>
      <div className={classes.timezoneContainer}>
        <TimezoneSelect
          placeholder="Default to current timezone"
          isClearable
          value={options.timezone ?? {}}
          onChange={(tz) => {
            setOptions({
              timezone: tz?.value ?? tz,
            });
          }}
        />
      </div>
    </FormControl>
  );
};

export default TimezoneOption;
