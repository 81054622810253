import React from "react";
import Widget from "../../widget/Widget";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { default as _last } from "lodash/last";
import CSVExporter, {
  CSV_TIMESTAMP_HEADERS
} from "../../../services/CSVExporter";
import { ChartService } from "../../../services/ChartService";
import { TimeSeriesService } from "../../../services/TimeSeriesService";

class BarChartWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        bounds: "ticks",
        elements: { line: { tension: 0 } },
        title: { display: true, text: "" },
        scales: {
          xAxes: [
            {
              type: "time",
              ticks: { autoSkip: true, maxRotation: 0, beginAtZero: true },
              display: true,
              time: {
                unit: "minute",
                displayFormats: { minute: "MM/DD HH:mm" },
                tooltipFormat: "MMMM DD, YYYY HH:mm:ss"
              },
              scaleLabel: { display: true, labelString: "Timestamp" }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: { display: true, labelString: "" }
            }
          ]
        },
        annotation: {
          events: ["click"],
          annotations: []
        }
      }
    };

    this.chartRef = React.createRef();
    this.timeFormat = "MM/DD HH:mm";
  }

  componentWillUnmount = () => {
    // this is a HACK needed due to bug in react-chartjs-2
    this.state.datasets.forEach(ds => {
      Object.keys(ds._meta || {}).forEach(id => {
        let meta = ds._meta[id];
        if (meta.controller == null) {
          delete ds._meta[id];
        }
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.processData(TimeSeriesService.populateDataSets(this.props));

    ChartService.refreshChart(
      this.props.widget,
      this.props.stacks,
      this.state.chartOptions,
      this.chartRef.current,
      prevProps
    );
  };

  processData = datasets => {
    const updatedDatasets = datasets.map(dataset => {
      dataset.data = dataset.rawData;
      return dataset;
    });

    const originalStateOfOptions = JSON.stringify(this.state.chartOptions);
    const newChartOptions = this.updateChartOptions();

    newChartOptions.scales.xAxes[0].time.min = this.props.startTime;
    newChartOptions.scales.xAxes[0].time.max = this.props.endTime;

    //Check on updating state
    const updatedOptions =
      originalStateOfOptions !== JSON.stringify(newChartOptions);

    const updatedData =
      updatedOptions ||
      (!this.state.datasets && !!updatedDatasets) ||
      (!!this.state.datasets && !updatedDatasets) ||
      this.state.datasets.length !== updatedDatasets.length ||
      this.state.datasets.some((dataset, index) => {
        const newDataset = updatedDatasets[index];
        return (
          dataset.label !== newDataset.label ||
          dataset.data.length !== newDataset.data.length ||
          dataset.data.some(
            (datum, dataIndex) => datum !== newDataset.data[dataIndex]
          )
        );
      });

    if (updatedOptions || updatedData) {
      this.setState({
        datasets: updatedDatasets,
        chartOptions: newChartOptions
      });
    }
  };

  updateChartOptions = () => {
    const tags = (this.props.widget || {}).tags || [];
    const stacks = this.props.stacks || {};

    const newChartOptions = { ...this.state.chartOptions };

    // ok, here we have to unpack the indicators from all tags
    let indicators = ChartService.getIndicators(
      this.props.widget,
      this.props.stacks
    );
    if (newChartOptions.annotation) {
      newChartOptions.annotation.annotations = ChartService.getAnnotations(
        indicators,
        this.chartRef.current
      );
    }

    if (newChartOptions.scales) {
      const units = tags.reduce(
        (units, tag) => {
          const st = stacks[tag.stack] || {};
          const dconfig = st.data_config || {};
          if (st) {
            const a = tag.attribute;
            const aunits = (dconfig[a] || {}).unit || "-";
            units.unique.add(aunits);
            units.all.push(aunits);
          }
          return units;
        },
        { unique: new Set(), all: [] }
      );

      newChartOptions.scales.yAxes[0] = ChartService.initializeYAxes(
        this.props.widget,
        newChartOptions
      );

      newChartOptions.scales.yAxes[0].scaleLabel.labelString = ChartService.getYAxesLabels(
        units.unique,
        units.all
      );
    }

    if (newChartOptions.title) {
      newChartOptions.title.text =
        tags
          .map(t => t.attribute[0].toUpperCase() + t.attribute.substr(1))
          .join(", ") || "untitled";
    }

    return newChartOptions;
  };

  exportToCSV = () => {
    let entries = {}; // map timestamps to values
    this.state.datasets.forEach(ds => {
      ds.data.forEach(pt => {
        const timestamp = pt.x.valueOf();
        const value = pt.y;
        let entry = entries[timestamp];
        if (entry == null) {
          entry = entries[timestamp] = {};
        }
        entry[ds.label] = value;
      });
    });
    let data = Object.entries(entries).map(e => {
      return { timestamp: parseInt(e[0]), ...e[1] };
    });
    data.sort((e1, e2) => e1.timestamp - e2.timestamp);

    const headers = this.state.datasets.map(ds => ds.label);

    const rows = [CSV_TIMESTAMP_HEADERS.concat(headers)].concat(
      data.map(d =>
        CSVExporter.timestampColumns(d.timestamp).concat(headers.map(h => d[h]))
      )
    );

    const filename = CSVExporter.formatFilename(
      this.props.widgetTitle || "Timeseries",
      data[0].timestamp,
      _last(data).timestamp
    );

    CSVExporter.export(filename, rows);
  };

  render() {
    return (
      <Widget
        {...this.props}
        dataType="tags"
        widgetTitle="Timeseries Bar Chart"
        exportToCSV={this.exportToCSV}
      >
        <Bar
          ref={this.chartRef}
          data={{ labels: [], datasets: this.state.datasets }}
          options={this.state.chartOptions}
        />
      </Widget>
    );
  }
}

export default BarChartWidget;
