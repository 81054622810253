import DashboardService from "services/DashboardService";

import { default as _last } from "lodash/last";
const uuidv4 = require("uuid/v4");

const LUBEGAUGE_WPROPS = {
  options: {
    range: { type: "tag", max: "", min: "" },
    linearfn: { type: "custom", tagprop: true },
  },
  type: "Gauge",
  tags: [],
};

const LUBEGAUGE_LG_LAYOUT = {
  static: false,
  minH: 3,
  minW: 1,
  w: 2,
  moved: false,
  h: 5,
};
const LUBEGAUGE_SM_LAYOUT = {
  static: false,
  minH: 3,
  minW: 1,
  w: 2,
  moved: false,
  h: 5,
};

const LUBECARTRIDGE_WPROPS = {
  type: "LubeCartridge",
  tags: [],
};

const LUBECARTRIDGE_LG_LAYOUT = {
  static: false,
  minH: 5,
  minW: 2,
  w: 2,
  moved: false,
  h: 5,
};
const LUBECARTRIDGE_SM_LAYOUT = {
  static: false,
  minH: 5,
  minW: 2,
  w: 2,
  moved: false,
  h: 5,
};

const LUBEHISTORY_WPROPS = {
  type: "LubeHistory",
  tags: [],
};

const LUBEHISTORY_LG_LAYOUT = {
  static: false,
  minH: 5,
  minW: 3,
  w: 4,
  moved: false,
  h: 5,
};
const LUBEHISTORY_SM_LAYOUT = {
  static: false,
  minH: 5,
  minW: 3,
  w: 4,
  moved: false,
  h: 5,
};

const AUTOLUBE_WPROPS = {
  type: "Autolube",
  tags: [],
};

const AUTOLUBE_LG_LAYOUT = {
  static: false,
  minH: 5,
  minW: 2,
  w: 4,
  moved: false,
  h: 5,
};

const AUTOLUBE_SM_LAYOUT = {
  static: false,
  minH: 5,
  minW: 2,
  w: 4,
  moved: false,
  h: 5,
};

const LUBELCH_WPROPS = {
  options: {
    timeframe: {
      "length.units": "hours",
      "interval.type": "auto",
      "interval.value": "10",
      type: "moving",
      "interval.units": "seconds",
      "length.value": "24",
    },
    yaxis: { type: "fixed", tagprop: true },
    linearfn: { type: "custom", tagprop: true },
  },
  type: "DatasetLineChart",
  tags: [],
};

const LUBELCH_LG_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 8,
  moved: false,
  h: 15,
};
const LUBELCH_SM_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 8,
  moved: false,
  h: 15,
};

const LUBE_ENS_HEIGHT = 15;
const DP_WPROPS = {
  options: {
    /*"interval.value": "10",
    "interval.type": "auto",
    metric: { type: "snapshot", samples: 30 },
    "interval.units": "seconds",*/
  },
  type: "Datapoint",
  tags: [],
};
const DP_LG_LAYOUT = {
  static: false,
  minH: 2,
  minW: 1,
  w: 2,
  moved: false,
  h: 4,
};
const DP_SM_LAYOUT = {
  static: false,
  minH: 2,
  minW: 1,
  w: 2,
  moved: false,
  h: 4,
};

const GAUGE_WPROPS = {
  options: {
    range: { type: "tag", max: "", min: "" },
    linearfn: { type: "custom", tagprop: true },
    /*"interval.value": "10",
    "interval.type": "auto",
    metric: { type: "snapshot", samples: 30 },
    "interval.units": "seconds",*/
  },
  type: "Gauge",
  tags: [],
};
const GAUGE_INDICATORS = [
  { options: { color: "#4caf50" }, condition: "<", value: "30" },
  { options: { color: "#4caf50" }, condition: "<", value: "38" },
  { options: { color: "#2196f3" }, condition: "<", value: "46" },
  { options: { color: "#fdd835" }, condition: "<", value: "62" },
  { options: { color: "#f44336" }, condition: ">=", value: "62" },
];
const GAUGE_LG_LAYOUT = {
  static: false,
  minH: 3,
  minW: 1,
  w: 2,
  moved: false,
  h: 4,
};
const GAUGE_SM_LAYOUT = {
  static: false,
  minH: 3,
  minW: 1,
  w: 2,
  moved: false,
  h: 4,
};

const LCH_WPROPS = {
  options: {
    timeframe: {
      "length.units": "hours",
      "interval.type": "auto",
      "interval.value": "10",
      type: "moving",
      "interval.units": "seconds",
      "length.value": "24",
    },
    yaxis: { type: "fixed", tagprop: true },
    linearfn: { type: "custom", tagprop: true },
  },
  type: "DatasetLineChart",
  tags: [],
};
const LCH_INDICATORS = [
  { options: { color: "#4caf50" }, condition: "=", value: "30" },
  { options: { color: "#2196f3" }, condition: "=", value: "38" },
  { options: { color: "#fdd835" }, condition: "=", value: "46" },
  { options: { color: "#f44336" }, condition: "=", value: "62" },
];
const LCH_LG_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 10,
  moved: false,
  h: 12,
};
const LCH_SM_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 10,
  moved: false,
  h: 12,
};

const QM_WPROPS = {
  stacks: [],
  options: {
    buttonName: "Real-Time Data",
    messagePayload: [
      { guuid: "", type: "Boolean", value: true, key: "FastSample" },
    ],
  },
  type: "QuickMessaging",
};
const QM_LG_LAYOUT = {
  static: false,
  minH: 4,
  minW: 2,
  w: 2,
  moved: false,
  h: 4,
};
const QM_SM_LAYOUT = {
  static: false,
  minH: 4,
  minW: 2,
  w: 2,
  moved: false,
  h: 4,
};

const BASELINE_WPROPS = {
  options: {
    histogram: { binStyle: "uniform", binCount: "10", highPassFilter: false },
    timeframe: {
      "length.units": "hours",
      "interval.value": "10",
      "interval.units": "seconds",
      "length.value": "24",
      start: "2021-01-01T12:00:00.000Z",
      "interval.type": "auto",
      type: "fixed",
    },
    yaxis: { type: "auto" },
  },
  type: "DatasetAnalytics",
};
const BASELINE_LG_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 4,
  moved: false,
  h: 8,
};
const BASELINE_SM_LAYOUT = {
  static: false,
  minH: 8,
  minW: 4,
  w: 4,
  moved: false,
  h: 8,
};

const ALERTS_WPROPS = {
  options: {
    timeframe: {
      "length.units": "days",
      "interval.type": "auto",
      "interval.value": "10",
      type: "moving",
      "interval.units": "seconds",
      "length.value": 30,
    },
  },
  type: "Alerts",
};
const ALERTS_LG_LAYOUT = {
  static: false,
  minH: 8,
  minW: 6,
  w: 12,
  moved: false,
  h: 8,
};
const ALERTS_SM_LAYOUT = {
  static: false,
  minH: 8,
  minW: 6,
  w: 6,
  moved: false,
  h: 8,
};

const ENS_HEIGHT = 12;

const BASELINE_HEIGHT = 8;
const BASELINE_WIDTH = 4;

const OVERVIEW_GAUGE_WIDTH = 2;
const OVERVIEW_GAUGE_HEIGHT = 4;

class LubeDashboardService extends DashboardService {
  constructor(stack, user, groups, prefix = "", overviewId, dashProperties) {
    super();
    const stackName =
      (stack?.name || JSON.parse(stack.dev_session)["stack.label"]) ?? "";
    this.stack = { ...stack, name: stackName };
    this.user = user;
    this.groups = groups;
    this.prefix = prefix;
    this.overviewId = overviewId;
    // all tags for overview dashboard
    this.tags = Object.values(this.groups)
      .flatMap((gr) => gr.tags)
      .sort();
    this.dashProperties = dashProperties;
  }

  addOverviewGaugeWidget(pos, m, d) {
    const MASUFFIX = "|ma-10sm-lc";
    const { stack: st, tag, stname = "", title: wtitle } = m;
    const neww = {
      ...GAUGE_WPROPS,
      guuid: uuidv4(),
      default_title: `${stname} - ${tag}`,
      title: wtitle,
      dashboard: d.guuid,
    };
    d.widgets.push(neww);
    // lg layout
    const newlg = {
      ...GAUGE_LG_LAYOUT,
      i: neww.guuid,
      x: (pos * OVERVIEW_GAUGE_WIDTH) % 12,
      y:
        Math.floor(pos / Math.floor(12 / OVERVIEW_GAUGE_WIDTH)) *
        OVERVIEW_GAUGE_HEIGHT,
    };
    d.widgets_layout.lg.push(newlg);
    // sm layout
    const newsm = {
      ...GAUGE_SM_LAYOUT,
      i: neww.guuid,
      x: 0,
      y: pos * OVERVIEW_GAUGE_HEIGHT,
    };
    d.widgets_layout.sm.push(newsm);
    // add tag data
    const ind = GAUGE_INDICATORS.map((ind) => ({ ...ind, guuid: uuidv4() }));
    neww.tags = [
      {
        guuid: uuidv4(),
        stack: st,
        attribute: tag + MASUFFIX,
        //indicators: ind,
        indicator_source: "tag",
      },
    ];
  }

  addOverviewAlertsWidget(pos, m, d) {
    const { stack: st, stname = "", title: wtitle = stname } = m;
    const neww = {
      ...ALERTS_WPROPS,
      guuid: uuidv4(),
      default_title: stname,
      title: `${wtitle} Alerts`,
      dashboard: d.guuid,
      stacks: st,
    };
    d.widgets.push(neww);
    // lg layout
    const last_lg_layout_entry = _last(d.widgets_layout.lg);
    const newlg = {
      ...ALERTS_LG_LAYOUT,
      i: neww.guuid,
      x: 0,
      y: last_lg_layout_entry
        ? last_lg_layout_entry.y + last_lg_layout_entry.w
        : 0,
    };
    d.widgets_layout.lg.push(newlg);
    // sm layout
    const last_sm_layout_entry = _last(d.widgets_layout.sm);
    const newsm = {
      ...ALERTS_SM_LAYOUT,
      i: neww.guuid,
      x: 0,
      y: last_sm_layout_entry
        ? last_sm_layout_entry.y + last_sm_layout_entry.w
        : 0,
    };
    d.widgets_layout.sm.push(newsm);
  }

  addBaselineWidget(pos, m, d) {
    const MSUFFIX = "|ma-10sm-lc";
    const { stack: st, tag, stname = "", title: wtitle } = m;
    const neww = {
      ...BASELINE_WPROPS,
      guuid: uuidv4(),
      default_title: `${stname} - ${tag}`,
      title: wtitle,
      dashboard: d.guuid,
    };
    d.widgets.push(neww);
    // lg layout
    const newlg = {
      ...BASELINE_LG_LAYOUT,
      i: neww.guuid,
      x: (pos * BASELINE_WIDTH) % 12,
      y: Math.floor(pos / Math.floor(12 / BASELINE_WIDTH)) * BASELINE_HEIGHT,
    };
    d.widgets_layout.lg.push(newlg);
    // sm layout
    const newsm = {
      ...BASELINE_SM_LAYOUT,
      i: neww.guuid,
      x: 0,
      y: pos * BASELINE_HEIGHT,
    };
    d.widgets_layout.sm.push(newsm);

    // add tag data
    neww.tags = [{ guuid: uuidv4(), stack: st, attribute: tag + MSUFFIX }];
  }

  addSensorWidgetEnsemble(pos, m, d) {
    const MSUFFIX = "|ma-10sm-lc";
    const { stack: st, tag, stname = "", title: wtitle } = m;
    const ey = pos * ENS_HEIGHT;
    const eylist = [0, 4, 8, 0].map((x) => ey + x);
    const slist = [0, 4, 8, ENS_HEIGHT].map((x) => pos * 24 + x);
    const lx = pos % 2 ? 0 : 4;
    const ox = pos % 2 ? 10 : 0;
    const esitems = {
      dp: [
        DP_WPROPS,
        DP_LG_LAYOUT,
        DP_SM_LAYOUT,
        {
          y: eylist[0],
          x: ox,
          sy: slist[0],
        },
      ],
      ga: [
        GAUGE_WPROPS,
        GAUGE_LG_LAYOUT,
        GAUGE_SM_LAYOUT,
        {
          y: eylist[1],
          x: ox,
          sy: slist[1],
        },
      ],
      qm: [
        QM_WPROPS,
        QM_LG_LAYOUT,
        QM_SM_LAYOUT,
        {
          y: eylist[2],
          x: ox,
          sy: slist[2],
        },
      ],
      lc: [
        LCH_WPROPS,
        LCH_LG_LAYOUT,
        LCH_SM_LAYOUT,
        {
          y: eylist[3],
          x: lx,
          sy: slist[3],
        },
      ],
    };
    const neww = {};
    Object.entries(esitems).forEach(([k, esitem]) => {
      neww[k] = esitem.map((x) => ({ ...x }));
      const widget = neww[k][0];
      Object.assign(widget, {
        guuid: uuidv4(),
        default_title: `${stname} - ${tag}`,
        title: wtitle,
        dashboard: d.guuid,
      });
      d.widgets.push(widget);
      // lg layout
      neww[k][1].i = widget.guuid;
      neww[k][1].x = neww[k][3].x;
      neww[k][1].y = neww[k][3].y;
      d.widgets_layout.lg.push(neww[k][1]);
      // sm layout
      neww[k][2].i = widget.guuid;
      neww[k][2].x = 0;
      neww[k][2].y = neww[k][3].sy;
      d.widgets_layout.sm.push(neww[k][2]);
      // widget specific
      // add message payload guuid for each message
      (widget.options?.messagePayload ?? []).forEach(
        (mp) => (mp.guuid = uuidv4())
      );
      // add tag data for each widget
      if (k === "dp") {
        widget.tags = [
          {
            guuid: uuidv4(),
            stack: st,
            attribute: tag + MSUFFIX,
          },
        ];
      } else if (k === "qm") {
        widget.tags = [{ guuid: uuidv4(), stack: st, attribute: tag }];
      } else if (k === "ga") {
        const ind = GAUGE_INDICATORS.map((ind) => ({
          ...ind,
          guuid: uuidv4(),
        }));
        widget.tags = [
          {
            guuid: uuidv4(),
            stack: st,
            attribute: tag + MSUFFIX,
            //indicators: ind,
            indicator_source: "tag",
          },
        ];
        //widget.title += " (Avg)";
      } else if (k === "lc") {
        const ind = LCH_INDICATORS.map((ind) => ({ ...ind, guuid: uuidv4() }));
        widget.tags = [
          {
            guuid: uuidv4(),
            stack: st,
            attribute: tag + MSUFFIX,
            //indicators: ind,
            indicator_source: "tag",
          },
          { guuid: uuidv4(), stack: st, attribute: tag },
        ];
        //neww[k][0]["title"] += " (Avg)";
      }
    });
  }

  addLubeWidgetEnsemble(pos, m, d) {
    const MSUFFIX = "|ma-10sm-lc";
    const LPREFIX = "lube_";
    const { stack: st, tag, stname = "", title: wtitle } = m;
    const ey = pos * LUBE_ENS_HEIGHT;
    const eylist = [0, 0, 5, 10, 0].map((x) => ey + x);
    const slist = [0, 0, 5, 10, LUBE_ENS_HEIGHT].map((x) => 2 * ey + x);
    const lx = pos % 2 ? 0 : 4;
    const ox = pos % 2 ? 8 : 0;
    const esitems = {
      ga: [
        LUBEGAUGE_WPROPS,
        LUBEGAUGE_LG_LAYOUT,
        LUBEGAUGE_SM_LAYOUT,
        {
          y: eylist[0],
          x: ox,
          sy: slist[0],
        },
      ],
      ct: [
        LUBECARTRIDGE_WPROPS,
        LUBECARTRIDGE_LG_LAYOUT,
        LUBECARTRIDGE_SM_LAYOUT,
        {
          y: eylist[1],
          x: ox + 2,
          sy: slist[1],
        },
      ],
      lh: [
        LUBEHISTORY_WPROPS,
        LUBEHISTORY_LG_LAYOUT,
        LUBEHISTORY_SM_LAYOUT,
        {
          y: eylist[2],
          x: ox,
          sy: slist[2],
        },
      ],
      al: [
        AUTOLUBE_WPROPS,
        AUTOLUBE_LG_LAYOUT,
        AUTOLUBE_SM_LAYOUT,
        {
          y: eylist[3],
          x: ox,
          sy: slist[3],
        },
      ],
      lc: [
        LUBELCH_WPROPS,
        LUBELCH_LG_LAYOUT,
        LUBELCH_SM_LAYOUT,
        {
          y: eylist[4],
          x: lx,
          sy: slist[4],
        },
      ],
    };
    const neww = {};
    Object.entries(esitems).forEach(([k, esitem]) => {
      neww[k] = esitem.map((x) => ({ ...x }));
      const widget = neww[k][0];
      const lubetitle =
        wtitle.split(" - ")[0] + " - Lube_" + wtitle.split(" - ")[1];
      Object.assign(widget, {
        guuid: uuidv4(),
        default_title: `${stname} - ${tag}`,
        title: wtitle,
        dashboard: d.guuid,
      });
      d.widgets.push(widget);
      // lg layout
      neww[k][1].i = widget.guuid;
      neww[k][1].x = neww[k][3].x;
      neww[k][1].y = neww[k][3].y;
      d.widgets_layout.lg.push(neww[k][1]);
      // sm layout
      neww[k][2].i = widget.guuid;
      neww[k][2].x = 0;
      neww[k][2].y = neww[k][3].sy;
      d.widgets_layout.sm.push(neww[k][2]);
      // widget specific
      // add tag data for each widget
      if (["ct", "lh", "al"].includes(k)) {
        widget.tags = [
          { guuid: uuidv4(), stack: st, attribute: LPREFIX + tag },
        ];
        widget.title = lubetitle;
      } else if (k === "ga") {
        const ind = GAUGE_INDICATORS.map((ind) => ({
          ...ind,
          guuid: uuidv4(),
        }));
        widget.tags = [
          {
            guuid: uuidv4(),
            stack: st,
            attribute: tag + MSUFFIX,
            //indicators: ind,
            indicator_source: "tag",
          },
        ];
        //widget.title += " (Avg)";
      } else if (k === "lc") {
        const ind = LCH_INDICATORS.map((ind) => ({ ...ind, guuid: uuidv4() }));
        widget.tags = [
          {
            guuid: uuidv4(),
            stack: st,
            attribute: tag + MSUFFIX,
            //indicators: ind,
            indicator_source: "tag",
          },
          { guuid: uuidv4(), stack: st, attribute: tag },
        ];
        //widget.title += " (Avg)";
      }
    });
  }

  getGroupDashboardTitle(name) {
    return `${this.prefix ? this.prefix + "." : ""}${this.stack.name}.${name}`;
  }

  createOrUpdateGroupDashboard(group) {
    const uguuid = this.user?.userid;
    const { id: groupId, dashboardId, name: groupName, tags } = group;
    const d = {
      guuid: dashboardId || uuidv4(),
      g_owner: uguuid,
      creator: uguuid,
      widgets: [],
      widgets_layout: { lg: [], sm: [] },
    };
    d.title = this.getGroupDashboardTitle(groupName);
    tags.forEach((k, i) => {
      const m = {
        stack: this.stack.guuid,
        stname: this.stack.name,
        tag: k,
        title:
          "CH" +
          (this.dashProperties[k]?.idx ||
            (k.startsWith("sensor") ? parseInt(k.slice(6)).toString() : "99")) +
          " - " +
          (this.dashProperties[k]?.name || k),
        /*title:
          (this.stack.name || "") +
          " : " +
          (this.stack.data_config[k]?.name || k),*/
      };
      const deviceType = this.dashProperties[`lube_${k}`]?.devicetype;
      console.log("dashProperties are", k, deviceType, this.dashProperties);
      if (deviceType !== undefined && deviceType.toLowerCase() !== "none") {
        //none is for non-luber dashboards
        this.addLubeWidgetEnsemble(i, m, d);
      } else {
        this.addSensorWidgetEnsemble(i, m, d);
        //this.addBaselineWidget(i, m, d);
      }
    });
    d.autogenerated_stack = this.stack.guuid;
    d.autogenerated_dashboard = d.guuid;
    d.autogenerated_group = groupId;
    d.autogenerated_title = d.title;
    return d;
  }

  getOverviewDashboardTitle(name) {
    return `${this.prefix ? this.prefix + "." : ""}${this.stack.name}.${name}`;
  }

  createOrUpdateOverviewDashboard(name = "Overview") {
    const uguuid = this.user?.userid;
    const d = {
      guuid: this.overviewId || uuidv4(),
      g_owner: uguuid,
      creator: uguuid,
      widgets: [],
      widgets_layout: { lg: [], sm: [] },
    };
    d.title = this.getOverviewDashboardTitle(name);
    // individual gauge widgets
    this.tags.forEach((k, i) => {
      const m = {
        stack: this.stack.guuid,
        stname: this.stack.name,
        tag: k,
        title:
          "CH" +
          (this.dashProperties[k]?.idx ||
            (k.startsWith("sensor") ? parseInt(k.slice(6)).toString() : "99")) +
          " - " +
          (this.dashProperties[k]?.name || k),
      };
      this.addOverviewGaugeWidget(i, m, d);
    });
    // stack widget - alerts
    const m = { stack: [this.stack.guuid], stname: this.stack.name };
    this.addOverviewAlertsWidget(this.tags.length, m, d);
    d.autogenerated_stack = this.stack.guuid;
    d.autogenerated_dashboard = d.guuid;
    d.autogenerated_title = d.title;
    return d;
  }

  createDashboards() {
    console.info("LubeDashboardService: create dashboards");
    const uguuid = this.user?.userid;
    if (!uguuid) {
      // TODO: throw exception?
      console.error("no user info to make dash");
      return;
    }
    const newdlist = [];
    Object.values(this.groups).forEach((group) => {
      const dash = this.createOrUpdateGroupDashboard(group);
      newdlist.push(dash);
    });
    const dash = this.createOrUpdateOverviewDashboard("Overview");
    newdlist.push(dash);
    return newdlist;
  }
}

export default LubeDashboardService;
