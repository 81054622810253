import React, { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import "chartjs-plugin-annotation";
import { default as _last } from "lodash/last";
import { default as _chunk } from "lodash/chunk";
import { default as _meanBy } from "lodash/meanBy";
import { default as _minBy } from "lodash/minBy";
import { default as _maxBy } from "lodash/maxBy";
import CSVExporter, { CSV_TIMESTAMP_HEADERS } from "services/CSVExporter";
import { ChartService } from "services/ChartService";
import { TimeSeriesService } from "services/TimeSeriesService";
import { TimeframeService } from "services/TimeframeService";
import ISXUtils from "services/Utils";
import Widget from "../../widget/Widget";
import { Button } from "@material-ui/core";
import moment from "moment-timezone";

// target maximum # points for each line; will likely be less after decimation
const TARGET_MAX_POINTS = 6000;
// in seconds, supported fixed periods when decimation is used
const DECIMATION_PERIOD_CUTOFFS = [30, 300, 1800];
// possible polcies are "sample", "mean", "min" and "max"
const DECIMATION_DEFAULT_TAG_POLICIES = {
  derived: "mean",
  original: "mean",
};
// override line properties when decimated
const DECIMATED_LINE_PROPS = {
  pointRadius: 0,
  hoverRadius: 0,
  pointHitRadius: 0,
  lineTension: 1,
};

const decimationChunkToValue = (chunk, policy) => {
  switch (policy) {
    case "mean":
      return _meanBy(chunk, "y");
    case "median":
      chunk.sort((a, b) => a.y - b.y);
      return chunk.length % 2 === 0
        ? (chunk[chunk.length / 2].y + chunk[chunk.length / 2 - 1].y) / 2
        : chunk[(chunk.length - 1) / 2].y;
    case "min":
      return _minBy(chunk, "y");
    case "max":
      return _maxBy(chunk, "y");
    case "sample":
    default:
      return _last(chunk).y;
  }
};

const datasetsDiffer = (d1, d2) => {
  return (
    !d1?.rawData ||
    !d2?.rawData ||
    d1.rawData.length !== d2.rawData.length ||
    d1.rawData[0]?.x !== d2.rawData[0]?.x ||
    _last(d1.rawData)?.x !== _last(d2.rawData)?.x ||
    d1.rawData[0]?.y !== d2.rawData[0]?.y ||
    _last(d1.rawData)?.y !== _last(d2.rawData)?.y ||
    d1?.tag?.stack !== d2?.tag?.stack ||
    d1?.tag?.attribute !== d2?.tag?.attribute
  );
};

const LineChartWidget = (props) => {
  const [datasets, setDatasets] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    bounds: "ticks",
    elements: { line: { tension: 0 } },
    title: { display: false, text: "" },
    tooltips: {
      enabled: true,
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += ISXUtils.formattedValue(tooltipItem.yLabel);
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          ticks: {
            autoSkip: true,
            autoSkipPadding: 24,
            beginAtZero: false,
          },
          display: true,
          time: {
            displayFormats: {
              second: "MM/DD HH:mm:ss",
              minute: "MM/DD HH:mm",
              hour: "MM/DD HH",
              day: "MM/DD",
            },
            tooltipFormat: "MMMM DD, YYYY HH:mm:ss",
          },
          scaleLabel: { display: true, labelString: "Time" },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: { display: true, labelString: "" },
        },
      ],
    },
    annotation: {
      events: ["click"],
      annotations: [],
    },
  });

  const chartRef = useRef();
  const prevProps = useRef();

  useEffect(() => {
    // this is a HACK needed due to bug in react-chartjs-2
    return () => {
      datasets.forEach((ds) => {
        Object.keys(ds._meta || {}).forEach((id) => {
          let meta = ds._meta[id];
          if (meta.controller == null) {
            delete ds._meta[id];
          }
        });
      });
    };
  }, [datasets]);

  const exportToCSV = () => {
    let entries = {}; // map timestamps to values
    datasets.forEach((ds) => {
      ds.data.forEach((pt) => {
        const timestamp = pt.x.valueOf();
        const value = pt.y;
        let entry = entries[timestamp];
        if (entry == null) {
          entry = entries[timestamp] = {};
        }
        entry[ds.label] = value;
      });
    });
    let data = Object.entries(entries).map((e) => {
      return { timestamp: parseInt(e[0]), ...e[1] };
    });
    data.sort((e1, e2) => e1.timestamp - e2.timestamp);

    const headers = datasets.map((ds) => ds.label);

    const rows = [CSV_TIMESTAMP_HEADERS.concat(headers)].concat(
      data.map((d) =>
        CSVExporter.timestampColumns(d.timestamp).concat(
          headers.map((h) => d[h])
        )
      )
    );

    const filename = CSVExporter.formatFilename(
      props.widgetTitle || "Timeseries",
      data[0].timestamp,
      _last(data).timestamp
    );

    CSVExporter.export(filename, rows);
  };

  const showPrevious = () => {
    props.setTimeframe(
      TimeframeService.getPreviousTimeframe(
        props.timeframe,
        props.startTime,
        props.maxWindowInSeconds
      )
    );
  };

  const showNext = () => {
    props.setTimeframe(
      TimeframeService.getNextTimeframe(
        props.timeframe,
        props.startTime,
        props.maxWindowInSeconds
      )
    );
  };

  const reset = () => {
    if (!!props.timeframe) {
      const newTimeframe = ((props.widget || {}).options || {}).timeframe;
      props.setTimeframe(newTimeframe);
    }
  };

  useEffect(() => {
    const updateChartOptions = () => {
      const tags = (props.widget || {}).tags || [];
      const stacks = props.stacks || {};

      const newChartOptions = { ...chartOptions };

      // ok, here we have to unpack the indicators from all tags
      let indicators = ChartService.getIndicators(props.widget, props.stacks);
      if (newChartOptions.annotation) {
        newChartOptions.annotation.annotations = ChartService.getAnnotations(
          indicators,
          chartRef.current
        );
      }

      if (newChartOptions.scales) {
        const units = tags.reduce(
          (units, tag) => {
            const st = stacks[tag.stack] || {};
            const dconfig = st.data_config || {};
            if (st) {
              const a = tag.attribute;
              const aunits = (dconfig[a] || {}).unit || "-";
              units.unique.add(aunits);
              units.all.push(aunits);
            }
            return units;
          },
          { unique: new Set(), all: [] }
        );

        newChartOptions.scales.yAxes[0] = ChartService.initializeYAxes(
          props.widget,
          newChartOptions,
          props.stacks,
          prevProps.current
        );

        newChartOptions.scales.yAxes[0].scaleLabel.labelString =
          ChartService.getYAxesLabels(units.unique, units.all);
      }

      if (!!props.startTime && !!props.endTime) {
        newChartOptions.scales.xAxes[0].ticks.min = props.startTime.valueOf();
        newChartOptions.scales.xAxes[0].ticks.max = props.endTime.valueOf();
      } else {
        newChartOptions.scales.xAxes[0].ticks.min = null;
        newChartOptions.scales.xAxes[0].ticks.max = null;
      }

      if (newChartOptions.title) {
        newChartOptions.title.text =
          tags
            .map((t) => t.attribute[0].toUpperCase() + t.attribute.substr(1))
            .join(", ") || "untitled";
      }
      return newChartOptions;
    };

    const getTagEventInterval = (st, a, data) => {
      let sampleInterval = st.data_config[a] && st.data_config[a].s_int;
      if (sampleInterval) {
        return sampleInterval < 1 ? 1 : sampleInterval;
      } else {
        sampleInterval = Math.min(
          ...data.slice(1).reduce((acc, d, idx) => {
            acc[idx] = d.x - data[idx].x;
            return acc;
          }, [])
        );
        return sampleInterval === Infinity || sampleInterval < 1000
          ? 1
          : sampleInterval / 1000;
      }
    };

    const getDataFromRawData = (ds) => {
      if (ds?.rawData?.length > TARGET_MAX_POINTS) {
        const st = props.stacks[ds.tag.stack];
        const attrParts = ds.tag.attribute.split("|"); //check for derived tags
        const derived = attrParts.length > 1;
        const attr = derived ? attrParts[0] : ds.tag.attribute;
        const sampleInterval = getTagEventInterval(
          st,
          attr,
          ds.rawData.slice(-100)
        );
        let targetChunkSize = ds.rawData.length / TARGET_MAX_POINTS;
        const targetChunkLength = targetChunkSize * sampleInterval;
        const lastPeriodCutoff = _last(DECIMATION_PERIOD_CUTOFFS);
        if (targetChunkLength > lastPeriodCutoff) {
          // have exceeded our supported decimation periods, so just use
          // last one though # points may exceed our desired threshold
          if (lastPeriodCutoff <= sampleInterval) {
            // chunk size would be 1, so no value to decimating
            return ds.rawData;
          }
          targetChunkSize = Math.ceil(lastPeriodCutoff / sampleInterval);
        } else {
          for (let period of DECIMATION_PERIOD_CUTOFFS) {
            if (targetChunkLength <= period) {
              targetChunkSize = Math.ceil(period / sampleInterval);
              break;
            }
          }
        }
        const decimatedData = _chunk(ds.rawData, targetChunkSize)?.map(
          (chunk) => {
            // x position of decimated chunk is at last point
            const x = _last(chunk).x;
            const policy = derived
              ? DECIMATION_DEFAULT_TAG_POLICIES.derived
              : DECIMATION_DEFAULT_TAG_POLICIES.original;
            const y = decimationChunkToValue(chunk, policy);
            return { x, y };
          }
        );
        return decimatedData;
      } else {
        return ds.rawData;
      }
    };

    const processData = (updatedDatasets) => {
      const originalStateOfOptions = JSON.stringify(chartOptions);
      const newChartOptions = updateChartOptions();
      // Check on updating options state; this is pretty heavy-handed
      const updatedOptions =
        originalStateOfOptions !== JSON.stringify(newChartOptions);
      // determine data needs to be updated if options changed; is that true?
      // also, if # datasets changed
      let updatedData =
        updatedOptions || datasets.length !== updatedDatasets.length;
      updatedDatasets = updatedDatasets.map((newDataset, index) => {
        const dataset = datasets[index];
        if (datasetsDiffer(dataset, newDataset)) {
          newDataset.data = getDataFromRawData(newDataset);
          updatedData = true;
        } else {
          newDataset.data = dataset.data;
          newDataset.rawData = dataset.rawData;
        }
        if (newDataset.data !== newDataset.rawData) {
          // if data does not match rawdata, data must be decimated
          Object.assign(newDataset, DECIMATED_LINE_PROPS);
          newDataset.label = newDataset.label + " (decimated)";
          // bit hacky, would be better to do elsewhere
          newDataset.backgroundColor = newDataset.decimatedColor;
          newDataset.borderColor = newDataset.decimatedColor;
        }
        return newDataset;
      });
      if (updatedData) {
        setDatasets(updatedDatasets);
      }
      if (updatedOptions) {
        setChartOptions(newChartOptions);
      }
    };

    if (prevProps.current && props !== prevProps.current) {
      processData(TimeSeriesService.populateDataSets(props));
      ChartService.refreshChart(
        props.widget,
        props.stacks,
        chartOptions,
        chartRef.current,
        prevProps.current
      );
    }
    prevProps.current = props;
  }, [props, datasets, chartOptions]);

  const timezone = props.widget?.options?.timezone;
  useEffect(() => {
    // console.log("timezone changed?", props.widget?.options?.timezone);
    const chart = chartRef.current?.chartInstance;
    const newChartOptions = { ...chart.options };
    newChartOptions.scales.xAxes[0].time.parser = timezone
      ? (val) => {
          return timezone
            ? moment(val).tz(timezone).format("YYYY-MM-DDTHH:mm:ss")
            : val;
        }
      : null;
    setChartOptions(newChartOptions);
  }, [timezone]);

  return (
    <Widget
      {...props}
      dataType="tags"
      widgetTitle="Timeseries Line Chart"
      exportToCSV={exportToCSV}
    >
      <Backdrop
        style={{ height: "calc(100% - 20px)", top: 20, zIndex: 100 }}
        open={props.loading}
      >
        <Paper style={{ backgroundColor: "white", padding: 10 }}>
          Loading Data...
        </Paper>
      </Backdrop>

      <div style={{ height: "calc(100% - 36px)" }}>
        <Line
          ref={chartRef}
          data={{ labels: [], datasets }}
          options={chartOptions}
        />
      </div>
      <Grid container style={{ height: 36 }} justifyContent="space-between">
        <Grid item>
          <Button onClick={showPrevious}>
            <NavigateBeforeIcon />
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={reset}
            disabled={TimeframeService.disableReset(
              props.timeframe,
              ((props.widget || {}).options || {}).timeframe
            )}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={showNext}
            disabled={(props.timeframe || {}).type === "moving"}
          >
            Next <NavigateNextIcon />
          </Button>
        </Grid>
      </Grid>
    </Widget>
  );
};

export default LineChartWidget;
