const stateHistoryProcessingWorker = () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener("message", (e) => {
    if (!e) return;

    const { dataset, history, segmentByHours, stateCounts, key } = e.data;

    const result = convertStateHistoryToPeriods(
      history,
      segmentByHours,
      stateCounts
    );

    postMessage({ dataset, result, key });
  });

  function convertStateHistoryToPeriods(history, segmentByHours, stateCounts) {
    return history
      .flatMap((state) => convertOneStateToPeriods(state, segmentByHours))
      .reduce((periodsByLabel, entry) => {
        const periodsForLabel = periodsByLabel.get(entry.label) || [];
        const periodEntry = periodsForLabel.find((data) => data.x === entry.x);

        const stateValue = !!stateCounts ? 1 : entry.y;

        if (!!periodEntry) {
          periodEntry.y = periodEntry.y + stateValue;
        } else {
          periodsForLabel.push({ x: entry.x, y: stateValue });
        }

        periodsByLabel.set(entry.label, periodsForLabel);

        return periodsByLabel;
      }, new Map());
  }

  function getStartOfSegmentFromTime(time, segmentDuration) {
    return Math.floor(time / segmentDuration) * segmentDuration;
  }

  function convertOneStateToPeriods(state, segmentByHours) {
    //Set segnment length
    const maximumLengthForDuration = segmentByHours ? 3600000 : 86400000;

    //This is the trigger for ending our loop
    const endingPeriod = getStartOfSegmentFromTime(
      state.end,
      maximumLengthForDuration
    );

    const returnValues = [];
    let temporaryStart = state.start;

    //We always want to go through one pass
    let processing = true;

    while (processing) {
      if (temporaryStart >= endingPeriod) {
        //We're in the last segment so we go from Start to Phase End
        returnValues.push({
          x: getStartOfSegmentFromTime(
            temporaryStart,
            maximumLengthForDuration
          ),
          y: state.end - temporaryStart,
          label: state.label,
        });
        processing = false;
      } else {
        //We're not in the last segment so we go from Start to Segment End,
        // and move the Start up to the next segment
        const nextPeriod =
          getStartOfSegmentFromTime(temporaryStart, maximumLengthForDuration) +
          maximumLengthForDuration;

        returnValues.push({
          x: getStartOfSegmentFromTime(
            temporaryStart,
            maximumLengthForDuration
          ),
          y: nextPeriod - temporaryStart,
          label: state.label,
        });
        temporaryStart = nextPeriod;
      }
    }
    return returnValues;
  }
};

export default stateHistoryProcessingWorker;
