import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const ChipInput = (props) => {
  const [inputValue, setInputValue] = React.useState("");

  const {
    disabled,
    readOnly = false,
    fullWidth = false,
    variant = "standard",
    autoFocus = false,
    type = "text",
    value,
    onAdd,
    onDelete,
    label,
    placeholder = "",
    allowDuplicates,
    limit = Number.MAX_SAFE_INTEGER,
  } = props;
  return (
    <Autocomplete
      {...props}
      value={value}
      allowDuplicates={allowDuplicates}
      freeSolo
      multiple
      // autoSelect
      fullWidth={fullWidth}
      options={[]}
      onInputChange={(event, value, reason) => {
        setInputValue(value);
      }}
      onChange={(event, newValue, reason) => {
        if (reason === "remove-option") {
          // find which was removed
          const idx = value.findIndex((x) => !newValue.includes(x));
          if (idx >= 0) {
            onDelete(value[idx], idx);
          }
        }
      }}
      disableClearable
      renderInput={(params) => {
        params.inputProps.onKeyDown = (event) => {
          if (event.key === "Tab" || event.key === "Enter") {
            if (
              inputValue &&
              !value.includes(inputValue) &&
              value.length < limit
            ) {
              onAdd(inputValue);
              setInputValue("");
            }
            event.preventDefault();
          }
        };
        return (
          <TextField
            {...params}
            label={label}
            placeholder={value.length > 0 ? "" : placeholder}
            autoFocus={autoFocus}
            type={type}
            variant={variant}
            // multiline
            // label={label}
            // disabled={value.length >= limit}
          />
        );
      }}
      disabled={disabled}
      ChipProps={readOnly ? { onDelete: null } : {}}
    />
  );
};

export default ChipInput;
