import moment from "moment";
import { default as _last } from "lodash/last";

const MAX_EVENTS_WINDOW = 86400;

const getDurationInSeconds = (value, units) => {
  let factor;
  if (units === "weeks") {
    factor = 604800;
  } else if (units === "days") {
    factor = 86400;
  } else if (units === "hours") {
    factor = 3600;
  } else if (units === "minutes") {
    factor = 60;
  } else if (units === "seconds") {
    factor = 1;
  }
  return value * factor;
};

const getStartAndEndTimes = (
  timeframe,
  stackAttrs,
  maxWindowInSeconds = MAX_EVENTS_WINDOW
) => {
  let startTime = null;
  let endTime = null;
  let durationInSeconds = Math.min(
    TimeframeService.getDurationInSeconds(
      timeframe["length.value"],
      timeframe["length.units"]
    ),
    maxWindowInSeconds
  );
  if (timeframe.type === "moving") {
    // end time based on most recent data point from all streams
    let last = null;
    stackAttrs.forEach((entry) => {
      const st = entry.stack;
      const attrs = entry.attributes;
      attrs.forEach((a) => {
        const data = st["ds_" + a] || [];
        if (data.length > 0) {
          last = Math.max(last, _last(data)[0]);
        }
      });
    });
    endTime = moment.utc(last);
    startTime = moment.utc(endTime).subtract(durationInSeconds, "seconds");
  } else if (timeframe.type === "fixed") {
    startTime = moment(timeframe.start);
    endTime = moment.utc(startTime).add(durationInSeconds, "seconds");
  }
  return [startTime, endTime];
};

const getStartAndEndTimes2 = (
  timeframe,
  streams,
  maxWindowInSeconds = MAX_EVENTS_WINDOW
) => {
  let startTime = null;
  let endTime = null;
  let durationInSeconds = Math.min(
    TimeframeService.getDurationInSeconds(
      timeframe["length.value"],
      timeframe["length.units"]
    ),
    maxWindowInSeconds
  );
  if (timeframe.type === "moving") {
    let last = null;
    Object.values(streams).forEach((data) => {
      if (data.length > 0) {
        last = Math.max(last, _last(data).x);
      }
    });
    endTime = last ? moment.utc(last) : moment.utc();
    startTime = moment.utc(endTime).subtract(durationInSeconds, "seconds");
  } else if (timeframe.type === "fixed") {
    startTime = moment(timeframe.start);
    endTime = moment.utc(startTime).add(durationInSeconds, "seconds");
  }
  return [startTime, endTime];
};

const getStartAndEndTimes3 = (
  timeframe,
  maxWindowInSeconds = MAX_EVENTS_WINDOW
) => {
  let endTime = moment.utc();
  let durationInSeconds = Math.min(
    TimeframeService.getDurationInSeconds(
      timeframe["length.value"],
      timeframe["length.units"]
    ),
    maxWindowInSeconds
  );
  const startTime = moment(endTime).subtract(durationInSeconds, "seconds");
  return [startTime, endTime];
};

const getTagEventInterval = (st, a, data = null, dataType = null) => {
  let sampleInterval = st.data_config[a] && st.data_config[a].s_int;
  if (sampleInterval) {
    return sampleInterval < 1 ? 1 : sampleInterval;
  } else {
    if (!data) {
      data = st["ds_" + a] ?? [];
    }
    sampleInterval = Math.min(
      ...data.slice(1).reduce((acc, d, idx) => {
        acc[idx] = dataType === "xy" ? d.x - data[idx].x : d[0] - data[idx][0];
        return acc;
      }, [])
    );

    return sampleInterval === Infinity || sampleInterval < 1000
      ? 1
      : sampleInterval / 1000;
  }
};

// const getMinDataInterval = (stackAttrs) => {
//   let minInterval = Infinity;

//   stackAttrs.forEach((entry) => {
//     const st = entry.stack;
//     const attrs = entry.attributes;
//     attrs.forEach((a) => {
//       const sampleInterval = st.data_config[a] && st.data_config[a].s_int;
//       if (sampleInterval) {
//         minInterval = Math.min(minInterval, sampleInterval * 1000);
//       } else {
//         const data = st["ds_" + a] || [];
//         minInterval = Math.min(
//           minInterval,
//           ...data
//             .slice(1)
//             .reduce((acc, d, idx) => {
//               acc[idx] = d[0] - data[idx][0];
//               return acc;
//             }, [])
//             .filter((d) => {
//               if (d > 0) {
//                 return d;
//               }
//             })
//         );
//       }
//     });
//   });
//   return minInterval === Infinity || minInterval < 1000
//     ? 1
//     : Math.round(minInterval / 1000);
// };

function getPreviousTimeframe(timeframe, startTime, maxWindowInSeconds) {
  if (!!timeframe) {
    const newTimeframe = { ...timeframe };
    let durationInSeconds = Math.min(
      getDurationInSeconds(
        newTimeframe["length.value"],
        newTimeframe["length.units"]
      ),
      maxWindowInSeconds
    );
    if (newTimeframe.type === "fixed") {
      newTimeframe.start = moment
        .utc(newTimeframe.start)
        .subtract(durationInSeconds, "seconds");
      // newTimeframe.start = moment
      //   .utc(newTimeframe.start)
      //   .subtract(newTimeframe["length.value"], newTimeframe["length.units"]);
      console.log("NEW TIME FRAMEX!!!", newTimeframe, durationInSeconds);
    } else if (newTimeframe.type === "moving") {
      newTimeframe.type = "fixed";
      newTimeframe.start = moment
        .utc(startTime)
        .subtract(durationInSeconds, "seconds");
      // newTimeframe.start = moment
      //   .utc(startTime)
      //   .subtract(newTimeframe["length.value"], newTimeframe["length.units"]);
      console.log("NEW TIME FRAMEX@@@", newTimeframe, durationInSeconds);
    }
    return newTimeframe;
  }
  return timeframe;
}

function getNextTimeframe(timeframe, startTime, maxWindowInSeconds) {
  if (!!timeframe) {
    const newTimeframe = { ...timeframe };
    let durationInSeconds = Math.min(
      getDurationInSeconds(
        newTimeframe["length.value"],
        newTimeframe["length.units"]
      ),
      maxWindowInSeconds
    );
    if (newTimeframe.type === "fixed") {
      newTimeframe.start = moment
        .utc(newTimeframe.start)
        .add(durationInSeconds, "seconds");
      // newTimeframe.start = moment
      //   .utc(newTimeframe.start)
      //   .add(newTimeframe["length.value"], newTimeframe["length.units"]);
    } else if (newTimeframe.type === "moving") {
      newTimeframe.type = "fixed";
      newTimeframe.start = moment
        .utc(startTime)
        .add(durationInSeconds, "seconds");
      // newTimeframe.start = moment
      //   .utc(startTime)
      //   .add(
      //     newTimeframe["length.value"],
      //     newTimeframe["length.units"]
      // );
    }
    return newTimeframe;
  }
  return timeframe;
}

function timeframeChanged(tf1, tf2) {
  if (tf1.type !== tf2.type) {
    return true;
  }

  if (
    tf1["length.value"] !== tf2["length.value"] ||
    tf1["length.units"] !== tf2["length.units"] ||
    tf1["interval.type"] !== tf2["interval.type"] ||
    tf1["interval.value"] !== tf2["interval.value"] ||
    tf1["interval.units"] !== tf2["interval.units"]
  ) {
    return true;
  }

  return tf1.type === "fixed" && tf1.start !== tf2.start;
}

function disableReset(propTimeframe, widgetTimeframe) {
  if (!propTimeframe || !widgetTimeframe) {
    return true;
  }

  const propTimeFrameKeys = Object.keys(propTimeframe);
  const widgetTimeFrameKeys = Object.keys(widgetTimeframe);

  return (
    propTimeFrameKeys.length === widgetTimeFrameKeys.length &&
    propTimeFrameKeys.every((key) => {
      return propTimeframe[key] === widgetTimeframe[key];
    })
  );
}

export const TimeframeService = {
  MAX_EVENTS_WINDOW,
  getDurationInSeconds,
  getStartAndEndTimes,
  getStartAndEndTimes2,
  getStartAndEndTimes3,
  getTagEventInterval,
  // getMinDataInterval,
  getPreviousTimeframe,
  getNextTimeframe,
  timeframeChanged,
  disableReset,
};
