import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { SizeMe } from "react-sizeme";
import { withStyles } from "@material-ui/core/styles";
import Widget from "../../widget/Widget";
import ISXUtils, { ISXContext } from "services/Utils";
import Backdrop from "@material-ui/core/Backdrop";
const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    bottom: 10,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
})(Backdrop);

const enableViewPrevious = false; //flag that allows the overlay backdrop to be cleared by clicking on it

const styles = (theme) => ({
  divone: {
    width: "100%",
    paddingBottom: ".1em",
  },
  divtwo: {
    width: "100%",
  },
  primary: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    marginTop: ".5em",
  },
});

const DatapointWidget = (props) => {
  const { classes, color, displayOptions, ...rest } = props;
  const isxContext = useContext(ISXContext);
  let primaryValue = null;
  let secondaryValue = null;
  let textValue = null;
  const value = ISXUtils.formattedValue(props.value, isxContext);
  if (displayOptions?.valuetext) {
    if (displayOptions?.valuetext.startsWith("{")) {
      //todo templating related to {}
      textValue = value;
    } else {
      textValue = displayOptions?.valuetext;
    }
  }
  if (displayOptions?.icon && displayOptions?.icon !== "") {
    primaryValue = displayOptions?.icon;
    secondaryValue = textValue;
  } else {
    primaryValue = textValue || value;
  }
  const [showBackdrop, setShowBackdrop] = React.useState(true);
  if ("status" in props && !showBackdrop && props.status) {
    setShowBackdrop(true);
  }
  const viewPrevious = () => {
    if (enableViewPrevious) {
      setShowBackdrop(false);
    }
  };
  return (
    <Widget {...rest} dataType="tags" maxTags={1} widgetTitle="Datapoint">
      <SizeMe monitorHeight monitorWidth>
        {({ size }) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LimitedBackdrop
                open={props.status ? false : showBackdrop}
                onClick={(e) => viewPrevious()}
              >
                <Typography variant="button">Not Available</Typography>
              </LimitedBackdrop>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: displayOptions?.bgcolor || "#fffff",
                }}
              >
                <div
                  className={classes.divone}
                  style={{ height: secondaryValue == null ? "100%" : "65%" }}
                >
                  <Typography
                    className={classes.primary}
                    display="block"
                    style={{
                      //backgroundColor: displayOptions.bgcolor || "#fffff",
                      color: displayOptions.color,
                      fontSize:
                        size && size.height && size.width
                          ? Math.min(size.height / 1.5, size.width / 4.0)
                          : 0,
                    }}
                  >
                    {primaryValue == null ? "---" : primaryValue}
                  </Typography>
                </div>
                <div className={classes.divtwo} style={{ height: "35%" }}>
                  <Typography
                    className={classes.primary}
                    display="block"
                    style={{
                      //backgroundColor: displayOptions.bgcolor || "#fffff",
                      color: displayOptions.color,
                      fontSize:
                        size && size.height && size.width
                          ? Math.min(size.height / 4.5, size.width / 6.0)
                          : 0,
                    }}
                  >
                    {secondaryValue == null ? "---" : secondaryValue}
                  </Typography>
                </div>
              </div>
            </div>
          );
        }}
      </SizeMe>
    </Widget>
  );
};

export default withStyles(styles)(DatapointWidget);
