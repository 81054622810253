import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { withStyles } from "@material-ui/core/styles";

const ALL_ATTRIBUTES = [
  {
    label: "label",
    heading: "Stack Label"
  },
  {
    label: "guuid",
    heading: "Stack UUID"
  },
  {
    label: "last_updated",
    heading: "Last Updated"
  },
  {
    label: "status",
    heading: "Status"
  }
];

const styles = theme => ({
  label: {
    textTransform: "capitalize"
  }
});

class AttributesOption extends React.Component {
  constructor(props) {
    super(props);
    const { allAttributes = ALL_ATTRIBUTES, maxAttributes = 0 } = props;
    const attributes = this.props.options.attributes;
    const attributesSelected =
      maxAttributes !== 1
        ? allAttributes.reduce((acc, attr) => {
            acc[attr] = attributes.includes(attr);
            return acc;
          }, {})
        : attributes[0] || null;

    this.state = {
      attributesSelected
    };
  }

  selectAttribute = (attr, selected = true) => {
    if (this.props.maxAttributes !== 1) {
      let newAttrsSelected = { ...this.state.attributesSelected };
      newAttrsSelected[attr] = selected;
      this.setState({ attributesSelected: newAttrsSelected }, () => {
        const attributes = Object.entries(newAttrsSelected)
          .filter(entry => !!entry[1])
          .map(entry => entry[0]);
        this.props.setOptions({
          attributes
        });
      });
    } else {
      this.setState({ attributesSelected: attr }, () => {
        this.props.setOptions({
          attributes: attr
        });
      });
    }
  };

  attrToLabel = attr => attr.replace("_", " ");

  render = () => {
    const {
      maxAttributes = 0,
      allAttributes = ALL_ATTRIBUTES,
      classes
    } = this.props;
    const { attributesSelected } = this.state;

    return (
      <FormControl>
        {maxAttributes !== 1 ? (
          <FormGroup>
            {allAttributes.map(attr => (
              <FormControlLabel
                key={attr}
                control={
                  <Checkbox
                    checked={attributesSelected[attr]}
                    onChange={(() => event => {
                      this.selectAttribute(attr, event.target.checked);
                    })()}
                    value={attr}
                  />
                }
                label={this.attrToLabel(attr)}
                classes={{ label: classes.label }}
              />
            ))}
          </FormGroup>
        ) : (
          <RadioGroup aria-label="Attribute" value={attributesSelected || ""}>
            {allAttributes.map(attr => (
              <FormControlLabel
                key={attr}
                value={attr}
                control={<Radio />}
                label={attr}
                onChange={(() => event => {
                  this.selectAttribute(attr);
                })()}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    );
  };
}
export default withStyles(styles)(AttributesOption);
