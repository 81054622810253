import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { isxOperations } from "store";

export const connectWidget = (WrappedWidget) => {
  const WidgetWrapper = (props) => {
    const username = useSelector((state) => state.isx.username);
    const password = useSelector((state) => state.isx.password);
    const stacks = useSelector((state) => state.isx.stacks);
    const user = useSelector((state) => state.isx.user);
    const org = useSelector((state) => state.isx.org);

    const dispatch = useDispatch();

    const updateWidget = (wprops) =>
      dispatch(isxOperations.updateWidget(props.widget.guuid, wprops));

    return (
      <WrappedWidget
        {...props}
        username={username}
        password={password}
        stacks={stacks}
        user={user}
        org={org}
        updateWidget={updateWidget}
      />
    );
  };

  WidgetWrapper.propTypes = {
    widget: PropTypes.object.isRequired,
  };

  return WidgetWrapper;
};
