import * as actions from "./actions";
import auth from "../services/Auth";
import QueryService from "services/QueryService";
import { default as _zip } from "lodash/zip";
// import dataStream from "./data-stream";
import _chunk from "lodash/chunk";

const ITEMS_ORGS_BATCH_SIZE = 20;
const ORGS_BATCH_SIZE = 100;

// window.ds = dataStream;
export const attemptRefresh = () => {
  return async (dispatch) => {
    const isAuthenticated = await auth.refresh();

    const user = {};
    if (isAuthenticated) {
      let response = await auth.fetch("/api/users/me");
      response = await response.json();
      user["userid"] = response.guuid;
      user["name"] = response.name;
      user["username"] = response.email;
      user["userRoles"] = response.user_roles;
      user["dashboards"] = response.dashboards;
      user["orgs"] = response.orgs;
      user["session"] = response.session;
      user["readOnly"] = response.access === "readonly";
      user["item_access_key"] = response?.item_access_key || null;
    }

    dispatch(actions.refreshResponse({ isAuthenticated, user }));
  };
};

// TODO: implement read only user login
export const attemptLogin = (
  username,
  password,
  newpassword,
  mfactor,
  msecure,
  resetpass,
  readOnly = false
) => {
  return async (dispatch) => {
    if (mfactor && mfactor.startsWith("REGISTER:")) {
      await auth.register(username, password, newpassword, mfactor.substr(9));
    } else if (resetpass) {
      await auth.resetpass(username);
      return false;
    } else if (newpassword) {
      await auth.changepass(username, password, mfactor, newpassword);
      return false;
    } else {
      const isAuthenticated = await auth.login(
        username,
        password,
        mfactor,
        msecure
      );

      const user = {};
      if (isAuthenticated) {
        let response = await auth.fetch("/api/users/me");
        response = await response.json();
        user["userid"] = response.guuid;
        user["name"] = response.name;
        user["username"] = response.email;
        user["userRoles"] = response.user_roles;
        user["dashboards"] = response.dashboards;
        user["orgs"] = response.orgs;
        user["session"] = response.session;
        user["readOnly"] = response.access === "readonly";
        user["item_access_key"] = response?.item_access_key || null;
      }
      dispatch(actions.loginResponse({ isAuthenticated, user }));
      return isAuthenticated;
    }
  };
};

export const attemptChangePassword = (
  username,
  newpassword,
  password,
  mfactor
) => {
  return async (dispatch) => {
    await auth.changepass(username, password, mfactor, newpassword);
    return false;
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    // this should be addressed in a better way -
    // perhaps keep track of selected dashboard;
    // for now look at all dashboards and see
    // which if any are unlocked for writing (should
    // be at most 1), and lock
    const dashboards = getState().isx.dashboards;

    // get user session to check locks
    const user = getState().isx.user;
    const key = `ISX-user-session-${user.userid}`;
    const session = localStorage.getItem(key);
    Object.values(dashboards).forEach(async (dashboard) => {
      const writeAccessState = dashboard.write_access_state;
      if (
        writeAccessState?.userid === user.userid &&
        writeAccessState?.session === session
      ) {
        await QueryService.lockDashboard(dashboard.guuid);
      }
    });

    const success = await auth.logout();
    dispatch(actions.logoutResponse(success));
    return success;
  };
};

export const updateUser = (props) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/users/me`, {
      method: "PATCH",
      body: JSON.stringify(props),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const user = await response.json();
    dispatch(actions.updateUserResponse(user));
    return true;
  };
};

export const getAllItems = (orgids = null, fields = null) => {
  return async (dispatch) => {
    const fieldsQueryParams = fields?.map((field) => `fields=${field}`) ?? [];

    let items;
    if (orgids) {
      const batches = _chunk(orgids, ITEMS_ORGS_BATCH_SIZE);
      const promises = batches.map((batch) => {
        let queryParams = batch.map((oid) => `orgids=${oid}`);
        if (fieldsQueryParams.length > 0) {
          queryParams = [...queryParams, ...fieldsQueryParams];
        }
        return auth.fetch(`/api/items?${queryParams.join("&")}`);
      });
      const responses = await Promise.all(promises);
      const itemBatches = await Promise.all(
        responses.map((response) => response.json())
      );
      items = itemBatches.flat();
    } else {
      const response = await (fieldsQueryParams
        ? auth.fetch(`/api/items?${fieldsQueryParams.join("&")}`)
        : auth.fetch(`/api/items`));
      items = await response.json();
    }
    dispatch(actions.getAllItemsResponse(items));
    return items;
  };
};

export const getItems = (guuids, attrs) => {
  return async (dispatch) => {
    const queryParams = guuids.map((guuid) => `ids=${guuid}`).join("&");
    const response = await auth.fetch(`/api/items?${queryParams}`);
    const items = await response.json();
    dispatch(actions.getItemsResponse(items));
    return items;
  };
};

export const getItem = (guuid, attrs) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/items/${guuid}`);
    const item = await response.json();
    dispatch(actions.getItemResponse(item));
    return item;
  };
};

export const getOrgs = (orgids, useOrganizationInvites = true) => {
  return async (dispatch) => {
    const batches = _chunk(orgids, ORGS_BATCH_SIZE);
    const promises = batches.map((batch) => {
      let queryParams = batch.map((oid) => `ids=${oid}`);
      queryParams = [
        ...queryParams,
        `use_invites=${useOrganizationInvites ? "1" : "0"}`,
      ];
      return auth.fetch(`/api/orgs?${queryParams.join("&")}`);
    });
    const responses = await Promise.all(promises);
    const orgBatches = await Promise.all(
      responses.map((response) => response.json())
    );
    const orgs = orgBatches.flat();
    dispatch(actions.getOrgsResponse(orgs));
    return orgs;
  };
};

export const updateCalibration = (guuid, params) => {
  return (dispatch) => {
    throw Error("Not implemented");

    // const state = getState();
    // return QueryService.updateCalibration(
    //   state.isx.username,
    //   state.isx.password,
    //   guuid,
    //   params
    // ).then(results => {
    //   dispatch(
    //     actions.updateCalibrationResponse(
    //       guuid,
    //       params,
    //       results.status
    //     )
    //   );
    //   return true;
    // });
  };
};

export const updateItem = (guuid, props) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/items/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify(props),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const item = await response.json();
    dispatch(actions.updateItemResponse(guuid, item));
    return true;
  };
};

export const updateAlarm = (guuid, params) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/items/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        data_config: params,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const item = await response.json();
    dispatch(actions.updateItemResponse(guuid, item));
    return true;
  };
};

export const getDashboard = (guuid, force = false) => {
  return async (dispatch, getState) => {
    if (guuid) {
      let dashboard = getState().isx.dashboards[guuid];
      const init = force
        ? {}
        : {
            headers: {
              "If-None-Match": (dashboard && dashboard._version) || "",
            },
          };
      const response = await auth.fetch(`/api/dashboards/${guuid}`, init);
      if (response.status !== 304) {
        dashboard = await response.json();
        dispatch(actions.getDashboardResponse(guuid, dashboard));
      }
      return dashboard;
    } else {
      return null;
    }
  };
};

export const getDashboards = (fields = null, guuids = null) => {
  return async (dispatch) => {
    let response;
    if (fields || guuids) {
      const idQueryParams = guuids?.map((guuid) => `ids=${guuid}`) ?? [];
      const fieldQueryParams = fields?.map((field) => `fields=${field}`) ?? [];
      const queryParams = [...idQueryParams, ...fieldQueryParams].join("&");
      response = await auth.fetch(`/api/dashboards?${queryParams}`);
      // queryParams = fields.map((field) => `fields=${field}`).join("&");
      // queryParams = fields.map((field) => `fields=${field}`);
      // response = await auth.fetch(`/api/dashboards?${queryParams}`);
    } else {
      response = await auth.fetch("/api/dashboards");
    }
    const dashboards = await response.json();
    dispatch(actions.getDashboardsResponse(dashboards));

    return dashboards;
  };
};

export const createDashboard = (properties) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/dashboards`, {
      method: "POST",
      body: JSON.stringify(properties),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dashboard = await response.json();
    dispatch(actions.createDashboardResponse(dashboard));
    return dashboard;
  };
};

export const updateDashboard = (guuid, properties) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify(properties),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dashboard = await response.json();
    dispatch(actions.updateDashboardResponse(guuid, dashboard));
    return true;
  };
};

export const removeDashboard = (guuid) => {
  return async (dispatch) => {
    await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "DELETE",
    });
    dispatch(actions.removeDashboardResponse(guuid));
  };
};

export const updateDashboards = (payload) => {
  return async (dispatch) => {
    const response = await auth.fetch("/api/dashboards/batch", {
      method: "POST",
      body: JSON.stringify({ payload }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const results = await response.json();

    const creations = [];
    const updates = [];
    const deletions = [];
    for await (const entry of _zip(results, payload.operations)) {
      const [result, operation] = entry;
      switch (operation.op) {
        case "create":
          {
            const dashboard = result;
            creations.push(dashboard);
          }
          break;
        case "update":
          {
            const dashboard = result;
            updates.push(dashboard);
          }
          break;
        case "delete":
          // will only get succeed or fail
          {
            const success = result === 0;
            deletions.push(operation.dashboard.guuid);
          }
          break;
        default:
          break;
      }
    }

    dispatch(
      actions.updateDashboardsResponse({
        creations,
        updates,
        deletions,
      })
    );
    return true;
  };
};

export const updateWidgetsLayout = (guuid, layout) => {
  return async (dispatch) => {
    const response = await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        widgets_layout: layout,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dashboard = await response.json();
    dispatch(
      actions.updateWidgetsLayoutResponse(
        guuid,
        dashboard.widgets_layout,
        dashboard._version
      )
    );
  };
};

export const addWidget = (guuid, widget) => {
  return async (dispatch, getState) => {
    const {
      isx: { dashboards, widgets: widgetsMap },
    } = getState();
    let dashboard = dashboards[guuid];
    let widgets = (dashboard.widgets || []).map((wid) => widgetsMap[wid]);
    widgets.push(widget);

    await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        widgets,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(actions.addWidgetResponse(guuid, widget));
  };
};

export const updateWidget = (guuid, properties) => {
  return async (dispatch, getState) => {
    const {
      isx: { dashboards, widgets: widgetsMap },
    } = getState();
    const widget = widgetsMap[guuid];
    const dashboard = dashboards[widget.dashboard];
    widgetsMap[guuid] = { ...widget, ...properties };
    let widgets = (dashboard.widgets || []).map((wid) => widgetsMap[wid]);

    await auth.fetch(`/api/dashboards/${dashboard.guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        widgets,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(actions.updateWidgetResponse(guuid, properties));
  };
};

export const removeWidget = (guuid, wguuid) => {
  return async (dispatch, getState) => {
    const {
      isx: { dashboards, widgets: widgetsMap },
    } = getState();
    const dashboard = dashboards[guuid];
    let widgets = (dashboard.widgets || [])
      .filter((wid) => wid !== wguuid)
      .map((wid) => widgetsMap[wid]);

    await auth.fetch(`/api/dashboards/${guuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        widgets,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(actions.removeWidgetResponse(guuid, wguuid));
  };
};

export const getUserMessages = () => {
  return async (dispatch) => {
    const response = await auth.fetch("/api/messages");
    const messages = await response.json();
    dispatch(actions.getUserMessagesResponse(messages));
    return messages;
  };
};

export const createUserMessage = (properties) => {
  return async (dispatch) => {
    const message = {};
    dispatch(actions.createUserMessageResponse(message));
    return true;
  };
};

export const updateUserMessage = (guuid, properties) => {
  return async (dispatch) => {
    const message = {};
    dispatch(actions.updateUserMessageResponse(guuid, message));
    return true;
  };
};

export const removeUserMessage = (guuid) => {
  return async (dispatch) => {
    await auth.fetch(`/api/messages/${guuid}`, {
      method: "DELETE",
    });
    dispatch(actions.removeUserMessageResponse(guuid));
  };
};

export const updateSubscription = (subscriptionId, data) => {
  return (dispatch) =>
    dispatch(actions.updateSubscription(subscriptionId, data));
};

export const updateStreams = (streamData) => (dispatch) =>
  dispatch(actions.updateStreams(streamData));

export const removeStreams = (tags) => (dispatch) =>
  dispatch(actions.removeStreams(tags));

export const clearStreams = () => (dispatch) =>
  dispatch(actions.clearStreams());

export const updateTagEventIntervals = (intervals) => (dispatch) =>
  dispatch(actions.updateTagEventIntervals(intervals));

export const removeTagEventIntervals = (tags) => (dispatch) =>
  dispatch(actions.removeTagEventIntervals(tags));

export const clearTagEventIntervals = () => (dispatch) =>
  dispatch(actions.clearTagEventIntervals());
