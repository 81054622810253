import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const MESSAGE_TYPES = {
  share_template: "A dashboard template was shared with you by",
};

const getMessageDetails = (msg) => {
  let info;
  if (msg.message_type === "share_template") {
    const template = msg.message_payload && msg.message_payload.template;
    if (template) {
      try {
        info = (
          <Typography>{`Dashboard Title: ${
            JSON.parse(template).title
          }`}</Typography>
        );
      } catch (e) {
        // do nothing
      }
    }
  }
  return info || <div></div>;
};

export default (props) => {
  async function accept(msg) {
    if (msg.message_type === "share_template") {
      const template = msg.message_payload && msg.message_payload.template;
      if (template) {
        const parsedTemplate = JSON.parse(template);
        const sender = msg.sender.name || msg.sender.email;
        const guuid = await props.copyDashboardTemplate(parsedTemplate, sender);
        window.location.hash = "#/dashboard/" + guuid;
        props.removeMessage(msg.guuid);
        props.handleClose();
      }
    }
  }

  const remove = (msg) => {
    props.removeMessage(msg.guuid);
  };

  const messages = Object.values(props.messages);
  return (
    <Dialog maxWidth={false} open={props.open} onClose={props.handleClose}>
      <DialogTitle>Your Pending Messages</DialogTitle>
      <DialogContent style={{ marginBottom: "1em" }}>
        {messages.length > 0 ? (
          messages.map((msg) => {
            const senderInfo = msg.sender.name
              ? `${msg.sender.name} (${msg.sender.email})`
              : msg.sender.email;
            return (
              <ExpansionPanel key={msg.guuid}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography color="primary" style={{ marginRight: "2em" }}>
                      {msg.tm_generated
                        ? moment(parseInt(msg.tm_generated)).format(DATE_FORMAT)
                        : "---"}
                    </Typography>
                    <Typography>
                      {`${
                        MESSAGE_TYPES[msg.message_type] ||
                        "Unknown message type from"
                      } ${senderInfo}`}
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {getMessageDetails(msg)}
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  <Button size="small" onClick={() => remove(msg)}>
                    Remove
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => accept(msg)}
                  >
                    Accept
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            );
          })
        ) : (
          <DialogContentText>
            You have no more outstanding messages.
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};
