import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboards } from "store/operations";

import { DashboardConfigurationWidget as DashboardConfigurationWidgetComponent } from "components/widgets";
import { connectWidget } from "../../widget-connector/WidgetConnector";

const DashboardConfigurationWidget = (props) => {
  const dashboards = useSelector((state) => state.isx.dashboards);
  const widgets = useSelector((state) => state.isx.widgets);

  const dispatch = useDispatch();

  const createOrUpdateDashboards = async (payload) => {
    console.log("PAYLOAD", payload);
    return dispatch(updateDashboards(payload)).then((success) => {
      return success;
    });
  };

  return (
    <DashboardConfigurationWidgetComponent
      {...props}
      dashboards={dashboards}
      widgets={widgets}
      createOrUpdateDashboards={createOrUpdateDashboards}
    />
  );
};

export default connectWidget(DashboardConfigurationWidget);
