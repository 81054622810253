import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import auth from "services/Auth";
import {
  updateTagEventIntervals,
  clearTagEventIntervals,
} from "store/operations";
import _groupBy from "lodash/groupBy";

const TagEventIntervalsContext = React.createContext({});

export default TagEventIntervalsContext;

const queryItemForEventIntervals = async (guuid, attrs) => {
  const fields = new Set(["guuid"]);
  attrs.forEach((attr) => {
    fields.add(`ds_${attr}`);
    fields.add(`data_config.${attr}.s_int`);
  });
  const fieldQueryParams = Array.from(fields)
    .map((field) => `fields=${field}`)
    .join("&");
  const queryParams = `ids=${guuid}&${fieldQueryParams}`;
  const results = await auth.fetch(`/api/items?${queryParams}`).catch(() => {
    console.warn(
      "TagEventIntervalsService: got error on fetching attrs for",
      guuid
    );
    return undefined;
  });
  return results?.json();
};

export const TagEventIntervalsProvider = ({ children }) => {
  const tagsSeen = useRef(new Set());

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Initialized Tag Event Intervals Service");
    return () => {
      console.log("Destroy Tag Event Intervals Service");
      dispatch(clearTagEventIntervals());
    };
  }, [dispatch]);

  const registerInterest = async (tags) => {
    const missing = tags.filter((t) => !tagsSeen.current.has(t));
    if (missing.length > 0) {
      missing.forEach((t) => tagsSeen.current.add(t));
      // query by stack, so that if one fails (e.g. no permissions) we can still
      // get the fields for the others
      const groupedByStack = _groupBy(
        missing.map((t) => t.split("|")),
        ([stackId]) => stackId
      );
      const promises = Object.entries(groupedByStack).map(
        ([stackId, entries]) => {
          const attrs = entries.map(([, attr]) => attr);
          return queryItemForEventIntervals(stackId, attrs);
        }
      );
      const items = (await Promise.all(promises)).filter(Boolean).flat();

      const itemsById = items.reduce((acc, item) => {
        acc[item.guuid] = item;
        return acc;
      }, {});

      const rv = {};
      missing.forEach((t) => {
        const [guuid, attr] = t.split("|");
        const item = itemsById[guuid];
        if (item) {
          let key = `data_config.${attr}.s_int`;
          let interval = item[key];
          if (interval) {
            rv[t] = interval < 1 ? 1 : interval;
          } else {
            key = `ds_${attr}`;
            const data = item[key] ?? [];
            // compute from data available
            const sampleInterval = Math.min(
              ...data.slice(1).reduce((acc, d, idx) => {
                acc[idx] = d[0] - data[idx][0];
                return acc;
              }, [])
            );
            rv[t] =
              sampleInterval === Infinity || sampleInterval < 1000
                ? 1
                : Math.round(sampleInterval / 1000);
          }
        } else {
          rv[t] = 1;
        }
      });
      dispatch(updateTagEventIntervals(rv));
      // should return a registration id
      return null;
    }
  };

  const reset = () => {
    tagsSeen.current = new Set();
    dispatch(clearTagEventIntervals());
  };

  return (
    <TagEventIntervalsContext.Provider
      value={{
        registerInterest,
        reset,
      }}
    >
      {children}
    </TagEventIntervalsContext.Provider>
  );
};
