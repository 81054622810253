import React, { useState } from "react";
import auth from "../../services/Auth";
import { Navigate } from "services/Router";
import { startSSOLogin } from "../../services/AuthUtil";
import { Login as LoginComponent } from "../../components";

const Login = (props) => {
  const [alreadyAuth,setAlreadyAuth] = useState(auth.isAuthenticated());
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [authMethods, setAuthMethods] = useState();
  const [methodName, setMethodName] = useState("");    
  const [authMethod, setAuthMethod] = useState();
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [mfa, setMfa] = useState(false);
  const [mfactor, setMfactor] = useState("");
  const [msecure, setMsecure] = useState(false);

  const hidePassword = authMethod?.name !== 'interstacks';
  const methodNames = authMethods && authMethods.reduce((a,v)=> a.push(v.name) && a, []);
   
  const handleUsername = (e) => setUsername(e.target.value);

  const handlePassword = (e) => setPassword(e.target.value);

  const handleNewPassword = (e) => setNewpassword(e.target.value);

  const handleMFA = (e) => setMfactor(e.target.value);

  const clearMFA = () => setMfactor("");

  const handleSecure = (e) => setMsecure(e.target.checked);

  const handleMethod = (e) => setMethodName(e.target.value);

  const chooseMethod = () => {
    console.log("CHOSE",authMethods, methodName)
    if(authMethods && methodName)
        for(var i=0; i<authMethods.length; i++)
            if(methodName === authMethods[i].name) {
                console.log("CHOSE",i, authMethods[i])
                setAuthMethod(authMethods[i])
                break;
            }
  };
    
  const handleResetpass = (uname) =>
    auth.resetpass(username).then( () => {  console.log("SOMETHING"); });
    
    const attemptLogin = (skipMFA) => {
        if(!username) {
            setError("Provide username.")
        } else if(hidePassword) { //still in phase 1
            if(!authMethods) {
                auth.loginType(username).then((a) => {
              console.log("1LOGINTYPES", a)              
              const auth_methods = a.auth_methods;
              if(Array.isArray(auth_methods)) {
                  if(auth_methods.length === 1) {
                      console.log("1LOGINTYPES", a)
                      setAuthMethod(auth_methods[0]);
                  } else {
                      console.log("nLOGINTYPES", a)
                      setAuthMethods(auth_methods);
                  }
              } else {
                  setAuthMethod({'name':'interstacks', 'method':'password'});
              }
          });
          } else {
              chooseMethod();
          }
      } else {

    if (username && (password || newpassword)) {
        setError("");
        setDisabled(true);
        auth.login(
            username,
            password,
            (skipMFA===true) ? undefined : mfactor,
            msecure,
            authMethod?.name
        ).then( (isAuthenticated) => {
            //this will deposit access token in auth service
                  auth.fetch("/api/users/me").then(async (response) => {
                      response = await response.json();
                      const user = {};
                      user["userid"] = response.guuid;
                      user["name"] = response.name;
                      user["username"] = response.email;
                      user["userRoles"] = response.user_roles;
                      user["dashboards"] = response.dashboards;
                      user["orgs"] = response.orgs;
                      user["session"] = response.session;
                      user["readOnly"] = response.access === "readonly";
                      console.log("USER", user);
                      //talk to stuart/mike about how to get to dashboard here
                      //not sure about next few lines of code
                      setAlreadyAuth(true);
                  })                  
              }).catch((err) => {
                  if (err.Message === "Correct MFA Token is required.") {
                      setMfa(true);
                  }
                  setError(err.Message);
                  setDisabled(false);
              });
          } else {
              setError("Both username and password are required.")
          }

      }
  };    

    if(alreadyAuth) {
        return <Navigate to="/dashboard"/>
    } else if(authMethod && authMethod.name !== "interstacks") {
      // possibly stash result in local storage and redirect
      // fill template
      // for a in redirect_params: fill in randoms etc....
      //TODO: add redirect URLS to authmethod
      startSSOLogin(authMethod, username).then(status => {
          setError(status);
      });
    }
    return (
      <LoginComponent
        attemptLogin={attemptLogin}
        handleUsername={handleUsername}
        handlePassword={handlePassword}
        handleNewPassword={handleNewPassword}
        handleMFA={handleMFA}
        hidePassword={hidePassword}
        clearMFA={clearMFA}
        handleSecure={handleSecure}
        handleResetpass={handleResetpass}
        error={error}
        disabled={disabled}
        mfa={mfa}
        mfactor={mfactor}
        methods={authMethod ? undefined: methodNames}
        method={methodName}      
        handleMethod={handleMethod}
        />
    );
};

export default Login;
