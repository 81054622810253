import React from "react";
import { QuickMessagingWidget as QuickMessagingWidgetComponent } from "../../../components/widgets";
import QueryService from "../../../services/QueryService";
import { connectWidget } from "../../widget-connector/WidgetConnector";

class QuickMessagingWidget extends React.Component {
  requestPending = false;

  render = () => (
    <QuickMessagingWidgetComponent {...this.props} setProps={this.setProps} />
  );

  setProps = (stackId, props) => {
    return QueryService.sendStackMessage(
      this.props.user.username,
      "",
      stackId,
      JSON.stringify(props),
      this.props?.stacks?.[stackId]?.access_key
    );
  };
}

export default connectWidget(QuickMessagingWidget);
