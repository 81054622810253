import React, { useState, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { ISXContext } from "services/Utils";

const uuidv4 = require("uuid/v4");

const useStyles = makeStyles((theme) => ({
  exit: {
    textAlign: "right",
    float: "right",
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  group: {
    margin: 16,
  },
  cancel: {
    color: "red",
  },
}));

const WIDGET_GROUPS = [
  "Single Value",
  "Time Series",
  "Analytics",
  "Administration",
  "Other",
];

const groupWidgets = (context) => {
  const widgetsByGroup = Object.entries(context.widgets).reduce(
    (acc, entry) => {
      const widget = entry[1];
      if (widget.addable || widget.addable == null) {
        let group = acc[widget.group];
        if (!group) {
          group = acc[widget.group] = [];
        }
        group.push(entry);
      }
      return acc;
    },
    {}
  );
  Object.values(widgetsByGroup).forEach((group) =>
    group.sort((e1, e2) => {
      return e1[1].displayName < e2[1].displayName ? -1 : 1;
    })
  );
  return widgetsByGroup;
};

const NewWidgetDialog = (props) => {
  const classes = useStyles();
  const [widgetType, setWidgetType] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);

  const isxContext = useContext(ISXContext);

  // initialize once
  const widgetsByGroup = useRef(null);
  if (!widgetsByGroup.current) {
    widgetsByGroup.current = groupWidgets(isxContext);
  }

  const setWidget = (event) => {
    setWidgetType(event.target.value);
    setNextDisabled(false);
  };

  const resetState = () => {
    setWidgetType("");
    setNextDisabled(true);
  };

  const reset = () => {
    props.removeDialog();
    resetState();
  };

  const addWidget = () => {
    const widgetInfo = isxContext.widgets[widgetType] || {};
    const dtype = widgetInfo.dataType;
    const widget = {
      guuid: uuidv4(),
      type: widgetType,
      options: {},
    };
    if (dtype) {
      widget[dtype] = [];
    }
    const defaultTitle = widgetInfo.displayName;
    if (defaultTitle) {
      widget.default_title = defaultTitle;
    }
    props.removeDialog();
    props.addWidget(widget);
    reset();
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        Add New Widget
        <IconButton className={classes.close} onClick={reset}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {WIDGET_GROUPS.map(
          (groupName) =>
            widgetsByGroup.current[groupName] && (
              <FormControl key={groupName} className={classes.group}>
                <FormLabel component="legend">{groupName}</FormLabel>
                <RadioGroup
                  key={groupName}
                  onChange={setWidget}
                  value={widgetType}
                >
                  {widgetsByGroup.current[groupName].map((entry) => {
                    const [widgetKey, widget] = entry;
                    const label = widget.preview ? (
                      <div>
                        {widget.displayName} <sup>preview</sup>
                      </div>
                    ) : (
                      widget.displayName
                    );
                    return (
                      <FormControlLabel
                        key={widgetKey}
                        value={widgetKey}
                        control={<Radio />}
                        label={label}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancel} onClick={reset}>
          Cancel
        </Button>
        <Button
          onClick={addWidget}
          disabled={nextDisabled}
          variant="contained"
          color="primary"
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewWidgetDialog;
