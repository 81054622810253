import { WIDGET_COLORS_ARRAY } from "../components/color/colors";
import ISXUtils from "services/Utils";
import { min, max, mean, median, quantile } from "d3-array";
import { default as _last } from "lodash/last";

var Chart = require("chart.js");

const EMA_MIN_SAMPLE_SIZE = 100;

// currently unused
// function sma(data, window) {
//   //console.log("sma data is", data);
//   let res = [];
//   let cc = 0;
//   while (cc < data.length) {
//     let start,
//       end = 0;
//     if (cc < window) {
//       start = 0;
//       end = cc + 1;
//     } else {
//       start = cc - window + 1;
//       end = cc + 1;
//     }
//     res.push({
//       x: data[cc].x,
//       y:
//         data.slice(start, end).reduce((a, b) => a + b.y, 0) /
//         parseFloat(end - start),
//     });

//     cc += 1;
//   }
//   return res;
// }

function ema(data, window, options = {}) {
  const dataType = options.dataType || "xy";
  // start with last sample, or need to seed
  let lastSample = options.lastSample;
  let res = [];
  let cc = 0;
  let alpha = 2 / parseFloat(window + 1);
  while (cc < data.length) {
    let start,
      end = 0;
    if (cc < window && !lastSample) {
      // need to seed using sma
      start = 0;
      end = cc + 1;
      res.push(
        dataType === "xy"
          ? {
              x: data[cc].x,
              y:
                data.slice(start, end).reduce((a, b) => a + b.y, 0) /
                parseFloat(end - start),
            }
          : [
              data[cc][0],
              data.slice(start, end).reduce((a, b) => a + b[1], 0) /
                parseFloat(end - start),
            ]
      );
    } else {
      // steady state; compute next sample from last sample and current value
      if (!lastSample) {
        // must init after a seed
        lastSample = _last(res);
      }
      lastSample =
        dataType === "xy"
          ? {
              x: data[cc].x,
              y: lastSample.y + alpha * (data[cc].y - lastSample.y),
            }
          : [
              data[cc][0],
              lastSample[1] + alpha * (data[cc][1] - lastSample[1]),
            ];
      res.push(lastSample);
    }
    cc += 1;
  }
  return res;
}

function populateDataSets(props) {
  const tags = (props.widget || {}).tags || [];
  const stacks = props.stacks || {};

  if (tags.length === 0) {
    return [];
  }
  return tags
    .filter((t) => !!stacks[t.stack])
    .map((t, i) => {
      const st = stacks[t.stack] || {};
      const a = t.attribute;
      let data = [];
      let derivedIndex = a.indexOf("|ma");
      if (derivedIndex > -1) {
        let emawindow =
          parseInt(
            st["data_config"][a.slice(0, derivedIndex)]?.numeric?.ema?.window
          ) || 10;
        data = ema(
          props.data[st.guuid + "|" + a.slice(0, derivedIndex)] || [],
          emawindow
        );
      } else {
        data = props.data[st.guuid + "|" + a] || [];
      }
      let label;
      try {
        label = st?.name || JSON.parse(st.dev_session)["stack.label"] || "?";
      } catch (err) {
        label = "?";
      }
      if (tags.length > 0) {
        label += ":" + ISXUtils.getTagDisplayText(a, st.data_config);
      }

      const color = Chart.helpers.color(
        WIDGET_COLORS_ARRAY[i % WIDGET_COLORS_ARRAY.length]
      );

      const mainColor = color.rgbString();
      const lightColor = color.alpha(0.3).rgbString();
      const decimatedColor = color.alpha(0.7).rgbString();
      //This logic is all speculation until the backend passes this across.
      // const devSession = JSON.parse(st.dev_session);
      // const sensorConfig =
      //   !!devSession &&
      //   !!devSession.sensorconfig &&
      //   Object.values(devSession.sensorconfig).filter(
      //     (conf) => conf.name === t.attribute
      //   );

      // const updateInterval = !!st.upd_int
      //   ? st.upd_int * 1000
      //   : !!sensorConfig
      //   ? sensorConfig.upd_int * 1000
      //   : null;

      // const missingDataCutoff = !!st.upd_int
      //   ? st.upd_int * 1500
      //   : !!sensorConfig
      //   ? sensorConfig.upd_int * 1500
      //   : null;

      let dataset = {
        type: "line",
        label,
        tag: t,
        data: [],
        rawData: data || [],
        //updateInterval: updateInterval, //forcing to compute interval time from data samples
        //missingDataCutoff: missingDataCutoff, //forcing to compute cutoff time from data samples
        coreColor: mainColor,
        decimatedColor,
        backgroundColor: lightColor,
        borderColor: lightColor,
        pointRadius: 2,
        pointHoverRadius: 5,
        pointBackgroundColor: mainColor,
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: mainColor,
        fill: false,
      };
      return dataset;
    });
}

function getSamplingStatistics(data) {
  const series = data
    .map((datum) => datum.x)
    .reduce(
      (gaps, time, index, source) =>
        index === 0 ? gaps : gaps.concat(time - source[index - 1]),
      []
    );

  return {
    min: min(series),
    max: max(series),
    mean: mean(series),
    median: median(series),
    ninety: quantile(series, 0.9),
    ninetynine: quantile(series, 0.99),
  };
}

export const TimeSeriesService = {
  EMA_MIN_SAMPLE_SIZE,
  ema,
  populateDataSets,
  getSamplingStatistics,
};
