import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles({
  groupLabel: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "14.25rem",
    whiteSpace: "nowrap",
  },
});

const SharedDashboardDialog = (props) => {
  const [requestPending, setRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dashboardGroups, setDashboardGroups] = useState([]);

  const { dashboard, groups, share, open, handleClose } = props;

  useEffect(() => {
    setDashboardGroups(dashboard.acu_groups ?? []);
  }, [dashboard.acu_groups]);

  async function shareDashboard() {
    setRequestPending(true);
    const results = await share(dashboardGroups);
    if (results.error) {
      setErrorMessage(results.error);
    } else {
      handleClose();
    }
    setRequestPending(false);
  }

  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth='sm' aria-labelledby="template-dialog-title">
      <DialogTitle id="template-dialog-title">
        Share Dashboard with Groups
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup row>
            {groups.map((group) => (
              <FormControlLabel
                key={group.guuid}
                control={
                  <Checkbox
                    checked={dashboardGroups.indexOf(group.guuid) !== -1}
                    onChange={(event) =>
                      setDashboardGroups((groups) =>
                        event.target.checked
                          ? [...groups, group.guuid]
                          : groups.filter((g) => g !== group.guuid)
                      )
                    }
                    name={group.name}
                  />
                }
                label={<Tooltip title={group.name}><div className={classes.groupLabel}>{group.name}</div></Tooltip>}
              />
            ))}
          </FormGroup>
        </FormControl>
        {errorMessage && (
          <DialogContentText style={{ color: "red" }}>
            {errorMessage}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button
          disabled={requestPending}
          onClick={shareDashboard}
          color="primary"
        >
          Save
        </Button>
        {requestPending && <CircularProgress size={20} color="primary" />}
      </DialogActions>
    </Dialog>
  );
};

export default SharedDashboardDialog;
