import "core-js/stable";
import "react-app-polyfill/ie11";
import "whatwg-fetch";

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Router, Route, Navigate, Routes } from "./services/Router";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "typeface-roboto";
import URI from "urijs";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import ISXUtils, { ISXContext } from "services/Utils";
import QueryService from "services/QueryService";
import Widgets from "containers/widgets/AllWidgets";

// const uri = new URI("https://dev.mystacks.interstacks.com");
const uri = new URI(window.location);
const subdomain = uri.subdomain();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, composeEnhancers(middleware));

const AppWrapper = () => {
  const location = useLocation();
  // updates the state when a new update is pending.
  // action for updating the service worker.
  const updateFrontEnd = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
          // send the skip message to kick off the service worker install.
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
          // add an listener to reload page when the new service worker is ready.
          registration.waiting.addEventListener("statechange", (event) => {
            const { state = "" } = event.target || {};
            if (state === "activated") {
              console.log("reloading");
              serviceWorkerRegistration.unregister();
              //console.log("unregistering.. do I need to?");
              window.location.reload();
            }
          });
        }
      });
    }
  };
  // effect added from router location to check for a new service worker on every
  // page transition (change of route).
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
        console.log("registration update j");
      });
    }
  }, [location]);

  serviceWorkerRegistration.register({ onUpdate: updateFrontEnd });

  const [bespokeContent, setBespokeContent] = useState(null);

  useEffect(() => {
    const subdomainParts = subdomain && subdomain.split(".");
    if (subdomainParts && subdomainParts.length > 0) {
      const themeids = subdomainParts
        .map((p, i) => subdomainParts.slice(i).join("."))
        .reverse();
      (async () => {
        try {
          const results = await QueryService.themesQuery(themeids);
          const combinedResults = results.reduce((acc, content) => {
            Object.assign(acc, content);
            return acc;
          }, {});
          setBespokeContent(combinedResults);
        } catch (e) {
          console.error("themes query exception", e);
          // can make no further progress, since we don't want to show
          // the default theme when they are expecting another; perhaps
          // we should have a _very_ generic default and make the current
          // default theme the one for "mystacks"...
        }
      })();
    } else {
      setBespokeContent({});
    }
  }, []);

  if (!bespokeContent) {
    return null;
  }

  const bespokeTheme = bespokeContent.theme || {};

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E28C05",
        contrastText: "#f8f8f8",
      },
      secondary: {
        main: "#E28C05",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    widget: {
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 24,
          paddingRight: 56,
        },
        head: {
          lineHeight: "1em",
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "auto",
          marginRight: 16,
        },
      },
      MuiListItemText: {
        inset: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: "0.875rem",
        },
      },
      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight: "none",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontSize: 13,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 16,
          fontWeight: 800,
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
      MUIDataTableSelectCell: {
        root: {
          backgroundColor: "#fff",
        },
      },
      MUIDataTableFilterList: {
        root: {
          flex: "0 0 auto",
        },
      },
      MUIDataTableToolbar: {
        root: {
          minHeight: 48,
        },
        icon: {
          padding: 6,
        },
        iconActive: {
          padding: 6,
        },
      },
      MUIDataTableSearch: {
        clearIcon: {
          padding: 6,
        },
      },
    },
    ...((bespokeTheme && bespokeTheme.styling) || {}),
  });

  const isxContext = {
    logo: bespokeTheme.logo,
    labels: {
      organization:
        (bespokeTheme.labels && bespokeTheme.labels.organization) ||
        "Interstacks",
      platform: bespokeTheme.labels?.platform ?? "MyStacks",
      device: ISXUtils.getDeviceLabel(bespokeTheme),
      devicePlural: ISXUtils.getDeviceLabel(bespokeTheme, false, true),
      deviceCapitalized: ISXUtils.getDeviceLabel(bespokeTheme, true),
      deviceCapitalizedPlural: ISXUtils.getDeviceLabel(
        bespokeTheme,
        true,
        true
      ),
    },
    termsOfUse: bespokeContent.termsOfUse,
    smsTermsUse:
      bespokeContent.smsTermsOfUse ?? "https://interstacks.com/sms-terms",
    privacyPolicy: bespokeContent.privacyPolicy,
    notations: bespokeTheme.notations,
    useOrganizationInvites: bespokeContent.useOrganizationInvites ?? true,
  };

  // add widgets configured here
  const widgets = Widgets(isxContext);
  isxContext.widgets = widgets;
  isxContext.alertTemplates = bespokeTheme.alertTemplates;
  /*isxContext.alertTemplates = bespokeTheme.alertTemplates || {
    "a068dd74-6f8e-41a9-b492-9083ba7ae54d": {
      id: "a068dd74-6f8e-41a9-b492-9083ba7ae54d",
      name: "Multilevel Alerts for Ultrasonic Sensor",
      tagprefix: ["sensor"],
    },
    "a516c834-0b2f-4d71-a42b-c434483b8f8a": {
      id: "a516c834-0b2f-4d71-a42b-c434483b8f8a",
      name: "Alert on Any Lubrication Event",
      tagprefix: ["lube_"],
    },
    "ca2012e0-42d0-494f-b3d3-26f6b163730b": {
      id: "ca2012e0-42d0-494f-b3d3-26f6b163730b",
      name: "Alerts for Available Grease % Volume",
      tagprefix: ["vol_"],
      prefixdata: {
        vol_: {
          name: "Alerts for Available Grease % Volume",
          description: {
            threshold: {
              level1: {
                low: "25% grease remaining in cartridge, order replacement",
              },
              level2: {
                low: "10% grease remaining in cartridge, replace soon",
              },
              level3: {
                low: "5% grease remaining in cartridge, replace ASAP",
              },
            },
          },
        },
      },
      default: true,
    },
    "2a89f671-d351-45cd-a11c-38229e58f0da": {
      id: "2a89f671-d351-45cd-a11c-38229e58f0da",
      name: "Alert for Connection Status",
      tagprefix: ["isxreserved|connected"],
    },
    "f57b25d6-7b99-49b9-a914-b2b9a14ec02f": {
    "id": "f57b25d6-7b99-49b9-a914-b2b9a14ec02f",
    "name": "Auto Lubrication Trigger Event",
    "tagprefix": [
     "sensor"
    ],
    "triggers":{"send_autolube_command":{}}
   }
  };*/

  return (
    <Provider store={store}>
      <CookiesProvider>
        <MuiThemeProvider theme={theme}>
          <ISXContext.Provider value={isxContext}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ISXContext.Provider>
        </MuiThemeProvider>
      </CookiesProvider>
    </Provider>
  );
};

/*ReactDOM.render(<AppWrapper />, document.getElementById("root"));*/
ReactDOM.render(
  <Router>
    <AppWrapper />
  </Router>,
  document.getElementById("root")
);
