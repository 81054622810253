import React, { useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Widget from "../../widget/Widget";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import moment from "moment";
import WidgetTagsConfigureDialog from "components/widget/widget-configure-dialogs/widget-tags-configure-dialog/WidgetTagsConfigureDialog";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const useStyles = makeStyles((theme) => ({
  messsageTime: {
    fontSize: 9,
    float: "right",
  },
  messageUser: {
    marginLeft: 16,
    fontSize: 10,
    marginBottom: -8,
  },

  messageContent: {
    position: "relative",
    textAlign: "left",
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    paddingTop: 6,
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 3,
    width: "60%",
    font: "400 .9em 'Open Sans', sans-serif",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
  },

  messageText: {
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  },

  message: {
    "&:last-of-type": {
      marginBottom: 10,
    },
  },

  messageSelf: {
    display: "flex",
    justifyContent: "flex-end",
    "& $messageContent": {
      backgroundColor: `${theme.palette.primary.main}D8`,
      color: theme.palette.text.primary,
    },
  },
  messageOthers: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    "& $messageContent": {
      backgroundColor: `${theme.palette.primary.main}4D`,
      color: theme.palette.text.primary,
    },
  },
}));

const Note = (props) => {
  const classes = useStyles();

  const { note, user, users, lastNoteBy } = props;
  const [, data] = note;

  const userIsSelf =
    data.created_by === user?.userid || data.created_by === user?.username;

  return (
    <div className={userIsSelf ? classes.messageSelf : classes.messageOthers}>
      {!userIsSelf && lastNoteBy !== data.created_by && (
        <div className={classes.messageUser}>
          {users[data.created_by] ?? data.created_by ?? "---"}
        </div>
      )}
      <div className={classes.messageContent}>
        <Typography component="pre" className={classes.messageText}>
          {data.value ?? ""}
        </Typography>
        <div className={classes.messsageTime}>
          {moment(data.created_at).format(DATE_FORMAT)}
        </div>
      </div>
    </div>
  );
};

const NotesConfigureDialog = (props) => (
  <WidgetTagsConfigureDialog
    {...props}
    maxTags={1}
    widgetTitle="Notes"
    allowCreateNewTag={true}
    showDerivedTags={false}
    createNewTagLabel="Add new topic..."
    attributeColumnHeader="Topic"
    filterTagsOptions={{ matchTypes: ["topic"] }}
  />
);

const NotesWidget = (props) => {
  const { notes, user, users, createNote, disabled, fetchNextPage, ...rest } =
    props;
  const [note, setNote] = useState("");
  const [postBusy, setPostBusy] = useState(false);
  const scrollRef = useRef();
  const atBottom = useRef(true);
  const programmmaticScroll = useRef(false);
  const fetching = useRef(false);
  const lastScrollHeight = useRef(0);

  useEffect(() => {
    // will scroll to bottom on tag change
    atBottom.current = true;
  }, [props.tag]);

  useEffect(() => {
    if (atBottom.current) {
      // if at bottom on update, keep scrollbar there after update
      scrollRef.current.parentNode.scrollTop = scrollRef.current.offsetTop;
      programmmaticScroll.current = true;
    } else if (lastScrollHeight.current > 0) {
      // if paging in progress reset scroll position to keep same note at top
      scrollRef.current.parentNode.scrollTop =
        scrollRef.current.parentNode.scrollHeight - lastScrollHeight.current;
      programmmaticScroll.current = true;
      lastScrollHeight.current = 0;
    }
    // possible that page size is smaller than scroll area, so initial fetch
    // will not trigger scroll bar, thus no ability to page; in this case keep
    // fetching until we have enough
    if (
      !fetching.current &&
      notes.length > 0 &&
      scrollRef.current.parentNode.scrollHeight <=
        scrollRef.current.parentNode.clientHeight
    ) {
      // keep track of fetching state, so that we don't fetch extra times
      fetching.current = true;
      fetchNextPage().then(() => {
        fetching.current = false;
      });
    }
  }, [notes, fetchNextPage]);

  const handleScroll = async (e) => {
    // keep track of whether scrolled to bottom, as this will change behavior
    // when new notes received
    if (programmmaticScroll.current) {
      programmmaticScroll.current = false;
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    atBottom.current = bottom;
    // if at top, fetch next page if more available
    if (e.target.scrollTop === 0) {
      if (fetchNextPage) {
        lastScrollHeight.current = e.target.scrollHeight;
        await fetchNextPage();
      }
    }
  };

  const handleCreateNote = async () => {
    setPostBusy(true);
    atBottom.current = true;
    await createNote(note);
    setNote("");
    setPostBusy(false);
  };

  // track last note author to avoid repeating author name
  let lastNoteBy = null;

  return (
    <Widget {...rest} configureDialog={NotesConfigureDialog}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          onScroll={handleScroll}
          style={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {notes.map((note) => {
            const data = note[1];
            const key = `${data.created_at}-${data.created_by}`;
            const entry = (
              <Note
                key={key}
                note={note}
                user={user}
                users={users}
                lastNoteBy={lastNoteBy}
              />
            );
            lastNoteBy = data.created_by;
            return entry;
          })}
          <div ref={scrollRef}></div>
        </div>
        {props.tag && (
          <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
            <TextField
              disabled={disabled}
              fullWidth
              variant="outlined"
              label="Message"
              multiline
              maxRows={6}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <div>
              <IconButton
                aria-label="Send"
                onClick={handleCreateNote}
                disabled={postBusy || !note}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </Widget>
  );
};

export default NotesWidget;
