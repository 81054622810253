import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { default as _last } from "lodash/last";

import Widget from "../../widget/Widget";

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

// const DEFAULT_COLUMNS = ["email", "org", "permissions", "last_login", "status"];
const DEFAULT_COLUMNS = ["email", "org", "permissions", "status"];

const displayStatus = (status) => {
  if (status === "sent") {
    return "Pending";
  } else if (status === "Verified User") {
    return "Verified User";
  } else if (status) {
    return "Unknown Status";
  } else {
    return null;
  }
};

const styles = (theme) => ({
  header: {
    textTransform: "capitalize",
  },
  toolbar: {
    float: "right",
  },
  detailsContainer: {
    flexGrow: 1,
  },
  detailsValue: {
    overflow: "hidden",
  },
  detailsName: {
    fontWeight: "bold",
  },
  detailsItem: {
    display: "flex",
    alignItems: "center",
  },
  filterSelected: {
    marginLeft: 0,
    marginRight: 0,
  },
  filterSelectedLabel: {
    marginRight: 6,
  },
});

var defaultState = {
  noData: true,
  dataTable: {},
  dataTableHeaders: [],
  timestampSet: new Set(),
  order: "asc",
  orderBy: "label",
  detailsPopupOpen: false,
  selected: {},
  searchText: "",
  filters: {},
  sort: {},
};

class UserManagementWidget extends React.Component {
  tableMuiTheme = (theme) =>
    createTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiIconButton: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        MuiSvgIcon: {
          root: {
            fontSize: "1em",
          },
        },
        MuiCheckbox: {
          root: {
            padding: 0,
          },
        },
      },
    });

  constructor(props) {
    super(props);
    const widget = this.props.widget || {};
    const widgetOptions = widget.options || {};
    const selected = (widgetOptions.selected || []).reduce((acc, s) => {
      acc[s] = true;
      return acc;
    }, {});
    const selectedList = [];
    const invites = [];
    const orgs = this.props.orgs || [];
    const orgId = orgs.length ? orgs[0]["orgid"] : null;
    const users = [];
    const msg = { content: null };
    const open = false;
    const isAdmin = false;
    const isOrgOwner = false;
    const openRemoveUsersConfirmDialog = false;
    const openNewOrgDialog = false;
    const newOrg = { name: "" };
    this.state = {
      ...defaultState,
      selected,
      selectedList,
      invites,
      orgId,
      msg,
      users,
      open,
      openRemoveUsersConfirmDialog,
      openNewOrgDialog,
      isAdmin,
      orgs,
      isOrgOwner,
      newOrg,
    };
    console.log("this state is", this.state, this.props);
  }

  columns = null;
  searchText = null;
  searchTextTimeout = null;
  sortColumn = null;
  sortDirection = null;
  filters = null;
  expanded = [];
  orginfo = new Map();
  fetchedusers = false;
  fetchedinvites = false;

  //default policies does not include Owner. Have to decide whether to include/ when to include
  defaultPolicies = [
    { policyid: "~01f0572050ec4f11e98f3295659e03fb04", name: "Admin" },
    { policyid: "~01c51b3fbaec4d11e98f32f00895e013fb", name: "Read" },
    { policyid: "~0100282a02ec5111e98f329393ccd6e13a", name: "Read Write" },
  ];
  //policies is a the list of all policies i.e, currently defaultPolicies + Owner
  policies = this.defaultPolicies.concat({
    policyid: "~0108364172f5c611e996ecf208b940ce30",
    name: "Owner",
  });
  lastWidgetOptions = null;
  lastSelected = null;

  toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  toggleRowSelect = (guuid) => (event) => {
    // const guuid = this.state.users[idx]["userid"];
    const checked = !this.state.selected[guuid];

    // shallow copy to update users
    const users = this.state.users.slice();
    const match = users.findIndex((user) => guuid === user.userid);
    // should always have match, but check to be sure
    if (match !== -1) {
      // copy user object as well, don't want to update
      // existing state in place
      users[match] = { ...users[match], selected: checked };
    }
    this.setState(
      {
        selected: { ...this.state.selected, [guuid]: checked },
        users,
      },
      () => {
        const selected = Object.entries(this.state.selected)
          .filter((s) => s[1])
          .map((s) => s[0]);
        this.props.updateWidgetOptions({ selected });
        console.log("local selection", selected);
        this.setState({ selectedList: selected });
        //this.selected = selected;
      }
    );
    // this.props.updateWidgetOptions({})
  };

  createTableColumns = () => {
    const classes = this.props.classes;

    // const attrs = this.props.widget.options.attributes;
    const attrs = DEFAULT_COLUMNS;
    return [
      {
        name: "selected",
        id: "selected",
        label: "\u2713",
        options: {
          sort: false,
          filterType: "custom",
          filterList: this.filters[0],
          customFilterListOptions: {
            render: (v) => {
              return v.length ? "selected only" : false;
            },
          },
          filterOptions: {
            names: [],
            logic: (selected, filters) => {
              return filters.length ? !selected : false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!filterList[index].length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          filterList[index].push("selected");
                        } else {
                          filterList[index].length = 0;
                        }
                        onChange(filterList[index], index, column);
                      }}
                    />
                  }
                  classes={{
                    root: classes.filterSelected,
                    label: classes.filterSelectedLabel,
                  }}
                  label="Selected Only"
                  labelPlacement="start"
                />
              );
            },
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Checkbox
                checked={value}
                // checked={!!this.state.selected[value]}
                onChange={this.toggleRowSelect(_last(tableMeta.rowData))}
              />
            );
          },
        },
      },
    ]
      .concat(
        (attrs || []).map((attr, idx) => {
          return {
            name: attr,
            label: attr.replace("_", " "),
            id: attr,
            options: {
              sortDirection:
                attr === this.sortColumn ? this.sortDirection : "none",
              filterList: this.filters[idx + 1],
              customBodyRender: (value, tableMeta, updateValue) => {
                if (attr === "last_login") {
                  return value ? moment(value).format(DATE_FORMAT) : "---";
                } else if (attr === "status") {
                  if (value === "Pending") {
                    return (
                      <div
                        style={{
                          color: "grey",
                        }}
                      >
                        Pending
                      </div>
                    );
                  } else if (value === "Verified User") {
                    return (
                      <div
                        style={{
                          color: "blue",
                        }}
                      >
                        Verified User
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          color: "grey",
                        }}
                      >
                        Unknown Status
                      </div>
                    );
                  }
                }
                return value;
              },
            },
          };
        })
      )
      .concat([
        {
          name: "userid",
          id: "userid",
          options: {
            display: "excluded",
            filter: false,
          },
        },
      ]);
  };

  createSortHandler = (property) => (event) => {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getSorting = () => {
    return this.state.order === "desc"
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy);
  };

  stableSort = (array, cmp) => {
    const stabilized = array.map((el, index) => [el, index]);
    stabilized.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
  };

  handleDoubleClick = (event, guuid) => {
    this.openDetailsPopup(guuid);
  };

  openDetailsPopup = (guuid) => {
    this.setState({ selected: guuid, detailsPopupOpen: true });
  };

  removeDetailsPopup = () => {
    this.setState({ detailsPopupOpen: false });
  };

  createOrg = () => {
    console.log("neworg is", this.state.newOrg);
    let newOrg = this.state.newOrg;
    newOrg.parent = this.state.orgId;
    console.log("neworg to be created", newOrg);
    this.props.createOrg(newOrg).then((rv) => {
      console.log("response for creating new org", rv);
      if (rv.status === 0) {
        let orgs = [...this.state.orgs];
        orgs.push({ orgid: rv.orgid });
        this.setState({ orgs });
        this.updateOrgInfo([rv.orgid]);
      }
      this.setState({ openNewOrgDialog: false, newOrg: { name: "" } });
    });
  };

  removeUsers = () => {
    console.log("selected users to remove are", this.state.selectedList);
    if (this.state.selectedList.length) {
      console.log("removing users", this.state.selectedList);
      let users = this.state.selectedList.filter(
        (u) => u !== this.props.userid
      );
      console.log("orgid and users are", this.state.orgId, users);
      this.props.removeUsers(this.state.orgId, users).then((rv) => {
        console.log("response for removing users are", rv);
        //this.selected = [];
        this.setState({
          openRemoveUsersConfirmDialog: false,
          selected: {},
          selectedList: [],
        });
        this.updateOrgInfo([this.state.orgId]);
        //this.generateUserPromises();
      });
    }
  };
  haveValidInvites = () => this.state.invites.some((a) => !!a.invitee);
  saveAttrsTimeout = null;
  saveAttrs = () => {
    const orgId = this.state.orgId;
    const invites = this.state.invites;
    if (Object.keys(invites).length > 0) {
      this.setState({ requestPending: true }, () =>
        this.props.setProps(orgId, invites).then(
          // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
          (rv) => {
            clearTimeout(this.saveAttrsTimeout);
            if (rv.status === 0) {
              // ok
              //this.generateUserPromises();
              //this.updateInvites();
              this.setState({ invites: [this.newInvite()] });
              this.setState(
                { msg: { content: rv.info + " successfully" } },
                () => {
                  this.saveAttrsTimeout = setTimeout(() => {
                    this.setState({
                      msg: { content: null },
                      open: false,
                      invites: [],
                    });
                    this.updateInvites(orgId);
                  }, 1000);
                }
              );
            } else {
              // error
              this.setState({
                msg: {
                  content: `ERROR: ${
                    rv.error || "Something went wrong with your request"
                  }; please try again or contact support`,
                  severity: "error",
                },
              });
            }
            this.setState({ requestPending: false });
          }
        )
      );
    }
  };

  newInvite = () => {
    return {
      //inviteid: uuidv4(),
      invitee: "",
      policies: ["~0100282a02ec5111e98f329393ccd6e13a"],
    };
  };
  addInvite = () => {
    let invites = [...this.state.invites];
    //const inviteid = uuidv4();
    invites.push(this.newInvite());
    this.setState({ invites });
    console.log("invites are", invites);
  };

  deleteInvite = (i) => {
    let invites = [...this.state.invites];
    invites.splice(i, 1);
    if (invites.length === 0) {
      invites = [this.newInvite()];
    }
    this.setState({ invites });
  };

  initInvites = () => {
    this.setState({ invites: [this.newInvite()] });
  };

  setOrgId = (event) => {
    let orgId = [...this.state.orgId];
    orgId = event.target.value;
    console.log("orgid selected", event.target.value, orgId, this.state.orgId);
    //this.selected = []; //clear current orgs selection
    this.setState({ orgId, selected: {}, selectedList: [] }, () => {
      this.populateusers();
      console.log(
        "in callback of set state",
        orgId,
        this.state.orgId,
        this.state.selected,
        this.state.selectedList
      );
    });
  };
  updateOrgInfo = (orgids) => {
    this.props.getOrgs(orgids).then((rv) => {
      if (rv.status === 0) {
        // ok
        console.log("getOrgs response is", rv);
        rv.items.forEach((item) => {
          let pending =
            item.guuid in this.orginfo &&
            this.orginfo.get(item.guuid).hasOwnProperty("pending")
              ? this.orginfo.get(item.guuid).pending
              : [];
          this.orginfo.set(item.guuid, item);
          if (pending.length > 0) {
            this.orginfo.get(item.guuid).pending = pending;
          }
        });
        console.log("orginfo after update", this.orginfo);
        this.populateusers();
      } else {
        console.log("couldn't fetch org info for ", orgids);
      }
    });
  };
  updateInvites = (orgid) => {
    this.props.getInvites(orgid, "sent").then((rv) => {
      if (rv.status === 0) {
        // ok
        console.log("getinvites response is", rv, orgid, this.state.orgId);
        this.orginfo.get(orgid)["pending"] = rv.items || [];
        if (this.state.orgId === orgid) {
          this.populateusers();
        }
      } else {
        console.log("couldn't fetch invites for ", orgid);
      }
    });
  };

  generateUserPromises() {
    let promises = [];
    promises.push(this.props.getOrgs(this.props.orgs.map((org) => org.orgid)));
    this.props.orgs.forEach((o) => {
      promises.push(this.props.getInvites(o.orgid, "sent"));
    });
    this.resolveGetUsers(promises);
  }

  componentDidMount() {
    //this.generateUserPromises();
    this.getOrgInfo();
  }

  getOrgInfo = () => {
    this.props
      .getOrgs((this.props.orgs || []).map((org) => org.orgid))
      .then((rv) => {
        if (rv.status === 0) {
          console.log("result for getOrgInfo", rv);
          rv.items.forEach((item) => {
            this.orginfo.set(item.guuid, item);
            this.updateInvites(item.guuid);
          });
          let orgs = [...this.orginfo.keys()].map((k) => {
            return { orgid: k };
          });
          this.setState({ orgs: orgs });
          this.populateusers();
        } else {
          console.log("failed to get org info", rv);
        }
      });
  };

  resolveGetUsers = (promises) => {
    Promise.all(promises).then((results) => {
      results.forEach((r) => {
        const items = r.items || [];
        console.log("response for getinvites", items);
        if (items.length > 0 && items[0].hasOwnProperty("inviter")) {
          this.orginfo.get(items[0].orgid)["pending"] = items;
        } else {
          items.forEach((item) => {
            this.orginfo.set(item.guuid, item);
          });
        }
      });
      console.log("orginfo is", this.orginfo);
      //update this.state.orgs to include the child orgs
      let orgs = [...this.orginfo.keys()].map((k) => {
        return { orgid: k };
      });
      this.setState({ orgs: orgs });
      this.populateusers();
    });
  };

  populateusers = () => {
    const policyidlist = this.policies.map((policy) => policy.policyid);
    let users = [];
    let isAdmin = false;
    let isOrgOwner = false;
    let orgid = this.state.orgId;
    console.log("orgid in popusers", orgid, this.orginfo.has(orgid));
    if (this.orginfo.has(orgid)) {
      if (this.orginfo.get(orgid).g_owner === this.props.userid) {
        isAdmin = true;
        isOrgOwner = true;
        console.log("logged in user is org owner", isOrgOwner);
      }
      this.orginfo.get(orgid).users.forEach((user) => {
        let pm = user.policies.map(
          (policy) => this.policies[policyidlist.indexOf(policy)].name
        );
        if (
          !isAdmin &&
          user.userid === this.props.userid &&
          pm.includes("Admin")
        ) {
          isAdmin = true;
          console.log("isAdmin should be true now", isAdmin);
        }
        if (
          !isOrgOwner &&
          user.userid === this.props.userid &&
          pm.includes("Owner")
        ) {
          isOrgOwner = true;
          console.log("isOrgOwner should be true now", isOrgOwner);
        }
        users.push({
          selected: !!this.state.selected[user.userid],
          email: user.email,
          userid: user.userid,
          orgid: orgid,
          org: this.orginfo.get(orgid).name,
          permissions: pm,
          // last_login: user.last_login || moment(),
          last_login: user.last_login,
          status: displayStatus(user.status) || "Verified User",
        });
      });
      if (this.orginfo.get(orgid).hasOwnProperty("pending")) {
        this.orginfo.get(orgid).pending.forEach((item) => {
          users.push({
            email: item.invitee,
            selected: !!this.state.selected[item.userid],
            orgid: item.orgid,
            org: this.orginfo.get(orgid).name,
            permissions: item.policies.map(
              (policy) => this.policies[policyidlist.indexOf(policy)].name
            ),
            // last_login: moment(parseInt(item.tm_sent)) || moment(),
            last_login: parseInt(item.tm_sent),
            status: displayStatus(item.status) || "Unknown Status",
          });
        });
      }
      console.log("users are", users);
      this.setState({ users: users, isAdmin: isAdmin, isOrgOwner: isOrgOwner });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true, invites: [this.newInvite()] });
  };

  handleClose = () => {
    this.setState({
      open: false,
      openRemoveUsersConfirmDialog: false,
      openNewOrgDialog: false,
      invites: [],
    });
  };

  handleRemoveUsersOpen = () => {
    this.setState({ openRemoveUsersConfirmDialog: true });
  };

  handleNewOrgOpen = () => {
    this.setState({ openNewOrgDialog: true, newOrg: { name: "" } });
    console.log("openNewOrgDialog is", this.state.openNewOrgDialog);
  };

  render() {
    const stacks = Object.keys(this.props.stacks || {});
    const { classes, ...rest } = this.props;

    const widget = this.props.widget || {};
    const widgetOptions = widget.options || {};
    if (this.lastWidgetOptions !== widgetOptions) {
      this.lastWidgetOptions = widgetOptions;

      const filters = widgetOptions.filters || {};
      this.filters = [
        filters.selected ? ["selected"] : [],
        filters.email || [],
        filters.org || [],
        filters.permissions || [],
        // filters.last_login || [],
        filters.status || [],
      ];
      const expanded = widgetOptions.expanded || [];
      this.expanded = expanded
        .map((guuid) => stacks.indexOf(guuid))
        .filter((i) => i !== -1);

      const sort = widgetOptions.sort;
      if (sort) {
        const [col, dir] = sort;
        this.sortColumn = col;
        this.sortDirection = dir;
      }

      if (this.searchText === null) {
        this.searchText = widgetOptions.search || "";
      }
    }

    this.columns = this.createTableColumns();
    const columns = this.columns;

    const options = {
      elevation: 0,
      filter: true,
      sort: true,
      filterType: "dropdown",
      selectableRows: "none",
      expandableRows: false,
      rowsExpanded: this.expanded,
      pagination: false,
      print: false,
      download: false,
      viewColumns: false,
      rowHover: false,
      searchText: this.searchText,
      onSearchChange: (searchText) => {
        this.searchText = searchText;
        clearTimeout(this.searchTextTimeout);
        this.searchTextTimeout = setTimeout(() => {
          this.props.updateWidgetOptions({ search: searchText || null });
        }, 1000);
      },
      onSearchClose: () => {
        this.searchText = "";
        this.props.updateWidgetOptions({ search: null });
      },
      onColumnSortChange: (col, dir) => {
        console.log("sort column changing", col, dir);
        this.sortColumn = col;
        this.sortDirection = dir === "ascending" ? "asc" : "desc";
        this.props.updateWidgetOptions({
          sort: [this.sortColumn, this.sortDirection],
        });
      },

      onFilterChange: (col, filterList) => {
        this.filters = filterList;
        const filters = {
          selected: !!this.filters[0].length,
          email: this.filters[1] || [],
          org: this.filters[2] || [],
          permissions: this.filters[3] || [],
          // last_login: this.filters[4] || [],
          status: this.filters[4] || [],
        };
        this.props.updateWidgetOptions({ filters });
      },
      onTableInit: (action, tableState) => {
        console.log(">>>> INIT", action, tableState);
      },
      onTableChange: (action, tableState) => {
        if (action === "expandRow") {
          const expanded = Object.entries(tableState.expandedRows.lookup)
            .filter((e) => e[1])
            .map((e) => e[0]);
          const expandedStacks = expanded.map((i) => stacks[i]).filter(Boolean);
          this.props.updateWidgetOptions({ expanded: expandedStacks });
        }
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    };

    return (
      <Widget
        {...rest}
        configurable={false}
        dataType="stacks"
        widgetTitle="Stacks Permission"
      >
        <Card style={{ height: "100%", overflow: "auto" }}>
          <CardContent>
            <div style={{ marginTop: 0, height: "100%" }}>
              {/* <Tooltip title="Filter stacks" className={classes.toolbar}>
                <IconButton aria-label="Filter stacks">
                  <FilterListIcon />
                </IconButton>
              </Tooltip> */}

              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle2">
                    Select Organization:&nbsp;
                  </Typography>
                </Grid>
                <Grid>
                  <Select
                    value={this.state.orgId ? this.state.orgId : ""}
                    inputProps={{ name: "orgid", id: "orgid" }}
                    onChange={this.setOrgId}
                    style={{ fontSize: "inherit" }}
                  >
                    {this.state.orgs.map((attr) => {
                      //console.log("org info is",Object.keys(this.orginfo),attr.orgid,attr.orgid in Object.keys(this.orginfo));
                      return (
                        <MenuItem value={attr.orgid} key={attr.orgid}>
                          {this.orginfo.get(attr.orgid)
                            ? this.orginfo.get(attr.orgid).name
                            : attr.orgid}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <MuiThemeProvider theme={(theme) => this.tableMuiTheme(theme)}>
                <MUIDataTable
                  data={this.state.users}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
              {this.state.isAdmin && (
                <React.Fragment>
                  <Button
                    disabled={!this.state.isAdmin}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClickOpen}
                  >
                    Invite Users
                  </Button>
                  <Button
                    disabled={this.state.selectedList.length === 0}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleRemoveUsersOpen}
                  >
                    Remove Users
                  </Button>
                  {this.state.isOrgOwner && (
                    <Button
                      disabled={!this.state.isOrgOwner}
                      variant="outlined"
                      color="primary"
                      onClick={this.handleNewOrgOpen}
                    >
                      Create Sub Organization
                    </Button>
                  )}
                </React.Fragment>
              )}
              <Dialog
                open={this.state.openRemoveUsersConfirmDialog}
                onClose={this.handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
              >
                <DialogTitle id="confirm-dialog-title">
                  {"Are you sure that you want to remove the selected Users?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="confirm-dialog-description">
                    Press the confirm button to finalize the removal of the
                    selected users.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.removeUsers} color="primary" autoFocus>
                    Confirm
                  </Button>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Invite Users</DialogTitle>
                <DialogContent>
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{ height: "100%", padding: 12 }}
                  >
                    <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
                      <Table>
                        <TableBody>
                          {this.state.invites.map((a, i) => {
                            return (
                              <TableRow key={"invite-row-" + i}>
                                <AttributeTableCell>
                                  <TextField
                                    id={"select-email-" + a.inviteid}
                                    label="Email"
                                    margin="normal"
                                    style={{ margin: 3, width: "100%" }}
                                    value={a.invitee}
                                    onChange={(event) => {
                                      let invites = [...this.state.invites];
                                      invites[i].invitee = event.target.value;
                                      this.setState({ invites });
                                    }}
                                  />
                                </AttributeTableCell>
                                <AttributeTableCell>
                                  <Select
                                    label="Policy"
                                    value={a.policies[0]}
                                    onChange={(event) => {
                                      let invites = [...this.state.invites];
                                      invites[i].policies[0] =
                                        event.target.value;
                                      this.setState({ invites });
                                    }}
                                    // value={this.props.attribute}
                                    // inputProps={{ name: "attribute", id: "attribute" }}
                                    // onChange={this.props.setAttribute}
                                    style={{
                                      fontSize: "inherit",
                                      margin: 3,
                                    }}
                                  >
                                    {this.defaultPolicies.map((attr) => {
                                      return (
                                        <MenuItem
                                          value={attr.policyid}
                                          key={attr.policyid}
                                        >
                                          {attr.name}
                                        </MenuItem>
                                      );
                                    })}
                                    ;
                                  </Select>
                                </AttributeTableCell>
                                <AttributeTableCell>
                                  <IconButton>
                                    <CancelIcon
                                      onClick={() => this.deleteInvite(i)}
                                    />
                                  </IconButton>
                                  {i === this.state.invites.length - 1 && (
                                    <IconButton
                                      aria-owns={
                                        this.state.typeAnchorEl
                                          ? "attr-type-menu"
                                          : null
                                      }
                                      onClick={this.addInvite}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  )}
                                </AttributeTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" wrap="nowrap">
                        <Grid item xs={9}>
                          <Typography
                            variant="body2"
                            color={this.state.msg.severity}
                          >
                            {this.state.msg.content}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.saveAttrs} color="primary">
                    Invite
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={this.state.openNewOrgDialog}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Create Sub Organization
                </DialogTitle>
                <DialogContent>
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{ height: "100%", padding: 12 }}
                  >
                    <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
                      <TextField
                        id={"neworg-name"}
                        label="Name"
                        margin="normal"
                        style={{ margin: 0, width: "100%" }}
                        value={this.state.newOrg.name}
                        onChange={(event) => {
                          let newOrg = this.state.newOrg;
                          newOrg.name = event.target.value;
                          this.setState({ newOrg });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" wrap="nowrap">
                        <Grid item xs={9}>
                          <Typography
                            variant="body2"
                            color={this.state.msg.severity}
                          >
                            {this.state.msg.content}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.newOrg.name === ""}
                    onClick={this.createOrg}
                    color="primary"
                  >
                    Create Org
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </CardContent>
        </Card>
      </Widget>
    );
  }
}

export default withStyles(styles)(UserManagementWidget);
