import { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { connectWidget } from "../../widget-connector/WidgetConnector";
import moment from "moment";
import RangeQueryService from "services/RangeQueryService";
import QueueService from "services/QueueService";
import { TimeframeService } from "services/TimeframeService";
import { TimeSeriesService } from "services/TimeSeriesService";
import ISXUtils from "services/Utils";
import TagsSubscriptionsContext from "services/TagsSubscriptionsService";
import TagEventIntervalsContext from "services/TagEventIntervalsService";

import { default as _last } from "lodash/last";
import { default as _isEqual } from "lodash/isEqual";
import { default as uniqBy } from "lodash/uniqBy";
import { default as _max } from "lodash/max";
import { default as _min } from "lodash/min";

const MA_DERIVED_TAG = "|ma-";
const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";
const DEFAULT_TIMEFRAME_INTERVAL = 10; // "auto" no longer supported, use this instead
// const RETRY_INTERVAL = 10000; // initial request retry in ms

const qservice = new QueueService(250);

const BaseSinglePointWidget = (props) => {
  const rawStreams = useSelector((state) => state.isx.streams);
  const tagEventIntervals = useSelector((state) => state.isx.tagEventIntervals);
  const stacks2orgs = useSelector((state) => state.isx.stacks2orgs);

  const subscriptionsContext = useContext(TagsSubscriptionsContext);
  const eventIntervalsContext = useContext(TagEventIntervalsContext);

  const [value, setValue] = useState(undefined);
  const [color, setColor] = useState();
  const [displayOptions, setDisplayOptions] = useState({});
  const [status, setStatus] = useState(1);

  const pendingData = useRef();

  const lastTagQuery = useRef();
  const lastTag = useRef({});
  // tag config from widget
  const lastTagConfig = useRef({});
  const lastUpdateTime = useRef();
  const lastWidget = useRef();
  const lastTagEventInterval = useRef();
  const windowInSeconds = useRef();
  const subscription = useRef();
  const lastScheduleRunTime = useRef(0);

  // keep around requested sample size
  const entries = useRef([]);

  // these are used multiple places, avoid recompute
  const intervalType = useRef(null);
  const intervalInSeconds = useRef(DEFAULT_TIMEFRAME_INTERVAL);
  const metricType = useRef(null);
  const metricSamples = useRef(null);
  const derived = useRef(false);
  const attr = useRef(null);
  const indicators = useRef([]);
  const linearFnProps = useRef(null);

  const { stacks, widget = {} } = props;

  const rangeQueryLatestOverPeriod = async (stackid, tag, period, orgids) => {
    const now = moment.utc().valueOf();
    pendingData.current = true;
    lastTagQuery.current = now;
    return RangeQueryService.queryLatestOverPeriod(
      stackid,
      [tag],
      period,
      orgids
    )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        pendingData.current = false;
      });
  };

  const _getSubscriptionStartTime = (timeseries, window, now) => {
    if (timeseries?.length > 0) {
      const lastTimestamp = _last(timeseries).x + 1;
      const startTime = moment.utc(lastTimestamp);
      if (now.diff(startTime, "seconds") < window) {
        return lastTimestamp;
      }
    }
    return now
      .clone()
      .subtract(window, "seconds")
      .add(intervalInSeconds.current, "milliseconds")
      .valueOf();
  };

  const _cullEntries = () => {
    if (entries.current.length > 0) {
      entries.current.sort((a, b) => a.x - b.x);
      entries.current = uniqBy(entries.current, (e) => e.x);
      if (entries.current.length > metricSamples.current) {
        entries.current = entries.current.slice(
          entries.current.length - metricSamples.current
        );
      }
    }
  };

  const _applyMovingAverage = () => {
    entries.current = TimeSeriesService.ema(
      entries.current,
      derived.current || 10
    );
  };

  const _computeValue = () => {
    const _computeColor = () => {
      let color;
      indicators.current.forEach((op) => {
        color = op(value).color || color;
      });
      return color;
    };
    const _computeDisplayOptions = () => {
      let displayOptions = {};
      indicators.current.forEach((op) => {
        displayOptions = op(value) || displayOptions;
      });
      return displayOptions;
    };

    let value = null;
    // const linearFnProps = getLinearFunctionParams();
    switch (metricType.current) {
      case "snapshot":
        const lastEntry = _last(entries.current);
        value = lastEntry
          ? linearFnProps.current
            ? ISXUtils.linearFunction(
                lastEntry.y,
                linearFnProps.current.slope || 1,
                linearFnProps.current.intercept || 0
              )
            : lastEntry.y
          : null;
        break;
      case "max":
        value = _max(
          entries.current?.map((e) =>
            linearFnProps.current
              ? ISXUtils.linearFunction(
                  e.y,
                  linearFnProps.current.slope || 1,
                  linearFnProps.current.intercept || 0
                )
              : e.y
          )
        );
        break;
      case "min":
        value = _min(
          entries.current?.map((e) =>
            linearFnProps.current
              ? ISXUtils.linearFunction(
                  e.y,
                  linearFnProps.current.slope || 1,
                  linearFnProps.current.intercept || 0
                )
              : e.y
          )
        );
        break;
      default:
        break;
    }
    lastUpdateTime.current = (_last(entries.current) ?? []).x;
    setValue(value);
    setColor(_computeColor);
    setDisplayOptions(_computeDisplayOptions);
  };

  const tag = useMemo(
    () => (widget.tags && widget.tags.length > 0 && widget.tags[0]) ?? {},
    [widget.tags]
  );

  const stackid = tag.stack;
  // TODO: in the future will contain stack metadata at most only; should
  // register interest in stack
  const stack = stacks[stackid];
  const orgids = stack && stacks2orgs[stack.guuid];

  useEffect(() => {
    // called on destruction to clear subscription
    return () => {
      if (subscription.current) {
        subscriptionsContext.unsubscribe(subscription.current);
        subscription.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stack) {
      if ("cs" in stack && stack.cs !== status) {
        setStatus(ISXUtils.getConnectionStatus(stack));
      }
    }
  }, [stack, status]);

  useEffect(() => {
    // check for derived, and if so get root attr
    if (tag.attribute) {
      const derivedIndex = tag.attribute.indexOf(MA_DERIVED_TAG);
      derived.current = derivedIndex !== -1;
      if (derived.current) {
        derived.current =
          parseInt(
            tag.attribute.slice(derivedIndex + 4, tag.attribute.indexOf("sm"))
          ) ?? 10; //store the window size in derived.current
        attr.current = tag.attribute.slice(0, derivedIndex);
      } else {
        attr.current = tag.attribute;
      }
    }
  }, [tag.attribute]);

  useEffect(() => {
    if (
      tag &&
      stack &&
      (lastTag.current !== tag ||
        !_isEqual(lastTagConfig.current, stack.data_config[attr.current] ?? {}))
    ) {
      lastTagConfig.current = stack.data_config?.[attr.current] ?? {};
      let tagIndicators = [];
      if (tag.indicator_source === "tag") {
        if (stack && attr.current) {
          tagIndicators = ISXUtils.getTagIndicators(lastTagConfig.current);
        }
      } else {
        tagIndicators = tag?.indicators ?? [];
      }
      indicators.current = ISXUtils.buildIndicators(tagIndicators);
    }
  }, [stack, tag]);

  useEffect(() => {
    const options = widget.options ?? {};

    const _getLinearFunctionParams = () => {
      const retval = {};
      if (options.linearfn?.type === "custom") {
        if (
          options["linearfn"]["tagprop"] &&
          tag.attribute in stack["data_config"]
        ) {
          retval["slope"] =
            parseFloat(
              stack["data_config"][attr.current].numeric?.linearfn?.slope
            ) || 1.0;
          retval["intercept"] =
            parseFloat(
              stack["data_config"][attr.current].numeric?.linearfn?.intercept
            ) || 0.0;
        }
        if (!options.linearfn?.tagprop) {
          retval["slope"] = parseFloat(options.linearfn?.slope) || 1.0;
          retval["intercept"] = parseFloat(options.linearfn?.intercept) || 0.0;
        }
      }
      return retval;
    };

    if (!stack || !tag || !orgids) {
      return;
    }

    // determine if refresh required;
    // - tag changed
    // - interval changed
    // - metric changed
    // - linear fn changed
    // - awaiting event interval for tag
    const tagChanged = tag !== lastTag.current;
    if (tagChanged) {
      lastTag.current = tag;
    }

    let intervalChanged = false,
      metricChanged = false,
      linearfnChanged = false;
    if (lastWidget.current !== widget) {
      const lastOptions = lastWidget.current?.options ?? {};
      lastWidget.current = widget;
      if (
        options["interval.type"] !== lastOptions["interval.type"] ||
        options["interval.units"] !== lastOptions["interval.units"] ||
        options["interval.value"] !== lastOptions["interval.value"]
      ) {
        intervalType.current = options["interval.type"];
        const intervalValue = options["interval.value"] ?? 10;
        const intervalUnits = options["interval.units"] ?? "seconds";
        intervalInSeconds.current =
          intervalType.current !== "auto"
            ? TimeframeService.getDurationInSeconds(
                intervalValue,
                intervalUnits
              )
            : DEFAULT_TIMEFRAME_INTERVAL;
        intervalChanged = true;
      } else {
        intervalChanged = false;
      }

      if (
        options.metric?.type !== lastOptions.metric?.type ||
        options.metric?.samples !== lastOptions.metric?.samples ||
        !metricSamples.current
      ) {
        metricType.current =
          options.metric?.type ?? options["interval.policy"] ?? "snapshot";
        metricSamples.current = options.metric?.samples ?? 30;
        metricChanged = true;
      } else {
        metricChanged = false;
      }

      if (
        options.linearfn?.type !== lastOptions.linearfn?.type ||
        options.linearfn?.tagprop !== lastOptions.linearfn?.tagprop ||
        options.linearfn?.slope !== lastOptions.linearfn?.slope ||
        options.linearfn?.intercept !== lastOptions.linearfn?.intercept
      ) {
        linearFnProps.current = _getLinearFunctionParams();
        linearfnChanged = true;
      } else {
        linearfnChanged = false;
      }
    }

    const tagKey = ISXUtils.createTagKeyFromStackAndAttr(
      lastTag.current.stack,
      attr.current
    );
    const eventIntervalChanged =
      tagEventIntervals[tagKey] !== lastTagEventInterval.current;
    if (eventIntervalChanged) {
      lastTagEventInterval.current = tagEventIntervals[tagKey];
    }
    if (!lastTagEventInterval.current) {
      eventIntervalsContext.registerInterest([tagKey]);
      return;
    }

    if (
      tagChanged ||
      intervalChanged ||
      metricChanged ||
      linearfnChanged ||
      eventIntervalChanged
    ) {
      // need to reset
      (async () => {
        lastScheduleRunTime.current = 0;
        if (subscription.current) {
          subscriptionsContext.unsubscribe(subscription.current);
        }
        const rangeNeeded =
          metricType.current !== "snapshot" || derived.current;
        const maxWindowInSeconds = Math.round(
          TimeframeService.MAX_EVENTS_WINDOW * lastTagEventInterval.current
        );

        // guard rail to avoid overly spamming server if something should go
        // wrong
        await qservice.wait();

        const seedSampleSize = rangeNeeded
          ? derived.current
            ? Math.max(
                TimeSeriesService.EMA_MIN_SAMPLE_SIZE,
                metricSamples.current
              )
            : metricSamples.current
          : intervalInSeconds.current * 2;
        windowInSeconds.current = Math.min(
          seedSampleSize * lastTagEventInterval.current,
          maxWindowInSeconds
        );
        const period = Math.round(windowInSeconds.current * 1000);
        const results = await rangeQueryLatestOverPeriod(
          stackid,
          attr.current,
          period,
          orgids
        );

        const newEntries =
          results?.items?.ds?.[attr.current]?.map(([x, y]) => ({ x, y })) ?? [];

        const now = moment.utc();
        const subId = subscriptionsContext.subscribe(
          tagKey,
          _getSubscriptionStartTime(newEntries, windowInSeconds.current, now),
          intervalInSeconds.current,
          {
            maxWindowInSeconds: windowInSeconds.current,
            eventInterval: lastTagEventInterval.current,
          }
        );
        subscription.current = subId;

        entries.current = newEntries;
        if (rangeNeeded) {
          _cullEntries();
        }
        /*if (derived.current) {
          _applyMovingAverage();
        }*/
        _computeValue();
      })();
    }
  }, [
    eventIntervalsContext,
    stack,
    stackid,
    subscriptionsContext,
    tag,
    tagEventIntervals,
    widget,
    orgids,
  ]);

  useEffect(() => {
    const subId = subscription.current;
    if (!subId) {
      return;
    }

    if (!lastTag.current?.stack) {
      return;
    }
    const tagKey = ISXUtils.createTagKeyFromStackAndAttr(
      lastTag.current.stack,
      attr.current
    );
    if (!(tagKey in rawStreams)) {
      return;
    }

    const runtime = subscriptionsContext.getScheduleRuntime(
      intervalInSeconds.current
    );

    if (!runtime || runtime <= lastScheduleRunTime.current) {
      return;
    }
    lastScheduleRunTime.current = runtime;

    let newEntries = rawStreams[tagKey];
    const now = moment.utc();
    const earliestStartTime = _getSubscriptionStartTime(
      newEntries,
      windowInSeconds.current,
      now
    );

    // console.log("updating subscription", subId, tagKey, earliestStartTime);
    subscriptionsContext.updateSubscription(subId, earliestStartTime);

    const rangeNeeded = metricType.current !== "snapshot" || derived.current;
    if (rangeNeeded) {
      // need to first cull new entries by timestamp
      const lastSample = _last(entries.current);
      if (lastSample) {
        let i = newEntries.length - 1;
        for (; i >= 0; i--) {
          if (newEntries[i].x <= lastSample.x) {
            break;
          }
        }
        newEntries = newEntries.slice(i + 1);
      }
      if (newEntries.length > 0 && derived.current) {
        newEntries = TimeSeriesService.ema(newEntries, derived.current || 10, {
          lastSample,
        });
      }
      entries.current =
        entries.current.length > 0 &&
        newEntries.length > 0 &&
        entries.current[0].x >= newEntries[0].x
          ? newEntries
          : entries.current.concat(newEntries);
      _cullEntries();
    } else if (newEntries.length > 0) {
      // only update value if there are new entries
      entries.current = newEntries;
    }
    if (derived.current) {
      _applyMovingAverage();
    }
    _computeValue();
  }, [rawStreams, subscriptionsContext]);

  return props.children({
    ...props,
    tag,
    color,
    displayOptions,
    value,
    status,
    timestamp: lastUpdateTime.current
      ? moment.utc(parseInt(lastUpdateTime.current)).local().format(DATE_FORMAT)
      : "",
  });
};

export default connectWidget(BaseSinglePointWidget);
