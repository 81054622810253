export const REFRESH_RESPONSE = "REFRESH_RESPONSE";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGOUT_RESPONSE = "LOGOUT_RESPONSE";
export const GET_ALL_ITEMS_RESPONSE = "GET_ALL_ITEMS_RESPONSE";
export const GET_ITEM_RESPONSE = "GET_ITEM_RESPONSE";
export const GET_ITEMS_RESPONSE = "GET_ITEMS_RESPONSE";
export const GET_ORGS_RESPONSE = "GET_ORGS_RESPONSE";
export const UPDATE_CALIBRATION_RESPONSE = "UPDATE_CALIBRATION_RESPONSE";
export const SET_STATE_TABLE = "SET_STATE_TABLE";
export const SET_HOURLY_AGGREGATES = "SET_HOURLY_AGGREGATES";
export const SET_DATASET_CONTENT = "SET_DATASET_CONTENT";
export const INITIALIZE_STREAM = "INITIALIZE_STREAM";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const GET_DASHBOARD_RESPONSE = "GET_DASHBOARD_RESPONSE";
export const GET_DASHBOARDS_RESPONSE = "GET_DASHBOARDS_RESPONSE";
export const CREATE_DASHBOARD_RESPONSE = "CREATE_DASHBOARD_RESPONSE";
export const UPDATE_DASHBOARD_RESPONSE = "UPDATE_DASHBOARD_RESPONSE";
export const REMOVE_DASHBOARD_RESPONSE = "REMOVE_DASHBOARD_RESPONSE";
export const UPDATE_DASHBOARDS_RESPONSE = "UPDATE_DASHBOARDS_RESPONSE";
export const UPDATE_WIDGETS_LAYOUT_RESPONSE = "UPDATE_WIDGETS_LAYOUT_RESPONSE";
export const ADD_WIDGET_RESPONSE = "ADD_WIDGET_RESPONSE";
export const UPDATE_WIDGET_RESPONSE = "UPDATE_WIDGET_RESPONSE";
export const REMOVE_WIDGET_RESPONSE = "REMOVE_WIDGET_RESPONSE";
export const GET_USER_MESSAGES_RESPONSE = "GET_USER_MESSAGES_RESPONSE";
export const CREATE_USER_MESSAGE_RESPONSE = "CREATE_USER_MESSAGE_RESPONSE";
export const UPDATE_USER_MESSAGE_RESPONSE = "UPDATE_USER_MESSAGE_RESPONSE";
export const REMOVE_USER_MESSAGE_RESPONSE = "REMOVE_USER_MESSAGE_RESPONSE";
export const UPDATE_ALARM = "UPDATE_ALARM";
export const UPDATE_SUBSCRIPTION_RESPONSE = "UPDATE_SUBSCRIPTION_RESPONSE";
export const UPDATE_STREAMS = "UPDATE_STREAMS";
export const REMOVE_STREAMS = "REMOVE_STREAMS";
export const CLEAR_STREAMS = "CLEAR_STREAMS";
export const UPDATE_TAG_EVENT_INTERVALS = "UPDATE_TAG_EVENT_INTERVALS";
export const REMOVE_TAG_EVENT_INTERVALS = "REMOVE_TAG_EVENT_INTERVALS";
export const CLEAR_TAG_EVENT_INTERVALS = "CLEAR_TAG_EVENT_INTERVALS";
