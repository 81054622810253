import React, { useState, useContext, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import CancelIcon from "@material-ui/icons/Cancel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Widget from "../../widget/Widget";

import { ISXContext } from "services/Utils";

import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

const uuidv4 = require("uuid/v4");

const DATE_FORMAT = "DD MMM YYYY HH:mm:ss";

const AttributeTableCell = withStyles((theme) => ({
  body: {
    verticalAlign: "bottom",
    paddingLeft: 12,
    paddingRight: 12,
    "&:last-child": {
      paddingRight: 12,
    },
  },
}))(TableCell);

const StackMessagingWidget = (props) => {
  const [attributes, setAttributes] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [msg, setMsg] = useState({ content: null });
  const [typeAnchorEl, setTypeAnchorEl] = useState(null);
  const saveAttrsTimeout = useRef(null);

  const isxContext = useContext(ISXContext);

  const handleAttributeTypeMenuOpen = (event) => {
    setTypeAnchorEl(event.currentTarget);
  };

  const handleAttributeTypeMenuClose = () => {
    setTypeAnchorEl(null);
  };

  const haveValidAttributes = () => attributes.some((a) => !!a.key);

  const saveAttrs = () => {
    const canonicalValue = (type, val) => {
      if (type === "Number") {
        return Number(val);
      } else {
        return val;
      }
    };

    const widget = props.widget || {};
    const stackId = (widget.stacks || [])[0];
    if (stackId) {
      const attrs = attributes.reduce((acc, a) => {
        if (a.key) {
          acc[a.key] = canonicalValue(a.type, a.value);
        }
        return acc;
      }, {});
      if (Object.keys(attrs).length > 0) {
        setRequestPending(true);
        props.setProps(stackId, attrs).then(
          // TESTING: Promise.resolve({ status: Math.floor(Math.random() + 0.5) }).then(
          (rv) => {
            clearTimeout(saveAttrsTimeout.current);
            if (rv.status === 0) {
              // ok
              setMsg({ content: "Request successfully submitted" });
              saveAttrsTimeout.current = setTimeout(() => {
                setMsg({ content: null });
                setRequestPending(false);
              }, 3000);
            } else {
              // error
              setMsg({
                content: `ERROR: ${
                  rv.error || "Something went wrong with your request"
                }; please try again or contact support`,
                severity: "error",
              });
              setRequestPending(false);
            }
          }
        );
      }
    }
  };

  const addAttribute = (type, initialval = null) => {
    let attrs = [...attributes];
    const guuid = uuidv4();
    attrs.push({
      guuid,
      type,
      key: "",
      value: initialval,
    });
    setAttributes(attrs);
    handleAttributeTypeMenuClose();
  };

  const deleteAttribute = (i) => {
    let attrs = [...attributes];
    attrs.splice(i, 1);
    setAttributes(attrs);
  };

  const widget = props.widget || {};
  const stackId = (widget.stacks || [])[0];
  const stacks = props.stacks || {};
  const stack = stacks[stackId] || {};
  const sttime = stack.st_time
    ? moment.utc(parseInt(stack.st_time)).format(DATE_FORMAT)
    : "---";

  const stackLabel =
    stack?.name ||
    JSON.parse(stack.dev_session || "{}")["stack.label"] ||
    `<Unnamed ${isxContext.labels.deviceCapitalized}>`;

  return (
    <Widget
      {...props}
      dataType="stacks"
      maxStacks={1}
      widgetTitle={`${isxContext.labels.deviceCapitalized} Messaging`}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: "100%", padding: 12 }}
      >
        <Grid item>
          <Grid container direction="row" wrap="nowrap">
            <Grid item xs={9}>
              <Typography variant="h6" noWrap>
                {stackLabel}
              </Typography>
              <Typography variant="caption" noWrap>
                {"(" + stackId + ")"}
              </Typography>
              <Typography variant="subtitle1" noWrap>
                {"Last update: " + sttime}
              </Typography>
              <Typography variant="body2" color={msg.severity}>
                {msg.content}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Button
                onClick={saveAttrs}
                disabled={
                  props.readOnly || requestPending || !haveValidAttributes()
                }
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ overflowY: "auto", paddingTop: 12 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">Message Type:&nbsp;</Typography>
            </Grid>
            <Grid>
              <Select
                value="setProp"
                inputProps={{ name: "messageType", id: "messageType" }}
                style={{ fontSize: "inherit" }}
              >
                <MenuItem value="setProp" key="setProp">
                  setProp
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          {attributes.length === 0 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Typography>Add a key/value:</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-owns={typeAnchorEl ? "attr-type-menu" : null}
                  onClick={handleAttributeTypeMenuOpen}
                  disabled={props.readOnly || requestPending || !stackId}
                >
                  <AddCircleIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}

          <Table>
            <TableBody>
              {attributes.map((a, i) => {
                let rowValue;
                if (a.type === "Boolean") {
                  rowValue = (
                    <Select
                      value={a.value}
                      onChange={(event) => {
                        let attrs = [...attributes];
                        attrs[i].value = event.target.value;
                        setAttributes(attrs);
                      }}
                      style={{
                        fontSize: "inherit",
                        margin: 3,
                      }}
                    >
                      <MenuItem value={true} key="attr-type-true">
                        True
                      </MenuItem>
                      <MenuItem value={false} key="attr-type-false">
                        False
                      </MenuItem>
                      })}
                    </Select>
                  );
                } else if (a.type === "Null") {
                  rowValue = (
                    <Typography style={{ fontWeight: "bold", margin: 3 }}>
                      Null
                    </Typography>
                  );
                } else {
                  rowValue = (
                    <TextField
                      id={"select-value=" + a.guuid}
                      label={a.type + " Value"}
                      type={a.type}
                      margin="normal"
                      style={{ margin: 3, width: "100%" }}
                      value={a.value}
                      onChange={(event) => {
                        let attrs = [...attributes];
                        attrs[i].value = event.target.value;
                        setAttributes(attrs);
                      }}
                    />
                  );
                }
                return (
                  <TableRow key={a.guuid}>
                    <AttributeTableCell>
                      <TextField
                        id={"select-key-" + a.guuid}
                        label="Key"
                        margin="normal"
                        style={{ margin: 3, width: "100%" }}
                        value={a.key}
                        onChange={(event) => {
                          let attrs = [...attributes];
                          attrs[i].key = event.target.value;
                          setAttributes(attrs);
                        }}
                      />
                    </AttributeTableCell>
                    <AttributeTableCell>{rowValue}</AttributeTableCell>
                    <AttributeTableCell>
                      <IconButton onClick={() => deleteAttribute(i)}>
                        <CancelIcon />
                      </IconButton>
                      {i === attributes.length - 1 && (
                        <IconButton
                          aria-owns={typeAnchorEl ? "attr-type-menu" : null}
                          onClick={handleAttributeTypeMenuOpen}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      )}
                    </AttributeTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Menu
        id="attr-type-menu"
        anchorEl={typeAnchorEl}
        open={Boolean(typeAnchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleAttributeTypeMenuClose}
        transitionDuration={{ enter: 0, exit: 0 }}
      >
        <MenuItem disabled>
          <Typography>Select Type</Typography>
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Text", "")}>
          <ListItemText primary="Text" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Number", "0")}>
          <ListItemText primary="Number" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Boolean", true)}>
          <ListItemText primary="Boolean" />
        </MenuItem>
        <MenuItem onClick={() => addAttribute("Null")}>
          <ListItemText primary="Null" />
        </MenuItem>
      </Menu>
    </Widget>
  );
};

export default StackMessagingWidget;
