import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { scaleLinear } from "d3-scale";
import bin from "d3-array/src/bin";
import { extent /*thresholdFreedmanDiaconis*/ } from "d3-array";
import { min, max, mean, median /*deviation*/ } from "d3-array";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Column from "react-virtualized/dist/commonjs/Table/Column";
import Table from "react-virtualized/dist/commonjs/Table";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import CSVExporter from "../../../services/CSVExporter";
import { ckmeans } from "simple-statistics";
import { ChartService } from "../../../services/ChartService";
import { TimeSeriesService } from "../../../services/TimeSeriesService";
import Widget from "../../widget/Widget";

const roundNumber = (data) => {
  if (data > 1000) {
    return Math.round(data);
  } else if (data > 100) {
    return data.toFixed(1);
  } else if (data > 10) {
    return data.toFixed(2);
  } else if (data > 0) {
    return data.toFixed(3);
  }
  if (typeof data === "number") {
    return data.toFixed(4);
  }
  return data;
};

//Trying dynamic rounding based on scale, should live in a common library if needed
const cellRenderer = (data) => roundNumber(data.cellData);

const _rowStyle = ({ index }) => {
  let style = { borderBottom: "1px solid #e0e0e0" };
  if (index < 0) {
    style.textTransform = "none";
  } else if (index % 2 === 0) {
    // won't see border without this
    style.zIndex = 1;
  } else {
    style.backgroundColor = "#fafafa";
  }
  style.fontSize = "0.8125rem";
  return style;
};

class AnalyticsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      bins: [],
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        bounds: "ticks",
        elements: { line: { tension: 0 } },
        title: { display: false, text: "" },
        scales: {
          xAxes: [
            {
              type: "category",
              display: true,
              scaleLabel: { display: false, labelString: "Value" },
              ticks: { autoSkipPadding: 5 },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: { display: false, labelString: "Frequency" },
            },
          ],
        },
        annotation: {
          events: ["click"],
          annotations: [],
        },
      },
    };

    this.chartRef = React.createRef();
    this.tableRef = React.createRef();
    this.peakTableRef = React.createRef();

    this.timeFormat = "MM/DD HH:mm";
  }

  componentWillUnmount = () => {
    // this is a HACK needed due to bug in react-chartjs-2
    this.state.datasets.forEach((ds) => {
      Object.keys(ds._meta || {}).forEach((id) => {
        let meta = ds._meta[id];
        if (meta.controller == null) {
          delete ds._meta[id];
        }
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.processData(TimeSeriesService.populateDataSets(this.props));

    if (!!this.tableRef.current) {
      this.tableRef.current.forceUpdateGrid();
    }

    if (!!this.peakTableRef.current) {
      this.peakTableRef.current.forceUpdateGrid();
    }

    ChartService.refreshChart(
      this.props.widget,
      this.props.stacks,
      this.state.chartOptions,
      this.chartRef.current,
      prevProps
    );
  };

  generateBins = (datasets) => {
    const { binCount, binStyle, highPassFilter, highPassCutoff } =
      this.props.widget.options.histogram || {};

    const binCountSetting = binCount || 24;
    const binStyleSetting = binStyle || "uniform";
    const highPassFilterSetting = highPassFilter || false;
    const highPassCutoffSetting = highPassCutoff || 0;

    //Global processing to get proper bins
    const fullDataSeries = datasets
      .slice()
      .flatMap((dataset) => {
        return dataset.rawData.map((datum) => {
          datum.label = dataset.label; //Add identifier so they can be reduced for display
          return datum;
        });
      })
      .filter(
        (datum) => !highPassFilterSetting || datum.y > highPassCutoffSetting
      ); //Remove low values to get cleaner data

    //Using d3-array's binning logic for the histogram axes
    //https://observablehq.com/@d3/d3-bin
    const binScaler = scaleLinear()
      .nice()
      .domain(extent(fullDataSeries, (datum) => datum.y));
    const binGenerator = bin();
    binGenerator.value((datum) => datum.y);
    binGenerator.domain(binScaler.domain());

    // Get approx. 'binCount' bins back, spaced uniformly or dynamically
    if (binStyleSetting === "variable") {
      //Simple statistics process suggested on https://observablehq.com/@d3/d3-bin

      //This caused a massive CPU spike and ruined browser performance
      //Potentially revisit it once things are more stable
      if (fullDataSeries.length > 0) {
        binGenerator.thresholds((data) => {
          return ckmeans(data, binCountSetting).map((l) => min(l));
        });
      }
    } else {
      //Set target bin count
      binGenerator.thresholds(binCountSetting);
    }

    const bins = binGenerator(fullDataSeries);
    return bins;
  };

  processIndividualDataset = (dataset, bins, binLabels) => {
    dataset.type = "bar";
    dataset.backgroundColor = dataset.coreColor;
    dataset.borderColor = dataset.coreColor;

    const series = dataset.rawData.map((datum) => datum.y);
    dataset.min = min(series);
    dataset.max = max(series);
    dataset.mean = mean(series);
    dataset.median = median(series);
    dataset.count = series.length;

    //Re-filter the data in place to maintain bins
    dataset.data = bins.map(
      (bin) => bin.filter((datum) => datum.label === dataset.label).length
    );

    dataset.peaks = dataset.data
      .reduce((peaks, bin, index, array) => {
        const up = index === 0 || bin >= array[index - 1];
        const top = index === array.length - 1 || bin >= array[index + 1];

        if (up && top) {
          peaks.push([binLabels[index], bin]);
        }

        return peaks;
      }, [])
      .sort((left, right) => right[1] - left[1])
      /*.filter((peak, index, peaks) => {
        return peak[1] >= 0.7 * peaks[0][1];
      })
            */
      .filter((peak) => peak[1] > 0);
    return dataset;
  };

  updateChartOptions = (binLabels) => {
    const tags = (this.props.widget || {}).tags || [];
    const stacks = this.props.stacks || {};

    const newChartOptions = { ...this.state.chartOptions };

    if (newChartOptions.scales) {
      const units = tags.reduce(
        (units, tag) => {
          const st = stacks[tag.stack] || {};
          const dconfig = st.data_config || {};
          if (st) {
            const a = tag.attribute;
            const aunits = (dconfig[a] || {}).unit || "-";
            units.unique.add(aunits);
            units.all.push(aunits);
          }
          return units;
        },
        { unique: new Set(), all: [] }
      );

      newChartOptions.scales.yAxes[0] = ChartService.initializeYAxes(
        this.props.widget,
        newChartOptions
      );

      newChartOptions.scales.yAxes[0].scaleLabel.labelString =
        ChartService.getYAxesLabels(units.unique, units.all);
    }

    if (newChartOptions.title) {
      newChartOptions.title.text =
        tags
          .map((t) => t.attribute[0].toUpperCase() + t.attribute.substr(1))
          .join(", ") || "untitled";
    }

    newChartOptions.scales.xAxes[0].labels = binLabels;
    return newChartOptions;
  };

  processData = (datasets) => {
    const bins = this.generateBins(datasets);
    const binLabels = bins.map(
      (bin) => roundNumber(bin.x0) + "-" + roundNumber(bin.x1)
    );

    const updatedDatasets = datasets
      .slice()
      .map((dataset) =>
        this.processIndividualDataset(dataset, bins, binLabels)
      );

    const originalStateOfOptions = JSON.stringify(this.state.chartOptions);
    const newChartOptions = this.updateChartOptions(binLabels);

    //Check on updating state
    const updatedOptions =
      originalStateOfOptions !== JSON.stringify(newChartOptions);

    const updatedData =
      updatedOptions ||
      (!this.state.datasets && !!updatedDatasets) ||
      (!!this.state.datasets && !updatedDatasets) ||
      this.state.datasets.length !== updatedDatasets.length ||
      this.state.datasets.some((dataset, index) => {
        const newDataset = updatedDatasets[index];
        return (
          dataset.label !== newDataset.label ||
          dataset.data.length !== newDataset.data.length ||
          dataset.data.some(
            (datum, dataIndex) => datum !== newDataset.data[dataIndex]
          )
        );
      });

    if (updatedOptions || updatedData) {
      this.setState({
        datasets: updatedDatasets,
        bins: bins,
        chartOptions: newChartOptions,
      });
    }
  };

  exportToCSV = () => {
    const headers = ["Range Start", "Range End"].concat(
      this.state.datasets.map((dataset) => dataset.label)
    );

    const rows = this.state.bins.map((bin, index) =>
      [bin.x0, bin.x1].concat(
        this.state.datasets.map((dataset) => dataset.data[index])
      )
    );

    const filename = CSVExporter.formatFilename(
      this.props.widgetTitle || "Signal Statistics",
      this.props.startTime,
      this.props.endTime
    );

    CSVExporter.export(filename, [headers].concat(rows));
  };

  render() {
    return (
      <Widget
        {...this.props}
        dataType="tags"
        maxTags={1}
        widgetTitle="Signal Statistics"
        exportToCSV={this.exportToCSV}
      >
        <div style={{ width: "100%", height: "calc(60%)" }}>
          <Bar
            ref={this.chartRef}
            data={{ labels: [], datasets: this.state.datasets }}
            options={this.state.chartOptions}
          />
        </div>
        <div style={{ width: "100%", height: "calc(40%)" }}>
          <Card style={{ height: "100%", marginTop: 0, marginBottom: 0 }}>
            <CardContent style={{ height: "calc(100% - 24px)", paddingTop: 0 }}>
              <div
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  height: "100%",
                  width: "35%",
                  display: "inline-block",
                  paddingRight: "5%",
                }}
              >
                <AutoSizer>
                  {({ width, height }) => {
                    const dataset = this.state.datasets[0];

                    const tableData =
                      (!!dataset && [
                        ["Count", dataset.count],
                        ["Minimum", dataset.min],
                        ["Maximum", dataset.max],
                        ["Mean", dataset.mean],
                        ["Median", dataset.median],
                      ]) ||
                      [];

                    return (
                      <Table
                        ref={this.tableRef}
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={25}
                        rowCount={tableData.length}
                        rowGetter={({ index }) => tableData[index] || []}
                        rowStyle={_rowStyle}
                      >
                        <Column
                          key="data"
                          label="Metric"
                          dataKey="0"
                          width={75}
                          flexGrow={0}
                        />
                        <Column
                          key="value"
                          label="Value"
                          dataKey="1"
                          width={75}
                          flexGrow={1}
                          cellRenderer={cellRenderer}
                        />
                      </Table>
                    );
                  }}
                </AutoSizer>
              </div>
              <div
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  height: "100%",
                  width: "55%",
                  display: "inline-block",
                  paddingLeft: "5%",
                }}
              >
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <Table
                        ref={this.peakTableRef}
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={25}
                        rowCount={
                          !!this.state.datasets &&
                          !!this.state.datasets[0] &&
                          this.state.datasets[0].peaks
                            ? this.state.datasets[0].peaks.length
                            : 0
                        }
                        rowGetter={({ index }) =>
                          this.state.datasets[0].peaks[index] || []
                        }
                        rowStyle={_rowStyle}
                      >
                        <Column
                          key="range"
                          label="Peak Range"
                          dataKey="0"
                          width={150}
                          flexGrow={0}
                        />
                        <Column
                          key="count"
                          label="Count"
                          dataKey="1"
                          width={75}
                          flexGrow={1}
                        />
                        <Column
                          key="count"
                          label="%"
                          dataKey="1"
                          width={75}
                          flexGrow={1}
                          cellRenderer={({ cellData, rowIndex }) => {
                            return (
                              roundNumber(
                                (100 * cellData) / this.state.datasets[0].count
                              ) + "%"
                            );
                          }}
                        />
                      </Table>
                    );
                  }}
                </AutoSizer>
              </div>
            </CardContent>
          </Card>
        </div>
      </Widget>
    );
  }
}

export default AnalyticsWidget;
