import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from "@material-ui/core/Collapse";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";

import last from "lodash/last";

const DEFAULT_DASHBOARD_TITLE = "UNNAMED DASHBOARD";
const DEFAULT_DASHBOARD_PATH_LABEL = "UNNAMED";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "0.875rem",
  },
  ctext: {
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  cancel: {
    color: "red",
  },
}));

const DashboardsNestedList = ({ node, removeDialog }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const toggle = () => setOpen(!open);

  return (
    <>
      <ListItem divider button onClick={toggle}>
        <ListItemText
          primary={node.label}
          classes={{
            primary: classes.ctext,
          }}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" className={classes.nested}>
        <DashboardsList nodes={node.children} removeDialog={removeDialog} />
      </Collapse>
    </>
  );
};

const DashboardsList = ({ nodes, removeDialog }) => {
  const classes = useStyles();
  return (
    <List>
      {Object.values(nodes)
        .sort((a, b) => (a.label < b.label ? -1 : 1))
        .map((cnode) => {
          if (cnode.type === "inner") {
            return (
              <DashboardsNestedList
                key={`$row-${cnode.label}`}
                node={cnode}
                removeDialog={removeDialog}
              />
            );
          } else {
            const dashboard = cnode.dashboard;
            return (
              <ListItem
                key={`$row-${cnode.label}`}
                divider
                button={true}
                component={Link}
                onClick={(event) => {
                  removeDialog();
                }}
                to={"/dashboard/" + dashboard.guuid}
              >
                {cnode.shared && (
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={cnode.label}
                  classes={{
                    primary: classes.text,
                  }}
                />
              </ListItem>
            );
          }
        })}
    </List>
  );
};

const ManageDashboardsDialog = (props) => {
  const classes = useStyles();

  // avoid overhead of render if not going to be visible
  if (!props.open) {
    return null;
  }

  const dashboards = Object.values(props.dashboards);
  const user = props.user;
  const dashboardHierarchy = {};
  dashboards.forEach((dashboard) => {
    let nodes = dashboardHierarchy;
    // convert to all uppercase for case-insensitivity and display
    const title = (dashboard.title || "").toUpperCase();
    const path = title.split(".");
    path.slice(0, -1).forEach((el) => {
      // trim leading and trailing whitespace, and replace all
      // remaining multiple whitespace with single space
      const label =
        el.trim().replace(/\s+/g, " ") || DEFAULT_DASHBOARD_PATH_LABEL;
      const key = `i-${label}`;
      let next = nodes[key];
      if (!next) {
        next = nodes[key] = { label, type: "inner", children: {} };
      }
      nodes = next.children;
    });
    nodes[`l-${dashboard.guuid}`] = {
      label: last(path) || DEFAULT_DASHBOARD_TITLE,
      type: "leaf",
      shared: user.userid !== dashboard.g_owner,
      dashboard,
    };
  });

  return (
    <Dialog fullWidth={true} open={props.open}>
      <DialogTitle>
        Select Dashboard
        {props.removeDialog && (
          <IconButton onClick={props.removeDialog} className={classes.close}>
            <ClearIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <DashboardsList
          nodes={dashboardHierarchy}
          removeDialog={props.removeDialog}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            props.addDashboard();
            props.removeDialog();
          }}
        >
          Add a new dashboard
        </Button>
        {props.removeDialog && (
          <Button onClick={props.removeDialog} style={{ color: "red" }}>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManageDashboardsDialog;
