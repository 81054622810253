import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import { Menu, MenuItem, ListItemText, ListItemIcon } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import DescriptionIcon from "@material-ui/icons/Description";
import { isIOS } from "react-device-detect";

class WidgetOptionsMenu extends React.Component {
  exportToCSV = () => {
    this.props.handleCSVExport();
    this.props.handleOptionsMenuClose();
  };

  render = () => (
    <Menu
      id="widget-options-menu"
      anchorEl={this.props.anchorEl}
      open={Boolean(this.props.anchorEl)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={this.props.handleOptionsMenuClose}
      transitionDuration={{ enter: 0, exit: 0 }}
    >
      <MenuItem onClick={this.props.handleWidgetDeleteConfirmDialog}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText inset primary="Delete this Widget" />
      </MenuItem>
      <MenuItem onClick={this.props.editWidgetTitle}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText
          inset
          primary={(this.props.hasTitle ? "Rename" : "Name") + " this Widget"}
        />
      </MenuItem>
      {!isIOS && this.props.handleCSVExport && (
        <MenuItem onClick={this.exportToCSV}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText inset primary="Export to CSV" />
        </MenuItem>
      )}
    </Menu>
  );
}

export default WidgetOptionsMenu;
