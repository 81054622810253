// GENERATING CODE VERIFIER
function dec2hex(dec) {
  return ("0" + dec.toString(16)).substr(-2);
}

function generateCodeVerifier(len) {
  var array = new Uint32Array(len / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}


async function generateCodeChallengeFromVerifier(v) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}
//
export async function paramListTemplateFill(template, srcDict) {
    const ret = {};
    const e = Object.keys(template)
    for(var i=0; i< e.length; i++) {
        const k = e[i]
        const v = template[k];
        if(v.startsWith("s:")) {
            ret[k] = v.substr(2)
        } else if(v.startsWith("p:")) {
            ret[k] = srcDict[v.substr(2)]
        } else if(v.startsWith("r:")) {
            ret[k] = generateCodeVerifier(parseInt(v.substr(2)))
        } else if(v === "x:pkce_code") {
            const code_verifier = generateCodeVerifier(56);
            const code_challenge = await generateCodeChallengeFromVerifier(code_verifier)
            ret[k] = code_challenge;
            srcDict[k+'_verifier'] = code_verifier;
        }
    }
    return ret;
};

export async function startSSOLogin(authMethod, username) {
    if(authMethod.redirect_host) {
        authMethod.username = username;
        authMethod.redirect_date = new Date();
        authMethod.redirect_coderesult = window.location.href.split('?')[0].split("#")[0]
        paramListTemplateFill(authMethod.redirect_params, authMethod).then( (p) => {
            const url = "https://" + authMethod.redirect_host + (authMethod?.tenant_path || '') + authMethod.redirect_path + "?" + Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
            //stash before redirect
            //todo: could minimize what is in storage (but would then need to refetch method after redirect):
            // methodname, username, code_verifier 
            // could even just send this stuff in state parameter... and not have to have localstorage (but probably dangerous to send
            // code verifier in state)
            window.localStorage.setItem('isxlogin',JSON.stringify(authMethod));
            window.location.href = url;
        });
        return "your browser should redirect soon....";
    }
    return "SSO Login failed.  Please reload this page";
}

export async function continueLogin(data, errorFunc) {
    //idea: use state to encrypt localstorage "cookie"
    const authMethod = JSON.parse(window.localStorage.getItem('isxlogin'))
    //todo: if it was stored too long ago - just delete it an ignore
    const token_host = authMethod?.token_host || authMethod?.redirect_host;
    if(authMethod?.token_path) {
        authMethod.code = data.code;
        //todo: decide whether to validate state match (means we needed to save it: also todo)
        //remove storage to avoid reloads causing multiple token fetches -- hopefully first one works
        window.localStorage.removeItem('isxlogin');
        const headers = await paramListTemplateFill(authMethod.token_headers || {}, authMethod);
        const method = authMethod.token_method || "POST";
        let body = await paramListTemplateFill(authMethod.token_data || {}, authMethod);
        if(headers['content-type'] === 'application/json') {
            body = JSON.stringify(body)
        } else { //default to urlencoded
            body = Object.entries(body).map((kv) => (encodeURIComponent(kv[0]) + '=' + encodeURIComponent(kv[1]))).join('&');
        }
        //grab the token
        const response = await fetch("https://" + token_host + (authMethod?.tenant_path || '') + authMethod.token_path, { method, headers, body });
        const token = await response.json();
        token.authmethod = authMethod
        return token;
    }
}
