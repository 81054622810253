import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// import { FormControl, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectInput: {
    backgroundColor: "transparent",
  },
});

class CountOption extends React.Component {
  render = () => {
    //const options = this.props.options;
    const count = this.props.options.count;
    return (
      <FormControl
        fullWidth={true}
        style={{
          flexDirection: "column",
          margin: 24,
          width: "calc(100% - 48px)",
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: 14, fontStyle: "italic" }}
            component="legend"
          >
            Count Type
          </FormLabel>
          <RadioGroup
            aria-label="Count Type"
            name="count-type"
            value={count.type}
            row={true}
            onChange={(event) => {
              const value = event.target.value;
              let newOptions = {
                ...count,
                type: value,
              };
              if (value === "diff") {
                if ("condition" in newOptions) {
                  newOptions.condition = "any";
                }
                if ("conditionValue" in newOptions) {
                  delete newOptions["conditionValue"];
                }
              }

              this.props.setOptions({
                count: newOptions,
              });
            }}
          >
            <FormControlLabel
              value="samples"
              control={<Radio />}
              label="Sample Count"
            />
            <FormControlLabel value="sum" control={<Radio />} label="Sum" />
            <FormControlLabel
              value="diff"
              control={<Radio />}
              label="Cumulative Sum"
            />
          </RadioGroup>
          {count.type !== "diff" && (
            <FormControl fullWidth={true} style={{ flexDirection: "row" }}>
              <TextField
                id="select-match-condition"
                select
                label="Match If Value"
                value={count.condition}
                onChange={(event) => {
                  const value = event.target.value;
                  let newOptions = {
                    ...count,
                    condition: value,
                  };
                  if (value === "any" && "conditionValue" in newOptions) {
                    delete newOptions["conditionValue"];
                  }
                  /*if (val === "seconds" && options["interval.value"] % 10 > 0) {
                  update["interval.value"] = "10";
                }*/
                  this.props.setOptions({ count: newOptions });
                }}
                margin="normal"
                variant="filled"
                style={{ margin: 6, width: "50%" }}
                SelectProps={{
                  className: this.props.classes.selectInput,
                }}
              >
                <MenuItem key="any" value="any">
                  Is Any Value
                </MenuItem>
                <MenuItem key="equals" value="equals">
                  Equals
                </MenuItem>
                <MenuItem key="notequals" value="notequals">
                  Not Equals
                </MenuItem>
                <MenuItem key="lessthan" value="lessthan">
                  Less Than
                </MenuItem>
                <MenuItem key="greaterthan" value="greaterthan">
                  Greater Than
                </MenuItem>
              </TextField>
              {count.condition !== "any" && (
                <TextField
                  id="standard-number"
                  label="Match Value"
                  value={count.conditionValue}
                  onChange={(event) => {
                    const value = event.target.value;
                    let newOptions = {
                      ...count,
                      conditionValue: value,
                    };
                    /*if (val === "seconds" && options["interval.value"] % 10 > 0) {
                  update["interval.value"] = "10";
                }*/
                    this.props.setOptions({ count: newOptions });
                  }}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  style={{ margin: 6, marginTop: 14, width: "50%" }}
                />
              )}
            </FormControl>
          )}
        </FormControl>
      </FormControl>
    );
  };
}

export default withStyles(styles)(CountOption);
