import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { default as _last } from "lodash/last";
import { default as _defaults } from "lodash/defaults";

import { updateStreams, clearStreams, removeStreams } from "store/operations";
import { RangeQueriesService } from "services/RangeQueryService";
import { TimeframeService } from "services/TimeframeService";

const uuidv4 = require("uuid/v4");

const TagsSubscriptionsContext = React.createContext({});

export default TagsSubscriptionsContext;

const REFRESH_CHECK_INTERVAL = 5000;
const GROUPING_DELTA = 3600000; // one hour in milliseconds

const createTagKeyFromStackAndAttr = (guuid, attr) => `${guuid}|${attr}`;

export const TagsSubscriptionsProvider = ({ children }) => {
  const stacks2orgs = useSelector((state) => state.isx.stacks2orgs);

  const streams = useRef({
    /**
     * [tagKey]: {
     *    earliestNeededTime: t0 // earliest time of any subscription
     *    latestTime: t1 // time of last datapoint
     *    subscriptions: {subId0, subId1, ...}
     * }
     */
  });

  const subscriptions = useRef({
    /**
     * [subscriptionId]: {
     *    tagKey: tagKey, // stack id + tag attr
     *    startTime: t0
     *    refreshInterval: 10 // in seconds
     *    options: {...} // optional dict
     */
  });

  const schedules = useRef({
    /**
     * [refreshInterval]: {
     *    lastRun: t0,
     *    tags: {tagKey0, tagKey1, ...}
     * }
     */
  });

  // interval for ongoing scheduled tag fetches
  const interval = useRef();

  // range queries outstanding by tag key
  const pendingData = useRef({});

  // throttle rate of requests when needed, keyed by tag
  const lastTagQueries = useRef({});

  // maximum default time window for range query
  const defaultMaxWindowInSeconds = useRef(TimeframeService.MAX_EVENTS_WINDOW);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(stacks2orgs).length === 0) {
      return;
    }

    const resolveRangeQueries = async (promises) => {
      const allResults = await Promise.all(promises);

      // map incoming data streams by key to merge with existing data in state
      let ustreams = {};
      allResults.forEach((result) => {
        result.forEach((entry) => {
          const { guuid, attr, data: timeseries } = entry;
          const key = createTagKeyFromStackAndAttr(guuid, attr);
          if (key in pendingData.current) {
            // if not in pending data, presume that this is stale data
            pendingData.current[key] = false;
            if (streams.current[key]) {
              const data = timeseries?.map(([x, y]) => ({ x, y })) ?? [];
              if (data.length > 0) {
                data.sort((a, b) => a.x - b.x);
                // need to track last time seen
                streams.current[key].latestTime = _last(data)?.x;
              }
              // add to payload to update state
              ustreams[key] = {
                data,
                earliestNeededTime: streams.current[key].earliestNeededTime,
              };
            }
          }
        });
      });

      dispatch(updateStreams(ustreams));

      return allResults;
    };

    // this is for multiple tags across stacks
    const rangeQueries = async (
      tags,
      startTime,
      endTime,
      maxWindowInSeconds
    ) => {
      const now = moment.utc().valueOf();
      const orgids = new Set();
      tags.forEach((tag) => {
        const oids = stacks2orgs[tag.guuid];
        oids && oids.forEach((oid) => orgids.add(oid));
        const key = createTagKeyFromStackAndAttr(tag.guuid, tag.attribute);
        pendingData.current[key] = true;
        lastTagQueries.current[key] = now;
      });

      return RangeQueriesService.query(
        tags,
        startTime,
        endTime,
        maxWindowInSeconds ?? defaultMaxWindowInSeconds.current,
        orgids.size > 0 ? orgids : null
      ).catch((error) => {
        console.error("CAUGHT EXCEPTION", error.toString());
        tags.forEach((tag) => {
          const key = createTagKeyFromStackAndAttr(tag.guuid, tag.attribute);
          pendingData.current[key] = false;
        });
        return [];
      });
    };

    clearInterval(interval.current);
    console.log("Start Tag Subscription Service Interval");
    interval.current = setInterval(() => {
      const now = moment.utc().valueOf();
      const tagsToFetch = new Set();
      Object.entries(schedules.current).forEach(
        ([refreshIntervalRaw, schedule]) => {
          const refreshInterval = parseInt(refreshIntervalRaw) * 1000;
          if (schedule.lastRun + refreshInterval <= now + 100) {
            schedule.lastRun = now; // update scheduled run time
            schedule.tags.forEach((tagKey) => {
              if (!pendingData.current[tagKey]) {
                // if not already a fetch for this tag in progress
                tagsToFetch.add(tagKey);
              }
            });
          }
        }
      );

      // now will have a set of tags to fetch; get effective start time for each
      const tagsToFetchInfo = [...tagsToFetch].map((tagKey) => {
        const tagStream = streams.current[tagKey];
        // find earliest subscription start time
        tagStream.earliestNeededTime = [...tagStream.subscriptions].reduce(
          (acc, subId) => {
            const sub = subscriptions.current[subId];
            return Math.min(sub.startTime, acc);
          },
          Number.MAX_VALUE
        );
        const allMaxWindowsInSeconds = [...tagStream.subscriptions].map(
          (subId) => {
            const sub = subscriptions.current[subId];
            return (
              sub?.options?.maxWindowInSeconds ||
              defaultMaxWindowInSeconds.current
            );
          }
        );
        const eventInterval =
          [...tagStream.subscriptions][0]?.eventInterval ?? 1;
        const maxWindowInSeconds = allMaxWindowsInSeconds
          ? Math.max(...allMaxWindowsInSeconds)
          : defaultMaxWindowInSeconds.current;
        const timestamp = Math.max(
          tagStream.latestTime || tagStream.earliestNeededTime,
          now - maxWindowInSeconds * 1000
        );
        return { tagKey, timestamp, eventInterval };
      });
      // sort tags by effective start time
      tagsToFetchInfo.sort((a, b) => a.timestamp - b.timestamp);

      // group fetches by (similar) start time; also ensure that the size of a
      // single request and payload do not grow too large
      const groups = [];
      let lastGroup = null;
      tagsToFetchInfo.forEach(({ tagKey, timestamp, eventInterval }) => {
        let createNewGroup = true;
        if (lastGroup && timestamp - lastGroup.timestamp < GROUPING_DELTA) {
          const numEvents = Math.round(
            (now - lastGroup.timestamp) / 1000 / eventInterval
          );
          if (
            lastGroup.expectedEvents + numEvents <=
            TimeframeService.MAX_EVENTS_WINDOW
          ) {
            createNewGroup = false;
            lastGroup.tagKeys.push(tagKey);
            lastGroup.expectedEvents += numEvents;
          }
        }
        if (createNewGroup) {
          const numEvents = Math.round(
            (now - timestamp) / 1000 / eventInterval
          );
          lastGroup = {
            timestamp,
            tagKeys: [tagKey],
            expectedEvents: numEvents,
          };
          groups.push(lastGroup);
        }
      });
      const promises = groups.map(({ timestamp, tagKeys }) => {
        const tags = tagKeys.map((key) => {
          const [, guuid, attribute] = key.match(/(.*?)\|(.*)/);
          return { guuid, attribute };
        });
        return rangeQueries(tags, timestamp, now, Number.MAX_VALUE);
      });
      if (promises.length > 0) {
        (async () => {
          await resolveRangeQueries(promises);
        })();
      }
    }, REFRESH_CHECK_INTERVAL);
    return () => {
      console.log("Clear Tag Subscription Service Interval");
      clearInterval(interval.current);
    };
  }, [dispatch, stacks2orgs]);

  const subscribe = (tagKey, startTime, refreshInterval, options = null) => {
    const subId = uuidv4();
    // round to closest integer, to keep number of schedules small
    refreshInterval = Math.round(refreshInterval);
    // (1) add to subscriptions table
    subscriptions.current[subId] = {
      tagKey,
      startTime,
      refreshInterval,
      options,
    };
    // (2) find/create tag in streams, add new subscription
    const tagStream = _defaults(streams.current, {
      [tagKey]: {
        latestTime: null,
        subscriptions: new Set(),
      },
    })[tagKey];
    tagStream.subscriptions.add(subId);
    // (3) create schedule for tag/interval if needed
    const schedule = schedules.current[refreshInterval];
    if (!schedule) {
      // create new schedule entry
      schedules.current[refreshInterval] = {
        tags: new Set([tagKey]),
        lastRun: moment.utc().valueOf(),
      };
    } else {
      schedule.tags.add(tagKey);
    }

    console.log("SUBSCRIBE", {
      subscriptions: subscriptions.current,
      streams: streams.current,
    });

    return subId;
  };

  const updateSubscription = (subId, newStartTime, options = null) => {
    const sub = subscriptions.current[subId];
    if (sub && newStartTime > sub.startTime) {
      sub.startTime = newStartTime;
    }
    if (options) {
      sub.options = options;
    }
  };

  const getSubscription = (subId) => subscriptions.current[subId];

  const unsubscribe = (subId) => {
    // (1) get subscription object
    // ...and remove subscription
    const sub = subscriptions.current[subId];
    delete subscriptions.current[subId];
    if (sub) {
      // (2a) remove subscription from this tag
      const tagStream = streams.current[sub.tagKey];
      console.log("REMOVE SUB", sub.tagKey, tagStream, subscriptions);
      tagStream.subscriptions.delete(subId);
      if (tagStream.subscriptions.size === 0) {
        // (2b) if no more interest in tag, remove
        console.log("REMOVE TAG FROM STREAMS", sub.tagKey);
        delete streams.current[sub.tagKey];
        dispatch(removeStreams([sub.tagKey]));
      }
      const refreshInterval = sub.refreshInterval;
      const schedule = schedules.current[refreshInterval];
      if (schedule) {
        // (3) see if tag needs to be removed for this interval
        if (schedule.tags.has(sub.tagKey)) {
          if (
            ![...tagStream.subscriptions].some((subId) => {
              const osub = subscriptions.current[subId];
              return (
                osub.tagKey === sub.tagKey &&
                osub.refreshInterval === refreshInterval
              );
            })
          ) {
            schedule.tags.delete(sub.tagKey);
            console.log(
              "REMOVE: DELETE TAG FROM SCHEDULE",
              sub.tagKey,
              schedule.tags
            );
            if (schedule.tags.size === 0) {
              console.log("REMOVE SCHEDULE", schedule);
              delete schedules.current[refreshInterval];
            }
          }
        }
      } else {
        // should always be a schedule for this subscription
        console.error(
          "Subscription",
          subId,
          "has no schedule at",
          sub.refreshInterval,
          "seconds"
        );
      }
      console.log("UNSUBSCRIBE", {
        subscriptions: subscriptions.current,
      });
      return true;
    } else {
      return false;
    }
  };

  const reset = useCallback(() => {
    streams.current = {};
    subscriptions.current = {};
    schedules.current = {};
    pendingData.current = {};
    lastTagQueries.current = {};
    defaultMaxWindowInSeconds.current = TimeframeService.MAX_EVENTS_WINDOW;
    dispatch(clearStreams());
  }, [dispatch]);

  const getScheduleRuntime = (scheduleInterval) =>
    schedules.current[Math.round(scheduleInterval)]?.lastRun;

  useEffect(() => {
    console.log("Initialized Tags Subscriptions Service");
    return () => {
      console.log("Destroy Tags Subscriptions Service");
      reset();
    };
  }, [dispatch, reset]);

  return (
    <TagsSubscriptionsContext.Provider
      value={{
        subscribe,
        updateSubscription,
        getSubscription,
        getScheduleRuntime,
        unsubscribe,
        reset,
      }}
    >
      {children}
    </TagsSubscriptionsContext.Provider>
  );
};
